<div class="icon-and-text-container"
     [class.onlyIcon]="!text && !linkText && onlyIcon">
    <div *ngIf="iconName" [title]="iconTitle | titlecase" class="icon-container {{iconClasses}}"
         [ngStyle]="{'min-width': width, 'width': width, 'height': height}">
        <ac-svg [updatable]="false"
                [name]="iconName"
                [fillColor]="fillColor"
        ></ac-svg>
    </div>

    <div *ngIf="text || linkText"
         acLabel
         [labelTitle]="titleOfText || text"
         class="link-and-text-container"
         [class.hasIcon]="iconName">

        <ac-link *ngIf="linkText" (click)="onClickLink()">{{linkText}}</ac-link>
        <span class="{{textClass}}">{{text}}</span>
    </div>
</div>
