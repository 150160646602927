import {Injectable} from '@angular/core';
import {GeneralService} from 'ac-infra';
import {NetworkDashboardEntity} from '../entities-summary.modals';

@Injectable()
export class NetworkDashboardSummary {

    model: {[key: string]: NetworkDashboardEntity} = {
        devices: {
            key: 'devices',
            icon: 'SBC',
            state: '/network/devices/manage',
            isReady: false,
            count: 0,
            type: 'device',
            familyType: ['ACL', 'CLOUDBOND', 'STACK_MANAGER']
        },
        links: {key: 'links',state: '/network/links', isReady: false, count: 0},
        sites: {key: 'sites', state: '/network/sites', isReady: false, count: 0},
        endpoints: {key: 'endpoints', icon: 'endpoint', state: '/network/endpoints', isReady: false, count: 0, preventCountReset: true},
        UMP: {
            key: 'UMP',
            borderColor: '#448CB3',
            state: '/network/devices/manage',
            isReady: false,
            count: 0,
            type: 'device',
            familyType: ['UMP']
        },
        TEAMS: {
            key: 'TEAMS',
            title: 'MS Teams & S4B',
            state: '/network/devices/manage',
            isReady: false,
            count: 0,
            type: 'device',
            familyType: ['LYNC', 'TEAMS']
        },
        voiceAI: {
            key: 'voiceAI',
            borderColor: GeneralService.statusColors.blueStatus,
            title: 'Voice.AI Solution & VAIC',
            state: '/network/devices/manage',
            isReady: false,
            count: 0,
            type: 'device',
            familyType: ['SMARTAP', 'VAIC']
        },
        genericApplication: {
            key: 'genericApplication',
            title: 'Generic Application',
            state: '/network/devices/manage',
            isReady: false,
            count: 0,
            type: 'device',
            familyType: ['GENERIC_APPLICATION']
        }
    };

}
