import {byString} from './by-string';


export class StringUtils {
    static byString = byString;

    static leadToUpper = (srcStr: string) => srcStr.charAt(0).toUpperCase() + srcStr.slice(1).toLowerCase();

    static toTitleCase = (str: string, {onlyLead = true, delimiter = ' '} = {}) => {
        if (!str) {
            return '';
        }

        return onlyLead ?
            StringUtils.leadToUpper(str) :
            str.split(delimiter).map((stringFragment) => StringUtils.leadToUpper(stringFragment)).join(' ');
    };
}
