import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AcSvgComponent} from './ac-svg/ac-svg.component';

import {AcIconComponent} from './ac-icon.component/ac-icon.component';
import {MatIconModule} from '@angular/material/icon';
import {InfraPipesModule} from '../../pipes/infra.pipes.module';


@NgModule({
    imports: [
        CommonModule,
        InfraPipesModule,
        MatIconModule
    ],
    entryComponents: [
        AcSvgComponent,
        AcIconComponent
    ],
    declarations: [
        AcSvgComponent,
        AcIconComponent
    ],
    exports: [
        AcSvgComponent,
        AcIconComponent
    ]
})
export class AcIconsModule {
}
