import {Component, Inject} from '@angular/core';
import {AcDialog, ArrayUtil, DIALOG_CONFIG, DialogConfig, StringUtils} from 'ac-infra';


@AcDialog({
    id: 'entities-summary-config-dialog',
    title: 'entities summary config',
    width: 360,
})
@Component({
    selector: 'entities-summary-config-dialog',
    template: `
        <widget-dialog-container>

            <ac-checkbox-group checkboxGroupId="entities-summary-config"
                               [parentModel]="checkboxModel"
                               [items]="checkboxItems"
                               [withoutHeader]="true"
                               (itemsChanged)="onEntityVisibilityChanged($event)"
            ></ac-checkbox-group>
        </widget-dialog-container>
    `,
})
export class EntitiesSummaryConfigDialogComponent {
    mapEntityToPrefixTitle = {
        zoomQualifyLeads: 'Zoom',
        ocQualifyLeads: 'Teams '
    };

    checkboxModel;
    checkboxItems;

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {
    }

    ngOnInit() {
        const {data, widgetConfig} = this.dialogConfig.dialogData;
        this.checkboxItems = Object.values(data).map((entity: any) => {
            return {name: entity.key, text: this.getConfigTitle(entity)};
        });
        this.checkboxModel = ArrayUtil.arrayToObjTransform(this.checkboxItems, (acc, {name}) => {
            acc[name] = !widgetConfig.data || widgetConfig.data[name];
        });
    }

    onEntityVisibilityChanged({name, value}: any) {
        this.checkboxModel[name] = value;
        this.dialogConfig.dialogData.widgetConfig.data = !this.isDataDefault(this.checkboxModel) ? this.checkboxModel : undefined;
    }

    isDataDefault = (data) => {
        return Object.values(data).reduce((acc, curr) => acc && curr, true);
    };

    getConfigTitle = ({key, title}: any) => {
        const prefix = this.mapEntityToPrefixTitle[key];
        title = title || StringUtils.toTitleCase(key);

        return prefix ? `${prefix} ${title}`: title;
    };

}


