<div class="summaryValues flexParenRow">
    <div *ngFor="let summary of summaries" class="summaryValue flexItem flexColumnParent">
        <ac-form-group [titles]="summary.title" (acClick)="isTitleClickable && innerOnClick()" size="large"
                              [borderMode]="true" [contentAsColumn]="false" [isClickable]="!!(isTitleClickable && onClick)">
            <ac-display-box  *ngFor="let content of summary.content" class="contentBox flexColumnParent"
                             [isNumberToK]="true"
                             (acClick)="innerOnClick(content)"
                             [isClickable]="!!onClick"
                             [color]="content.color"
                             [upperValue]="content.text"
                             [lowerValue]="content.value">
            </ac-display-box>
        </ac-form-group>
    </div>
</div>
