<input #textFieldInput matInput
       (ngModelChange)="textFieldChanged($event)"
       [id]="textFieldId ? textFieldId : null"
       [placeholder]="placeholderText?placeholderText:null"
       [maxlength]="maxLength"
       [disabled]="(readOnly || ngDisabled || (acInputContainerComponent && acInputContainerComponent.isDisabled))?true:null"
       [attr.autocomplete]="autocomplete"
       [ngModel]="acModel"
       [value]="acModel"
       class="ac-textfield"
       type="text" />
<ng-content></ng-content>
