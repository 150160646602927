import {Component, Inject} from '@angular/core';
import {AcDialog, DIALOG_CONFIG, DialogConfig} from 'ac-infra';

export interface EntitySelectionDialogObject{
    items: any;
    entity: any;
    selectLabel: string;
}

@AcDialog({
    submitButtonText: 'Select',
    cancelButtonText: 'Close',
    width: 450,
})
@Component({
    selector: 'entity-selection-dialog',
    templateUrl: './entity-selection-dialog.component.html',
})
export class EntitySelectionDialogComponent {

    entitySelectionObject: EntitySelectionDialogObject = {items: [], entity: {}, selectLabel: ''};

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {}

    ngOnInit() {
        this.entitySelectionObject =  this.dialogConfig.dialogData;
        this.dialogConfig.formSettings = {valid: true, touched: true};
    }
}


