import {Injectable} from '@angular/core';
import {AuthGroup, AuthGroupOption, SessionHelperService} from '../../common/utilities/session-helper.service';
import {AuthorizationService} from '../../common/services/authorization.service';
import {DashboardResetService} from './dashboard-reset.service';
import {DashboardConfiguration} from '../dashboard.modals';
import {DashboardComponent} from '../dashboard.component';
import {AcDialogService, GeneralService} from 'ac-infra';
import {BehaviorSubject} from 'rxjs';
import {cloneDeep, merge} from 'lodash';
import {GridsterItem} from 'angular-gridster2';
import {ServerInfoService} from "../../common/services/server-info.service";

interface SecurityLevelConfig {
    [itemType: string]: Partial<GridsterItem>;
}

type AuthGroupConfig = {
    [key in AuthGroupOption]?: SecurityLevelConfig;
};
const DEFAULT_ONE_LIVE_CONFIG = {'entities-summary': {cols: 8}, 'calls-statistics': {cols: 6}};


@Injectable({
    providedIn: 'root'
})
export class DashboardAuthGroupConfigurationsService {

    OVOC: AuthGroupConfig = {};
    ONE_LIVE: AuthGroupConfig = {};

    dashboardsConfigurationsSubject = new BehaviorSubject<DashboardConfiguration[]>(undefined);
    dashboardsConfigurations$ = this.dashboardsConfigurationsSubject.asObservable();

    get authGroupsConfigurations() {
        return cloneDeep(this.dashboardsConfigurationsSubject.getValue());
    }

    constructor(private dashboardResetService: DashboardResetService,
                private authorizationService: AuthorizationService,
                private generalService: GeneralService,
                private acDialogService: AcDialogService) {
        Object.keys(SessionHelperService.ONE_LIVE_SECURITY_LEVEL_MAP).forEach((oneLiveAuthGroup) => {
            this.ONE_LIVE[oneLiveAuthGroup] = merge(this.ONE_LIVE[oneLiveAuthGroup], DEFAULT_ONE_LIVE_CONFIG);
        });
    }

    initDashboardConfig = () => {
        if (this.authorizationService.validFor(AuthGroup.TENANT_MONITORING_LINKS)) {
            return Promise.resolve();
        }
        return this.updateConfigs();
    };

    updateConfigs = () => {
        return this.dashboardResetService.getDashboardAuthGroupsConfigs().then((dashboards) => {
            this.dashboardsConfigurationsSubject.next(dashboards);
        });
    };

    getConfig = (operatorType, securityLevel) => {
        return this.authGroupsConfigurations?.find((authGroupConfig) => {
            return (authGroupConfig.securityLevel === securityLevel) &&
                (authGroupConfig.operatorType === operatorType) &&
                (authGroupConfig.ovocMode === this.dashboardResetService.getOvocMode());
        });
    };

    getConfigByAuthGroup = (targetAuthGroup: string) => {
        const [operatorType, securityLevel] = this.unpackAuthGroup(targetAuthGroup);
        return this.getConfig(operatorType, securityLevel);
    };

    unpackAuthGroup = (targetAuthGroup: string) => {
        const [operatorType, ...securityLevelSegments] = targetAuthGroup.split('_');
        return [operatorType, securityLevelSegments.join('_')];
    };

    editAuthGroupConfiguration(selection: DashboardConfiguration) {
        const serverConfigState = this.getConfig(selection.operatorType, selection.securityLevel);

        this.acDialogService.open(DashboardComponent, {
            title: `Dashboard Configuration (${selection.operatorType} ${selection.securityLevel})`,
            dialogData: {authGroup: selection.id, ...(serverConfigState || selection)},
            onSubmit: async ({operatorType, securityLevel, dashboardItems}) => {
                const sameAsDefaultItems = !dashboardItems;

                if (!serverConfigState && sameAsDefaultItems) {
                    return;
                } else if (!serverConfigState && !sameAsDefaultItems) {
                    await this.dashboardResetService.addDashboardAuthGroupConfig({operatorType, securityLevel, widgets: {dashboardItems}});
                } else if (serverConfigState && sameAsDefaultItems) {
                    await this.dashboardResetService.deleteById({id: serverConfigState.id});
                } else { // Server config exist & items not same as default
                    await this.dashboardResetService.updateDashboardAuthGroupConfigById(serverConfigState.id, {dashboardItems});
                }
                await this.updateConfigs();
            }
        });
    }

    getAuthGroupConfig() {
        return ServerInfoService.serverInfo.oneLiveMode ? this.ONE_LIVE : this.OVOC;
    }
}
