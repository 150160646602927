import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';

import {AcDialogModule} from './ac-dialog/ac-dialog.module';
import {AcTreeModule} from './ac-tree/ac-tree.module';
import {AcTextFieldModule} from './ac-text-field/ac-text-field.module';
import {AcSelectModule} from './ac-select/ac-select.module';
import {AcButtonModule} from './ac-button/ac-button.module';
import {AcMenuModule} from './ac-menu/ac-menu.module';
import {AcActionButtonModule} from './ac-action-button/ac-action-button.module';
import {AcDropdownMenuModule} from './ac-dropdown-menu/ac-dropdown-menu.module';
import {AcTabsModule} from './ac-tabs/ac-tabs.module';
import {AcSearchModule} from './ac-search/ac-search.module';
import {AcNumSpinnerModule} from './ac-num-spinner/ac-num-spinner.module';
import {AcInputContainerModule} from './ac-input-container/ac-input-container.module';
import {AcIconsModule} from './ac-icons/ac-icons.module';
import {AcTextareaModule} from './ac-textarea/ac-textarea.module';
import {AcSliderModule} from './ac-slider/ac-slider.module';
import {AcPasswordModule} from './ac-password/ac-password.module';
import {AcGeoLocationModule} from './ac-geo-location/ac-geo-location.module';
import {AcProgressModule} from './ac-progress/ac-progress.module';
import {AcFormModule} from './ac-form/ac-form.module';
import {AcFormGroupModule} from './ac-form-group/ac-form-group.module';
import {AcFilePickerModule} from './ac-file-picker/ac-file-picker.module';
import {AcNavModule} from './ac-nav/ac-nav.module';
import {AcAllocationBarModule} from './ac-allocation-bar/ac-allocation-bar.module';
import {AcRangeModule} from './ac-range/ac-range.module';
import {AcLineSeparatorModule} from './ac-line-separator/ac-line-separator.module';
import {AcCheckboxModule} from './ac-checkbox/ac-checkbox.module';
import {AcDatePickerModule} from './ac-date-picker/ac-date-picker.module';
import {AcListModule} from './ac-list/ac-list.module';
import {AcTableModule} from './ac-table/ac-table.module';
import {AcDropdownModule} from './ac-dropdown/ac-dropdown.module';
import {AcToastModule} from './ac-toast/ac-toast.module';
import {AcDisplayBoxModule} from './ac-display-box/ac-display-box.module';
import {AcListItemModule} from './ac-list-item/ac-list-item.module';
import {AcRadioButtonsGroupModule} from './ac-radio-buttons-group/ac-radio-buttons-group.module';
import {AcToggleModule} from './ac-toggle/ac-toggle.module';
import {AcLayoutModule} from './ac-layout/ac-layout.module';
import {AcDragAndDropModule} from './ac-drag-and-drop/ac-drag-and-drop.module';
import {AcCheckboxGroupModule} from './ac-checkbox-group/ac-checkbox-group.module';
import {AcChartModule} from './ac-chart/ac-chart.module';
import {AcPaginationModule} from './ac-pagination/ac-pagination.module';
import {AcExpansionPanelModule} from './ac-expansion-panel/ac-expansion-panel.module';
import {AcSingleSelectModule} from './ac-single-select/ac-single-select.module';

const modules = [
    AcDialogModule,
    AcTreeModule,
    AcTextFieldModule,
    AcSelectModule,
    AcSingleSelectModule,
    AcButtonModule,
    AcMenuModule,
    AcActionButtonModule,
    AcDropdownMenuModule,
    AcTabsModule,
    HttpClientModule,
    AcSearchModule,
    AcNumSpinnerModule,
    AcInputContainerModule,
    AcIconsModule,
    AcTextareaModule,
    AcSliderModule,
    AcPasswordModule,
    AcGeoLocationModule,
    AcAllocationBarModule,
    AcProgressModule,
    AcFormModule,
    AcFormGroupModule,
    AcFilePickerModule,
    AcExpansionPanelModule,
    AcNavModule,
    AcRangeModule,
    AcPaginationModule,
    AcDragAndDropModule,
    AcChartModule,
    AcCheckboxGroupModule,
    AcLineSeparatorModule,
    AcCheckboxModule,
    AcDatePickerModule,
    AcListModule,
    AcLayoutModule,
    AcToggleModule,
    AcRadioButtonsGroupModule,
    AcTableModule,
    AcDropdownModule,
    AcToastModule,
    AcListItemModule,
    AcDisplayBoxModule,
];

@NgModule({
    imports: [CommonModule, ...modules],
    exports: [...modules],
})
export class InfraComponentsModule {
}
