import {NgSelectComponent} from '@ng-select/ng-select';
import {isFunction} from 'lodash';

NgSelectComponent.prototype.close = function($event = undefined) {
    if (!this.isOpen || this._manualOpen) {
        return;
    }
    if ($event) {
        $event.preventDefault();
        $event.stopPropagation();
    }
    this.isOpen = false;
    this.closeEvent.emit();
    this._clearSearch();
    this._onTouched();
    this._cd.markForCheck();
};

NgSelectComponent.prototype.open = function() {
    if (this.disabled || this.isDisabled || this.isOpen || this.itemsList.maxItemsSelected || this._manualOpen) {
        return;
    }

    this.isOpen = true;
    this.itemsList.markSelectedOrDefault(this.markFirst);
    this.openEvent.emit();
    if (!this.searchTerm) {
        this.focus();
    }
    this.detectChanges();
    this.dropdownPanel._dropdown.style.visibility = 'hidden';
    setTimeout(() => {
        if (this.dropdownPanel) {
            if (!this.dropdownPanel._updateAppendedDropdownPositionHasBeenOverriden) {
                this.dropdownPanel._updateAppendedDropdownPositionHasBeenOverriden = true;
                this.dropdownPanel._updateAppendedDropdownPosition = _updateAppendedDropdownPosition;
            }
            this.dropdownPanel._parent && this.dropdownPanel?.adjustPosition();
            this.dropdownPanel._dropdown.style.visibility = '';

            setTimeout(() => {
                //* ************************************************
                // on github, looks the same but the problem was different
                // https://user-images.githubusercontent.com/70687140/127499555-d606094f-5a5c-48f4-a8af-4157e0c7b7e3.png
                // reproduce bug:
                // 1. on the table pagination footer (page selection) first have filter to show only 1 item (we drilldown from dashboard 'devices')
                // 2. open the select and see 1 item.
                // 3. close the select and remove filter
                // 4. now table pages should have 10 items~
                // 5. open select dropdown, the items list is not displayed fully
                if (this.dropdownPanel) {
                    this.dropdownPanel._panelService.setDimensions(this.dropdownPanel._panelService.dimensions.itemHeight, this.dropdownPanel._dropdown.offsetHeight);
                    this.dropdownPanel.scrollElementRef.nativeElement.scrollTop = 1;
                    this.dropdownPanel.scrollElementRef.nativeElement.scrollTop = 0;
                    //* **************************************************

                    this.dropdownPanel._parent && this.dropdownPanel?.adjustPosition();
                    if (this.selectedItems && this.selectedItems.length === 1) {
                        this.dropdownPanel._parent && this.dropdownPanel?.adjustPosition();
                        if (this.dropdownPanel) {
                            this.dropdownPanel.scrollTo(this.selectedItems[0]);
                            // this.dropdownPanel.refresh(); doesnt exist after upgrade to 4.0.4 (ng10)
                        }
                    }
                }
            });
        }

    });
};

const _updateAppendedDropdownPosition = function() {
    /** @type {?} */
    const parent = document.querySelector(this.appendTo) || document.body;
    this._dropdown.style.display = 'none';
    /** @type {?} */
    const selectRect = this._select.getBoundingClientRect();
    /** @type {?} */
    const boundingRect = parent.getBoundingClientRect();
    this._dropdown.style.display = '';
    /** @type {?} */
    const offsetTop = selectRect.top - boundingRect.top;
    /** @type {?} */
    const offsetLeft = selectRect.left - boundingRect.left;
    /** @type {?} */
    const openRight = this._select.classList.contains('open-dropdown-right');
    let topDelta = (this._currentPosition === 'bottom') ? selectRect.height : -this._dropdown.clientHeight;
    if (openRight) {
        if (this._currentPosition === 'bottom') {
            topDelta -= selectRect.height;
        } else {
            topDelta += selectRect.height;
        }
    }
    this._dropdown.style.top = offsetTop + topDelta + 'px';
    this._dropdown.style.bottom = 'auto';
    if (openRight) {
        this._dropdown.style.left = selectRect.left + selectRect.width + 'px';
    } else {
        this._dropdown.style.left = offsetLeft + 'px';
    }
    this._dropdown.style.width = selectRect.width + 'px';
    this._dropdown.style.minWidth = selectRect.width + 'px';
    this.refresh(); // Added since when opening dropdown there is an extra space on the bottom, (when user scroll it disappears but with this fix it doesn't appear in the first place)
};

NgSelectComponent.prototype.selectTag = /**
 * @return {?}
 */
function() {
    const _this = this;
    let _a;
    /** @type {?} */
    let tag;
    if (isFunction(this.addTag)) {
        tag = ( /** @type {?} */(this.addTag))(this.searchTerm);
    } else {
        tag = this._primitive ? this.searchTerm : (_a = {}, _a[this.bindLabel] = this.searchTerm, _a);
    }

    let tags = [tag];
    if (this.multiple && this.addTag && tag && isFunction(tag.ReplaceAll)) {
        tag = tag.ReplaceAll(',', ';');
        tags = tag.split(';').map(x=>x.trim());
    }


    /** @type {?} */
    tags.forEach((_tag) => {
        const handleTag = function(item) {
            // return _this._isTypeahead || !_this.isOpen ? _this.itemsList.mapItem(item, null) : _this.itemsList.addItem(item);
            return _this._isTypeahead || _this.itemsList.addItem(item); // This line instead above because of SPLIT ";" "," " "
        };
        if (_tag instanceof Promise) {
            _tag.then(function(item) {
                return _this.select(handleTag(item));
            }).catch(function() {
            });
        } else if (_tag) {
            this.select(handleTag(_tag));
            this.items.push(_tag);
        }
    });

    this._clearSearch();
};
const KeyCode = {
    Tab: 9,
    Enter: 13,
    Esc: 27,
    Space: 32,
    ArrowUp: 38,
    ArrowDown: 40,
    Backspace: 8,
};
KeyCode[KeyCode.Tab] = 'Tab';
KeyCode[KeyCode.Enter] = 'Enter';
KeyCode[KeyCode.Esc] = 'Esc';
KeyCode[KeyCode.Space] = 'Space';
KeyCode[KeyCode.ArrowUp] = 'ArrowUp';
KeyCode[KeyCode.ArrowDown] = 'ArrowDown';
KeyCode[KeyCode.Backspace] = 'Backspace';

NgSelectComponent.prototype.handleKeyDown = /**
 * @param {?} $event
 * @return {?}
 */
function($event) {
    if (KeyCode[$event.which]) {
        switch ($event.which) {
            case KeyCode.ArrowDown:
                this._handleArrowDown($event);
                break;
            case KeyCode.ArrowUp:
                this._handleArrowUp($event);
                break;
            case KeyCode.Space:
                this._handleSpace($event);
                break;
            case KeyCode.Enter:
                this._handleEnter($event);
                break;
            case KeyCode.Tab:
                this._handleTab($event);
                break;
            case KeyCode.Esc:
                this.close($event);
                break;
            case KeyCode.Backspace:
                this._handleBackspace();
                break;
        }
    } else if ($event.key && $event.key.length === 1) {
        this._keyPress$.next($event.key.toLocaleLowerCase());
    }
    this.dropdownPanel && this.dropdownPanel._parent && this.dropdownPanel?.adjustPosition();
    this.detectChanges();
};
