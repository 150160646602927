<div id="error-dialog-errors">
    <ul id="error-dialog-error-list">
        <li *ngFor="let error of errors" class="error-list-item">
            <span class="error-list-item-row">
                <span class="error-list-item-error-expand">
                    <i (click)="error.showStack = true" *ngIf="!error.showStack" class="fa fa-chevron-down"></i>
                    <i (click)="error.showStack = false" *ngIf="error.showStack" class="fa fa-chevron-up"></i>
                </span>
                <span class="error-list-item-error-timestamp">{{error.timestamp | dateTimeFormatter}}</span>
                <span class="error-list-item-error-message">{{error.message}}</span>
                <div *ngIf="error.showStack" ac-scrollbar class="error-list-item-error-stack">
                    <span class="error-list-item-error-stack-content">{{error[0]?error[error[0].originalStack]:error.stack}}</span>
                </div>
            </span>
        </li>
    </ul>
</div>

