import {Injectable, isDevMode} from '@angular/core';
import * as StackTrace from 'stacktrace-js';
import {isNil} from 'lodash';
import {stringify} from 'flatted/esm';
import {AcDialogService, AcToastService, GeneralService, PromiseService, SessionStorageService, Toast} from 'ac-infra';
import {SessionService} from '../session.service';


@Injectable({
    providedIn: 'root'
})
export class ExceptionService {

    exceptionObj: any;
    ignoredErrors: Array<string> = [
        'transition superseded',
        'transition prevented',
        'transition aborted',
        'transition failed',
        '\'style\'',
        '\'matches'
    ];
    ignoredStackErrors: Array<string> = [];

    constructor(private acDialogService: AcDialogService,
                private generalService: GeneralService,
                private sessionService: SessionService,
                private acToastService: AcToastService) {
    }

    reportException = ({exceptionObj = undefined, type = undefined, message = undefined, data = {}}) => {
        const exception = exceptionObj || this.createServerException(type, message, data);

        if (['AuthenticationException', 'ServerDownException', 'WSServerException'].includes(exception.type)) {
            this.endSessionHandler(exception);
        } else if (exception.type === 'UncaughtException') {
            this.errorDialogExceptionHandler(exception);
        } else {
            return this.failedActionExceptionHandler(exception);// 'FailedActionException', 'AuthorizationException'
        }
    };

    public checkError = (exceptionObj) => {
        this.exceptionObj = exceptionObj;
        for (let i = 0; i < this.ignoredErrors.length; i++) {
            if (this.exceptionObj && this.exceptionObj.message && this.exceptionObj.message.indexOf(this.ignoredErrors[i]) >= 0) {
                return;
            }
        }

        for (let i = 0; i < this.ignoredStackErrors.length; i++) {
            if (this.exceptionObj && this.exceptionObj.stack && this.exceptionObj.stack.indexOf(this.ignoredStackErrors[i]) >= 0) {
                return;
            }
        }

        if (isNil(this.exceptionObj.message)) {
            this.exceptionObj = undefined;
        }

        this.createCodeException('UncaughtException', (exceptionObj && exceptionObj.message) || exceptionObj, null, exceptionObj,
            exceptionObj && exceptionObj.stack
        ).then((resolvedExceptionObj) => {
            this.reportException({exceptionObj: resolvedExceptionObj});
        });
    };

    createCodeException = (type, message, data, error, originalStack) => {
        const deferred = PromiseService.defer();

        const exceptionObj: any = {type, message, data, error, originalStack};

        const onSuccessTrace = (stackTrace) => {
            if (stackTrace.error) {
                delete stackTrace.error.ngErrorLogger;
                delete stackTrace.error.ngDebugContext;
            }

            if (Array.isArray(stackTrace)) {
                exceptionObj.stack = stackTrace.map((sf) => sf.toString()).join('\n');
            } else {
                exceptionObj.stack = stringify(stackTrace);
            }

            deferred.resolve(exceptionObj);
        };

        if (SessionStorageService.getData(GeneralService.DEBUG_MODE_SESSION_KEY)) {
            if (!error) {
                StackTrace.get().then(onSuccessTrace).catch(deferred.resolve(exceptionObj));
            } else {
                setTimeout(() => {
                    StackTrace.fromError(error).then(onSuccessTrace).catch(deferred.resolve(exceptionObj));
                }, 0);
            }
        } else {
            onSuccessTrace(exceptionObj);
        }

        return deferred.promise;
    };

    private createServerException = (type, message, data = {}) => {
        const exception: any = {type, message, data};

        try {
            throw new Error();
        } catch (e) {
            exception.error = e;
        }

        return exception;
    };

    private endSessionHandler = (exception) => {
        this.generalService.loginErrorMessage = exception.message;
        this.sessionService.endSession();
    };

    private failedActionExceptionHandler = (exception) => {
        let errorMessage = exception.message;
        if (exception.data && exception.data.description && errorMessage !== exception.data.description) {
            errorMessage += '\r\n' + exception.data.description;
        }
        return this.acDialogService.fail(errorMessage);
    };

    private errorDialogExceptionHandler = (exception) => {
        if (this.isAngularSelectNullValueException(exception) && !this.excludedExceptions(exception)) {
            if (isDevMode()) {
                console.error(exception);
                this.acToastService.show(new Toast(Date.now(), exception.error.message));
                debugger; // Do not remove!! This is for finding exceptions without Chrome Exceptions
            }
            if (this.generalService.debugMode) {
                this.acDialogService.error(exception);
            }
        }
    };

    private isAngularSelectNullValueException = (exception) => exception && exception.error && exception.error.message && exception.error.stack;

    private excludedExceptions(exception) {
        return exception.error.message.includes('ExpressionChangedAfterItHasBeenCheckedError') ||
            exception.error.message.includes('Set map center and zoom first') ||
            exception.error.message.includes('getAllChildMarkers') ||
            exception.error.message.startsWith('Uncaught (in promise)') ||
            exception.message.includes('_leaflet_pos');
    }
}
