import {Injectable} from '@angular/core';

import * as _ from 'lodash';

import {AlarmsFilterFormatterService} from '../../alarms/services/alarms-filter-formatter.service';
import {UsersFilterFormatterService} from '../../users/services/users-filter-formatter.service';
import {CallsFilterFormatterService} from '../../calls/services/calls-filter-formatter.service';
import {NetworkFilterFormatterService} from '../../network/services/network-filter-formatter.service';


@Injectable({providedIn: 'root'})
export class DynamicTabsFilterFormatterService {

    apiMap: any;

    constructor(
        private networkFilterFormatterService: NetworkFilterFormatterService,
        private alarmsFilterFormatterService: AlarmsFilterFormatterService,
        private usersFilterFormatterService: UsersFilterFormatterService,
        private callsFilterFormatterService: CallsFilterFormatterService) {

        this.apiMap = {
            NetworkFilterFormatter: this.networkFilterFormatterService,
            AlarmsFilterFormatter: this.alarmsFilterFormatterService,
            UsersFilterFormatter: this.usersFilterFormatterService,
            CallsFilterFormatter: this.callsFilterFormatterService
        };
    }

    buildFilterForDynamicTab = (filterObject, filterMethodsMap) => {
        const resultObject = {};

        filterMethodsMap = Array.isArray(filterMethodsMap) ? filterMethodsMap : [];
        filterMethodsMap.forEach((methodMapItem) => {

            const methodAPI = this.apiMap[methodMapItem.filterApi];

            resultObject[methodMapItem.fieldName] = (methodAPI && _.isFunction(methodAPI.format)) ?
                methodAPI.format(filterObject, methodMapItem.filterMethod) : null;
        });

        return resultObject;
    };
}
