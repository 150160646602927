<div [class.flexRowParent]="limitTableWidth" class="statistics-summary-container">

    <div *ngIf="!hideStatisticTable" [class.limit-table-width]="limitTableWidth"
         class="statistics-values-container flexItem">
        <div class="flexRowParent">
            <summary-values [summaries]="statisticsSummary"></summary-values>
        </div>
    </div>

    <div [class.single-line-mode]="isSingleLine" [class.flexRowParent]="isSingleLine"
         [ngStyle]="isSingleLine && {'height': (height) + 'px'}"
         class="pie-chart-container flexItemBiggest">
        <ac-chart [chartOptions]="successFailedChart.options"
                  [chartTitle]="successFailedChart.title"
                  [height]="height"
                  [largeTitle]="largeTitle"
                  [class.flexItemRowEquals]="isSingleLine">
        </ac-chart>

        <ac-chart [chartOptions]="qualityDistributionChart.options"
                  [chartTitle]="qualityDistributionChart.title"
                  [largeTitle]="largeTitle"
                  [height]="height"
                  [class.flexItemRowEquals]="isSingleLine">
        </ac-chart>
    </div>
</div>
