<div class="firstNav">
    <div class="logo">
        <ng-content select="[logo]"></ng-content>
    </div>
    <div class="first">
        <ng-content select="[first]"></ng-content>
    </div>

    <ac-nav-dynamic class="dynamic">
    </ac-nav-dynamic>

    <div class="menu">
        <ng-content select="[menu]"></ng-content>
    </div>
</div>

<div class="secondary">
    <ng-content select="[secondary]"></ng-content>
</div>
