import {Component, Inject, Input} from '@angular/core';
import {AcDialog, AcDialogComponent, DIALOG_CONFIG, DialogConfig} from 'ac-infra';
import {LogoSelectionService} from '../../common/components/logo-selection/logo-selection.service';
import * as _ from 'lodash';

@AcDialog({
    id: 'choosing-customer-dialog-id',
    cancelButtonText: 'Logout',
    noSubmitButton: true,
    width: 500
})
@Component({
    selector: 'choosing-customer-dialog',
    templateUrl: './choosing-customer-dialog.component.html',
    styleUrls: ['./choosing-customer-dialog.component.less'],
})
export class ChoosingCustomerDialogComponent {

    @Input() showTree = true;

    entities;
    logos;
    searchedEntities;

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig,
                private acDialogComponent: AcDialogComponent,
                private logoSelectionService: LogoSelectionService) {}

    ngOnInit() {
        this.entities = this.dialogConfig.dialogData.entities;
        this.dialogConfig.title = this.dialogConfig.dialogData.type === 'customers' ? 'Choose Customer' : 'Choose Channel';

        this.logos = {};

        const fieldName = this.dialogConfig.dialogData.type === 'customers' ? 'customerName' : 'channelName';
        this.entities = _.orderBy(this.entities, [item => item[fieldName].toLowerCase()], ['asc']);

        this.entities.forEach((entity) => {
            this.logoSelectionService.getLogoImage(entity, 'tenantLogoId').then((responseImage) => {
                this.logos[entity.customerId || entity.channelId] = responseImage;
            });
        });
    }

    entitySelectionChanged = (selection) => {
        this.dialogConfig.dialogData.entity = _.cloneDeep(selection);

        this.acDialogComponent.onSubmit();
    };

    searchEvent = (search) => {
        this.searchedEntities = search ? this.entities.filter((entity) => {
            return (entity.customerName || entity.channelName).toLowerCase().includes(search.toLowerCase()) || entity.tenantName.toLowerCase().includes(search.toLowerCase());
        }) : this.entities;
    };
}


