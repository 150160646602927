<div class="example-header">
    <ac-button buttonId="ac-date-picker-previous-year" buttonType="icon-button" class="full-width medium ac-date-nav-btn" (acClick)="previousClicked('year')">
        <ac-svg class="icon position-right" name="double_chevron"></ac-svg>
    </ac-button>

    <ac-button buttonId="ac-date-picker-previous-month" buttonType="icon-button" class="full-width medium ac-date-nav-btn" (acClick)="previousClicked('month')">
        <ac-svg class="icon" name="chevron"></ac-svg>
    </ac-button>

    <span class="example-header-label">{{this.calendar.activeDate | acFunc: periodLabel}}</span>

    <ac-button buttonId="ac-date-picker-next-month" buttonType="icon-button" class="full-width medium ac-date-nav-btn" (acClick)="nextClicked('month')">
        <ac-svg class="icon" name="chevron" [mirrorHor]="true"></ac-svg>
    </ac-button>

    <ac-button buttonId="ac-date-picker-next-year" buttonType="icon-button" class="full-width medium ac-date-nav-btn" (acClick)="nextClicked('year')">
        <ac-svg class="icon position-right" name="double_chevron" [mirrorHor]="true"></ac-svg>
    </ac-button>
</div>
