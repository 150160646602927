import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {RefreshTableProperties} from '../components/ac-table/ac-table.interface';

@Injectable({
    providedIn: 'root'
})
export class CommonNotifiersService {

    static isEntitiesAreReady = false;

    private static httpErrorSubject = new Subject();
    static httpError$ = CommonNotifiersService.httpErrorSubject.asObservable();

    static updateHttpError = (data: any) => CommonNotifiersService.httpErrorSubject.next(data);

    private static finishedDataAndFilteredUpdate: Subject<RefreshTableProperties> = new Subject();
    static finishedDataAndFilteredUpdate$ = CommonNotifiersService.finishedDataAndFilteredUpdate.asObservable();

    private static entitiesAreReadySubject: Subject<any> = new Subject<any>();
    static entitiesAreReady$ = CommonNotifiersService.entitiesAreReadySubject.asObservable();

    static updateFinishedDataAndFiltered = (tableProperties: RefreshTableProperties = {}) => {// meaning at AcTable
        CommonNotifiersService.finishedDataAndFilteredUpdate.next({...tableProperties, entityName: 'finishedDataAndFilteredUpdate'});
    };

    static entitiesAreReady = () => {
        CommonNotifiersService.isEntitiesAreReady = true;
        CommonNotifiersService.entitiesAreReadySubject.next({});
    };
}
