import {Injectable} from '@angular/core';
import {FilterFormatter} from '../../common/filters/filter-formatter';

@Injectable({providedIn: 'root'})
export class QoeFilterFormatter extends FilterFormatter {

    constructor() {
        super({
            setFilter: (resultFilterObject, filterObject, callingApi, saveToFile) => {
                this.setFilter(resultFilterObject, filterObject, callingApi);
            },
            setFilterForDynamicTab: (resultFilterObject, filterObject, callingApi) => undefined,
        });
    }

    setFilter = (resultFilterObject, filterObject, callingApi) => {
        const analyticsFilter = callingApi === 'analytics';

        if (callingApi !== 'teamsDevice' && !analyticsFilter) {
            resultFilterObject.topology = callingApi === 'userLocation' ? 'location' : callingApi;
        }
        this.addTopologyOrCustomerGroupIdsFilter(filterObject, resultFilterObject);
        if (callingApi === 'userLocation') {
            this.addArrayFilterParameter(filterObject, 'userLocations', resultFilterObject, 'locations', 'adFilters');
        }
        this.addTopologyFilter(filterObject, resultFilterObject, {
            excludeTenant: analyticsFilter,
            entities: analyticsFilter ? ['device'] : ['device', 'link', 'site'],
            filterName: analyticsFilter && 'deviceId',
        });
        this.addTimeAndDateFilter(filterObject, resultFilterObject, analyticsFilter ? 'callEndTime' : 'timestamp');
    };


    private addTopologyFilter = (filterObject, resultFilterObject, {excludeTenant = false, entities, filterName}: {excludeTenant: boolean, entities: string[], filterName: string}) => {
        const statisticsTopologyFilter = filterObject && filterObject.Topology;

        if (statisticsTopologyFilter) {
            !excludeTenant && this.assignToFieldIfArray({resultFilterObject, field: 'tenantId', array: statisticsTopologyFilter.tenant});
            this.assignToFieldIfArray({resultFilterObject, field: 'regionId', array: statisticsTopologyFilter.region});

            const currentTopology = resultFilterObject.topology?.toLowerCase();

            entities.forEach((entity) => {
                filterName = filterName || 'entityId';
                if (currentTopology !== 'site' && entity === 'site') {
                    filterName = 'siteId';
                }

                if (statisticsTopologyFilter[entity] && statisticsTopologyFilter[entity].length > 0) {
                    resultFilterObject[filterName] = resultFilterObject[filterName] || [];
                    resultFilterObject[filterName].push.apply(resultFilterObject[filterName], statisticsTopologyFilter[entity].map((entity) => entity.id));
                }
            });
        }
    };
}
