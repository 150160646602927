<div (click)="entity.state && goTo()" class="entity-container" [class.entity-click-container]="entity.state" [class.notConnected]="entity.icon?.includes('ERROR')">
    <div class="entityContainer" [title]="entity.icon?.includes('ERROR') ? entity.tooltip : ''">
        <div *ngIf="hasIcon"
             [style.border-color]="entity.borderColor || colors.primary"
             class="entityIcon">
            <ac-svg [class.entityInnerIcon]="!entity.icon?.includes('ERROR')" class="{{entity.key}}Icon"
                    [name]="entity.icon || entity.key"
                    [fillColor]="colors.primary"
            ></ac-svg>
        </div>

        <div class="flexColumnParent entity-details-value-container">
            <div class="entityValue">
                <ng-container *ngIf="!(entity.count | acFunc: isArray)">
                    <div *ngIf="entity.count === -1" class="entityProgress">
                        <ac-progress size="small"></ac-progress>
                    </div>
                    <div *ngIf="entity.count >= 0">
                        {{entity.count | number}}
                    </div>
                </ng-container>

                <div *ngIf="entity.count | acFunc: isArray">
                    <ng-container *ngFor="let counter of entity.count; let last=last">
                        {{counter}} <ng-container *ngIf="!last">/</ng-container>
                    </ng-container>
                </div>
            </div>

            <div class="entityType">{{entity.title || entity.key}}</div>
        </div>

    </div>
</div>
