import {Observable} from 'rxjs';
import {untilDestroyed} from '@ngneat/until-destroy';

export function ACSetTimeout(component: { ngOnDestroy(): void }, callback, ms = 0) {
    new Observable<() => any>((sub) => {
        setTimeout(() => {
            sub.next(callback);
            sub.complete();
        }, ms);
    }).pipe(untilDestroyed(component)).subscribe(cb => cb());
}
