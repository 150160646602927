<div class="table-layout-container flexColumnParent" *ngIf="isReady">

    <div class="metricLabelContainer flexItem flexColumnParent">
        <span class="metricLabel flexItem">Select report metrics and arrange the table columns</span>
        <span class="noteLabel flexItem">Reorder elements in a list using the mouse</span>
    </div>

    <ac-drag-and-drop #dndElement
                      class="metric-dnd-container flexItemBiggest"
                      (listChanged)="listChanged.emit()"
                      (onDropItemOrder)="onDropItemOrder($event)"
                      (sortableOrderArrayChanged)="reportRestService.sortableOrderArray = $event"
                      *ngIf="report"
                      [buttonsDisabled]="report.reportType === reportRestService.reportTypes.TREND || isViewOnly"
                      [isViewOnly]="isViewOnly"
                      [listsObject]="listsObject"
                      [lockedTypes]="lockedTypes"
                      [sortableOrderArray]="reportRestService.sortableOrderArray"
                      [updateColumnSortArray$]="updateColumnSortArray$"
                      lockPropertyName="columnType">
    </ac-drag-and-drop>

    <ac-input-container formFieldName="list.metrics" [displayOnlyError]="true" [sideMargin]="false"
                        class="dnd-input-container flexItem">
        <div class="fakeMetricContent"></div>
    </ac-input-container>


    <div class="table-layout-inputs-container flexItem">

        <ac-checkbox [(acModel)]="topType.enable" labelText="Enable Top Values"
                     [sideMargin]="false"
                     [ngDisabled]="additionalSettings.entityType | acFunc: isTopTypeCheckBoxDisabled :additionalSettings.reportType"
                     (acModelChange)="topTypeCheckboxChanged()"
        ></ac-checkbox>

        <ac-input-container formFieldName="report.topType" label="Number of top records" [sideMargin]="false">
            <ac-single-select [(acModel)]="report.topType"
                              [items]="topTypeList"
                              [ngDisabled]="!topType.enable"
                              class="top-type-select">
            </ac-single-select>
        </ac-input-container>

        <ac-input-container formFieldName="report.itemsPerPage" label="Number Of Rows Per Page" [sideMargin]="false">
            <ac-single-select [(acModel)]="report.itemsPerPage"
                              [items]="itemsPerPageList"
                              class="items-per-page-select">
            </ac-single-select>
        </ac-input-container>
    </div>
</div>
<ac-progress *ngIf="!isReady"></ac-progress>
