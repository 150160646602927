import {Injectable} from '@angular/core';
import {Rest} from '../../common/server-actions/rest';

@Injectable({
    providedIn: 'root'
})
export class SessionRestService extends Rest {
    constructor() {
        super({url: 'security/sessionsInfo'});
    }

}
