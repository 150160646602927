import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GeneralService, SessionStorageService} from 'ac-infra';
import {BehaviorSubject} from 'rxjs';
import {ServerInfoService} from "../../../common/services/server-info.service";

@Injectable({
    providedIn: 'root'
})
export class InfoPanelService {
    visibleHighlights;

    constructor(private generalService: GeneralService,
                private httpClient: HttpClient) {
    }

    getVisibleHighlights = (callback) => {
        this.httpClient.get(location.protocol + '//' + 'ovoc.blob.core.windows.net/default/ovoc.json')
            .subscribe({
                next: (success: any) => {
                    this.setVisibleHighlights(callback, success.dashboard);
                },
                error: (error) => {
                    this.setVisibleHighlights(callback, [
                        {
                            title: 'QoE: End-to-End Calls Correlation',
                            content: 'OVOC is now able to correlate calls which traverse multiple devices (AUDC SBC-SBC calls or TEAMS-SBC calls) into the same call record.',
                            Type: 'QOE',
                            lastUpdated: '1/1/2022',
                            url: 'https://www.audiocodes.com/solutions-products/products/management-products-solutions/one-voice-operations-center',
                            applicationType: 'OVOC'
                        },

                        {
                            title: 'QoE: Calls representation improvements',
                            content: 'The Calls table displays now call media types and each media type severity status. The number of participants and call originator in Teams group calls. Together with caller / callee Network type it provides the high-level view of the call with full QoE summary.',
                            Type: 'QOE',
                            lastUpdated: '1/1/2022',
                            url: 'https://www.audiocodes.com/solutions-products/products/management-products-solutions/one-voice-operations-center',
                            applicationType: 'OVOC'
                        },

                        {
                            title: 'IPV6 Support',
                            content: 'OVOC now can perform network management of AudioCodes MSBR devices in IPv6 network. OVOC supports smooth transition from IPv4 to IPv6 network allowing to provision network interface for each network type.',
                            Type: 'Management',
                            lastUpdated: '21/4/2021',
                            url: 'https://www.audiocodes.com/solutions-products/products/management-products-solutions/one-voice-operations-center',
                            applicationType: 'OVOC'
                        },

                        {
                            title: 'Support AudioCodes MTRfW/RXV100 meeting room solution',
                            content: 'New Meeting Room Peripherals Status Layout.',
                            Type: 'DeviceManager',
                            lastUpdated: '1/1/2022',
                            url: 'https://www.audiocodes.com/media/15872/rxv100-product-suite-solution-guide.pdf',
                            applicationType: 'OVOC'
                        },

                        {
                            title: 'EPOS Headsets Management',
                            content: 'The partnership between EPOS and AudioCodes enables IT administrators to manage EPOS devices directly in the AudioCodes One Voice Operations Center (OVOC) solution for a premium and seamless user experience.',
                            Type: 'DeviceManager',
                            lastUpdated: '1/1/2022',
                            url: 'https://www.eposaudio.com/globalassets/files/enterprise/partnerships/uc-management/audiocodes/audiocodes_epos_solution_paper_en.pdf',
                            applicationType: 'OVOC'
                        }
                    ]);
                },
            });
    }

    get releaseHighlights() {
        return this.visibleHighlights;
    }

    getSavedInfoPanel() {
        const infoPanel = SessionStorageService.getData('infoPanel');
        if (infoPanel?.version === ServerInfoService.serverInfo.appVersion) {
            return infoPanel;
        }
        return {isOpen: true, currentReleaseHighlight: 0};
    }

    saveInfoPanel(infoPanel) {
        SessionStorageService.setData('infoPanel', {version: ServerInfoService.serverInfo.appVersion, ...infoPanel});
    }

    setVisibleHighlights = (callback, highlights) => {
        const infoPanel = this.getSavedInfoPanel();
        const oneLiveMode = ServerInfoService.serverInfo.oneLiveMode;
        this.visibleHighlights = highlights.filter((highlight) => {
            return (highlight.applicationType === 'ALL') ||
                (highlight.applicationType === 'TLC' && oneLiveMode) ||
                (highlight.applicationType === 'OVOC' && !oneLiveMode);
        });

        infoPanel.currentReleaseHighlight = Math.min(infoPanel.currentReleaseHighlight, this.visibleHighlights.length - 1);
        this.saveInfoPanel(infoPanel);
        callback(this.visibleHighlights);
    };
}
