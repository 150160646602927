import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {MetadataService} from '../../metadata/metadata.service';

@Injectable({providedIn: 'root'})
export class PMProfilesService {

    supportedTypes: any;
    LAST_POLLING_STATUS_MAP = {
        FAIL: 'Fail',
        SUCCESS: 'Success',
        UNKNOWN: 'Initializing'
    };

    constructor() {
        this.supportedTypes = MetadataService.getType('SupportedTypes');
    }

    buildProfileDataObject = (topic, groupsArray) => {
        const uniqueGroupDisplayNames = this.generateUniqueKeys(topic.parameters, 'groupDisplayName');
        const uniqueDisplayNames = this.generateUniqueKeys(topic.parameters, 'displayName');
        const uniqueIds = this.generateUniqueKeys(topic.parameters, 'id');

        const emptiedGroupsArray = this.removeRedundantGroups(groupsArray, uniqueIds);

        this.applyChangesOnProvidedGroupsArray(groupsArray, emptiedGroupsArray);
        _.forOwn(uniqueGroupDisplayNames, (groupDisplayName) => {
            let groupArray = this.findGroupArray(groupsArray, groupDisplayName);

            if (!groupArray) {
                groupArray = [];

                _.forOwn(uniqueDisplayNames, (displayName) => {
                    this.addAllParametersFamilyType(groupArray, topic.parameters, groupDisplayName, displayName);
                });

                groupsArray.push(groupArray);
            }

            this.cleanEmptyGroups(groupArray, groupsArray);
        });


    };

    getPMStatus = (device) => device.sbcInfo.pmInfo.pollingStatus === 'NOT_SUPPORTED' ? 'Not Supported' :
        ((device.sbcInfo.pmInfo.pollingEnable === 'Disable') ? 'Not Polling' :
            this.LAST_POLLING_STATUS_MAP[device.sbcInfo.pmInfo.lastPollingStatus]);

    isDeviceHasPMInfo = (device: any) => {
        if (!device) {
            return false;
        }

        return !_.isNil(device.sbcInfo) && !_.isNil(device.sbcInfo.pmInfo);
    };

    isDeviceSupportPM = (device: any) => {
        return device?.sbcInfo?.pmInfo && (device.sbcInfo.pmInfo.pollingStatus !== 'NOT_SUPPORTED');
    };

    private generateUniqueKeys = (parametersArray, propertyName) => {
        const tempArray = [];

        _.forOwn(parametersArray, (parameter) => {
            if (tempArray.indexOf(parameter[propertyName]) === -1) {
                tempArray.push(parameter[propertyName]);
            }
        });

        return tempArray;
    };


    private removeRedundantGroups = (groupsArray, uniqueIds) => _.filter(groupsArray, (group: any) => _.filter(group, (row: any) => _.filter(row, (parameter: any) => uniqueIds.indexOf(parameter.id) > -1).length > 0).length > 0);

    private applyChangesOnProvidedGroupsArray = (groupsArray, emptiedGroupsArray) => {
        groupsArray.length = 0;
        groupsArray.push.apply(groupsArray, emptiedGroupsArray);
    };

    private findGroupArray = (groupsArray, groupDisplayName) => groupsArray.find((groupItem) => {
        if (groupItem.length > 0) {
            const firstParamTypeInFamily = Object.getOwnPropertyNames(groupItem[0])[0];

            return groupItem[0][firstParamTypeInFamily].groupDisplayName === groupDisplayName;
        }
    });

    private addAllParametersFamilyType = (groupArray, parameters, groupDisplayName, displayName) => {
        const currentParameter = parameters.filter((parameter) => parameter.groupDisplayName === groupDisplayName &&
            parameter.displayName === displayName);

        if (currentParameter.length > 0) {
            const obj = {};

            _.forOwn(currentParameter, (item) => {
                obj[item.paramTypeInFamily] = item;
            });

            groupArray.push(obj);
        }
    };

    private cleanEmptyGroups = (groupArray, groupsArray) => {
        if (groupArray && groupArray.length === 0) {
            const index = groupsArray.indexOf(groupArray);
            if (index > -1) {
                groupsArray.splice(index, 1);
            }
        }
    };
}
