import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {AcTimePickerComponent} from './ac-time-picker.component';
import {InfraPipesModule} from '../../pipes/infra.pipes.module';
import {MatInputModule} from '@angular/material/input';
import {AcInputContainerModule} from '../ac-input-container/ac-input-container.module';
import {FormsModule} from '@angular/forms';
import {AcIconsModule} from '../ac-icons/ac-icons.module';
import {AcFormModule} from '../ac-form/ac-form.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AcFormModule,
        MatInputModule,
        NgxMaterialTimepickerModule,
        InfraPipesModule,
        AcInputContainerModule,
        AcIconsModule,
    ],
    declarations: [
        AcTimePickerComponent
    ],
    exports: [
        AcTimePickerComponent
    ],
})
export class AcTimePickerModule {
}
