import {Component, Input} from '@angular/core';


@Component({
    selector: 'ac-menu-countdown',
    templateUrl: './ac-menu-countdown.component.html',
    styleUrls: ['./ac-menu-countdown.component.less'],
})
export class AcMenuCountdownComponent {

    @Input() acTimeStamp: number = Date.now();

    constructor() {
    }
}
