import {Component, HostListener, Inject, Optional} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {GridsterConfig} from 'angular-gridster2';
import {AcDialog, AcDialogComponent, DIALOG_CONFIG, DialogConfig} from 'ac-infra';

import {DashboardService} from './services/dashboard.service';
import {AuthorizationService} from '../common/services/authorization.service';
import {DashboardConfigurationsService} from './services/dashboard-configurations.service';
import {SessionService} from '../common/services/session.service';
import {WidgetItem} from '../common/widget/widget.modals';
import {WidgetService} from '../common/widget/services/widget.service';
import {GroupsDashboardSummary} from './components/entities-summary/services/groups-dashboard-summary';
import {NetworkDashboardSummary} from './components/entities-summary/services/network-dashboard-summary';


@AcDialog({
    id: 'dashboard-dialog',
    submitButtonText: 'Save',
    height: 9999,
    width: 9999,
})
@UntilDestroy()
@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.less'],
    providers: [DashboardService, DashboardConfigurationsService],
})
export class DashboardComponent {

    options: GridsterConfig;
    dashboardItems: Array<WidgetItem>;
    isEditMode = false;

    constructor(public dashboardService: DashboardService,
                private widgetService: WidgetService,
                private authorizationService: AuthorizationService,
                private sessionService: SessionService,
                @Optional() @Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig,
                @Optional() public acDialogComponent: AcDialogComponent,
    ) {
        this.isEditMode = !!acDialogComponent;
        const securityLevel = dialogConfig?.dialogData?.authGroup;
        if (securityLevel) {
            this.authorizationService.securityLevel = securityLevel;
        }
    }

    ngOnDestroy() {
        if (AuthorizationService.isGlobalScope()) {
            this.authorizationService.securityLevel = this.sessionService.activeSession.securityLevel;
        }
    }

    ngAfterViewInit(): void {
        this.options = this.dashboardService.getDashboardGridOptions();

        this.dashboardService.widgetItems$.pipe(untilDestroyed(this)).subscribe({
            next: (dashboardItems) => setTimeout(() => {
                this.dashboardItems = dashboardItems ? [...dashboardItems] : dashboardItems;
            }),
        });
    }

    @HostListener('window:resize')
    onResize() {
        let margin = 10;
        if (window.innerWidth < 1400) {
            margin = 5;
        }
        this.options = this.widgetService.updateGridOptions(this.options, {margin});
    }

    // @HostListener('window:beforeunload', ['$event'])
    canDeactivate(): boolean {
        return !this.isEditMode || this.dashboardService.isCurrentStateSaved();
    }

    cancelEditMode = () => {
        if (this.acDialogComponent) {
            this.acDialogComponent.close();
        }
        this.dashboardService.restoreSavedItems();
        this.toggleEditMode();
    };

    onBackToDefault() {
        this.dashboardService.resetToDefaultItems();
    }

    saveDashboardState() {
        if (this.acDialogComponent) {
            this.dialogConfig.dialogData.dashboardItems = this.dashboardService.getDashboardItemsToSave();
            this.acDialogComponent.onSubmit();
            return;
        }

        this.widgetService.workspaceNotActiveDialog(this.dashboardService.dashboardSessionKey, () => {
            this.dashboardService.saveDashboardItemsState();
            this.toggleEditMode();
        });
    }

    toggleEditMode = () => {
        this.isEditMode = !this.isEditMode;
        this.isEditMode && this.dashboardService.createSavedSnapshotFromCurrent();
    };

    isItemsDefault = (dashboardItems: WidgetItem[]) => {
        return dashboardItems && this.dashboardService.isDashboardItemsDefault(this.dashboardItems);
    };
}
