import {Injectable} from '@angular/core';
import {NetworkFilterFormatterService} from '../network-filter-formatter.service';
import {NetworkRest} from '../../../common/server-actions/network-rest';


@Injectable({providedIn: 'root'})
export class LinksRestService extends NetworkRest {
    constructor(networkFilterFormatter: NetworkFilterFormatterService) {
        super({url: 'topology/links', entityType: 'links', entityFilterFormatter: networkFilterFormatter, filterEventName: 'NetworkFilter',
            urlToFilterCallingApi:  {
                'topology/links': 'links',
                'callsMonitor/statistics/entities': 'linksMonitorStatistics',
                'callsMonitor/statistics/linksEntities': 'linksCallsStatistics',
                'topology/statistics/links': 'linksTopologyStatistics'
            }});
    }
}
