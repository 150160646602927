import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AcDisplayBoxComponent} from './ac-display-box.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    declarations: [
        AcDisplayBoxComponent,
    ],
    entryComponents: [
        AcDisplayBoxComponent,
    ],
    exports: [
        AcDisplayBoxComponent,
    ]
})
export class AcDisplayBoxModule {
}
