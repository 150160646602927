<div [id]="componentId" [class.hasValue]="search&&search.length > 0"
     class="ac-search-component flexRowParent">
    <div class="flexItemBiggest inputContainer">
        <input (ngModelChange)="onSearch($event)" [ngModel]="search" #input
               [placeholder]="inputHasFocus?'':_placeHolder"
               (focusin)="inputHasFocus=true"
               (focusout)="inputHasFocus=false"
               [value]="search"
               class="search-input"
               spellcheck="false"
               type="text"/>

        <a (click)="clearContent()" class="flexItem clear-search-button">
            <i class="fa fa-times"></i>
        </a>

        <ac-svg name="magnify" class="magnifying-glass" (click)="input.focus()"></ac-svg>
    </div>
</div>
