<ac-form *ngIf="dialogConfig.ready"
         [auxiliaryModel]="passwords"
         [formModel]="operator"
         [formSchema]="schema"
         [isEdit]="false"
         [ignoreViewMode]="true"
         [requiredsAuxiliaryForm]="['operator.oldPassword', 'operator.newPassword', 'passwords.confirmNewPassword']"
         [validateAuxiliaryForm]="validateAuxiliaryForm"
         formModelName="operator">

    <div class="update-password-container">

        <ac-input-container label="User Name" [displayAsInfo]="true">
            <div class="operators-name-container" [attr.disabled]="true">
                <span>{{operator.operatorName}}</span>
            </div>
        </ac-input-container>

        <ac-input-container formFieldName="operator.oldPassword" label="Old Password">
            <ac-password [(acModel)]="operator.oldPassword"
                         [ngDisabled]="oldPassword"
                         focusOnInput="true"
                         passwordId="operator-old-password">
            </ac-password>
        </ac-input-container>

        <div class="flexRowParentEqualChildren">
            <ac-input-container formFieldName="operator.newPassword" label="New Password">
                <ac-password [(acModel)]="operator.newPassword"
                             passwordId="operator-new-password">
                </ac-password>
            </ac-input-container>

            <ac-input-container formFieldName="passwords.confirmNewPassword" label="Confirm New Password">
                <ac-password [(acModel)]="passwords.confirmNewPassword"
                             passwordId="operator-confirm-new-password">
                </ac-password>
            </ac-input-container>
        </div>
    </div>
</ac-form>
