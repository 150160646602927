import {Component} from '@angular/core';
import {GeneralService} from 'ac-infra';
import {Widget} from '../../../common/widget/widget.modals';
import {EntitiesSummaryService} from './services/entities-summary.service';

@Component({
    selector: 'entities-summary',
    template: `
        <ng-container *ngFor="let entity of entitiesSummaryService.summaryEntities | acFunc: filterEntities: widgetItem.widgetConfig?.data | keyvalue : generalService.noCompare; let last = last">
            <entity-details
                [attr.id]="'dashboard-link-' + entity.key"
                class="entity-details"
                [entity]="entity.value"
                [attachedCustomer]="entitiesSummaryService.attachedCustomer">
            </entity-details>

            <div class="entity-details-divider-wrapper"><span class="entity-details-divider" *ngIf="!last"></span></div>
        </ng-container>
    `,
    styleUrls: ['./entities-summary.component.less'],
    providers: [EntitiesSummaryService]
})
export class EntitiesSummaryComponent extends Widget {

    constructor(public entitiesSummaryService: EntitiesSummaryService,
                public generalService: GeneralService) {
        super();
    }

    filterEntities = (entities: any[], widgetConfig) => {
        const authorizedEntities = this.entitiesSummaryService.getAuthorizedEntities(entities);
        if (!widgetConfig) {
            return authorizedEntities;
        }

        return Object.entries(widgetConfig).reduce((acc, [key, value]) => {
            if (value && authorizedEntities[key]) {
                acc[key] = authorizedEntities[key];
            }
            return acc;
        }, {});
    };

    getWidgetConfigData = () => {
        return this.entitiesSummaryService.getAuthorizedEntities(this.entitiesSummaryService.summaryEntities);
    };
}




