import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    ViewChild
} from '@angular/core';


import {cloneDeep, isEmpty, merge} from 'lodash';
import * as Highcharts from 'highcharts';
import {Subscription} from 'rxjs';
import {GeneralService} from '../../services/general.service';

import noData from 'highcharts/modules/no-data-to-display';
import {DateFormat, FormatterType} from '../../utils/date-time-formatter';
import {AcTrackerService} from '../../services/utilities/ac-tracker.service';

@Component({
    selector: 'ac-chart',
    templateUrl: './ac-chart.component.html',
    styleUrls: ['./ac-chart.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AcChartComponent {

    @ViewChild('contentWrapper', {static: true}) contentWrapper: ElementRef;
    @Input() chartTitle: string;
    @Input() chartId: string;
    @Input() height: number;
    @Input() largeTitle = false;
    @Input() autoLegend = true;
    @Output() fullScreenModeChanged: EventEmitter<boolean> = new EventEmitter();
    readonly maxYPadding = 0.1;
    public chart: any;
    public isFullScreen: boolean;
    private _chartsOptions: any;
    private subscription: Subscription;
    private observable: any;
    private defaultOptions: any;
    private readonly DEFAULT_NO_DATA_TEXT = 'No data to display';

    constructor(private generalService: GeneralService,
                private acTrackerService: AcTrackerService) {

        noData(Highcharts);
        this.disableFullScreen = this.generalService.disableFullScreenAcChart;
    }

    _disableFullScreen;

    @Input() set disableFullScreen(disableFullScreen) {
        this._disableFullScreen = this.generalService.disableFullScreenAcChart || disableFullScreen;
    }

    private _noDataText = this.DEFAULT_NO_DATA_TEXT;

    @Input() set noDataText(text: string) {
        this._noDataText = text || this.DEFAULT_NO_DATA_TEXT;
        if (this.chart) {
            this.chart.options.lang.noData = this._noDataText;
            if (this.chart.noDataLabel) {
                this.chart.noDataLabel.textSetter(this._noDataText);
                this.chart.noDataLabel.alignOptions.width = this.chart.noDataLabel.width;
                this.chart.noDataLabel.align();
            }
        }
    }

    @Input() set chartOptions(chartOptions: any) {

        this.createDefaultOptions();
        this.setChartOptions(chartOptions);
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        this.closeFullScreen();
    }

    @HostListener('window:resize')
    onResize = () => {
        setTimeout(() => {
            if (this.chart && this.chart.container) {
                this.chart.reflow();
            }
        }, 500);
    };

    ngOnDestroy() {
        this.unsubscribeIfNeeded();
        this.destroyOldChartIfNeeded();
    }

    handleChartResize() {
        this.isFullScreen = !this.isFullScreen;
        this.acTrackerService.trackEvent('Chart "' + this.chartTitle + '"', 'fullscreen toggle ' + (this.isFullScreen ? 'ON' : 'OFF'));
        this.fullScreenModeChanged.emit(this.isFullScreen);
        this.dispatchResizeEvent();
    }

    private createDefaultOptions = () => {
        this.createChartDefaultOptions();
        this.appendDefaultTitleOptions();
        this.appendDefaultsXAxisOptions();
        this.appendDefaultsYAxisOptions();
        this.appendDefaultLegendOptions();
        this.appendDefaultTooltipOptions();
        this.dispatchResizeEvent();
    };

    private setChartOptions = (chartOptions) => {
        if (!chartOptions) {
            this.destroyOldChartIfNeeded();
            return;
        }

        this.appendDefaultPlotOptions(chartOptions);
        this.subscribeObservable(chartOptions);
        this.refreshChart(chartOptions);
    };


    private closeFullScreen = () => {
        if (this.isFullScreen) {
            this.isFullScreen = false;
            this.dispatchResizeEvent();
        }
    };


    private createChartDefaultOptions = () => {
        this.defaultOptions = {
            lang: {
                noData: this._noDataText
            },
            chart: {
                plotAreaHeight: 30,
            },
            time: {
                useUTC: false
            },
            credits: false
        };
    };

    private appendDefaultTitleOptions = () => {
        this.defaultOptions.title = {
            text: null
        };
    };

    private appendDefaultsXAxisOptions = () => {
        this.defaultOptions.xAxis = {
            gridLineWidth: 1,
            dateTimeLabelFormats: {
                millisecond: DateFormat[FormatterType.timeChart],
                second: DateFormat[FormatterType.timeChart],
            },
        };
    };

    private appendDefaultsYAxisOptions = () => {
        this.defaultOptions.yAxis = {
            title: {
                text: null
            },
            maxPadding: this.maxYPadding,
            tickInterval: 1,
            min: 0,
            labels: {
                formatter() {
                    return Highcharts.numberFormat(this.value, 0, '.', ',');
                }
            }
        };
    };

    private appendDefaultLegendOptions = () => {
        this.defaultOptions.legend = {
            layout: 'horizontal',
            alignColumns: false,
            itemDistance: 10,
            symbolPadding: 5,
            symbolRadius: 10,
            symbolWidth: 9,
            symbolHeight: 9,
            padding: 0,
            margin: 10,
            itemStyle: {
                fontWeight: 'normal',
                'text-transform': 'capitalize',
                'padding-top': '4px'
            }
        };
    };

    private appendDefaultTooltipOptions = () => {
        this.defaultOptions.tooltip = {
            pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.formattedY}</b><br/>',
            formatter(tooltip) {
                this.point.formattedY = Highcharts.numberFormat(this.point.y, -1, '.', ',');

                return tooltip.defaultFormatter.call(this, tooltip);
            }
        };
    };


    private unsubscribeIfNeeded = () => {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };

    private destroyOldChartIfNeeded = () => {
        if (this.chart) {
            this.chart.destroy();
            this.chart = null;
        }
    };


    private appendDefaultPlotOptions = (chartOptions) => {
        const chartType = chartOptions.chart?.type;

        chartOptions.plotOptions = chartOptions.plotOptions || {};
        if (!chartType) {
            return;
        }
        chartOptions.plotOptions[chartType] = chartOptions.plotOptions[chartType] || {};
        chartOptions.plotOptions[chartType].borderRadius = 4;


        if (chartType === 'pie') {
            chartOptions.plotOptions.pie.tooltip = chartOptions.plotOptions.pie.tooltip || {};
            chartOptions.plotOptions.pie.tooltip.footerFormat = '{point.percentage:.1f}%';
        }

        if (chartType === 'area') {
            chartOptions.plotOptions.area.marker = chartOptions.plotOptions.area.marker || {};
            chartOptions.plotOptions.area.marker.enabled = false;
            chartOptions.plotOptions.area.marker.symbol = 'circle';
            chartOptions.plotOptions.area.marker.radius = 4;
            chartOptions.plotOptions.area.marker.states = chartOptions.plotOptions.area.marker.states || {};
            chartOptions.plotOptions.area.marker.states.hover = chartOptions.plotOptions.area.marker.states.hover || {};
            chartOptions.plotOptions.area.marker.states.hover.enabled = true;
        }
    };

    private subscribeObservable = (chartOptions) => {
        this.unsubscribeIfNeeded();

        if (chartOptions && chartOptions.observable) {
            this.observable = chartOptions.observable;
            this.subscription = this.observable.subscribe(() => {
                this.refreshChart(chartOptions);
            });
        }
    };

    private refreshChart = (chartOptions) => {
        this.prepareChartOptions(chartOptions);
        this.createNewChartWithOptions();
        this.dispatchResizeEvent();
    };

    private dispatchResizeEvent = () => {
        GeneralService.dispatchResizeEvent();
    };

    private prepareChartOptions = (chartOptions) => {
        const tempDefaults = cloneDeep(this.defaultOptions);
        this._chartsOptions = merge(tempDefaults, chartOptions);

        const chartType = this._chartsOptions.chart.type;

        this.hideLegendIfChartEmpty(chartOptions);
        this.addCursorWhenHasEvents(chartType);
        this.addSeriesTooltipStyles(chartType);
    };

    private createNewChartWithOptions = () => {
        if (this.chart) {
            this.chart.update(this._chartsOptions);
            this.changeYAxisExtremesDynamically(this.chart);
        } else {
            this.chart = Highcharts.chart(this.contentWrapper.nativeElement, this._chartsOptions, this.changeYAxisExtremesDynamically);
        }
    };


    private hideLegendIfChartEmpty = (chartOptions) => {
        if (!this.autoLegend) {
            this._chartsOptions.legend = chartOptions.legend;
            return;
        }
        this._chartsOptions.legend.enabled = !this.isChartSeriesEmpty() && !this.hasSeriesButWithNoData();
    };

    private addCursorWhenHasEvents = (chartType) => {
        if (this._chartsOptions.plotOptions[chartType] && this._chartsOptions.plotOptions[chartType].events) {
            this._chartsOptions.plotOptions[chartType].cursor = 'pointer';
        }
    };

    private addSeriesTooltipStyles = (chartType) => {
        if (this._chartsOptions.plotOptions[chartType] && !this._chartsOptions.plotOptions[chartType].tooltip) {
            this._chartsOptions.plotOptions[chartType].tooltip = {
                headerFormat: '<span style="font-size: 12px">{point.key}</span><br/>',
                pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: {point.formattedY}<br/>'
            };
        }
    };


    private changeYAxisExtremesDynamically = (chart) => {
        const dataMin = chart.yAxis[0].dataMin;
        let dataMax = chart.yAxis[0].dataMax;

        if (dataMin === 0 && dataMax === 0) {
            dataMax = dataMin + 5;
        }

        chart.yAxis[0].update({
            max: dataMax * (1 + this.maxYPadding)
        });
    };


    private isChartSeriesEmpty = () => this._chartsOptions && isEmpty(this._chartsOptions.series);

    private hasSeriesButWithNoData = () => {
        const seriesCount = this._chartsOptions.series.length;

        for (let i = 0; i < seriesCount; i++) {
            if (!isEmpty(this._chartsOptions.series[i].data)) {
                return false;
            }
        }
        return true;
    };
}
