import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AcSliderComponent} from './ac-slider.component';
import {Ng5SliderModule} from 'ng5-slider';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";

@NgModule({
    imports: [
        CommonModule,
        Ng5SliderModule,
        FormsModule,
        MatSlideToggleModule
    ],
    declarations: [
        AcSliderComponent,
    ],
    entryComponents: [
        AcSliderComponent,
    ],
    exports: [
        AcSliderComponent,
    ]
})
export class AcSliderModule {
}
