import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {GeneralService} from 'ac-infra';

export interface GroupIcon {
    [key: string]: any;

    name?: string;
    title?: string;
}

export interface IconsGroup {
    [key: string]: GroupIcon;
}

@Component({
    selector: 'icon-group-template',
    templateUrl: './icon-group-template.component.html',
    styleUrls: ['./icon-group-template.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconGroupTemplateComponent {

    @Input() collapse = false;
    @Input() icons: IconsGroup;
    @Input() iconOrder: string[] = [];

    colors = GeneralService.statusColors;
    colorByStatusMap = GeneralService.statusToColorMap;
}

