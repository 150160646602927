import {ChangeDetectorRef, Component, ElementRef, HostBinding, Inject, OnInit, Type, ViewChild, ViewContainerRef,} from '@angular/core';

import {AcDialogRef, DIALOG_CONFIG, DialogConfig, DialogData} from './ac-dialog.models';
import {animate, style, transition, trigger} from '@angular/animations';

import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AcFormComponent} from '../ac-form/ac-form.component';
import {AcDialogHostComponent} from './ac-dialog-host/ac-dialog-host.component';
import {Subject} from 'rxjs';
import {AcTrackerService} from '../../services/utilities/ac-tracker.service';

@UntilDestroy()
@Component({
    selector: 'ac-dialog',
    templateUrl: './ac-dialog.component.html',
    styleUrls: ['./ac-dialog.component.less'],
    animations: [
        trigger('dialogInOut', [
            transition(':enter', [
                style({opacity: 0}),
                animate('0.2s ease-in', style({opacity: 1}))
            ]),
            transition(':leave', [
                animate('0.1s ease-out', style({opacity: 0}))
            ])
        ])
    ],
    host: {
        tabindex: '0'
    }
})
export class AcDialogComponent implements OnInit {
    @ViewChild('dialogContentHost', {read: ViewContainerRef, static: true}) private dialogContentHost: ViewContainerRef;

    @HostBinding('@dialogInOut') dialogInOut;
    @ViewChild('dialog', {static: true}) dialog: ElementRef;
    forms: AcFormComponent[] = [];
    allFormsAreInViewMode;
    loading = true;
    private closeDialogSubject = new Subject();
    private dragStartSubject = new Subject();
    closeDialog$ = this.closeDialogSubject.asObservable().pipe(untilDestroyed(this));
    dragStart$ = this.dragStartSubject.asObservable().pipe(untilDestroyed(this));

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig,
                private acTrackerService: AcTrackerService,
                private acDialogRef: AcDialogRef,
                private acDialogHostComponent: AcDialogHostComponent,
                private elementRef: ElementRef,
                public cdRef: ChangeDetectorRef) {
        this.acDialogHostComponent.escape$
            .pipe(untilDestroyed(this))
            .subscribe((viewRefToClose) => {
                if (viewRefToClose !== this.acDialogRef.dialogHostView) {
                    return;
                }
                !this.dialogConfig.submitButtonProcessing && !this.dialogConfig.preventEscape && this.close();
            });
    }

    close(data?: DialogData) {
        data = typeof data === 'boolean' ? data : (data || this.dialogConfig.dialogData);
        this.closeDialogSubject.next(data);
    }

    async cancel() {
        let resolvedData;
        try {
            resolvedData = await this.dialogConfig.onCancel?.(this.dialogConfig.dialogData);
            this.acTrackerService.trackEvent('DialogCancel', this.dialogConfig.title);
        } catch (err) {
        }
        this.close(resolvedData);
    }

    ngOnInit(): void {
        this.elementRef.nativeElement.focus();
        setTimeout(() => this.loading = false);
    }

    ngAfterViewInit() {
        this.optionsChanged();
    }

    optionsChanged() {
        this.cdRef.detectChanges();
    }

    addForm(form: AcFormComponent) {
        this.forms.push(form);

        this.allFormsAreInViewMode = !this.dialogConfig.overrideSystemViewMode && !this.forms.some((acForm) => acForm.isViewMode === false);
    }

    createDialogContent(acDialogContentComponent?: Type<any>) {
        this.dialogContentHost.clear();
        this.dialogContentHost.createComponent(acDialogContentComponent);
    }

    onSubmit = () => {
        let formsAreValid = true;

        this.forms.forEach((form) => {
            form.formSettings.touched = true;
            form.handleValidation(true);
            formsAreValid = formsAreValid && form.formSettings.valid;
        });

        formsAreValid && this.submit();
    };

    ngOnDestroy() {
        this.dialogConfig.onClose?.(this.dialogConfig.dialogData);
    }

    onDrag = () => this.dragStartSubject.next(null);

    private async submit() {
        this.dialogConfig.submitButtonProcessing = true;
        try {
            const preSubmitResolvedData =  await this.dialogConfig.preSubmit?.(this.dialogConfig.dialogData);
            const resolvedData = await this.dialogConfig.onSubmit(preSubmitResolvedData || this.dialogConfig.dialogData);
            this.acTrackerService.trackEvent('DialogSubmitted', this.dialogConfig.title);
            this.close(resolvedData);
        } catch (err) {
            this.dialogConfig.submitButtonProcessing = false;
        }
    }

    getButtonText = (buttonText, defaultText, postfix = '') => {
        return (buttonText || defaultText) + postfix;
    }
}
