import {NgModule} from '@angular/core';
import {AcIconsModule, InfraPipesModule} from 'ac-infra';
import {CommonModule} from '@angular/common';
import {LogoDisplayComponent} from './logo-display.component';

@NgModule({
    imports: [
        AcIconsModule,
        CommonModule,
        InfraPipesModule
    ],
    declarations: [
        LogoDisplayComponent,
    ],
    entryComponents: [
        LogoDisplayComponent
    ],
    exports: [
        LogoDisplayComponent
    ]
})
export class LogoDisplayModule {
}
