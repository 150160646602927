import {ChangeDetectorRef, Component, ContentChildren, ElementRef, EventEmitter, HostBinding, Input, Output, TemplateRef,} from '@angular/core';

import {ActivatedRoute, Params, Router} from '@angular/router';
import {AcLayoutSectionChildDirective} from '../directives/ac-layout-section-child.directive';
import {BehaviorSubject} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {GeneralService} from '../../../services/general.service';
import {SessionStorageService} from '../../../services/session-storage.service';
import {AcTrackerService} from '../../../services/utilities/ac-tracker.service';


@Component({
    selector: 'ac-layout-section',
    templateUrl: './ac-layout-section.component.html',
    styleUrls: ['./ac-layout-section.component.less'],
})
export class AcLayoutSectionComponent {

    // COLLAPSE
    @Input() section = '';
    @Input() collapseDir = '';
    @Input() ContentClasses: string | string[] | Set<string> | { [klass: string]: any } = '';
    @Input() isLoading = false;
    @Input() hasHeader = true;
    @Input() spacerTemplate: TemplateRef<any>;
    @Output() collapse = new EventEmitter<boolean>();
    @Output() fullsScreenChanged = new EventEmitter<boolean>();

    @HostBinding('class.collapsed') public isCollapsed = false;
    // PANELS
    @ContentChildren(AcLayoutSectionChildDirective) panels: any;
    @Input() sectionTitle = '';
    @Input() titlePostfix = '';
    @Input() fullScreenToggle = false;
    sessionRouteKey = '';
    isFullScreen = false;
    @HostBinding('class.flex-w1') private notCollapsed = true;

    constructor(private generalService: GeneralService,
                private cdRef: ChangeDetectorRef,
                private route: ActivatedRoute,
                public elementRef: ElementRef,// DO NOT REMOVE!! Needed to get the width on resize call-flow.component.ts
                private router: Router,
                private acTrackerService: AcTrackerService) {
    }

    ngAfterContentInit() {
        const params = new HttpParams({fromObject: (this.route.params as BehaviorSubject<Params>).value});
        this.sessionRouteKey = this.router.url.split('/')[1] + '_' + params.toString();

        const collapseState = SessionStorageService.getData('section-collapse-state');

        this.setCollapseState(collapseState && collapseState[this.sessionRouteKey] && collapseState[this.sessionRouteKey][this.collapseDir] || false);
    }

    ngAfterViewInit() {
        this.cdRef.detectChanges();
    }

    trackSectionCollapse = () => {
        const trackerCategory = [
            this.sectionTitle,
            this.titlePostfix,
            this.panels.map(({title}) => title).join(' & ')
        ].filter(str => !!str).join(' ');

        this.acTrackerService.trackEvent(trackerCategory, this.isCollapsed ? 'COLLAPSE' : 'EXPAND');
    };

    toggleCollapseState = (emitChange = true) => {
        this.setCollapseState(!this.isCollapsed, emitChange);

        emitChange && this.trackSectionCollapse();
    };

    setCollapseState = (state = false, emitChange = true) => {

        this.isCollapsed = state;
        this.updateGlobalCollapseState(this.isCollapsed);
        if (emitChange) {
            this.collapse.emit(this.isCollapsed);
        }
        this.triggerResize();
    };

    isSectionCollapsed = (): boolean => this.isCollapsed;

    updateGlobalCollapseState = (state = false) => {

        let collapseState = SessionStorageService.getData('section-collapse-state');

        if (!collapseState) {
            collapseState = {};
        }
        if (!collapseState[this.sessionRouteKey]) {
            collapseState[this.sessionRouteKey] = {};
        }

        collapseState[this.sessionRouteKey][this.collapseDir] = state;

        SessionStorageService.setData('section-collapse-state', collapseState);
    };

    triggerResize = () => {
        GeneralService.dispatchResizeEvent();
    };

    getPanelTemplates = (panel) => ({
        panelHeaderTemplate: panel.headerTemplate,
        panelActionsTemplate: panel.panelActions,
    });

    toggleFullScreen() {
        this.isFullScreen = !this.isFullScreen;
        this.fullsScreenChanged.emit(this.isFullScreen);
    }
}
