import {Injectable} from '@angular/core';

import {GeneralService} from 'ac-infra';
import {LoginRestService} from './login-rest.service';
import {LoginService} from './login.service';
import {RestResponseFailure} from '../../common/server-actions/rest';
import {NavigationService} from '../../common/utilities/navigation.service';
import {SessionService} from '../../common/services/session.service';

@Injectable({
    providedIn: 'root'
})
export class UnlockService {

    constructor(private sessionService: SessionService,
                private loginRestService: LoginRestService,
                private navigationService: NavigationService,
                private generalService: GeneralService,
                private loginService: LoginService) {
    }

    lock = () => {
        const onFailure = (error: RestResponseFailure) => {
            this.loginService.handleErrorStatus(undefined, undefined, error.status);
        };

        this.loginRestService.lock(() => {
            this.generalService.resetLoading();
        }, onFailure);
    };

    unlock = (username, password, success, failure) => {
        const onSuccess = () => {
            this.sessionService.unlockSession();
            this.navigationService.goTo('/dashboard');
        };
        const onFailure = (error) => {
            // this.loginService.handleErrorStatus(username, password, error.status);
            failure(error);
        };

        this.loginRestService.unlock(username, password, onSuccess, onFailure);
    };
}
