import {Component, EventEmitter, Input, Optional, Output} from '@angular/core';


import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {AcFormComponent} from '../ac-form/ac-form.component';
import {GeneralService} from '../../services/general.service';
import {HttpClient} from "@angular/common/http";


@Component({
    selector: 'ac-geo-location',
    templateUrl: './ac-geo-location.component.html',
    styleUrls: ['./ac-geo-location.component.less'],
})

export class AcGeoLocationComponent {
    @Input() acModel: any;
    @Input() ngDisabled: any;
    @Output() acChanged: EventEmitter<any> = new EventEmitter<any>();

    SERVER_UNAVAILABLE_MESSAGE = 'location service unavailable';
    KEY_ARROW_DOWN = 40;
    KEY_ARROW_UP = 38;
    KEY_ENTER = 13;
    KEY_ESCAPE = 27;

    activeIndex = 0;

    isDisabled = false;
    loadingText = 'fetching location...';
    isLocationReady = false;

    location: any;
    locations: any;
    addressValue;
    geoLocation = new Subject<string>();

    private resetLocationObject: any = {latitude: null, longitude: null};

    constructor(private httpClient: HttpClient,
                @Optional() public acFormComponent: AcFormComponent,
                private generalService: GeneralService) {
        this.geoLocation.pipe(
            debounceTime(700),
            distinctUntilChanged())
            .subscribe(value => {
                this.onTextChange();
            });
    }

    ngOnInit(){
        if(this.acFormComponent && this.acFormComponent.isViewMode){
            this.ngDisabled = true;
        }
    }

    ngAfterContentInit() {
        this.initializeLocation();
    }

    onTextChange = () => {
        const newValue = this.addressValue || '';

        if (!newValue) {
            this.acChanged.emit(this.resetLocationObject);
        }

        if (newValue === this.SERVER_UNAVAILABLE_MESSAGE || !newValue || (this.location && this.location.display_name === newValue)) {
            return;
        }
        const onSuccess = (res, status, xhr) => {
            this.locations = res;
        };
        const onFailure = (xhr, status, error) => {
            this.disabledLocationField();
            this.locations = undefined;
        };

        this.activeIndex = 0;

        this.getLocationsSuggestions(newValue, onSuccess, onFailure);
    };

    getLocationsSuggestions = (locationValue, onSuccess, onFailure) => {
        this.httpClient.get('https://nominatim.openstreetmap.org/search?format=json&limit=5&q=' + locationValue).subscribe({
            next: onSuccess,
            error: onFailure
        });
    };

    getLocationName = (lat, lon, onSuccess, onFailure) => {
        this.httpClient.get('https://nominatim.openstreetmap.org/reverse?&format=json&lat=' + lat + '&lon=' + lon).subscribe({
            next: onSuccess,
            error: onFailure
        });
    };

    keyPressed = (event) => {
        if (this.locations && this.locations.length) {
            switch (event.keyCode) {
                case this.KEY_ARROW_DOWN:
                    this.activeIndex = Math.min(this.locations.length - 1, this.activeIndex + 1);
                    break;
                case this.KEY_ARROW_UP:
                    event.preventDefault();
                    this.activeIndex = Math.max(0, this.activeIndex - 1);
                    break;
                case this.KEY_ENTER:
                    this.locationSelected(this.activeIndex);
                    break;
                case this.KEY_ESCAPE:
                    this.clear();
                    break;
            }
        }
    };


    locationSelected = (index) => {
        this.location = this.locations[index];
        this.addressValue = this.location.display_name;
        if (this.location) {
            this.acChanged.emit({latitude: this.location.lat, longitude: this.location.lon});
        } else {
            this.acChanged.emit(this.resetLocationObject);
        }
        this.locations = [];
    };


    clear = () => {
        this.addressValue = '';
        this.locations = [];
        this.location = '';
    };


    isActive = (index) => {
        return index === this.activeIndex;
    };


    disabledLocationField() {
        this.isDisabled = true;
        this.addressValue = this.SERVER_UNAVAILABLE_MESSAGE;
    }


    initializeLocation() {
        if (this.acModel && this.acModel.latitude && this.acModel.longitude) {
            this.isLocationReady = false;
            const onSuccess = (res) => {
                this.addressValue = res && res.display_name ? res.display_name : '';
                this.location = res;

                this.isLocationReady = true;
            };
            const onFailure = (error) => {
                this.isLocationReady = true;
                this.disabledLocationField();
            };

            this.getLocationName(this.acModel.latitude, this.acModel.longitude, onSuccess, onFailure);
        } else {
            this.isLocationReady = true;
        }
    }
}
