import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {isString, isNumber} from 'lodash';
import {numberToKPipe} from '../../pipes/number-to-k.pipe';

@Component({
    selector: 'ac-display-box',
    templateUrl: './ac-display-box.component.html',
    styleUrls: ['./ac-display-box.component.less']
})

export class AcDisplayBoxComponent {

    @ViewChild('upperValue', {static: true}) upperValueEl: ElementRef;
    @ViewChild('lowerValue', {static: true}) lowerValueEl: ElementRef;

    @Output() acClick = new EventEmitter<any>();

    @Input() set upperValue(upperValue) {
        this._upperValue = this.handleValueChange(upperValue);
    }

    @Input() set lowerValue(lowerValue) {
        this._lowerValue = this.handleValueChange(lowerValue);
    }

    @Input() isFlexItem = true;
    @Input() showGreyBackground = false;
    @Input() isNumberToK = false;

    @Input() upperSize: 'extra-small' | 'small' | 'medium' | 'large' = 'small';
    @Input() lowerSize: 'extra-small' | 'small' | 'medium' | 'large' = 'medium';

    @Input() color;
    @Input() isClickable = true;

    _upperValue;
    _lowerValue;
    hover = false;

    constructor(private numberToKPipe: numberToKPipe, private decimalPipe: DecimalPipe) {
    }

    mouse = (value) => {
        this.hover = value;
    };

    handleValueChange = (value) => {
        if (isString(value)) {
            return value;
        } else if (isNumber(value)) {
            return this.isNumberToK ? this.numberToKPipe.transform(value) : this.decimalPipe.transform(value);
        }

        return undefined;
    };
}



