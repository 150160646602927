import {Injectable} from '@angular/core';
import {Rest, RestResponseSuccess} from '../../common/server-actions/rest';
import {AuthGroupWidgets, DashboardConfiguration} from '../dashboard.modals';
import {GeneralService} from 'ac-infra';
import {PromiseService} from '../../../../cypress/support/models/promise';
import {ServerInfoService} from "../../common/services/server-info.service";

@Injectable({providedIn: 'root'})
export class DashboardResetService extends Rest {

    constructor(private generalService: GeneralService) {
        super({
            url: 'settings/dashboards'
        });
    }

    getDashboardAuthGroupsConfigs(): Promise<DashboardConfiguration[]> {
        return this.get({extendParameters: true, skipErrorHandler: true}).then(({data}: RestResponseSuccess<{dashboards: any[]}>) => data?.dashboards);
    }

    addDashboardAuthGroupConfig(dashboardConfig: DashboardConfiguration): Promise<DashboardConfiguration> {
        const defer = PromiseService.defer<DashboardConfiguration>();

        this.add(({data}) => defer.resolve(data), () => defer.reject(), {
            ovocMode: this.getOvocMode(),
            ...dashboardConfig,
        }, this.url);

        return defer.promise;
    }

    updateDashboardAuthGroupConfigById(id, authGroupWidgets: AuthGroupWidgets): Promise<any> {
        const defer = PromiseService.defer();

        this.edit(({data}) => defer.resolve(data), () => defer.reject(), {widgets: authGroupWidgets}, id);
        return defer.promise;
    }

    getOvocMode = () => ServerInfoService.serverInfo.oneLiveMode ? 'OneLive' : 'Standard';
}
