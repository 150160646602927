import {Component, EventEmitter, Input, Optional, Output} from '@angular/core';
import {AcFormComponent} from '../ac-form/ac-form.component';


@Component({
    selector: 'ac-checkbox-group',
    styleUrls: ['./ac-checkbox-group.component.less'],
    templateUrl: './ac-checkbox-group.component.html',
})
export class AcCheckboxGroupComponent {

    @Input() parentModel: any;
    items: any;
    @Input('items') set setItems(items: any) {
        this.items = items;
        this.dataItems = this.items;
    }
    @Input() checkboxGroupId = '';
    @Input() withoutHeader: boolean;
    @Input() disableCheckboxGroup = false;
    @Input() searchMode = false;
    @Input() fullHeight = false;
    @Input() searchName: string;
    @Input() getFilterObj: Function;
    @Input() showIcon = false;
    @Input() iconClasses = 'fa-circle';
    @Input() iconType = '';
    @Input() textClass = '';
    @Input() checkboxGroupLabel;
    @Input() isHorizontal = false;

    /** don't use directly **/
    @Output() itemsChanged: EventEmitter<any> = new EventEmitter<any>();''

    dataItems: any;
    placeholderText = 'Search by name';


    constructor(@Optional() public acFormComponent: AcFormComponent) {
    }

    ngOnInit() {
        if (this.acFormComponent && this.acFormComponent.isViewMode) {
            this.disableCheckboxGroup = true;
        }
    }

    searchNameChanged(search) {
        this.dataItems = this.getFilterObj(this.items, search);
    }

    getChangeByItem = (item: any) => {
        return {...item, value: this.parentModel[item.name]};
    };

    setAll = (value) => {
        const changes = [];
        this.items.forEach((item) => {
            if (item.disabled) {
                return;
            }
            if (this.parentModel[item.name] !== !!value) {
                this.parentModel[item.name] = !!value;
                changes.push(this.getChangeByItem(item));
            }
        });
        this.handleChange(changes);
    };

    invertAll = () => {
        const changes = [];
        this.items.forEach((item) => {
            if (item.disabled) {
                return;
            }
            this.parentModel[item.name] = !this.parentModel[item.name];
            changes.push(this.getChangeByItem(item));
        });

        this.handleChange(changes);
    };

    allItemsAreDisabled = () => {
        let allItemsAreDisabled = true;

        this.items.forEach((item) => {
            if (!item.disabled) {
                allItemsAreDisabled = false;
            }
        });

        return allItemsAreDisabled;
    };

    parentModelChanged(item, newValue: boolean) {
        this.parentModel[item.name] = newValue;
        this.handleChange([this.getChangeByItem(item)]);
    }

    handleChange = (changedItems: any[]) => {
        this.itemsChanged.emit(changedItems);
    };
    trackByName = (index, item) => {
        return item?.name || index;
    };
}



