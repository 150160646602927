import {Injectable} from '@angular/core';

import * as _ from 'lodash';
import {Rest, RestResponseSuccess} from '../../../common/server-actions/rest';
import {NetworkFilterFormatterService} from '../network-filter-formatter.service';
import {RequestOptions} from 'ac-infra';

@Injectable({providedIn: 'root'})
export class LicenseRestService extends Rest {

    constructor(networkFilterFormatter: NetworkFilterFormatterService) {
        super({url: 'ovocLicense/allocations', entityFilterFormatter: networkFilterFormatter, filterEventName: 'NetworkFilter'});
    }

    public getLicense = (success, failure = (...args) => null) => {
        this.get({url: 'ovocLicense/general', skipPopulateFilter: true, requestAPI: 'general'}).then(success).catch(failure);
    };

    public getSystemLicenseAllocations = (success, failure) => {
        this.get({url: 'ovocLicense/systemAllocations', skipPopulateFilter: true, build204JSON: false}).then(success).catch(failure);
    };

    public getLicenseAllocations = ({success, failure = () => null, parameters, skipPopulateFilter = true, ...requestOptions}: RequestOptions) => {
        const mergedParams = _.extend({detail: 1, limit: 100000}, parameters);
        this.get({...requestOptions, parameters: mergedParams, skipPopulateFilter, requestAPI: 'allocations', filteredEntity: 'tenantAllocations'}).then(success).catch(failure);
    };

    public getLicenseAllocationById = (licenseAllocationId, options?: RequestOptions) => {
        return this.getById({id: licenseAllocationId, skipPopulateFilter: true, requestAPI: 'allocations', ...options});
    };

    public editSystemLicenseAllocations = (success, failure, systemAllocations) => {
        this.put(success, failure, systemAllocations, 'ovocLicense/systemAllocations');
    };

    public validateLicense = (success, failure, file) => {
        this.upload({success, failure, files: [file], url: 'ovocLicense/actions/validate', responseType: 'json', dialogConfig: undefined, isTextFilesWithData: false, showSuccessMessage: false});
    };

    public loadLicense = (success, failure, file, dialogConfig) => {
        this.upload({success, failure, files: [file], url: 'ovocLicense/actions/load', responseType: 'json', dialogConfig, isTextFilesWithData: false});
    };


    protected handleSuccess = (requestAPI, response): RestResponseSuccess => {
        const defaultObject = requestAPI !== 'general' ? {data: {
            licenseAllocations: [],
            pages: {total: 0, totalElements: 0}
        }} : {};

        return (response.status === 204 ? defaultObject : response);
    };

}
