import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';

import {ReportRestService} from '../../../services/report-rest.service';
import * as _ from 'lodash';
import {ReportEntitiesService} from '../../../reports/services/report-entities.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Observable} from 'rxjs';
import {AcDragAndDropComponent} from 'ac-infra';

@UntilDestroy()
@Component({
    selector: 'table-layout-tab',
    templateUrl: './table-layout-tab.component.html',
    styleUrls: ['./table-layout-tab.component.less'],
})
export class TableLayoutTabComponent {

    @Input() report: any;
    @Input() isViewOnly = false;
    @Input() isEdit = false;
    @Input() isReady = false;
    @Input() listsObject: any;
    @Input() additionalSettings: any;
    @Input() lockedTypes: {[key: string]: boolean};
    @Input() executeUpdateColumnSortArray: Function;
    @Input() updateColumnSortArray$: Observable<any>;
    @Input() handleOrderArray$: Observable<any>;
    @Output() listChanged = new EventEmitter<any>();

    @ViewChild('dndElement', {static: false}) dndElement: AcDragAndDropComponent;
    topType = {enable: false};

    topTypeList: any;

    itemsPerPageList = [
        {value: 'ITEMS_PER_PAGE_25', text: '25'},
        {value: 'ITEMS_PER_PAGE_50', text: '50'},
        {value: 'ITEMS_PER_PAGE_100', text: '100'},
        {value: 'ITEMS_PER_PAGE_500', text: '500'}
    ];

    constructor(public reportRestService: ReportRestService,
                private reportEntitiesService: ReportEntitiesService) {
    }

    ngOnInit() {
        this.reportEntitiesService.updateTopType$.pipe(untilDestroyed(this)).subscribe(() => {
            this.updateTopType();
        });
        if (this.additionalSettings.reportType === this.reportRestService.reportTypes.ELEMENT) {
            this.topTypeList = _.cloneDeep(this.reportRestService.topTypeListElement);
        } else {
            this.topTypeList = _.cloneDeep(this.reportRestService.topTypeListAggAndTrend);
        }

        if (this.report.topType !== 'NOT_DEFINED') {
            this.topType.enable = true;
        }

        if (!this.topType.enable) {
            this.report.topType = 'NOT_DEFINED';
        }

        if (!this.itemsPerPageList) {
            this.report.itemsPerPage = this.itemsPerPageList[0].value;
        }

        this.handleOrderArray$.pipe(untilDestroyed(this)).subscribe((item) => {
            const itemIdx = this.dndElement.sortableOrderArray.indexOf(item.orderObj);
            if (itemIdx !== -1) {
                this.dndElement.sortableOrderArray.splice(itemIdx, 1);
                delete item.orderObj;
            }
        });
    }

    ngAfterViewInit() {
        this.executeUpdateColumnSortArray();
    }

    checkUniqueStates = () => {
        if (!this.additionalSettings) {
            return false;
        }

        const isEntitySupported = ['Location', 'URI', 'User', 'Endpoint'].includes(this.additionalSettings.entityType);

        const isReportTypeElement = this.additionalSettings.reportType === this.reportRestService.reportTypes.ELEMENT;
        const isReportTypeAgg = this.additionalSettings.reportType === this.reportRestService.reportTypes.AGGREGATION;
        const isReportTypeTrend = this.additionalSettings.reportType === this.reportRestService.reportTypes.TREND;

        if (isReportTypeElement && isEntitySupported) {
            return 'TOP_10000';
        } else if ((isReportTypeAgg || isReportTypeTrend) && isEntitySupported) {
            return 'TOP_100';
        }

        return false;
    };

    updateTopType = () => {
        const TopTypeValue = this.checkUniqueStates();
        if (TopTypeValue) {
            this.topType.enable = true;
            setTimeout(() => {
                if (!this.isEdit || this.report.topType === undefined || this.report.topType === 'NOT_DEFINED') {
                    this.report.topType = TopTypeValue;
                }
            });
        }
    };

    isTopTypeCheckBoxDisabled = (entityType, reportType) => {
        return !!this.checkUniqueStates() || this.isViewOnly;
    };

    topTypeCheckboxChanged = () => {
        if (this.topType.enable) {
            this.report.topType = this.topTypeList[0].value;
        } else {
            this.report.topType = 'NOT_DEFINED';
        }
    };

    onDropItemOrder = (droppedItem) => {
        const idx = this.reportRestService.sortableOrderArray.findIndex((item) => item.id === droppedItem.id);
        this.reportRestService.sortableOrderArray.splice(idx, 1);

        setTimeout(() => {
            this.listChanged.emit();
            this.executeUpdateColumnSortArray();
        });
    };
}


