import {Component, Inject, Optional} from '@angular/core';
import {AcDialog, AcDialogComponent, DIALOG_CONFIG, DialogConfig} from 'ac-infra';

@AcDialog({
    id: 'workspace-not-active-dialog',
    title: 'Workspace Auto-Save not enabled',
})
@Component({
    selector: 'workspace-not-active-dialog',
    template: `
        <div>Workspace is not auto-saved.</div>
        <div>Changes will reset upon logging out. Turn on Workspace auto-save for persistent changes?</div>

        <ng-container *ac-dialog-actions>
            <ac-button class="clear" (acClick)="acDialogComponent.cancel()">Cancel</ac-button>
            <ac-button class="clear"
                       [ngDisabled]="dialogConfig.submitButtonProcessing"
                       (acClick)="submit({autoSave: false})">
                No, Save Only
            </ac-button>
            <ac-button [ngDisabled]="dialogConfig.submitButtonProcessing"
                       (acClick)="submit({autoSave: true})">
                Yes
            </ac-button>
        </ng-container>
    `,
    host:{
        'style': 'padding-bottom: 24px;',
    }
})
export class WorkspaceNotActiveDialogComponent {
    constructor(@Optional() @Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig,
                @Optional() public acDialogComponent: AcDialogComponent) {
    }

    submit(dialogData) {
        this.dialogConfig.dialogData = dialogData;
        this.acDialogComponent.onSubmit();
    }
}


