import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'taskName'})
export class TaskNamePipe implements PipeTransform {

    constructor() {
    }

    transform(task: any): any {
        return task.description || 'Unknown';
    }
}
