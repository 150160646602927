import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    EventEmitter,
    Input,
    OnInit,
    Output, Renderer2,
    TemplateRef,
    ViewChild
} from '@angular/core';

import {ITreeOptions, TreeNode} from '@circlon/angular-tree-component';
import {ITreeNode} from '@circlon/angular-tree-component/lib/defs/api';
import {AcDropDownComponent, AcTreeComponent, AcTreeService, ArrayUtil, GeneralService, IPDisplayPipe} from 'ac-infra';
import {WsEntitiesService} from '../../../common/services/communication/ws-entities.service';
import {MatMenuTrigger} from '@angular/material/menu';

@Component({
    selector: 'topology-tree',
    templateUrl: './topology-tree.component.html',
    styleUrls: ['./topology-tree.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopologyTreeComponent implements OnInit {

    @ViewChild('popupDropDown', {static: false}) popupDropDown: AcDropDownComponent;

    @ViewChild('acTree', {static: true}) acTree: AcTreeComponent;
    @ContentChild('nodePrefixIconTemplate', {static: true}) nodePrefixIconTemplate: TemplateRef<any>;

    @Input() treeId = 'topology-tree';

    @Input() treeNodes;
    @Input() checkbox = false;
    @Input() useTreeState = true;
    @Input() disabled = false;
    @Input() restoreExpanded = true;
    @Input() searchPlaceHolder;
    @Input() fakeAllSelected = false;
    @Input() getChildren: (node: TreeNode) => TreeNode[];

    @Input() active: any[];
    @Output() activeChange = new EventEmitter<any[]>();

    @Output() selectChange = new EventEmitter<any[]>();
    @Output() initialized = new EventEmitter<any[]>();
    @Output() updateData = new EventEmitter<any[]>();
    @Output() searchChange = new EventEmitter<string>();

    search: string;
    extendTreeOptions: ITreeOptions;
    statusColors = GeneralService.statusColors;
    entityType;
    dataItem;
    openedNodeId;

    constructor(private acTreeService: AcTreeService,
                private wsEntitiesService: WsEntitiesService,
                private ipDisplayPipe: IPDisplayPipe,
                private renderer: Renderer2,
    ) {}

    ngOnInit() {
        this.extendTreeOptions = {useCheckbox: this.checkbox, useTriState: this.useTreeState, getChildren: this.getChildren};
    }

    toggleMenu = (event: MouseEvent, node: TreeNode, actionMenu, menuTrigger: MatMenuTrigger) => {
        this.renderer.appendChild(actionMenu, (menuTrigger as any)._element.nativeElement);
        if (node.data.entityType) {
            this.entityType = node.data.entityType;
        } else {
            this.entityType = !node.realParent ? 'tenant' : 'region';
        }

        this.dataItem = this.wsEntitiesService.getEntity(this.entityType + 's', node.data.id);
        this.openedNodeId = node.data.id;
        menuTrigger.openMenu();
    };

    popupDropDownStatusChanged = (opened) => {
        if (!opened) {
            this.openedNodeId = undefined;
        }
    };

    filterByIpAddress = (node: ITreeNode, filter: string): boolean => {
        const ip = node?.data?.ipAddress && this.ipDisplayPipe.transform(node.data.ipAddress);
        return !!ip?.includes(filter);
    }
    filterBySbcSerialNumber = (node: ITreeNode, filter: string): boolean => (node.data.sbcInfo && node.data.sbcInfo.serialNum && node.data.sbcInfo.serialNum.indexOf(filter) > -1);
    topologyFilter = (node: ITreeNode, filter: string): boolean => this.filterByIpAddress(node, filter) || this.filterBySbcSerialNumber(node, filter);

    isNodeMarked = (node: ITreeNode, filter) => {
        return filter && (this.acTreeService.filterByName(node, filter) || this.topologyFilter(node, filter));
    };

    onActiveNodes = (nodes: any[]) => this.activeChange.emit(nodes.map(node => node.data));
    onInitialized = ($event) => this.initialized.next($event);
    onUpdateData = ($event) => {
        this.updateData.next($event);

        if (this.openedNodeId) {
            this.dataItem = this.wsEntitiesService.getEntity(this.entityType + 's', this.openedNodeId);

            setTimeout(() => {
                const nodeEl = document.getElementById(this.treeId + '-node-' + this.openedNodeId);
                const treeEl = document.getElementById(this.treeId);

                const nodeRect = nodeEl?.getBoundingClientRect();
                const treeRect = treeEl?.getBoundingClientRect();
                let nodeIsVisible = false;

                if (nodeRect && treeRect) {
                    nodeIsVisible = (nodeRect.y - treeRect.y) < treeRect.height;
                }

                if (!nodeIsVisible || !nodeEl || !this.dataItem) {
                    this.openedNodeId = undefined;
                    this.popupDropDown.close();
                }
            });
        }
    };

    onSelectNodes(nodes: TreeNode[]) {
        let selectedNodes = [];

        if (this.useTreeState) {
            this.findParents(nodes, selectedNodes);
        } else {
            selectedNodes = nodes;
        }

        this.selectChange.emit(selectedNodes.map(node => node.data));
    }

    findParents(nodes: TreeNode[], selectedNodes) {
        nodes && nodes.forEach((node: TreeNode) => {
            if (node.children && node.isPartiallySelected) {
                this.findParents(node.children, selectedNodes);
            } else if (node.isSelected) {
                selectedNodes.push(node);
            }
        });
    }

    resizeTree = () => this.acTree && this.acTree.resizeTree();
    updateTree = () => this.acTree && this.acTree.updateTree();
    isNodeHaveActions = (node, actionMenu, menuTrigger) => {
        if (node.data.id === this.openedNodeId) {
            this.renderer.appendChild(actionMenu, (menuTrigger as any)._element.nativeElement);
        }
        return !node.data.artificial;
    }

    setSelect(nodes: Array<any> | any, state = false, loadFromState = false) {
        const treeModel = this.acTree.tree.treeModel;
        if (!treeModel) {
            return;
        }

        const leafs = {};

        this.acTreeService.updateSelectNodes(nodes || treeModel.roots, state, leafs, treeModel.options.useTriState);
        this.acTree.setSelect(leafs, loadFromState);
    }

    setActive(nodes: Array<any> | any, state = true, expand = false, emit = true) {
        const activeNodeIds = {};
        nodes = ArrayUtil.oneToMany<any>(nodes);
        nodes.forEach((node) => activeNodeIds[node.id] = state);
        this.acTree.setActive(activeNodeIds, emit);
        expand && this.setExpand(nodes, true, true);
    }

    setExpand(nodes: any[], state = true, loadFromState = false) {
        const parentsIds = {};

        nodes.forEach((node) => {
            if (node.tenantId && !parentsIds[node.tenantId]) {
                parentsIds[node.tenantId] = state;
            }
        });
        this.acTree.setExpand(parentsIds, loadFromState);
    }

    onSearchChange($event: string) {
        this.search = $event;
        this.searchChange.emit(this.search);
    }
}
