import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {SoftwareManagerRestService} from '../../../system/configuration/configuration-api/software-manager-rest.service';

import {AcDialogService, GeneralService} from 'ac-infra';
import {ServerConfigurationRestService} from '../../../system/administration/administration-api/server-configuration-rest.service';
import {TenantsRestService} from '../../../network/services/apis/tenants-rest.service';
import * as _ from 'lodash';
import {RestResponseFailure, RestResponseSuccess} from '../../server-actions/rest';
import {LogoSelectionService} from './logo-selection.service';
import {AuthorizationService} from '../../services/authorization.service';
import {ServerInfoService} from "../../services/server-info.service";

@Component({
    selector: 'logo-selection',
    templateUrl: './logo-selection.component.html',
    styleUrls: ['./logo-selection.component.less']
})
export class LogoSelectionComponent {

    @Input() logoObject: any;
    @Output() logoIdChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() logotypeChanged: EventEmitter<string> = new EventEmitter<string>();
    @Input() fixedFiles: any;
    @Input() isLogoTypeIncluded = false;
    @Input() allowUpload = true;
    @Input() logoTypeName: string;
    @Input() ngDisabled = false;
    @Input() systemLogoId: number;
    @Input() systemLogoFile: any;
    @Input() label = '';
    @HostBinding('style.margin') margin = '0 10px';

    @Input() set sideMargin(sideMargin) {
        this.margin = sideMargin ? '0 10px' : null;
    }

    selectedTenant: any;
    hashedFiles: any = {};
    logoIsReady = false;
    firstInit = true;
    logoPath: any;
    statusColorsList: any;
    ngDisabledColor;
    FileSaver;
    oneLiveMode = ServerInfoService.serverInfo.oneLiveMode;
    isMonitorLinkOrOneLiveTenantUserOrGroupUsers: boolean;
    logoTypeGroupItems;

    constructor(private softwareManagerRestService: SoftwareManagerRestService,
                private generalService: GeneralService,
                private serverConfigurationRestService: ServerConfigurationRestService,
                private tenantsRestService: TenantsRestService,
                private acDialogService: AcDialogService,
                private logoSelectionService: LogoSelectionService,
                private authorizationService: AuthorizationService) {
        this.FileSaver = require('file-saver');
    }

    localTenantId: number;

    @Input() set tenantId(tenantId: number) {
        const success = () => {
            this.handleTenantChange(tenantId);
        };

        if (this.firstInit) {
            this.getSystemLogo(success);
        } else {
            this.handleTenantChange(tenantId);
        }
    }

    ngOnInit() {
        this.isMonitorLinkOrOneLiveTenantUserOrGroupUsers = this.authorizationService.validForMonitorLinkOrOneLiveTenantUserOrGroupUsers();

        this.statusColorsList = GeneralService.statusColors;

        if (this.ngDisabled) {
            this.ngDisabledColor = this.statusColorsList.disabledDark;
        }
    }

    getSystemFile = (onSuccessSystemFileProcess) => {
        const onGetFileSuccess = (systemFile) => {
            if (systemFile) {
                this.systemLogoFile = systemFile;
            }

            onSuccessSystemFileProcess();
        };
        this.getFiles(this.localTenantId, this.systemLogoId, onGetFileSuccess);
    };

    handleGlobalType = () => {
        this.updateFilesList(this.systemLogoFile ? [this.systemLogoFile] : []);
        this.logoObject.logoId = this.systemLogoId;
        this.logoIdChanged.emit(this.logoObject.logoId);
        if (this.systemLogoFile && this.logoObject.logoId > -1) {
            this.hashedFiles[this.logoObject.logoId] = this.systemLogoFile;
        }
        this.readImage();
    };

    handleTenantType = () => {
        if (!this.authorizationService.validForMonitorLinkOrOneLiveTenantUserOrGroupUsers()) {
            this.logoObject.logoId = this.selectedTenant && this.selectedTenant.logoId || -1;
            this.logoIdChanged.emit(this.logoObject.logoId);

            this.getFiles(this.localTenantId, this.logoObject.logoId, (file) => {
                if (file) {
                    this.hashedFiles[this.logoObject.logoId] = file;
                    this.updateFilesList([file]);
                } else {
                    this.updateFilesList([]);
                }
                this.readImage();
            });
        } else {
            this.readImage();
        }
    };

    onFileChange = (files) => {
        const tenantId = this.localTenantId < 0 ? -1 : this.localTenantId;
        if (this.logoObject[this.logoTypeName]) {
            this.logoObject[this.logoTypeName] = 'Custom';
            this.logotypeChanged.emit('Custom');
        }

        const file: any = {};
        file.content = files[0];
        file.data = {name: files[0].name, fileType: 'LOGO_TYPE', tenantId};

        const onSuccess = (response: RestResponseSuccess) => {
            file.data.id = response.data.id;
            const logoFiles = _.cloneDeep(this.logoObject.files);
            logoFiles.push(file.data);
            this.updateFilesList(logoFiles);

            this.logoObject.logoId = response.data.id;
            this.logoIdChanged.emit(this.logoObject.logoId);
            this.hashedFiles[this.logoObject.logoId] = file.data;
            this.readImage();
        };
        const onFailure = (error: RestResponseFailure) => {
            this.logoPath = undefined;
            this.logoObject.logoId = -1;
            this.logoIdChanged.emit(this.logoObject.logoId);
        };

        this.softwareManagerRestService.addFile(file.data, file.content, undefined, onSuccess, onFailure);
    };

    readImage = () => {
        const onSuccess = (response: RestResponseSuccess) => {
            this.logoSelectionService.handleLogoImage(response).then((resultLogo) => {
                this.logoPath = resultLogo;
                this.logoIsReady = true;
            });
        };

        if (!this.logoObject.logoId || this.logoObject.logoId === -1 || (this.hashedFiles[this.logoObject.logoId] && this.hashedFiles[this.logoObject.logoId].name === 'None')) {
            this.logoPath = undefined;
            this.logoIsReady = true;
        } else {
            this.softwareManagerRestService.getFileContent(onSuccess, () => {
                this.logoIsReady = true;
            }, this.logoObject.logoId);
        }
    };

    private handleTenantChange = (tenantId) => {
        this.localTenantId = tenantId;
        if (this.localTenantId && this.localTenantId > -1) {
            this.selectedTenant = this.tenantsRestService.getEntityById(this.localTenantId);
            if (this.logoObject[this.logoTypeName] === 'Global') {
                this.handleGlobalType();
            } else if (this.logoObject[this.logoTypeName] === 'Tenant') {
                this.handleTenantType();
            } else {
                this.getFiles(this.localTenantId);
            }

        } else {
            if (this.logoObject[this.logoTypeName] === 'Global') {
                this.handleGlobalType();
            } else if (this.logoObject[this.logoTypeName] === 'Tenant') {
                this.logoObject[this.logoTypeName] = 'Global';
                this.handleGlobalType();
            } else {
                this.getFiles(-1);
            }
        }

        this.handleLogoTypes();
    };

    private getSystemLogo = (onSuccessSystemFileProcess) => {
        if (this.systemLogoId) {
            this.getSystemFile(onSuccessSystemFileProcess);
        } else {

            const serverConfigurationSuccess = (response: RestResponseSuccess) => {
                this.systemLogoId = response && response.data && response.data.logoId || -1;

                this.getSystemFile(onSuccessSystemFileProcess);
            };

            this.serverConfigurationRestService.getServerConfiguration(serverConfigurationSuccess, () => {
                this.systemLogoId = -1;
            });
        }
    };

    typeChanged = () => {
        switch (this.logoObject[this.logoTypeName]) {
            case 'Global':
                this.handleGlobalType();
                break;
            case 'Tenant':
                this.handleTenantType();
                break;
            case 'Custom':
                this.logoObject.logoId = this.logoObject.files && this.logoObject.files.length > 0 && this.logoObject.files[0] && this.logoObject.files[0].id || -1;

                this.getFiles(this.localTenantId > -1 ? this.localTenantId : -1, undefined, undefined, true);
                break;
        }
    };

    private handleLogoTypes = () => {
        if (this.isLogoTypeIncluded) {
            const globalRadio = {
                value: 'Global',
                text: this.oneLiveMode && this.isMonitorLinkOrOneLiveTenantUserOrGroupUsers ? 'Tenant' : 'Global',
                disabled: this.ngDisabled
            };

            const tenantRadio = {
                value: 'Tenant',
                text: 'Tenant',
                disabled: this.ngDisabled || this.localTenantId < 0 || (this.selectedTenant && !this.selectedTenant.logoId),
                title: this.localTenantId < 0 ? 'Global or System Report Scope has no tenant logo' : ''
            };

            const customRadio = {value: 'Custom', text: 'Custom', disabled: this.ngDisabled};

            this.logoTypeGroupItems = !this.oneLiveMode || !this.isMonitorLinkOrOneLiveTenantUserOrGroupUsers ?
                [globalRadio, tenantRadio, customRadio] : [globalRadio, customRadio];
        }
    }

    private updateFilesList = (files) => {
        this.logoObject.files = files;
    };

    private checkIfFileExist = () => {
        let logoIdExistInFiles = false;
        this.logoObject.files.forEach((file) => {
            this.hashedFiles[file.id] = file;
            if (file.id === this.logoObject.logoId) {
                logoIdExistInFiles = true;
            }
        });

        if (!logoIdExistInFiles) {
            // this.logoObject.logoId = this.logoObject.files && this.logoObject.files.length > 0 ? this.logoObject.files[0].id : -1;
            // this.logoIdChanged.emit(this.logoObject.logoId);
        }
    };

    private getFiles = (tenantId, singleFileId = undefined, callback = undefined, forceReadImage = false) => {
        const softwareManagerFilter: any = {
            filter: {
                fileType: {values: ['LOGO_TYPE'], operators: ['=']},
                tenantId
            }
        };

        if (singleFileId) {
            softwareManagerFilter.filter = {id: singleFileId};
        }

        this.softwareManagerRestService.getFiles({
            success: (response: RestResponseSuccess) => {
                if (singleFileId) {
                    callback(response.data.files && response.data.files.length > 0 && response.data.files[0] || undefined);
                    return;
                }

                const resultFiles = response.data.files;
                this.updateFilesList(this.fixedFiles && this.fixedFiles.concat(resultFiles) || resultFiles);

                this.checkIfFileExist();

                if (this.firstInit || this.logoObject[this.logoTypeName] === 'Tenant' || forceReadImage) {
                    this.firstInit = false;
                    this.readImage();
                }
            },
            parameters: softwareManagerFilter
        });
    };

}



