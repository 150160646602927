import {Injectable} from '@angular/core';

import {Subject} from 'rxjs';
import {NetworkFilterFormatterService} from '../network-filter-formatter.service';
import {NetworkRest} from '../../../common/server-actions/network-rest';
import {AuthorizationService} from '../../../common/services/authorization.service';

@Injectable({providedIn: 'root'})
export class TenantsRestService extends NetworkRest {

    private validateAllocAuxiliaryFormSubject: Subject<any> = new Subject<any>();
    validateAllocAuxiliaryForm$ = this.validateAllocAuxiliaryFormSubject.asObservable();

    constructor(networkFilterFormatter: NetworkFilterFormatterService) {
        super({
            url: 'topology/tenants',
            entityType: 'tenants',
            entityFilterFormatter: networkFilterFormatter,
            filterEventName: 'NetworkFilter'
        });
    }

    executeValidateAllocAuxiliaryForm = ({errors, auxModel, formModel}) => {
        this.validateAllocAuxiliaryFormSubject.next({errors, auxModel, formModel});
    };


    public getOperatorConnect = (success, failure, tenantId) => {
        this.get({url: 'topology/operatorConnect/' + tenantId, extendParameters: true}).then(success).catch(failure);
    };
}
