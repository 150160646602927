import {Component, Inject} from '@angular/core';

import {AcDialog, DIALOG_CONFIG, DialogConfig} from '../../ac-dialog.models';

@AcDialog({
    id: 'info-dialog',
    cancelButtonText: 'OK',
    width: 450,
})
@Component({
    selector: 'info-dialog',
    templateUrl: './info-dialog.component.html',
})
export class InfoDialogComponent {
    message;

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {
    }

    ngOnInit() {
        this.message = this.dialogConfig.dialogData.message;
        this.dialogConfig.title = this.dialogConfig.title || 'Information';
    }
}


