import {Component, Inject} from '@angular/core';
import {AcDialog, DIALOG_CONFIG, DialogConfig} from 'ac-infra';
import {SessionService} from '../../../common/services/session.service';

@AcDialog({
    id: 'select-tenant-scope-dialog',
    submitButtonText: 'Select',
    cancelButtonText: 'Close',
    width: 420,
    overrideSystemViewMode: true
})
@Component({
    selector: 'select-tenant-scope-dialog',
    templateUrl: './select-tenant-scope-dialog.component.html',
    styleUrls: ['./select-tenant-scope-dialog.component.less']
})
export class SelectTenantScopeDialogComponent {

    entity: any = {};
    tenants: any[];
    sortTenants;
    globalMode;
    additionalSettings = {isGlobal: false};

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig,
                private sessionService: SessionService) {}

    ngOnInit() {
        this.entity =  this.dialogConfig.dialogData.entity;
        this.tenants =  this.dialogConfig.dialogData.tenants;
        this.sortTenants =  this.dialogConfig.dialogData.sortTenants;
        this.dialogConfig.title = this.dialogConfig.dialogData.title || 'Select Tenant';
        this.dialogConfig.formSettings = {valid: true, touched: true};
        this.globalMode = this.sessionService.activeSession.isSystemOperator;
        this.additionalSettings.isGlobal = this.globalMode && this.entity.tenantId === -1;
        this.isGlobalChanged();
    }

    isGlobalChanged = () => {
        if (this.additionalSettings.isGlobal) {
            this.entity.tenantId = -1;
        }else{
            this.entity.tenantId = (this.entity.tenantId !== -1 && this.entity.tenantId) || this.tenants[0]?.id;
        }
    };

    requiredsAuxiliaryForm = () => {
        const requiredList = [];

        if (!this.globalMode && this.entity.tenantId === -1) {
            requiredList.push('entity.tenantId');
        }

        return requiredList;
    };
}


