import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AcToggleComponent} from './ac-toggle.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

@NgModule({
    imports: [
        MatSlideToggleModule,
        CommonModule,
        FormsModule
    ],
    declarations: [
        AcToggleComponent,
    ],
    entryComponents: [
        AcToggleComponent,
    ],
    exports: [
        AcToggleComponent,
    ]
})
export class AcToggleModule {
}
