import {Component, EventEmitter, Input, Optional, Output, TemplateRef, ViewChild} from '@angular/core';
import {AcFormComponent} from '../ac-form/ac-form.component';
import {AcInputContainerComponent} from '../ac-input-container/ac-input-container.component';

@Component({
    selector: 'ac-file-picker',
    templateUrl: './ac-file-picker.component.html',
    styleUrls: ['./ac-file-picker.component.less'],
})

export class AcFilePickerComponent {
    @Input() fileName: any;
    @Input() filePickerButtonText: string;
    @Input() alternativeName: string;
    @Input() inputDisable: any;
    @Input() isReadOnly;
    @Input() multiple = false;
    @Input() onlyIcon = false;
    @Input() clearable = false;
    @Input() displayTextBox = true;
    @Input() ngDisabled = false;
    @Input() ngDisabledColor = 'gray';
    @Input() textFieldLabel = '';
    @Input() buttonId;
    @Output() acChanged: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('filePickerButton', {static: true}) filePickerButton: TemplateRef<any>;
    fileTypesString = '';

    constructor(@Optional() public acFormComponent: AcFormComponent,
                @Optional() public acInputContainerComponent: AcInputContainerComponent) {}

    _fileTypeAllowed: any;

    @Input() set fileTypeAllowed(fileTypeAllowed: any) {
        this._fileTypeAllowed = fileTypeAllowed;
        this.setFileTypes();
    }

    ngOnInit() {
        if(this.acInputContainerComponent){
            this.acInputContainerComponent.postTemplate = this.filePickerButton;
        }

        if(this.acFormComponent && this.acFormComponent.isViewMode){
            this.ngDisabled = true;
        }

        this.fileName = this.fileName || '';
    }

    setFileTypes = () => {
        this.fileTypesString = '';
        if (this._fileTypeAllowed) {
            this._fileTypeAllowed.forEach((type, idx, array) => {
                if (idx === array.length - 1) {
                    this.fileTypesString = this.fileTypesString + '.' + type;
                } else {
                    this.fileTypesString = this.fileTypesString + '.' + type + ',';
                }
            });
        }
    };

    fileChange = (event) => {
        const fileList: FileList = event.target.files;
        const filesToSend = [];
        const filesCount = fileList ? fileList.length : 0;
        if ((filesCount === 1 && !this.multiple) || (filesCount > 1 && this.multiple)) {// preparation for multiple file select limit
            this.fileName = '';

            for (let i = 0; i < filesCount; i++) {
                const fileExt = /[^.]+$/.exec(fileList[i].name)[0].toLowerCase();
                if ((this._fileTypeAllowed && this._fileTypeAllowed.indexOf(fileExt) !== -1) || !this._fileTypeAllowed) {
                    this.fileName += (i > 0) ? ', ' : '';
                    this.fileName += fileList[i].name;
                    filesToSend.push(fileList[i]);
                }
            }

            this.acChanged.emit(filesToSend);
            event.target.value = '';
        } else {
            console.log('only 1 file allowed');
        }
    }

    clearContent(event) {
        this.fileName = undefined;
        this.acChanged.emit();
        this.fileChange(event);
    }
}



