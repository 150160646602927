import {Injectable} from '@angular/core';
import {Rest} from '../../common/server-actions/rest';
import {AlarmsFilterFormatterService} from '../services/alarms-filter-formatter.service';

@Injectable({providedIn: 'root'})
export class AlarmsStatisticsRestService extends Rest {

    constructor(alarmsFilterFormatter: AlarmsFilterFormatterService) {
        super({entityFilterFormatter: alarmsFilterFormatter, filterEventName: 'AlarmsFilter'});
    }

    public getAlarmsSeveritySummary = (success, failure, moduleName) => {
        this.get({url: 'alarms/statistics/' + moduleName + '/severitySum', filteredEntity: moduleName, requestAPI: 'severitySum'}).then(success).catch(failure);
    };

    public getAlarmsTopXCriticalDevices = (success, failure, moduleName) => {
        this.get({url: 'alarms/statistics/' + moduleName + '/topXCriticalDevices', filteredEntity: moduleName, requestAPI: 'topXCriticalDevices'}).then(success).catch(failure);
    };

    public getAlarmsTopXAlarmNames = (success, failure, moduleName) => {
        this.get({url: 'alarms/statistics/' + moduleName + '/topXAlarmNames', filteredEntity: moduleName, requestAPI: 'topXAlarmNames'}).then(success).catch(failure);
    };

    public getAlarmsTopXHourlySeverity = (success, failure, moduleName) => {
        this.get({url: 'alarms/statistics/' + moduleName + '/topXHourlySeverity', filteredEntity: moduleName, requestAPI: 'topXHourlySeverity'}).then(success).catch(failure);
    };


    public getJournalTopXActivityDevices = (success, failure) => {
        this.get({url: 'journal/statistics/topXActivityDevices', filteredEntity: 'journal', requestAPI: 'topXActivityDevices'}).then(success).catch(failure);
    };

    public getJournalTopXActionTypes = (success, failure) => {
        this.get({url: 'journal/statistics/topXActionTypes', filteredEntity: 'journal', requestAPI: 'topXActionTypes'}).then(success).catch(failure);
    };


    // For Dashboard
    public getAllActiveAlarmsBySeverity = (success, failure, parameters) => {
        this.get({parameters, url: 'alarms/statistics/active/severitySum', requestAPI: 'severitySum', skipPopulateFilter: true}).then(success).catch(failure);
    };

    protected handleSuccess = (requestAPI, response) => {
        const defaultObject = requestAPI === 'severitySum' ? {data: {}} : {data: {items: []}};
        return response.status === 204 ? defaultObject : response;
    };
}
