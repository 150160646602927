import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {AcSelectComponent} from './ac-select.component';

import './ng-select-overrides';
import {InfraPipesModule} from '../../pipes/infra.pipes.module';
import {InfraDirectivesModule} from '../../directives/infra.directives.module';
import {AcButtonModule} from '../ac-button/ac-button.module';
import {AcIconsModule} from '../ac-icons/ac-icons.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        InfraPipesModule,
        InfraDirectivesModule,
        AcButtonModule,
        NgSelectModule,
        AcIconsModule,
    ],
    declarations: [
        AcSelectComponent,
    ],
    entryComponents: [
        AcSelectComponent,
    ],
    exports: [
        AcSelectComponent,
    ]
})
export class AcSelectModule {
}
