import {Component, Inject} from '@angular/core';
import {DIALOG_CONFIG, DialogConfig} from 'ac-infra';

@Component({
    selector: 'server-down-dialog',
    templateUrl: './server-down-dialog.component.html',
    styleUrls: ['./server-down-dialog.component.less'],
})
export class ServerDownDialogComponent {

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {
    }

    ngOnInit() {
        this.dialogConfig.id = 'server-down-dialog';
        this.dialogConfig.title = 'Server might be down!';
    }
}


