import {Directive, ElementRef} from '@angular/core';


@Directive({
    selector: '[acChild]',
})
export class AcChildDirective {

    constructor(private elementRef: ElementRef) {
    }
}
