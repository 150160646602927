import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {ExceptionService} from './exception.service';

@Injectable()
export class ExceptionHandlerService implements ErrorHandler {

    constructor(private injector: Injector) {}

    handleError(error) {
        if (error.message.includes('ExpressionChangedAfterItHasBeenCheckedError')) {
            return;
        }
        const ExceptionClass = this.injector.get(ExceptionService);


        ExceptionClass.checkError(error);
    }
}
