import {Injectable} from '@angular/core';
import * as _ from 'lodash';

import {SessionStorageService} from 'ac-infra';
import {FilterState} from '../../common/components/ac-filter/services/ac-filter-state.service';


@Injectable({providedIn: 'root'})
export class FilterUtilsService {

    constructor(private filterState: FilterState) {}

    getSelectedTopologyItem = () => {
        const item = this.filterState.getStorageData(FilterState.PINNED_STATE);

        if (_.isNil(item)) {
            return undefined;
        }

        if (item.Topology && item.Topology.tenant && item.Topology.region === undefined && item.Topology.tenant.length === 1) {
            return item.Topology.tenant[0];
        }

        if (item.Topology && item.Topology.region && item.Topology.tenant === undefined && item.Topology.region.length === 1) {
            return item.Topology.region[0];
        }

        return undefined;
    }
}
