<ac-layout-section-header *ngIf="layoutHeaderTemplate || layoutTitle"
                          [sectionTitle]="layoutTitle"
                          [postfixTitle]="postfixTitle"
                          class="flexRowParent flexItem layout-header">
    <ng-template [ngTemplateOutlet]="layoutHeaderTemplate"></ng-template>
</ac-layout-section-header>

<div class="flexRowParent flexItemBiggest layout-sections">
    <ng-content select="ac-layout-section, [section]"></ng-content>
</div>
