import {Injectable, Optional} from '@angular/core';
import {Subject} from 'rxjs';

export interface AcTrackerParams {
    category: string;
    action: string;
    name?: string;
    value?: number;
}

@Injectable({
    providedIn: 'root'
})
export class AcTrackerService {

    acTrackerIsActive = false;
    private trackEventSubject = new Subject<AcTrackerParams>();
    trackEvent$ = this.trackEventSubject.asObservable();

    setAcTrackerIsActive = (acTrackerIsActive = false) => {
        this.acTrackerIsActive = acTrackerIsActive;
    };

    trackEvent = (category: string, action: string, name?: string, value?: number) => {
        if(this.acTrackerIsActive){
            this.trackEventSubject.next({category, action, name, value});
        }
    };
}
