import {Component, Inject, Input} from '@angular/core';
import {DIALOG_CONFIG, DialogConfig} from 'ac-infra';

@Component({
    selector: 'widget-dialog-container',
    template: `
        <ac-input-container *ngIf="dialogConfig.dialogData.title" label="Panel Title" [upperPadding]="false">
            <ac-textfield [placeholderText]="placeholder"
                          [(acModel)]="widgetCustomTitle"
                          (acModelChange)="onPanelTitleChange($event)"></ac-textfield>
        </ac-input-container>

        <ng-content></ng-content>
    `,
})
export class WidgetDialogContainerComponent {
    @Input() placeholder = ''
    widgetCustomTitle = '';

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {
    }

    ngOnInit() {
        const {title, widgetConfig} = this.dialogConfig.dialogData;
        this.widgetCustomTitle = (widgetConfig?.title || title)?.toLowerCase();
    }

    onPanelTitleChange($event: string) {
        const title = $event?.toLowerCase();
        if (!title || title === (this.dialogConfig.dialogData.title)?.toLowerCase()) {
            delete this.dialogConfig.dialogData.widgetConfig.title;
            return;
        }
        this.dialogConfig.dialogData.widgetConfig.title = title;
    }

}


