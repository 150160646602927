<ac-progress *ngIf="loading || !dialogConfig.ready"></ac-progress>

<div [hidden]="loading || !dialogConfig.ready"
     [attr.id]="dialogConfig.id"
     class="dialog flexColumnParent {{dialogConfig.customClasses?.dialog}}"
     cdkDragBoundary="body"
     cdkDrag
     (cdkDragStarted)="onDrag()"
     [style.width.px]="dialogConfig.width"
     #dialog>
    <div class="dialog-header flexItem prevent-text-selection {{dialogConfig.customClasses?.header}}"
         cdkDragHandle
         #windowHeader>

        <div class="{{dialogConfig.elementsColorPalette?.titleColor || dialogConfig.colorPalette}}" acLabel>
            <ng-container *ngIf="!dialogConfig.titleTemplate">{{dialogConfig.title | uppercase}}</ng-container>
            <ng-template [ngTemplateOutletContext]="dialogConfig"
                         [ngTemplateOutlet]="dialogConfig.titleTemplate"
            ></ng-template>
        </div>

    </div>

    <div class="dialog-body flexItemBiggest {{dialogConfig.customClasses?.content}}"
         [style.height.px]=" dialogConfig.height">
        <ng-template #dialogContentHost></ng-template>
    </div>

    <div class="dialog-footer flexItem {{dialogConfig.customClasses?.footer}}" #buttonsEl
         [class.no-buttons]="buttonsEl?.children?.length === 0"
         [class.reverse-order]="dialogConfig.reverseButtonsOrder">

        <ng-template [ngTemplateOutlet]="dialogConfig.actionsTemplate"
                     [ngTemplateOutletContext]="dialogConfig"
        ></ng-template>

        <ng-container *ngIf="!dialogConfig.actionsTemplate">

            <ng-template [ngTemplateOutlet]="dialogConfig.additionalActionsTemplate"
                         [ngTemplateOutletContext]="dialogConfig"
            ></ng-template>

            <ac-button *ngIf="!dialogConfig.noCancelButton"
                       [buttonId]="dialogConfig.cancelButtonText | acFunc : getButtonText: 'Cancel': '-dialog-btn'"
                       [buttonValue]="dialogConfig.cancelButtonText | acFunc : getButtonText: 'Cancel'"
                       buttonType="stroked-button"
                       class="ac-dialog-btn ac-dialog-btn-cancel {{dialogConfig.colorPalette || dialogConfig.elementsColorPalette?.cancelButtonColor}}"
                       (acClick)="cancel()"
            ></ac-button>

            <ac-button *ngIf="!dialogConfig.noSubmitButton && dialogConfig.onSubmit && !allFormsAreInViewMode"
                       [buttonId]="dialogConfig.submitButtonText | acFunc : getButtonText: 'OK': '-dialog-btn'"
                       [buttonValue]="dialogConfig.submitButtonText | acFunc : getButtonText: 'OK'"
                       [ngDisabled]="dialogConfig.submitButtonDisabled || dialogConfig.submitButtonProcessing"
                       class="ac-dialog-btn ac-dialog-btn-submit {{dialogConfig.elementsColorPalette?.submitButtonColor || dialogConfig.colorPalette}}"
                       (acClick)="onSubmit()"
            ></ac-button>
        </ng-container>
    </div>
</div>
