import {Injectable} from '@angular/core';
import {NetworkFilterFormatterService} from '../network-filter-formatter.service';
import {NetworkRest} from '../../../common/server-actions/network-rest';


@Injectable({providedIn: 'root'})
export class RegionsRestService extends NetworkRest {

    constructor(networkFilterFormatter: NetworkFilterFormatterService) {
        super({url: 'topology/regions', entityType: 'regions', entityFilterFormatter: networkFilterFormatter, filterEventName: 'NetworkFilter'});

    }
}
