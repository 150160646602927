<tree-root class="ac-tree"
           [class.visibilityHidden]="loading"
           [id]="treeId"
           [nodes]="_treeNodes"
           [options]="treeOptions"
           (initialized)="onInitialized($event)"
           (updateData)="onUpdateData($event)"
           #tree>
    <!--TREE TEMPLATE-->
    <ng-template #treeNodeWrapperTemplate *ngIf="customTreeNodeWrapperTemplate" let-index="index" let-node>
        <!--        OVOC TEMPLATE-->
        <ng-template [ngTemplateOutletContext]="{$implicit: node, index: index}"
                     [ngTemplateOutlet]="customTreeNodeWrapperTemplate"
        ></ng-template>
    </ng-template>

    <ng-template #treeNodeFullTemplate
                 let-index="index"
                 let-node="node"
                 let-templates="templates">

        <div class="tree-node tree-node-level-{{ node.level }}">

            <tree-node-wrapper [index]="index" [node]="node" [templates]="templates"></tree-node-wrapper>
            <tree-node-children *ngIf="node.hasChildren" [node]="node" [templates]="templates"></tree-node-children>
        </div>
    </ng-template>
</tree-root>

<ac-progress [class.visibilityHidden]="!loading" [center]="true" size="small"></ac-progress>
