import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {AcDropDownComponent} from '../../ac-dropdown/ac-dropdown.component';

@UntilDestroy()
@Component({
    selector: 'ac-date-picker-container',
    templateUrl: './ac-date-picker-container.component.html',
    styleUrls: ['./ac-date-picker-container.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcDatePickerContainerComponent {
    @ViewChild(AcDropDownComponent, {static: true}) acDropDown: AcDropDownComponent;

    @Input() disabled = false;
    @Output() onToggleClick = new EventEmitter();
    @Output() close = new EventEmitter();
}

