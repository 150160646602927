import {ComponentFactoryResolver, Injectable, Injector, RendererFactory2, Type} from '@angular/core';
import {GeneralService} from '../../services/general.service';
import {AcTableRow} from './ac-table.interface';

export type ComponentInputs<T> = { [key: string]: any } | T;

@Injectable({
    providedIn: 'root'
})
export class AcTableService {
    private renderer;
    readonly SELECT_DEBOUNCE_TIME = 500;
    readonly NO_DEBOUNCE = 0;

    constructor(private injector: Injector,
                private factoryResolver: ComponentFactoryResolver,
                private rendererFactory2: RendererFactory2) {
        this.renderer = this.rendererFactory2.createRenderer(null, null);
    }

    get selectDebounceTime() {
        return GeneralService.testMode ? this.NO_DEBOUNCE : this.SELECT_DEBOUNCE_TIME;
    }

    trackById = (index: number, row) => row.id;
    trackByField = (index: number, column) => column.field;

    widthToPixels = (width: number, minValue = 0) => {
        return isNaN(width) ? '' : (Math.max(width, minValue) + 'px');
    };

    columnWidth = (width: number, minValue = 0) => {
        return Math.max(isNaN(width) ? 0 : width, minValue);
    }

    getRowIndexById = (rows: AcTableRow[], rowId) => {
        if (!rowId) {
            return 0;
        }
        return rows?.findIndex(row => row.id === rowId);
    };

    createComponentRef = <T>(componentType: Type<T>, componentInputs?: ComponentInputs<T>, attributes?: { [key: string]: any }, styles?: Array<{style: string, value: string}>) => {

        const componentRef = this.factoryResolver.resolveComponentFactory(componentType).create(this.injector);

        componentInputs && Object.assign(componentRef.instance, componentInputs);

        attributes && Object.getOwnPropertyNames(attributes).forEach((attributeName) => {
            this.renderer.setAttribute(componentRef.location.nativeElement, attributeName, attributes[attributeName]);
        });

        styles?.forEach(({style, value}) => {
            this.renderer.setStyle(componentRef.location.nativeElement, style, value);
        });


        return componentRef;
    };
}
