import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'bar-chart',
    templateUrl: './bar-chart.component.html',
    styleUrls: ['./bar-chart.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarChartComponent {

    title = '';
    fractions = '';

    _barsInfo: Array<{value: number; title: string; color: string}>;
    @Input() set barsInfo(barsInfo: Array<{value: number; title: string; color: string}>) {
        this._barsInfo = barsInfo;
        this.title = '';
        this.fractions = '';
        const sum = barsInfo.reduce((acc, barInfo) => (acc += barInfo.value, acc), 0);

        barsInfo.forEach((barInfo) => {
            const val = (sum > 0 ? (barInfo.value / sum) * 100 : 0).toString();
            const idx = val.indexOf('.');
            this.title += (barInfo.title) + ': ' + val.substr(0,idx > 0 ? idx + 2 : val.length) + '% ';
            this.fractions += val + 'fr ';
        });
    }

    isEmpty = (barInfo) => {
        return !barInfo.reduce((acc, curr) => acc || (curr?.value > 0), false);
    };
}



