import {Component, EventEmitter, Input, Optional, Output, ViewChild} from '@angular/core';
import {AcFormComponent} from '../ac-form/ac-form.component';
import {GeneralService} from '../../services/general.service';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {AcInputContainerComponent} from '../ac-input-container/ac-input-container.component';

@Component({
    selector: 'ac-toggle',
    templateUrl: './ac-toggle.component.html',
    styleUrls: ['./ac-toggle.component.less'],
})
export class AcToggleComponent {
    @Input() toggleId = 'slideToggleId';
    @Input() acModel = false;
    @Output() acModelChange = new EventEmitter<boolean>();

    @Input() ngDisabled = false;
    @Input() text = '';
    @Input() size = '';
    @Input() allowPropagation = false;
    @ViewChild('matSlideToggle') matSlideToggle: MatSlideToggle;

    backgroundColor = GeneralService.statusColors.systemBackground;

    extraSmall = false;

    constructor(@Optional() public acInputContainerComponent: AcInputContainerComponent,
                @Optional() public acFormComponent: AcFormComponent) {}

    ngOnInit() {
        if(this.acFormComponent && this.acFormComponent.isViewMode){
            this.ngDisabled = true;
        }

        if (this.size === 'extra-small') {
            // this.size = 'small';
            this.extraSmall = true;
        }
    }

    ngAfterViewInit(){
        this.matSlideToggle.writeValue(this.acModel);
    }

    onChange = (value: boolean) => {
        this.acModelChange.emit(value);
    };
}



