import {Injectable} from '@angular/core';

interface Sound {
    soundPath: string;
    audio?: any;
}

interface SoundsLibrary {
    [sound: string]: Sound;
}

@Injectable({
    providedIn: 'root'
})
export class AudioPlayerService {

    sounds: SoundsLibrary = {
        alarm: {
            soundPath: 'assets/sounds/alarms/alarm.wav',
        },
    };

    constructor() {

    }

    setSoundAudio = (sound: Sound) => {
        sound.audio = new Audio();
        sound.audio.src = sound.soundPath;
        sound.audio.load();
    };

    play = (soundType: string) => {
        const sound = this.sounds[soundType];

        if (!sound) {
            return;
        } else if (!sound.audio) {
            this.setSoundAudio(sound);
        }
        sound.audio.play();
    };


}
