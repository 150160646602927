<ac-layout-panel class="fullContainer">
    <ng-template #panelHeader>Entities</ng-template>
    <div class="inner-container filter-container statistics-pm-parameters-filter-tab flexColumnParent">
        <div class="flexItemBiggest">
            <div *ngIf="selectedTopic && profilesOptionsObject[selectedTopic.id]?.fetch === true"
                 class="data-in-progress">
                <ac-progress size="small"></ac-progress>
            </div>

            <ac-input-container label="All Selected" class="pm-indexes-select-container"
                                *ngIf="selectedTopic && profilesOptionsObject[selectedTopic.id]?.fetch !== null && profilesOptionsObject[selectedTopic.id]?.fetch !== true && shouldContainEntitiesIndexes()">
                <ac-select [(acModel)]="selectedTopic.indexes"
                           (acModelChange)="changeHappened()"
                           [emptyModelIndicatesAllSelected]="true"
                           [items]="profilesOptionsObject[selectedTopic.id]?.options"
                           [showSelectedCount]="true"
                           direction="auto">
                </ac-select>
            </ac-input-container>


            <div *ngIf="selectedTopic && profilesOptionsObject[selectedTopic.id]?.fetch === null"
                 class="data-completed">
                No Data Found
            </div>
        </div>

        <div *ngIf="!shouldContainEntitiesIndexes()" class="flexColumnParent">
            Entities are not available for a singular topic
        </div>
    </div>
</ac-layout-panel>


