import {UntilDestroy} from '@ngneat/until-destroy';
import {Component, EventEmitter, Input, Optional, Output, TemplateRef, ViewChild} from '@angular/core';
import {AcInputContainerComponent} from '../ac-input-container/ac-input-container.component';
import {MatFormFieldControl} from '@angular/material/form-field';
import {AcFormComponent} from '../ac-form/ac-form.component';
import {MatAutocomplete, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import * as _ from 'lodash';
import {GeneralService} from '../../services/general.service';
import {CdkConnectedOverlay, OverlayContainer, OverlayRef} from '@angular/cdk/overlay';
import {ArrayUtil} from '../../utils/array-util';

@UntilDestroy()
@Component({
    selector: 'ac-single-select',
    templateUrl: './ac-single-select.component.html',
    styleUrls: ['./ac-single-select.component.less']
})
export class AcSingleSelectComponent {

    @Input() selectId: string;
    @Input() placeholder = '';
    @Input() bindLabel = 'text';
    @Input() bindValue = 'value';
    @Input() useFullObjectInModel = false;
    @Input() selectFirstAfterSort = true;
    @Input() labelPostfix = '';
    @Input() ngDisabled = false;
    @Input() addCustomTags = false;
    @Input() allowClear = false;
    @Input() overrideSystemViewMode = false;
    @Input() optionTitle: string;
    @Input() displayImage = false;
    @Input() dropDownOptionsClass = '';
    @Input() dynamicWidth = false;
    @Input() itemTemplate: TemplateRef<any>;
    @Input() labelTemplate: TemplateRef<any>;
    @ViewChild(MatFormFieldControl) matFormFieldControl;
    @ViewChild('autocompleteInput', {read: MatAutocompleteTrigger}) triggerAutocompleteInput: MatAutocompleteTrigger;
    @ViewChild('auto', {static: true}) matAutocomplete: MatAutocomplete;

    @Output() acModelChange = new EventEmitter<any>();
    @Output() onOpen: EventEmitter<any> = new EventEmitter<any>();

    _acModel;
    @Input() set acModel(acModel) {
        this._acModel = acModel;
        this.selected = this.itemsMapper?.[this._acModel];
        this.initializeItems();
    }

    itemsMapper;
    _items: any[] = [];
    @Input() set items(items: any) {
        this.itemsMapper = [];
        items = items?.map(item => {
            const itemType = typeof item;
            if (itemType === 'object' || itemType === 'function') {
                return item;
            }
            return {[this.bindLabel]: item, [this.bindValue]: item};
        });

        items?.forEach(item => {
            this.itemsMapper[item[this.bindValue]] = item;
            if (item[this.bindLabel]) {
                item[this.bindLabel] = item[this.bindLabel] + this.labelPostfix;
            }
        });
        this.selected = this.itemsMapper?.[this._acModel];
        this._items = items;

        this.initializeItems();
        this.filterItems();
    }

    _sortItems;
    @Input() set sortItems(sortItems: boolean) {
        this._sortItems = sortItems;
        this.initializeItems();
    }

    filteredItems;
    selected;
    isFocused;
    newTag;
    colors = GeneralService.statusColors;

    constructor(@Optional() public acInputContainerComponent: AcInputContainerComponent,
                @Optional() public acFormComponent: AcFormComponent,
                public generalService: GeneralService) {
        // console.log(o);
    }


    ngOnInit() {
        this.filterItems();
    }

    ngAfterViewInit() {
        if (this.acInputContainerComponent) {
            this.acInputContainerComponent.initializeMaterialField(this.matFormFieldControl);
        }

        if (this.acFormComponent && this.acFormComponent.isViewMode) {
            this.ngDisabled = !this.overrideSystemViewMode;
        }

        setTimeout(() => {
            const selectId = this.selectId || (this.acInputContainerComponent?.acInputContainerId);
            if (selectId) {
                this.selectId = `ngSelect-${selectId}`;
            }
        });
    }

    checkIfValueIsInItems = (event) => {
        let valueToEmit;
        if (this.filteredItems?.length === 1 &&
            (event?.toString().toLowerCase() === this.filteredItems[0][this.bindLabel]?.toLowerCase() ||
                event?.toString().toLowerCase() === this.filteredItems[0][this.bindValue]?.toString().toLowerCase())) {
            valueToEmit = this.useFullObjectInModel ? this.filteredItems[0] : this.filteredItems[0][this.bindValue];
            !_.isNil(valueToEmit) && this.modelChanged(valueToEmit);
        } else if (!_.isNil(event)) {
            valueToEmit = (typeof event === 'object' && this.useFullObjectInModel) ? event : this.itemsMapper?.[event]?.[this.bindValue];
            if (this.addCustomTags) {
                this.addTag(event);
            }else if ((!_.isNil(valueToEmit) && (typeof event === 'string' || typeof  event === 'number')) || this._items?.length === 0) {
                 this.modelChanged(valueToEmit);
            }
        }

        // don't do this.updateSelection(valueToEmit) if both condition are false.
    };

    addTag = (value) => {
        if(!value){
            return;
        }

        if (typeof value !== 'object') {
            this.newTag = {[this.bindLabel]: value.toString(), [this.bindValue]: value.toString()};
        } else {
            this.newTag = undefined;
            this.modelChanged(this.bindValue ? value[this.bindValue] : value);
        }
    };

    findItemInFilteredItemsList = (value) => {
        const checkedValue = value?.toString().toLowerCase();

        return this.filteredItems.find(item => {
            const itemValue = item?.[this.bindValue]?.toString().toLowerCase();
            const itemLabel = item?.[this.bindLabel]?.toString().toLowerCase();
            return [itemValue, itemLabel].includes(checkedValue);
        });
    };

    enterWasPressed = () => {
        if (this.addCustomTags) {
            this.handleNewTag();
        }

        this.triggerAutocompleteInput.closePanel();
    }
    handleNewTag = () => {
        // this.triggerAutocompleteInput.closePanel();
        if (this.addCustomTags && this.newTag) {
            const item = this.findItemInFilteredItemsList(this.newTag[this.bindValue]);

            if (item) {
                this.modelChanged(this.useFullObjectInModel ? item : item[this.bindValue]);
            } else {
                const itemExistInList = this._items.some((checkedItem) => checkedItem[this.bindValue] === this.newTag[this.bindValue]);
                if (!itemExistInList) {
                    this.items = [...this._items, this.newTag];
                }

                this.modelChanged(this.useFullObjectInModel ? this.newTag : this.newTag[this.bindValue]);
                this.newTag = undefined;
            }
        }
    };

    focusOut() {
        this.isFocused = false;
        if (this.addCustomTags && this.newTag) {
            this.handleNewTag();
        } else {
            this.triggerAutocompleteInput?.writeValue(this._acModel);
        }
    }

    focusIn = () => {
        this.isFocused = true;
    };

    modelChanged = (value) => {
        if (this.addCustomTags) {
            this.addTag(value);
        }

        this.acModelChange.emit(value);
        this.triggerAutocompleteInput?.writeValue(value);
        this.filterItems();
    };

    filterItems = (filterValue = undefined) => {
        if (!this._items) {
            return;
        }

        this.filteredItems = !filterValue ? this._items : this._items.filter(item => {
            const exactValue = (filterValue && item[this.bindValue].toString().toLowerCase() === filterValue);
            return ArrayUtil.findInArrDelimitered([item], this.bindLabel, filterValue).length === 1 || exactValue;
        });
    };

    initializeItems = () => {
        if (!this._sortItems) {
            return;
        }

        this._items = ArrayUtil.sort(this._items, this.bindLabel);

        if (this.selectFirstAfterSort && (this._acModel === '' || this._acModel === undefined) && this._items?.length > 0) {
            this.modelChanged(this._items[0][this.bindValue]);
        }
    };

    isSelectDisabled = () => {
        return (this.ngDisabled || this.acInputContainerComponent?.isDisabled);
    };

    displayWith = (value) => {
        if (_.isNil(value)) {
            return '';
        }

        let displayedValue;
        if (typeof value === 'object') {
            const mapperValue = this.itemsMapper?.[value[this.bindValue]]?.[this.bindLabel];
            const actualValue = value?.[this.bindLabel];

            displayedValue = !_.isNil(mapperValue) ? mapperValue : (!_.isNil(actualValue) ? actualValue : (value || ''));
        }

        return displayedValue || this.itemsMapper?.[value]?.[this.bindLabel] || '';
    };

    clear = () => {
        this.acModelChange.emit(undefined);
        this.triggerAutocompleteInput.writeValue(undefined);

        const selectFirstAfterSort = this.selectFirstAfterSort;
        this.selectFirstAfterSort = false;
        setTimeout(() => {
            this.selectFirstAfterSort = selectFirstAfterSort;
        });
    };

    getTextValue = (item, returnFakeValue = false) => {
        const result = item?.[this.bindLabel] || (typeof item === 'string' ? item : undefined) || undefined;

        return result || (returnFakeValue ? 'hide' : '');
    };

    opened = ($event) => {
        this.triggerAutocompleteInput?.['_overlayRef']?.['_attachBackdrop']?.(); // Fix for: items-dropdown-panel not being closed after clicked outside. happens when ac-single-select is put inside ac-dropdown-menu (or any other parent of material component with overlay).
        this.onOpen.emit($event)
    }
}



