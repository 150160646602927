import {Component, Inject} from '@angular/core';

import {forOwn} from 'lodash';

import {SessionStorageService} from '../../../../services/session-storage.service';
import {dateTimeFormatter, FormatterType} from '../../../../utils/date-time-formatter';
import {AcDialog, DIALOG_CONFIG, DialogConfig} from '../../ac-dialog.models';


@AcDialog({
    id: 'error-dialog',
    title: 'Unhandled Errors',
    cancelButtonText: 'Close',
    submitButtonText: 'Copy to clipboard',
    width: 700,
    colorPalette: 'red'
})
@Component({
    selector: 'error-dialog',
    templateUrl: './error-dialog.component.html',
    styleUrls: ['./error-dialog.component.less'],
})
export class ErrorDialogComponent {

    ERROR_LIMIT = 10;
    errors;

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {
    }

    ngOnInit() {
        this.errors = SessionStorageService.getData('errorDialog.errors') || [];

        this.dialogConfig.dialogData.exception.showStack = false;
        this.dialogConfig.dialogData.exception.timestamp = Date.now();

        this.errors.unshift(this.dialogConfig.dialogData.exception);

        const exceeding = this.errors.length - this.ERROR_LIMIT;

        for (let i = 0; i < exceeding; i++) {
            this.errors.pop();
        }
        this.dialogConfig.preSubmit = () => this.copyToClipboard();
        this.dialogConfig.onSubmit = this.dialogConfig.onSubmit || (() => null);

        SessionStorageService.setData('errorDialog.errors', this.errors);
    }

    copyToClipboard = () => {
        let valueToCopy = '';

        forOwn(this.errors, (error) => {
            const date = new Date(error.timestamp * 1000);
            valueToCopy += dateTimeFormatter(date, FormatterType.dateAndTime) + ': ';
            valueToCopy += error.error.message + '\n';
            valueToCopy += error.stack + '\n\n';
        });

        this.copyTextFromHiddenTextArea(valueToCopy);
    };

    copyTextFromHiddenTextArea = (valueToCopy) => {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = valueToCopy;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    };
}


