import {DecimalPipe, TitleCasePipe} from '@angular/common';

import {NgModule} from '@angular/core';
import {RemoveUnderscorePipe} from './common/pipes/remove-underscore.pipe';
import {AuthorizationPipe} from './common/pipes/authorization.pipe';

@NgModule({
    providers: [

        // Angular
        DecimalPipe,
        TitleCasePipe,

        // Pipes
        AuthorizationPipe,
        RemoveUnderscorePipe,
    ]
})
export class OVOCServicesModule {}
