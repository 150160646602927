import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {debounceTime} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Subject} from 'rxjs';
import {ArrayUtil} from '../../utils/array-util';


@UntilDestroy()
@Component({
    selector: 'ac-search',
    templateUrl: './ac-search.component.html',
    styleUrls: ['./ac-search.component.less'],
})
export class AcSearchComponent {
    private _items: any[];
    @Input() set items(value: any[]) {
        this._items = value;
        this.doSearch();
    }

    @Input() componentId: string;
    @Input() key: string;
    @Output() filteredItems = new EventEmitter<any>();
    @Input() instant = true;
    @Input() autoFocusOnInit = false;
    @Input() hideSearchButton = false;

    @Input() search = '';
    @Output() searchChange: EventEmitter<string> = new EventEmitter<string>();
    inputHasFocus = false;
    private readonly DEFAULT_PLACEHOLDER = 'Search by name';
    private update$: Subject<any>;

    _placeHolder = this.DEFAULT_PLACEHOLDER;

    @Input() set placeHolder(placeholder) {
        this._placeHolder = placeholder || this.DEFAULT_PLACEHOLDER;
    }

    @ViewChild('input', {static: true}) input: ElementRef;

    constructor() {
        this.update$ = new Subject<any>();
        this.update$.pipe(
            debounceTime(400),
            untilDestroyed(this),
        ).subscribe(this.doSearch);
    }

    ngOnInit() {
        this.input.nativeElement.focus();
    }

    ngOnDestroy() {

    }

    clearContent() {
        this.search = '';
        this.onSearch('');
    }

    onSearch = (event: string) => {
        this.search = event;
        this.update$.next(null);
    };

    private doSearch = () => {
        this.filteredItems.emit(ArrayUtil.findInArrDelimitered(this._items, this.key, this.search));
        this.searchChange.emit(this.search);
    };
}
