import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class MiscellaneousJSUtilsService {
    constructor() {
    }

    public arrayToObjectByKey = (array, key) => array.reduce(function(acc, cur) {
        acc[cur[key]] = cur;
        return acc;
    }, {});
}
