import {Injectable} from '@angular/core';

declare global {
    // eslint-disable-next-line id-blacklist
    interface String {
        ReplaceAll(search: string, replacement: string): string;
    }
}

String.prototype.ReplaceAll = function(search, replacement) {
    const target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
};

Object.defineProperty(Array.prototype, 'filterKeepRef', {
    writable: true,
    value(func) {
        const temp = this.filter(func);
        this.length = 0;
        temp.forEach(function(item) {
            this.push(item);
        });
    }
});


@Injectable()
export class PrototypesService {

}
