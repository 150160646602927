import {Component, HostListener, Input} from '@angular/core';
import {NavigationService} from '../../utilities/navigation.service';


@Component({
    selector: 'ac-link',
    templateUrl: './ac-link.component.html',
    styleUrls: ['./ac-link.component.less'],
})

export class AcLinkComponent {

    @Input() state;
    @Input() parameters = {};
    @Input() ngDisabled = false;

    constructor(private navigationService: NavigationService) {}

    @HostListener('click', ['$event'])
    onClick(event: Event) {
        event.stopPropagation();
        this.state && this.navigationService.goToWithFilter(this.state, this.parameters);
    }
}



