<div class="ac-form-container" [class.flexColumnParent]="!isDialog">
    <form #formElement
          [class.flexItemBiggest]="!isDialog"
          name="{{formName}}"
          novalidate>
<!--          [ngFormOptions]="{ allowInvalid: true }"-->
        <div class="ac-form-content">
            <ng-content></ng-content>
        </div>
    </form>

    <div *ngIf="!isDialog" [class.visibilityHidden]="!_canSubmit" [class.flexItem]="!isDialog" class="submit-button-wrapper">
        <ng-template *ngIf="additionalFormActionsTemplate" [ngTemplateOutlet]="additionalFormActionsTemplate"
                     [ngTemplateOutletContext]="{form: this}"></ng-template>

        <ac-button (acClick)="onSubmit()" *ngIf="!isViewMode"
                   [ngDisabled]="disableOkButton || false"
                   buttonValue="{{submitButtonValue}}"
                   class="ac-dialog-btn submit-button">
        </ac-button>
    </div>

</div>

