<ac-layout-panel class="fullContainer statistics-pm-parameters-filter-tab-container">
    <ng-template #panelHeader><span class="filter-header">Filter</span></ng-template>

    <div class="inner-container filter-container statistics-pm-parameters-filter-tab flexColumnParent">
        <div *ngIf="shouldContainEntitiesIndexes()" class="flexColumnParent">
            <div class="flexItem">
                <ac-input-container label="Entities">
                    <ac-single-select [(acModel)]="filterEntities.selected"
                               (acModelChange)="onFilterEntitiesChange($event)"
                               [items]="filterEntities.options"
                               [ngDisabled]="isDisabled">
                    </ac-single-select>
                </ac-input-container>


                <ac-input-container *ngIf="filterEntities.selected !== 'All'" label="Rule">
                    <ac-textfield [(acModel)]="_selectedTopic[filterRuleType]"
                                  [ngDisabled]="isDisabled"
                                  textFieldId="filter-rule">
                    </ac-textfield>
                </ac-input-container>

            </div>
        </div>

        <div *ngIf="!shouldContainEntitiesIndexes()" class="flexColumnParent">
            Filters are not available for a singular topic
        </div>
    </div>
</ac-layout-panel>


