import {Inject, Injectable, Injector, Optional} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {AcDialogService, AcDropdownChild, DIALOG_CONFIG, DialogConfig, SessionStorageService} from 'ac-infra';
import {SaveDashboardItem} from '../dashboard.modals';
import {isEqual} from 'lodash';
import {AddDashboardWidgetDialogComponent} from '../components/add-dashboard-widget-dialog.component';
import {InfoPanelService} from '../components/info-panel/info-panel.service';
import {AuthorizationService} from '../../common/services/authorization.service';
import {DashboardConfigurationsService} from './dashboard-configurations.service';
import {DashboardAuthGroupConfigurationsService} from './dashboard-auth-group-configurations.service';
import {Widget, WidgetItem, WidgetsState} from '../../common/widget/widget.modals';
import {CompactType, DisplayGrid, GridsterConfig, GridType} from 'angular-gridster2';
import {WidgetService} from '../../common/widget/services/widget.service';

@UntilDestroy()
@Injectable()
export class DashboardService extends WidgetsState {

    static readonly DASHBOARD_STATE_KEY = 'dashboardItems';

    constructor(private dashboardConfigurationsService: DashboardConfigurationsService,
                private dashboardAuthGroupConfigurationsService: DashboardAuthGroupConfigurationsService,
                private infoPanelService: InfoPanelService,
                private acDialogService: AcDialogService,
                private authorizationService: AuthorizationService,
                private widgetService: WidgetService,
                @Optional() @Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig,
                private injector: Injector,) {
        super();
        this.infoPanelService.getVisibleHighlights(() => this.generateDashboardItems());
    }

    get isOneLiveGroupsView() {
        return this.authorizationService.validForServiceProvider() || this.authorizationService.validForChannel() || this.authorizationService.validForEndCustomer();
    }

    get dashboardSessionKey() {
        const sessionKeyPostFix = `_${AuthorizationService.isGlobalScope() ? 'global' : 'tenant'}Scope`;
        return DashboardService.DASHBOARD_STATE_KEY + sessionKeyPostFix;
    }

    addWidget = () => {
        this.acDialogService.open(AddDashboardWidgetDialogComponent, {
            injector: this.injector,
            dialogData: {
                visibleDashboardItems: this.getDefaultItems(false),
                currentDashboardItems: this.currentWidgetItemsSubject.getValue(),
            },
            onSubmit: (addWidgetType) => {
                const newDashboardItem = this.dashboardConfigurationsService.getDefaultDashboardItemByType(addWidgetType);
                super.addWidgets([newDashboardItem]);
            }
        });
    };

    saveDashboardItemsState = () => {
        const ItemsToSave = this.getDashboardItemsToSave();
        this.saveItemsState(this.dashboardSessionKey, ItemsToSave);
    };

    getDashboardItemsToSave = (): SaveDashboardItem[] => {
        const currentItems = this.removeWidgetFromItems(this.currentWidgetItemsSubject.getValue());

        return !this.isDashboardItemsDefault(currentItems, false) ? currentItems : undefined;
    };

    isDashboardItemsDefault = (dashboardItems: SaveDashboardItem[], removeWidgets = true): boolean => {
        if (!dashboardItems) {
            return false;
        }
        dashboardItems = removeWidgets ? this.removeWidgetFromItems(dashboardItems) : dashboardItems;
        const defaultItems = this.removeWidgetFromItems(this.getDefaultItems(!this.dialogConfig));

        return isEqual(dashboardItems, defaultItems);
    };

    isCurrentStateSaved = (): boolean => {
        const savedDashboardItems = this.removeWidgetFromItems(this.savedStateWidgetItems);
        const currentDashboardItems = this.removeWidgetFromItems(this.currentWidgetItemsSubject.getValue());

        return isEqual(savedDashboardItems, currentDashboardItems);
    };

    resetToDefaultItems = () => {
        this.generateDashboardItems(true);
    };

    registerDropdownActionsToWidget = (widgetItem: WidgetItem, widgetComponentInstance?: Widget): AcDropdownChild[] => {
        return [
            this.widgetService.widgetConfigureAction({widgetItem, widgetComponentInstance},
                () => {
                    this.updateCurrentItems(widgetItem, {...widgetItem});
                }
            ),
            this.widgetService.widgetClearConfigAction(widgetItem,
                (widgetItemNoConfig) => {
                    this.updateCurrentItems(widgetItem, {...widgetItemNoConfig});
                }
            ),
            {
                displayName: 'remove',
                onClick: () => this.updateCurrentItems(widgetItem),
            }
        ].filter(action => !!action);
    };

    getDashboardGridOptions(config?: GridsterConfig): GridsterConfig {
        return {
            ...WidgetService.DEFAULT_CONFIG,
            gridType: GridType.VerticalFixed,
            displayGrid: DisplayGrid.None,
            compactType: CompactType.CompactUp,
            fixedRowHeight: 186,
            minCols: 8,
            maxCols: 8,
            ...config
        };
    }

    private generateDashboardItems = (resetItems = false) => {
        const savedItems = SessionStorageService.getData(this.dashboardSessionKey);
        const isAuthGroupConfig = !!this.dialogConfig;
        const needAuthGroup = !resetItems || !isAuthGroupConfig;
        const dashboardItems = (!savedItems || resetItems || isAuthGroupConfig) ? this.getDefaultItems(needAuthGroup) : savedItems.map(this.enrichSavedItem);

        this.updateItems(dashboardItems, {updateSaved: !resetItems});
    };

    private enrichSavedItem = (savedItem: SaveDashboardItem) => {
        return {
            ...savedItem,
            widget: this.dashboardConfigurationsService.getWidgetByType(savedItem.type)
        };
    };

    private getDefaultItems = (needAuthGroup = true): WidgetItem[] => {
        if (needAuthGroup) {
            const {widgets} = this.dashboardAuthGroupConfigurationsService.getConfigByAuthGroup(this.authorizationService.securityLevel) || {};

            if (widgets?.dashboardItems) {
                return widgets.dashboardItems.map((authGroupDashboardItem) => {
                    const {widget} = this.dashboardConfigurationsService.getDefaultDashboardItemByType(authGroupDashboardItem.type);
                    return {widget, ...authGroupDashboardItem};
                });
            }
        }

        const callsStatisticsItem = this.dashboardConfigurationsService.getDefaultDashboardItemByType('calls-statistics');
        const externalLinksItem = this.dashboardConfigurationsService.getDefaultDashboardItemByType('external-links');
        const isOneLiveGroupsView = this.isOneLiveGroupsView;
        const isGlobalScope = this.authorizationService.securityLevel.startsWith('SYSTEM') && AuthorizationService.isGlobalScope();

        return [
            this.dashboardConfigurationsService.getDefaultDashboardItemByType('entities-summary'),
            !isOneLiveGroupsView && externalLinksItem,
            this.dashboardConfigurationsService.getDefaultDashboardItemByType('active-alarms'),
            !isGlobalScope && callsStatisticsItem,
            (this.infoPanelService.releaseHighlights.length > 0) && this.dashboardConfigurationsService.getDefaultDashboardItemByType('info-panel'),
        ].filter(item => !!item);
    };

}
