import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AcLabelComponent} from './ac-label.component';
import {InfraDirectivesModule} from '../../directives/infra.directives.module';

@NgModule({
    imports: [
        CommonModule,
        InfraDirectivesModule,
        FormsModule
    ],
    declarations: [
        AcLabelComponent,
    ],
    entryComponents: [
        AcLabelComponent,
    ],
    exports: [
        AcLabelComponent,
    ]
})
export class AcLabelModule {
}
