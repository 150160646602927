import {Component, Input, TemplateRef} from '@angular/core';
import {GeneralService} from 'ac-infra';
import {LogoDisplayService} from '../../services/logo-display.service';
import {ServerInfoService} from "../../services/server-info.service";

@Component({
    selector: 'ac-splash-screen',
    templateUrl: './ac-splash-screen.component.html',
    styleUrls: ['./ac-splash-screen.component.less'],
})
export class AcSplashScreenComponent {
    @Input() pageTitle = '';
    @Input() errorMessage = '';
    @Input() ready = true;
    @Input() hasErrorField = false;
    @Input() logoTemplate: TemplateRef<any>;
    @Input() companyLogoTemplate: TemplateRef<any>;
    @Input() backgroundTemplate: TemplateRef<any>;
    serverInfoService = ServerInfoService;

    constructor(public generalService: GeneralService, public logoDisplayService: LogoDisplayService) {}
}



