<div class="externalLinkContainer">
    <div class="app-explanation">{{appExplanationObj[linkValue.id]}}</div>

    <a class="app-discover-more" *ngIf="linkValue.discoverMoreUrl" (click)="dicoverMore()">Discover more</a>

    <div class="app-actions-container flexRowParent">
        <ac-action-button class="flexItemBiggest dashboard-action-button"
                          buttonType="flat-button"
                          type="custom"
                          [text]="linkValue.name"
                          PointerEventDefault=""
                          (acClick)="openLink()"
                          [ngDisabled]="hasNoLinkInConfig && !linkValue.disabledLink"
        ></ac-action-button>

        <ac-button class="small flexItem configContainer {{getButtonClass(linkValue.name)}}"
                   [class.visibilityHidden]="!hasConfig"
                   buttonType="icon-button"
                   (acClick)="goToConfig()">
            <ac-svg name="settings" [fillColor]="systemColors.primary"></ac-svg>
        </ac-button>
    </div>
</div>
