import {Directive, forwardRef, Host, Input, Optional, SkipSelf, TemplateRef} from '@angular/core';
import {AcTabsComponent} from './ac-tabs.component';
import {AcParentDirective} from '../../directives/ac-parent.directive';

@Directive({
    selector: '[acTab]',
    providers: [{provide: AcParentDirective, useExisting: forwardRef(() => AcTabDirective)}]
})
export class AcTabDirective extends AcParentDirective {
    title: string;
    public errors = {};
    hasErrors = false;

    @Input('acTab') set titleSetter(title: string) {
        this.title = title;
        if (!this.id) {
            this.id = this.title.toLowerCase().replace(' ', '-');
        }
    }

    @Input('acTabContentTitle') contentTitle: string;
    @Input('acTabId') id: string = null;
    @Input('acTabHeight') height: string = null;
    @Input('acTabDisabled') disabled = false;
    @Input('acTabIsValid') isValid = true;

    @Input('acTabIf')
    private set if(ifResult: boolean) {
        this._if = !!ifResult;
        this.acTabsComponent.refresh();
    }

    _if = true;

    constructor(public templateRef: TemplateRef<any>,
                @SkipSelf() @Optional() private parentAcTabDirective: AcTabDirective,
                @Host() public acTabsComponent: AcTabsComponent) {
        super();
    }

    setError = (key, value) => {
        this.errors[key] = value;
        this.hasErrors = Object.values(this.errors).some(error => error)
        this.parentAcTabDirective?.setError(this.id, this.hasErrors);
    }

    clearErrorsObject = () =>{
        this.errors = {};
    }
}
