'use strict';
module.exports = function localize_en(errors) {
    if (!(errors && errors.length)) {
        return;
    }
    for (let i = 0; i < errors.length; i++) {
        const e = errors[i];
        let out;
        switch (e.keyword) {
            case 'srcAndDestURIRequired':
                out = 'At least one of the  URIs must be filled';
                break;
            case 'userAttrsNotFilled':
                out = 'User name or at least one of the dictionary attributes must be filled';
                break;
            case 'atLeastOne':
                out = 'At least one checkbox must be selected';
                break;
            case 'atLeastOneShouldntBeNone':
                out = 'At least one of values needs to be active';
                break;
            case 'outOfRangeThershold':
                out = 'goodFairThreshold must be lower than fairPoorThreshold';
                break;
            case 'outOfRangeReverseThershold':
                out = 'goodFairThreshold must be higher than fairPoorThreshold';
                break;
            case 'outOfRangeAlarm':
                out = 'criticalThreshold must be higher than majorThreshold';
                break;
            case 'outOfRangeReverseAlarm':
                out = 'majorThreshold must be higher than criticalThreshold';
                break;
            case 'IPFDQNAux':
                out = 'Parameter should match format "IPv4" or "FDQN"';
                break;
            case 'IPv4Aux':
                out = 'Parameter should match format "IPv4"';
                break;
            case 'IPv6Aux':
                out = 'Parameter should match format "IPv6"';
                break;
            case 'IPv4or6Aux':
                out = 'Parameter should match format "IPv4" or "IPv6"';
                break;
            case 'IPRangeAux':
                out = 'The specified IP range is invalid';
                break;
            case 'IPvIdentical':
                out = 'IP Versions does not match';
                break;
            case 'valueExceeded':
                out = 'Field exceeded its allowed value';
                break;
            case 'SerialNumAndLettersOnlyAux':
                out = 'Parameter should contain only numbers and letters';
                break;
            case 'SerialLimitsAux':
                out = 'Parameter should match format 6-50 digits';
                break;
            case 'invalidMail':
                out = 'Parameter should match format "email"';
                break;
            case 'futureSuspend':
                out = 'Auto Suspend date must be in the future';
                break;
            case 'noGroupAux':
                out = 'Parameter can not be \'NO GROUP\'';
                break;
            case 'timeAux':
                out = 'From Time is later then To Time';
                break;
            case 'requiredAtLeastOneMetric':
                out = 'at least one metric should be selected';
                break;
            case 'biggerThen':
                const n = e.params.limit;
                out = 'Parameter should have value bigger then ' + n;
                break;
            case 'equalPasswords':
                out = 'Passwords can not be matched';
                break;
            case 'confirmPassword':
                out = 'Confirm password does not match';
                break;
            case 'invalidPassword':
                out = 'Password should not be shorter than 8 characters';
                break;
            default:
                continue;
        }
        e.message = out;
    }
};
