<div *ngIf="(loading || !noData) else noDataRef"
     class="progress-container"
     [class.ac-progress-center]="center"
     [style.height.px]="currentSize"
     [style.width.px]="currentSize">

    <ng-container>
        <ng-container *ngIf="!animationLoaded">Loading...</ng-container>

        <ng-lottie [options]="options"
                   (domLoaded)="onAnimationLoaded()"
        ></ng-lottie>
    </ng-container>

</div>

<ng-template #noDataRef>
    <div class="no-data">No Data</div>
</ng-template>
