import {Directive, Inject, TemplateRef} from '@angular/core';
import {DIALOG_CONFIG, DialogConfig} from '../ac-dialog.models';


@Directive({selector: '[ac-dialog-actions], [acDialogActions]'})
export class AcDialogActionsDirective {

    constructor(
        @Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig,
        private templateRef: TemplateRef<any>,
    ) {
        dialogConfig.actionsTemplate = templateRef;
    }
}
