import {Component, EventEmitter, Input, Optional, Output} from '@angular/core';
import {AcFormComponent} from '../ac-form/ac-form.component';
import {AcInputContainerComponent} from '../ac-input-container/ac-input-container.component';

@Component({
    selector: 'ac-checkbox',
    templateUrl: './ac-checkbox.component.html',
    styleUrls: ['./ac-checkbox.component.less'],
})
export class AcCheckboxComponent {
    @Input() set acModel(model: boolean | 'indeterminate') {
        this.model = model;
        this.indeterminate = model === 'indeterminate';
    }

    @Input() checkboxId = '';
    @Input() labelText = '';
    @Input() labelPosition: 'before' | 'after' = 'after';
    @Input() ngDisabled: boolean;
    @Input() sideMargin = true;
    @Input() labelOnly = false;
    @Input() noRipple = false;
    @Input() fullLine = false;

    @Output() acModelChange = new EventEmitter<boolean>();


    model;
    indeterminate: boolean;

    constructor(@Optional() public acInputContainerComponent: AcInputContainerComponent,
                @Optional() public acFormComponent: AcFormComponent) {
    }

    ngOnInit() {
        if(this.acFormComponent && this.acFormComponent.isViewMode){
            this.ngDisabled = true;
        }
    }

    checkboxValueChanged($event) {
        this.acModelChange.emit($event);
    }
}



