import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AcCheckboxModule} from '../ac-checkbox/ac-checkbox.module';

import {MatInputModule} from '@angular/material/input';
import {AcInputContainerComponent} from './ac-input-container.component';
import {AcLabelModule} from '../ac-label/ac-label.module';
import {InfraDirectivesModule} from '../../directives/infra.directives.module';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
    imports: [
        CommonModule,
        AcCheckboxModule,
        MatInputModule,
        AcLabelModule,
        InfraDirectivesModule,
        MatTooltipModule
    ],
    declarations: [
        AcInputContainerComponent,
    ],
    entryComponents: [
        AcInputContainerComponent,
    ],
    exports: [
        AcInputContainerComponent,
    ]
})
export class AcInputContainerModule {
}
