import {Component, inject, Input} from '@angular/core';
import * as _ from 'lodash';
import {FilterState} from '../services/ac-filter-state.service';

@Component({
    template: ''
})
export abstract class AcFilterType {

    filterState = inject(FilterState);

    emitFilterChange = () => {
        this.filterState.emitFilterChange(this);
    }

    abstract filterName: string;
    @Input() filterTitle = '';
    @Input() checkboxGroups: FilterTypeCheckboxGroups;
    @Input() fieldNameMapper;
    @Input() disableRemove;
    hasChanged;
    createItem;
    getCustomContent;
    getCustomContentTemplate;
    getTitleByField: Function;
    initializeState;
    notEditable = false;
    onRemoveItem;
    showPinnable = false;
    pinnable = false;
    pinned = false;
    previouslyPinned: boolean;
    expanded;
    pinningDisabled = false;
    first: boolean;
    selectionFields: FilterTypeSelectionFields;
    lastItem;
    lastItem_default;
    abstract setStateForItem;

    isValid: Function = () => true;

    onExpand = () => {};

    ngOnInit(){
        if(this.lastItem_default){
            this.lastItem = _.cloneDeep(this.lastItem_default);
        }
    }
}

export interface FilterTypeCheckboxGroups {
    [key: string]: {
        checkboxGroupId: string;
        value?: any;
        map?: any;
        textClass?: any;
        label?: string;
        iconType?: string;
        showIcon?: boolean;
        iconClasses?: string;
        options?: Array<{
            text: string;
            name: string;
        }>;
    };
}

export interface FilterTypeSelectionFields {
    [key: string]: {
        addCustomTags?: boolean;
        isAuthorized?: boolean;
        isVisible?: boolean;
        listName?: string;
        list?: any;
        options?: any;
        prefixList?: any;
    };
}
