import {Injectable} from '@angular/core';
import {Rest, RestResponseSuccess} from '../../../common/server-actions/rest';
import {QoeFilterFormatter} from '../../services/qoeFilterFormatter';
import {AnalyticsResults} from '../analytics.modal';
import {GeneralService, RequestOptions} from 'ac-infra';
import {AuthorizationService} from '../../../common/services/authorization.service';
import {LicenseRestService} from '../../../network/services/apis/license-rest.service';
import {AuthGroup} from '../../../common/utilities/session-helper.service';
import {SessionService} from '../../../common/services/session.service';
import {ServerInfoService} from "../../../common/services/server-info.service";


@Injectable({
    providedIn: 'root'
})
export class AnalyticsRestService extends Rest {

    private isAnalyticsLicense = false;

    constructor(private authorizationService: AuthorizationService,
                private qoeFilterFormatter: QoeFilterFormatter,
                private sessionService: SessionService,
                private generalService: GeneralService,
                private licenseRestService: LicenseRestService) {
        super({
            entityFilterFormatter: qoeFilterFormatter,
            filterEventName: 'QOEFilter',
            urlToFilterCallingApi: {
                'analytics/analyticsReports/actions/execute': 'analytics'
            }
        });

    }

    updateScopeLicense = async () => {
        this.isAnalyticsLicense = false;
        const isAuthorized = (!ServerInfoService.serverInfo.oneLiveMode && !this.authorizationService.validFor(AuthGroup.TENANT_MONITORING_LINKS)) ||
            (ServerInfoService.serverInfo.oneLiveMode && this.sessionService.activeSession.isSystemOperator);

        if (!AuthorizationService.isGlobalScope() && isAuthorized) {
            const allocatedLicense = await this.licenseRestService.getLicenseAllocationById(AuthorizationService.tenantScope, {useCachedConnection: true}).then((res) => res?.data);
            this.isAnalyticsLicense = !!allocatedLicense?.voiceQualityFeatures?.analyticsStatus;
        }
    }

    isLicenseActive = (): boolean => {
        return this.isAnalyticsLicense;
    }

    getReportPresets = async () => {
        const {data} = await this.get({
            url: 'analytics/analyticsReports/reports',
            extendParameters: true
        }) as RestResponseSuccess;

        if (!data) {
            return;
        }

        return data.analyticsReports;
    }

    getMetrics = async () => {
        const {data} = await this.get({
            url: 'analytics/analyticsReports/metrics',
            extendParameters: true
        }) as RestResponseSuccess;
        if (!data) {
            return;
        }

        console.log('data', data)
        return data.analyticsCallsColumns;
    }

    executeReportByIds = async (reportIds: number[], requestOptions?: RequestOptions): Promise<AnalyticsResults> => {
        const {data} = await this.get({
            url: 'analytics/analyticsReports/actions/execute',
            extendParameters: true,
            parameters: {
                filter: {id: [...reportIds]}
            },
            ...requestOptions
        }) as RestResponseSuccess;

        if (!data) {
            return;
        }

        return data.results;
    }
}
