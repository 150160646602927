import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'ac-form-group',
    templateUrl: './ac-form-group.component.html',
    styleUrls: ['./ac-form-group.component.less']
})

export class AcFormGroupComponent {

    @Output() acClick = new EventEmitter<any>();
    @Input() isClickable = false;
    @Input() titles;
    @Input() titleText;
    @Input() size: 'small' | 'large' = 'small';
    @Input() borderMode = false;
    @Input() contentAsColumn = true;
    @Input() noSideGap = false;
    @Input() subTitleMode = false;
}



