import {Component, Inject} from '@angular/core';
import {AcDialog, DIALOG_CONFIG, DialogConfig} from 'ac-infra';
import {MetadataService} from '../../../metadata/metadata.service';
import * as _ from 'lodash';

@AcDialog({
    id: 'log-levels-dialog',
    title: 'Log Levels Details',
    cancelButtonText: 'Close',
    width: 400
})
@Component({
    selector: 'log-levels-dialog',
    templateUrl: './log-levels-dialog.component.html',
    styleUrls: ['./log-levels-dialog.component.less'],
})
export class LogLevelsDialogComponent {

    logDebugLevelList;
    logDebugLevelItems = [];
    logNameTypeList;
    logLevel;

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {
    }

    ngOnInit() {
        this.logLevel = this.dialogConfig.dialogData.logLevel;
        this.logDebugLevelList = MetadataService.getType('LogDebugLevel');
        this.logNameTypeList = MetadataService.getType('LogNameType');
        this.logDebugLevelItems = [];

        _.forOwn(this.logDebugLevelList, (value) => {
            this.logDebugLevelItems.push(value);
        });

        this.logLevel.value = this.logDebugLevelItems.length > 0 ? this.logDebugLevelItems[0].name : undefined;
    }
}


