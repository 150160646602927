import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {GeneralService} from 'ac-infra';

@Component({
    selector: 'icon-and-text-template',
    templateUrl: './icon-and-text-template.component.html',
    styleUrls: ['./icon-and-text-template.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconAndTextTemplateComponent {

    @Input() iconTitle: string;
    @Input() iconName: string;
    @Input() iconClasses: string;
    @Input() width = '16px';
    @Input() height = '16px';
    @Input() fillColor: string;
    @Input() linkText: string;
    @Input() text: string;
    @Input() titleOfText: string;
    @Input() textClass: string;
    @Input() onClickLink: () => any;
    @Input() onlyIcon = true;

    constructor() {
        this.fillColor = this.fillColor || GeneralService.statusColors.primary;
    }
}



