import {Directive, HostListener, Input} from '@angular/core';
import {AcDialogRef, DialogData} from '../ac-dialog.models';

@Directive({
    selector: '[ac-dialog-close], [acDialogClose]',
    exportAs: 'acDialogClose',
})
export class AcDialogCloseDirective {

    @Input('ac-dialog-close') onCloseResult: DialogData;

    constructor(
        private acDialogRef: AcDialogRef
    ) {}

    @HostListener('click')
    onClick() {
        this.acDialogRef.close(this.onCloseResult);
    }
}
