<ac-form *ngIf="dialogConfig.ready"
         [formModel]="customer"
         [formSchema]="schema"
         [isEdit]="isEdit"
         formModelName="customer">

    <div id="customer-details-dialog-content">
        <div class="flexRowParentEqualChildren">
            <ac-input-container label="Customer Name" [displayAsInfo]="true" formFieldName="additionalSettings.name">
                <ac-textfield [(acModel)]="additionalSettings.name"
                              [ngDisabled]="true" textFieldId="customer-name">
                </ac-textfield>
            </ac-input-container>


            <ac-input-container label="Customer Source" [displayAsInfo]="true" formFieldName="additionalSettings.customerSource">
                <ac-textfield [(acModel)]="additionalSettings.customerSource"
                              [ngDisabled]="true"
                              textFieldId="customer-customerSource">
                </ac-textfield>
            </ac-input-container>
        </div>

        <ac-input-container label="Description" formFieldName="customer.description">
            <ac-textfield [(acModel)]="customer.description"
                         textFieldId="customer-description">
            </ac-textfield>
        </ac-input-container>

        <ac-input-container *ngIf="serverInfoService?.serverInfo?.meteringEnabled &&
                                    supportedCustomerSourceTypes.includes(additionalSettings.customerSource)"
                            label="Live Setup ID" formFieldName="customer.meteringTag">
            <ac-textfield [(acModel)]="customer.meteringTag"
                          textFieldId="audio-codes-customer-metering-tag">
            </ac-textfield>
        </ac-input-container>

        <ac-input-container label="Azure AD Group: Object ID" formFieldName="customer.adGroupId">
            <ac-textfield [(acModel)]="customer.adGroupId"
                          textFieldId="audio-codes-customer-adGroupId">
            </ac-textfield>
        </ac-input-container>
    </div>
</ac-form>
