import {Component, Input, SimpleChanges} from '@angular/core';
import * as _ from 'lodash';

@Component({
    selector: 'statistics-pm-parameters-filter-tab',
    templateUrl: './statistics-pm-parameters-filter-tab.component.html',
    styleUrls: ['./statistics-pm-parameters-filter-tab.component.less'],

})

export class StatisticsPmParametersFilterTabComponent {
    _selectedTopic;
    @Input() set selectedTopic(selectedTopic: any) {
        this._selectedTopic = selectedTopic;
        this.selectedTopicChanged();
    }

    @Input() isDisabled: boolean;

    FILTER_RULE_MAP = {
        All: 'None',
        'By Name': 'Name',
        'By Index': 'Index'
    };

    lastSelectedProfile = null;

    filterRuleType;
    filterEntities = {
        options: [],
        selected: 'All'
    };

    constructor() {

    }

    shouldContainEntitiesIndexes = () => this._selectedTopic && this._selectedTopic.type !== 'SingleParameters';

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            const funcName = propName;
            if (_.isFunction(this[funcName])) {
                this[funcName](changes, propName);
            }
        }
    }

    selectedTopicChanged = () => {
        if (this._selectedTopic) {
            this.initializeRuleTypeData();
            this.initializeFilterData();
        }
    };

    onFilterEntitiesChange = (value) => {
        this.filterEntities.selected = value;
        this.setFilterEntitiesKey(value);
        this.setFilterRuleParameterName(value);
        if (_.isObject(this._selectedTopic) && (this._selectedTopic !== this.lastSelectedProfile)) {
            this.lastSelectedProfile = this._selectedTopic;

            this.initializeRuleTypeData();
            this.initializeFilterData();
        }


    };

    private setFilterEntitiesKey = (value) => {
        this._selectedTopic.ruleType = this.FILTER_RULE_MAP[value];
    };

    private setFilterRuleParameterName = (value) => {
        this.filterRuleType = value.includes('Index') ? 'indexRule' : 'nameRule';
    };

    private initializeFilterData() {
        this.filterEntities.selected = (this._selectedTopic && this._selectedTopic.ruleType) ?
            this.getKeyObjectByValue(this.FILTER_RULE_MAP, this._selectedTopic.ruleType) : 'All';

        this.setFilterRuleParameterName(this.filterEntities.selected);
    }

    private getKeyObjectByValue = (obj, value) => Object.keys(obj).filter((key) => this.FILTER_RULE_MAP[key] === value)[0];

    private initializeRuleTypeData = () => {
        this.filterEntities.options = [];

        const ruleMapArr = (this._selectedTopic.type === 'TableParamsWithName') ?
            ['All', 'By Index', 'By Name'] : ['All', 'By Index'];

        _.forOwn(ruleMapArr, (ruleMap) => {
            this.filterEntities.options.push({value: ruleMap, text: ruleMap});
        });
    };

}



