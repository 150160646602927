import {Injectable} from '@angular/core';
import {ExternalApplicationsRestService} from '../../system/configuration/configuration-api/external-applications-rest.service';
import * as _ from 'lodash';
import {Action, createSelector, Selector, State, StateContext, StateToken} from '@ngxs/store';
import {ExternalApplication} from './external-application.actions';
import {RestResponseSuccess} from '../server-actions/rest';
import {ExternalApplicationsActionsService} from '../../system/configuration/configuration-actions/external-applications-actions.service';

export type ExternalApplicationStateModel = any[];

export const EXTERNAL_APPLICATIONS_STATE_TOKEN = new StateToken<ExternalApplicationStateModel>('externalApplications');

@State({
    name: EXTERNAL_APPLICATIONS_STATE_TOKEN,
    defaults: []
})
@Injectable()
export class ExternalApplicationState {

    static readonly LowSeverityStatus = {Unmanaged: true, Managed: true, Clear: true};
    static readonly MediumSeverityStatus = {'Conf Mismatch': true, Warning: true, Minor: true, Major: true};

    constructor(private externalApplicationsRestService: ExternalApplicationsRestService,
                private externalApplicationsActionsService: ExternalApplicationsActionsService) {
    }

    static application(applicationId: string) {
        const findApplication = (state) => state.find((app) => app.id.toLowerCase() === applicationId);

        return createSelector([ExternalApplicationState], findApplication);
    }

    @Selector()
    static highSeverityCount(state): number {
        return state.reduce((counter, curApp) => {
            if (!this.LowSeverityStatus[curApp.status]) {
                counter++;
            }
            return counter;
        }, 0);
    }

    @Selector()
    static highestSeverityColor(state): string {
        let highestSeverityLevel = 0;
        return state?.reduce((severityColor, curApp) => {
            let severityLevel = 0;
            if (this.LowSeverityStatus[curApp.status]) {
                return severityColor;
            } else if (this.MediumSeverityStatus[curApp.status]) {
                severityLevel = 1;
                if (severityLevel > highestSeverityLevel) {
                    highestSeverityLevel = severityLevel;
                    return 'orange';
                }
                return severityColor;
            } else {
                highestSeverityLevel = 2;
                return 'red';
            }
        }, '') || [];
    }

    @Action(ExternalApplication.Set)
    setApplications(ctx: StateContext<ExternalApplicationStateModel>, {applications}: ExternalApplication.Set) {
        applications = _.cloneDeep(this.externalApplicationsActionsService.getSortedExternalApplications(applications));
        this.externalApplicationsActionsService.extendExternalApplications(applications);
        this.externalApplicationsActionsService.setStatusExternalApplications(applications);

        ctx.setState(applications);
    }

    @Action([ExternalApplication.FetchWS, ExternalApplication.FetchService])
    fetchApplications(ctx: StateContext<ExternalApplicationStateModel>) {
        return this.externalApplicationsRestService.getApplicationsStatuses((response: RestResponseSuccess) => {
            const apps = this.externalApplicationsActionsService.getApps(response.data['external applications'] || []);

            ctx.dispatch(new ExternalApplication.Set(apps));
        }, () => null);
    }
}
