import {Action, createSelector, State, StateContext, StateToken} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {AcTableStateModels} from '../ac-table/state/ac-table-state.models';

export namespace AcListEvents {
    export class select {
        static readonly type = '[List Selection] Select';

        constructor(public listId: string, public selection: any) {
        }
    }

    export class deselect {
        static readonly type = '[List Deselection] Deselect';

        constructor(public listId: string, public deselectIds: Array<number | string>) {
        }
    }

    export class clearSelect {
        static readonly type = '[List Deselection] clear';

        constructor(public listId: string) {
        }
    }
}

export type AcListStateStateModel = any;

export const AC_LIST_STATE_TOKEN = new StateToken<AcListStateStateModel>('acListState');

@State({
    name: AC_LIST_STATE_TOKEN,
    defaults: {}
})
@Injectable({providedIn: 'root'})
export class AcListState {

    static selection(listId: string, asList = false) {
        return createSelector([AcListState], (state: AcTableStateModels) => {
            const selection = state[listId]?.selection || {};
            return asList ? Object.getOwnPropertyNames(selection) : selection;
        });
    }

    @Action(AcListEvents.select)
    select(ctx: StateContext<AcListStateStateModel>, {
        listId,
        selection
    }: AcListEvents.select) {
        ctx.patchState({[listId]: {selection}});
    }

    @Action(AcListEvents.deselect)
    deselect(ctx: StateContext<AcListStateStateModel>, {
        listId,
        deselectIds
    }: AcListEvents.deselect) {
        const selection = ctx.getState()[listId];
        selection && deselectIds?.forEach((deselectId) => {
            delete selection[deselectId];
        });
        ctx.patchState({[listId]: {selection}});
    }

    @Action(AcListEvents.clearSelect)
    clearSelect(ctx: StateContext<AcListStateStateModel>, {listId}: AcListEvents.clearSelect) {
        ctx.patchState({[listId]: {}});
    }
}
