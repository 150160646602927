<ng-container *ngIf="!dropdown then dropdownContent"></ng-container>

<ac-drop-down *ngIf="dropdown" #thirdNavDropdown [class.open]="thirdNavDropdown.isOpen" [upperCaseContent]="true"
              parentClass="third-navbar-dropdown {{parentClass}}">
    <div parent routerLinkActive="active">
        <ng-container *ngTemplateOutlet="dropdownContent"></ng-container>
    </div>
    <div (click)="thirdNavDropdown.close()" class="third-nav-content-container" tooltip>
        <ng-content select="[dropdown-option]"></ng-content>
    </div>
</ac-drop-down>

<ng-template #dropdownContent>
    <div class="button-container flexRowParent">
        <span class="button-text-container flexRowParent flexItemBiggest">

            <ac-progress *ngIf="isLoading" [center]="false" size="small" class="flexItem"></ac-progress>
            <ac-svg *ngIf="!isLoading && dynamic && icon" class="icon dynamic-tab-icon flexItem" [name]="icon" height="14px" width="14px"></ac-svg>

            <span class="flexItemBiggest flexColumnParent button-text">
                <ng-container *ngIf="wrapContent then wrapped else content"></ng-container>
            </span>
        </span>
        <i *ngIf="dropdown" class="arrow fa fa-sort-down flexItem"></i>
        <span *ngIf="closeButton" (click)="onClose(); $event.stopPropagation();" class="close-button flexItem">
            <ac-svg class="icon close-button-icon" name="failure" [fillColor]="systemColors.text"></ac-svg>
        </span>
    </div>
</ng-template>

<ng-template #wrapped>
    <div acLabel parentSelectorForTitle="ac-nav-button">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
</ng-template>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>
