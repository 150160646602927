import {Component, Input} from '@angular/core';
import {NavigationService} from '../../../../common/utilities/navigation.service';
import {ExternalApplicationsActionsService} from '../../../../system/configuration/configuration-actions/external-applications-actions.service';
import {SessionService} from '../../../../common/services/session.service';
import {AuthorizationService} from '../../../../common/services/authorization.service';
import {GeneralService} from "ac-infra";

@Component({
    selector: 'external-link',
    templateUrl: './external-link.component.html',
    styleUrls: ['./external-link.component.less'],
})
export class ExternalLinkComponent {

    @Input() hasConfig: boolean;
    @Input() linkKey: string;
    @Input() linkValue: any;
    hasNoLinkInConfig;

    appExplanationObj = {
        IPPMANAGER: 'Powerful and intuitive lifecycle management for IP phones, Headsets and Room Experience (RX) suite',
        ARM: 'Simplifying voice networks to deliver optimized performance and agility',
        MASTERSCOPE: 'Data layer network monitoring and analytics solution',
        ZOOM: 'One solution for chats and channels, phone, whiteboard, meetings, and more.'
    };

    systemColors = GeneralService.statusColors;

    constructor(private externalApplicationsActionsService: ExternalApplicationsActionsService,
                private sessionService: SessionService,
                private authorizationService: AuthorizationService,
                private navigationService: NavigationService) {}

    ngOnInit() {
        this.hasNoLinkInConfig = this.linkValue.webUrl === '' && this.linkKey !== 'arm';
    }

    goToConfig() {
        if (this.isSystemAdmin) {
            this.navigationService.goTo(this.linkValue.configUrl);
        }
    }

    isSystemAdmin() {
        return this.authorizationService.validFor('SYSTEM_ADMIN');
    }

    openLink() {
        if (this.linkValue.webUrl || this.linkValue.disabledLink) {
            this.externalApplicationsActionsService.openApplicationURL(this.linkValue);
        }
    }

    dicoverMore() {
        window.open(this.linkValue.discoverMoreUrl, '_blank');
    }

    getButtonClass = (name) => {
        return name.toLowerCase().replace(/\s+/g, '_');
    };
}




