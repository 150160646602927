import {Injectable} from '@angular/core';

import {Rest} from '../../common/server-actions/rest';
import {QoeFilterFormatter} from './qoeFilterFormatter';

@Injectable({providedIn: 'root'})
export class StatisticsRestService extends Rest {

    constructor(statisticsFilterFormatter: QoeFilterFormatter) {
        super({url: 'callsMonitor/statistics/intervals', filterEventName: 'QOEFilter', entityFilterFormatter: statisticsFilterFormatter});
    }

    getStatistics = (success, failure, parameters, pageName, url?) => {
        this.get({url, parameters, extendParameters: true, addScopeIdFilter: true, filteredEntity: pageName, build204JSON: false}).then(success).catch(failure);
    };

    getAllDeviceStatistics = (success, failure, parameters = {}) => {
        this.get({parameters, extendParameters: true, skipPopulateFilter: true, addScopeIdFilter: true, useCachedConnection: true, build204JSON: false}).then(success).catch(failure);
    };
}
