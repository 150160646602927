import {Injectable} from '@angular/core';

import * as _ from 'lodash';

@Injectable({providedIn: 'root'})
export class NetworkSearchService {

    public searchEntity = (entities, searchString, entityName) => {
        const searchFields = entityName === 'devices' ?
            ['name', 'ipAddress', 'description'] : ['name'];

        this.searchEntities(entities, searchString, searchFields);
    };

    public searchDevices = (devices, searchString) => {
        const searchFields = ['name', 'ipAddress', 'description'];

        this.searchEntities(devices, searchString, searchFields);
    };

    public searchLinks = (links, searchString) => {
        const searchFields = ['name'];

        this.searchEntities(links, searchString, searchFields);
    };

    public searchSites = (sites, searchString) => {
        const searchFields = ['name'];

        this.searchEntities(sites, searchString, searchFields);
    };

    private searchEntities = (entities, searchString, searchFields) => {
        entities.forEach((entity) => {
            entity.highlight = this.isEntityInSearch(entity, searchString, searchFields);
        });
    };

    private isEntityInSearch = (entity, searchString, fieldsList) => (_.isString(searchString) && searchString.length > 0) &&
            this.isContainedFields(entity, searchString, fieldsList);

    private isContainedFields = (dataObject, searchString, fieldsArray) => {
        const fieldsCount = fieldsArray.length;

        for (let i = 0; i < fieldsCount; i++) {
            if (this.fieldContains(dataObject, fieldsArray[i], searchString)) {
                return true;
            }
        }

        return false;
    };

    private fieldContains = (object, fieldName, text) => object &&
            _.isString(object[fieldName]) &&
            object[fieldName].toLowerCase().indexOf(text.toLowerCase()) > -1;
}
