import {Injectable} from '@angular/core';

import {Rest} from '../../common/server-actions/rest';

@Injectable({providedIn: 'root'})
export class TasksRestService extends Rest {

    constructor() {
        super({url: 'jobs'});
    }

    getTasks = (success, failure) => {
        const onSuccess = (response) => {
            success(this.extractTasks(response));
        };
        this.get({skipPopulateFilter: true, build204JSON: false}).then(onSuccess).catch(failure);
    };

    getTaskById = (taskId, success, failure) => {
        this.getById({success, failure, id: taskId, skipPopulateFilter: true, build204JSON: false, skipErrorHandler: true});
    };

    extractTasks = (value) => value.status === 204 ? {data: {jobs: []} } : value;

}
