import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';
import {GeneralService} from 'ac-infra';
import {STATUS_MAP} from './status-map.model';


@Pipe({
    name: 'statusToClass'
})
export class StatusToClassPipe implements PipeTransform {

    static readonly STATUS_MAP = STATUS_MAP;

    STATUS_MAP_TO_CLASS = _.mapValues(StatusToClassPipe.STATUS_MAP, (x) => x.class);
    STATUS_MAP_TO_COLOR = _.mapValues(StatusToClassPipe.STATUS_MAP, (x) => x.color);
    STATUS_MAP_TO_TITLE = _.mapValues(StatusToClassPipe.STATUS_MAP, (x) => x.title);
    STATUS_MAP_TO_TEXT = _.mapValues(StatusToClassPipe.STATUS_MAP, (x) => x.text);

    readonly FLEX_POOL_STATUS_MAP = {
        ok: {class: GeneralService.classColors.green, text: 'OK'},
        warning: {class: GeneralService.classColors.yellow, text: 'Warning'},
        overLicense: {class: GeneralService.classColors.red, text: 'Over License'}
    };

    public transform = (value: any, type?: string): any => {
        if (type === undefined) {
            return StatusToClassPipe.STATUS_MAP[value];
        }
        return StatusToClassPipe.STATUS_MAP[value][type];
    };
}
