import {Injectable} from '@angular/core';
import {AcDialogService, AcTrackerService, AutoRefreshService, DynamicTabsService, GeneralService, RestServerUrlService} from 'ac-infra';
import {WebSocketNotification} from './common/services/ws/ws.notification.service';
import {CachedConnection} from './common/services/communication/cached-connection.service';
import {LogoDisplayService} from './common/services/logo-display.service';
import {WorkspaceManagerService} from './workspace/workspace-manager.service';
import {TopologyTreeService} from './network/components/topology-tree/topology-tree.service';
import {LoginService} from './login/services/login.service';
import {WebSocketService} from './common/services/ws/ws.service';
import {Router} from '@angular/router';
import {SessionService} from './common/services/session.service';
import {UnlockService} from './login/services/unlock.service';
import {NavigationService} from './common/utilities/navigation.service';

import {MatomoTracker} from '@ngx-matomo/tracker';
import {TenantScopeService} from './common/services/tenant-scope.service';
import {WsEntitiesService} from "./common/services/communication/ws-entities.service";

@Injectable({
    providedIn: 'root'
})
export class AppService {

    constructor(
        private sessionService: SessionService,
        private generalService: GeneralService,
        private restServerURLService: RestServerUrlService,
        private webSocketNotification: WebSocketNotification,
        private dynamicTabsService: DynamicTabsService,
        private cachedConnection: CachedConnection,
        private autoRefreshService: AutoRefreshService,
        private logoDisplayService: LogoDisplayService,
        private workspaceManagerService: WorkspaceManagerService,
        private topologyTreeService: TopologyTreeService,
        private loginService: LoginService,
        private unlockService: UnlockService,
        private webSocketService: WebSocketService,
        private acDialogService: AcDialogService,
        private navigationService: NavigationService,
        private router: Router,
        private wsEntitiesService: WsEntitiesService,
        private acTrackerService: AcTrackerService,
        private tenantScopeService: TenantScopeService,
        private readonly matomoTracker: MatomoTracker,
    ) {

        this.acTrackerService.trackEvent$.subscribe({
            next: ({category, action, name, value}) => {
                this.matomoTracker.trackEvent(category, action, name, value);
            }
        });

        this.sessionService.sessionLocked$.subscribe(() => {
            this.unlockService.lock();
            this.autoRefreshService.stop();
            this.router.navigateByUrl('login/unlock');
        });

        this.sessionService.sessionTerminated$.subscribe(() => {
            // this.systemInitialized = false;
            this.tenantScopeService.updateTenantOrGlobalScope(undefined);
            this.generalService.systemInitialized = null;
            this.restServerURLService.init(); // FOR STORING HTTP OR HTTPS ON SESSION STORAGE "communication.protocol"
            this.webSocketNotification.clearAll();
            this.dynamicTabsService.clear();
            this.cachedConnection.clearCache();
            this.autoRefreshService.stop();
            this.logoDisplayService.clear();
            this.workspaceManagerService.clearActiveWorkspace();
            this.wsEntitiesService.reset();
            this.topologyTreeService.clearTreeNodes();
            this.loginService.logout();
            this.webSocketService.closeWsConnectionDialog();
            this.acDialogService.closeAllDialogs();
            this.router.navigateByUrl('login');
        });
    }
}
