import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {cloneDeep, merge} from 'lodash';

import {AcNavAutoService} from '../ac-nav-auto/ac-nav-auto.service';
import {SessionStorageService} from '../../../services/session-storage.service';

export class NavItem {
    url: string;
    text?: string;
    icon?: string;
    visible?: boolean;
    isLoading? = false;
    active?: boolean;
    resolverReturn?: any;

    onRemove?: () => null;

    constructor(url, text, icon?) {
        this.url = url;
        this.text = text;
        this.icon = icon;
    }
}

@Injectable({providedIn: 'root'})
export class DynamicTabsService {
    dynamicTabs$: Observable<NavItem[]>;
    anyHidden = false;
    anyOfHiddenActive = false;
    private _dynamicTabs: BehaviorSubject<NavItem[]> = new BehaviorSubject<NavItem[]>([]);

    constructor(private acNavAutoService: AcNavAutoService) {
        this.dynamicTabs$ = this._dynamicTabs.asObservable();
        this._dynamicTabs.next(SessionStorageService.getData('dynamicTabs') || []);
        /* this._dynamicTabs.next([
            new NavItem("aa", "asdasd1"),
            new NavItem("bb", "asdasd2"),
            new NavItem("ac", "asdasd3"),
            new NavItem("ad", "asdasd4"),
            new NavItem("ae", "asdasd5"),
            new NavItem("af", "asdasd6")
        ]);*/

    }

    get currentTabs() {
        return this._dynamicTabs.value;
    }

    getByUrl(url: string): NavItem {
        return this.currentTabs.find((tab) => url.startsWith(tab.url));
    }

    addTab(url: string, text?: string, icon?: string) {
        if (!this._dynamicTabs.value.some(x => x.url === url)) {
            this._dynamicTabs.value.push(new NavItem(url, text, icon));
            this.update();
        }
    }

    updateTab(navItem: NavItem) {
        const tab = this.getByUrl(navItem.url);
        if (!tab) {
            return;
        }

        delete navItem.url;
        merge(tab, navItem);
        this.update();
    }

    removeTab(dynTab: NavItem, isActive = false) {
        if (isActive) {
            this.acNavAutoService.gotoLastStaticState(true);
        }
        this.acNavAutoService.removeRoute(dynTab.url);
        const index = this._dynamicTabs.value.indexOf(dynTab);
        if (index > -1) {
            dynTab.onRemove && dynTab.onRemove();
            this._dynamicTabs.value.splice(index, 1);
            this.update();
        }
    }

    setVisible(i: number, isVisible: boolean) {
        const currentIsVisible = this._dynamicTabs.value[Object.getOwnPropertyNames(this._dynamicTabs.value)[i]]?.visible;
        if (this._dynamicTabs.value.length > 0 && currentIsVisible !== isVisible) {
            this._dynamicTabs.value[Object.getOwnPropertyNames(this._dynamicTabs.value)[i]].visible = isVisible;
            this.update();
        }
    }

    update() {
        this._dynamicTabs.next(this._dynamicTabs.value);
        this.updateHiddenTabsActive();

        const tabs = cloneDeep(this._dynamicTabs.value);
        tabs.forEach((tab: NavItem) => {
            tab.isLoading = false;
            delete tab.resolverReturn;
            delete tab.onRemove;
        });
        SessionStorageService.setData('dynamicTabs', tabs);
    }

    clear() {
        this._dynamicTabs.next([]);
    }

    updateHiddenTabsActive() {
        this.anyOfHiddenActive = false;
        this.currentTabs.forEach((d, index) => {
            this.anyOfHiddenActive = this.anyOfHiddenActive || (!d.visible && d.active);
        });
    }
}
