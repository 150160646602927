import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'floatingLicenseMode'
})
export class FloatingLicenseModePipe implements PipeTransform {

    readonly LICENSE_MODE_MAP = {
        FlexPool: {text: 'Flex Pool'},
        CloudService: {text: 'Cloud Service'},
        Disabled: {text: 'Disabled'}
    };

    constructor() {
    }

    public transform = (value: any): any => this.LICENSE_MODE_MAP[value].text;
}
