import {Injectable} from '@angular/core';
import {GridsterConfig} from 'angular-gridster2';
import {AcDialogService, AcDropdownChild, DialogData, GeneralService, SessionStorageService} from 'ac-infra';
import {cloneDeep} from 'lodash';
import {Widget, WidgetConfig, WidgetItem} from '../widget.modals';
import {WorkspaceManagerService} from '../../../workspace/workspace-manager.service';
import {WorkspaceNotActiveDialogComponent} from '../dialogs/workspace-not-active-dialog.component';
import {Workspace, WorkspaceRestService} from '../../../workspace/workspace-rest.service';

interface WidgetActionInputs {
    widgetItem: WidgetItem;
    widgetComponentInstance: Widget;
}

@Injectable({
    providedIn: 'root'
})
export class WidgetService {

    static readonly DEFAULT_CONFIG = {
        outerMargin: true,
        outerMarginRight: 1,
        swap: true,
        pushItems: true,
        pushResizeItems: true,
        pushDirections: {north: true, east: false, south: true, west:false},
        draggable: {
            ignoreContentClass: 'ac-layout-panel-actions',
        },
        itemResizeCallback: () => {
            GeneralService.dispatchResizeEvent();
        },
    };

    constructor(private acDialogService: AcDialogService,
                private workspaceManagerService: WorkspaceManagerService,
                private workspaceRestService: WorkspaceRestService) {
    }

    getWidgetTitle = ({widget, widgetConfig}: WidgetItem) => {
        if (widgetConfig?.title) {
            return widgetConfig.title;
        }

        const widgetTitle = widget.title;
        return (widgetTitle instanceof Function) ? widgetTitle(widgetConfig) : widgetTitle;
    };

    getWidgetClass = ({widget, widgetConfig}: WidgetItem) => {
        if (!widget?.class) {
            return '';
        }

        const widgetClass = widget.class;
        return (widgetClass instanceof Function) ? widgetClass(widgetConfig) : widgetClass;
    }

    widgetConfigureAction = (widgetActionInputs: WidgetActionInputs, onSubmit: (...args) => any): AcDropdownChild => {
        const {widgetItem, widgetComponentInstance} = widgetActionInputs;
        return widgetItem.widget.configDialog && {
            displayName: 'configure',
            onClick: () => this.acDialogService.open(widgetItem.widget.configDialog, {
                dialogData: cloneDeep({
                    widgetConfig: {},
                    ...widgetItem,
                    title: this.getWidgetTitle(widgetItem),
                    data: widgetComponentInstance.getWidgetConfigData(),
                }),
                onSubmit: ({widgetConfig}: DialogData) => {
                    widgetItem.widgetConfig = widgetConfig;
                    if (!widgetConfig || WidgetConfig.isEmpty(widgetConfig)) {
                        delete widgetItem.widgetConfig;
                    }
                    onSubmit(widgetConfig);
                },
            }),
        };
    };

    widgetClearConfigAction = (widgetItem: WidgetItem, onClick: (...args) => any): AcDropdownChild => {
        const {widgetConfig, ...widgetItemNoConfig} = widgetItem;
        return widgetItem.widgetConfig ? {
            displayName: 'Clear Config',
            onClick: () => onClick(widgetItemNoConfig),
        } : null;
    };


    updateGridOptions = (srcConfig: GridsterConfig, updatePropsConfig: GridsterConfig): GridsterConfig => {
        return {...srcConfig, ...updatePropsConfig};
    };

    setEditModeState = (isEditMode: boolean, config: GridsterConfig): GridsterConfig => {
        return {
            ...config,
            draggable: {...config?.draggable, enabled: isEditMode},
            resizable: {...config?.resizable, enabled: isEditMode},
        };
    };

    workspaceNotActiveDialog = async (widgetsSessionKey: string, onSave: (...args) => void) => {
        if (this.workspaceManagerService.isAutoSaveEnabled) {
            onSave();
            return;
        }

        this.acDialogService.open(WorkspaceNotActiveDialogComponent, {
            preSubmit: () => {
                onSave();
            },
            onSubmit: async (dialogData) => {
                if (!dialogData.autoSave) {
                    return;
                }

                if (this.workspaceManagerService.isActiveWorkspace) {
                    await this.workspaceManagerService.setWorkspaceAutoSave(true);
                    return;
                }

                const workspaces: Workspace[] = await this.workspaceRestService.getWorkspaceState();
                let {id, data} = workspaces?.[0] || {};
                if (data) {
                    const savedItems = SessionStorageService.getData(widgetsSessionKey);
                    data = {...data, [widgetsSessionKey]: savedItems};
                }
                const workspace: any = await this.workspaceManagerService.export({id, data, isActive: true, autoSave: true})
                this.workspaceManagerService.activateWorkspace(id || workspace.id);
            }
        });
    }
}
