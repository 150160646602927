import {Injectable} from '@angular/core';
import {MetadataService} from '../../metadata/metadata.service';
import * as _ from 'lodash';
import {NetworkFilterFormatterService} from '../../network/services/network-filter-formatter.service';
import {FilterState} from '../components/ac-filter/services/ac-filter-state.service';
import {NavigationService} from '../utilities/navigation.service';
import {merge} from "lodash";
import {DevicesRestService} from "../../network/services/apis/devices-rest.service";
import {DateTime} from 'luxon';
@Injectable({providedIn: 'root'})
export class DrillDownFiltersService {

    private audioCodesDevicesList: any;
    private alarmOIDTypeByCategories: any;
    private journalActivityType: any;
    private familyTypeList: any;
    private linkTypeList: any;
    private qualityFields: Array<string>;
    private statusFields: Array<string>;
    private qualityCategory: any;
    private intervalSize: any;

    constructor(private navigationService: NavigationService,
                private filterState: FilterState,
                private devicesRestService: DevicesRestService,
                private networkFilterFormatterService: NetworkFilterFormatterService) {

        this.audioCodesDevicesList = MetadataService.getType('AudioCodesDevices');
        this.familyTypeList = MetadataService.getType('FamilyType');
        this.linkTypeList = MetadataService.getType('LinkType');
        this.alarmOIDTypeByCategories = MetadataService.getType('AlarmOIDTypeByCategories');
        this.journalActivityType = MetadataService.getType('JournalActivityType');
        this.qualityFields = ['goodQualityCalls', 'fairQualityCalls', 'poorQualityCalls', 'unknownQualityCalls'];
        this.statusFields = ['successCalls', 'failedCalls'];

        this.qualityCategory = {
            goodQualityCalls: 'good',
            fairQualityCalls: 'fair',
            poorQualityCalls: 'poor',
            unknownQualityCalls: 'unknown',
            successCalls: 'success',
            failedCalls: 'failed'
        };

        this.intervalSize = {
            FiveMinutes(date) {
                date.setMinutes(date.getMinutes() - 5);
            },
            Hour(date) {
                date.setHours(date.getHours() + 1);
            },
            Day(date) {
                date.setDate(date.getDate() + 1);
            }
        };
    }

    public gotoNetworkPageFilteredBy = (viewName, filterField) => {
        const url = '/network/' + viewName;

        switch (filterField) {
            case 'status'      :
                return this.networkFilteredByStatus(url);
            case 'linkType'    :
                return this.networkFilteredByLinkType(url);
            case 'productType' :
                return this.networkFilteredByProductType(url);
        }
    };

    public gotoAlarmsPageFilteredBySeverity = (viewName, eventName, selection?) => (severity, fields, time, goToView) => {
        severity = severity ? {severities: [severity]} : undefined;

        let alarmView;
        const obj: any = {auxiliary: {filter: {}}};

        if(selection){
            if(['device', 'link', 'site', 'endpoint'].includes(viewName)){
                obj.auxiliary.filter = {Topology : {[viewName]: selection}};
            }else if(viewName === 'customer'){
                obj.auxiliary.filter = {Customers : {customerGroups: []}};
                obj.auxiliary.filter.Customers.customerGroups = selection.map((item) => item.id);
            }else{
                obj.auxiliary.filter = {unitId : {customerGroups: []}};
                const unitIds = selection.map((item) => item.id);
                obj.auxiliary.filter.unitId = unitIds.join(',');
            }
        }

        const aggregatedFilter = this.filterState.get(eventName);

        // if(time){
        //     const date = new Date(time);
        //     const date1 = new Date(time);
        //     const date2 = DateTime.fromJSDate(date1).plus({minutes: 24*60}).toJSDate();
        //     obj.auxiliary.filter.timeRange = {};
        //     this.setTimeFilter(obj, date, date1);
        // }

        if (['device', 'link', 'site', 'endpoint', 'customer'].includes(viewName)) {
            obj.auxiliary.filter.sourceType = {types: [viewName]};
            this.addUnpinnedFilters(aggregatedFilter, obj);
            this.goToPageWithFilter('/alarms/active', obj, 'Severity', severity);
        } else {
            if(viewName === 'topology'){
                obj.auxiliary.filter.sourceType = {types: ['device', 'link', 'site', 'endpoint']};
            }
            alarmView = goToView ? goToView : (viewName === 'topology' ? 'active' : viewName);
            this.addUnpinnedFilters(aggregatedFilter, obj);
            if (fields) {
                this.goToPageWithFilters('/alarms/' + alarmView, obj, fields);
            } else {
                this.goToPageWithFilter('/alarms/' + alarmView, obj, 'Severity', severity);
            }
        }
    };

    public gotoAlarmsPageFilteredByDevice = (eventName) => (deviceId) => {
        const device = deviceId ? this.devicesRestService.getEntityById(deviceId) : undefined;
        if(device){
            const obj: any = {auxiliary: {filter: {}}};
            obj.auxiliary.filter = {Topology : {device: [device]}};

            const aggregatedFilter = this.filterState.get(eventName);
            this.addUnpinnedFilters(aggregatedFilter, obj);
            this.navigationService.goToWithFilter('/alarms/journal', obj);
        }
    };

    public gotoAlarmsPageFilteredByJournalActivityType = (eventName) => (type) => {
        const journalActivityType = _.find(this.journalActivityType, (activityType) => activityType.viewName === type);
        const obj: any = {auxiliary: {filter: {moreFilters_journal: {}}}};

        const aggregatedFilter = this.filterState.get(eventName);
        this.addUnpinnedFilters(aggregatedFilter, obj);
        obj.auxiliary.filter.moreFilters_journal.journalName = [journalActivityType.name];
        this.navigationService.goToWithFilter('/alarms/journal', obj);
    };

    public gotoAlarmsPageFilteredByAlarmName = (alarmView, eventName) => (name) => {
        const alarmName = _.find(this.alarmOIDTypeByCategories, (alarmName) => alarmName.viewName === name);
        const obj: any = {auxiliary: {filter: {moreFilters_alarms: {}}}};

        const aggregatedFilter = this.filterState.get(eventName);
        this.addUnpinnedFilters(aggregatedFilter, obj);
        obj.auxiliary.filter.moreFilters_alarms.alarmNames = [alarmName.name];
        this.navigationService.goToWithFilter('/alarms/' + alarmView, obj);
    };

    public gotoPageFilteredAlarmById = (route, id) => {
        const obj: any = {auxiliary: {filter: {specificAlarm: id}}};
        this.navigationService.goToWithFilter(route, obj);
    };

    public goToDevicePageFilteredByFamilyType = () => (familyTypes) => {
        const aggregatedFilter = this.networkFilterFormatterService.getAggregatedFilter('NetworkFilter');
        const obj: any = {auxiliary: {filter: {moreFilters_network: {}}}};

        this.addUnpinnedFilters(aggregatedFilter, obj);
        obj.auxiliary.filter.moreFilters_network.familyType = familyTypes;

        this.navigationService.goToWithFilter('/network/devices/manage', obj);
    };

    public gotoCallPageWithSelectionFilteredBy = ({viewName, selectedObj = undefined, selectedItems = undefined, localTimeFilter = undefined}) => { // selected is object or array
        const statuses = ['failed', 'success'];

        return (donutChart) => {
            const obj: any = {auxiliary: {filter: {Quality: {}}}};
            const category = statuses.indexOf(donutChart.category) !== -1 ? 'status' : 'quality';

            obj.auxiliary.filter.Quality[category] = [donutChart.category];

            if (localTimeFilter && !_.isEqual(localTimeFilter, {})) {
                obj.auxiliary.filter.timeRange = localTimeFilter;
            } else if (['device', 'link', 'site'].indexOf(viewName) >= 0) {
                const aggregatedFilter: any = this.networkFilterFormatterService.getAggregatedFilter('NetworkFilter');

                if (aggregatedFilter.timeRange && aggregatedFilter.timeRange.unpinned) {
                    obj.auxiliary.filter.timeRange = aggregatedFilter.timeRange.unpinned;
                }
            }

            if (viewName === 'user' && selectedObj) { // selected is user object
                const caller = selectedObj.fullName.toLowerCase();

                obj.auxiliary.filter.moreFilters_calls = {};
                obj.auxiliary.filter.moreFilters_calls.callerOrCallee = [caller];
            } else if(viewName === 'endpoint' && selectedItems?.length === 1){
                obj.auxiliary.filter.specificEntity = {id: selectedItems[0].id, type: viewName, name: selectedItems[0].name};
            } else {
                obj.auxiliary.filter.Topology = {};
                obj.auxiliary.filter.Topology[viewName] = [];

                selectedItems.forEach((item) => {
                    if (item && !!item.id && !!item.name) {
                        obj.auxiliary.filter.Topology[viewName].push({
                            id: item.id,
                            name: item.name,
                            productType: item.productType
                        });
                    }
                });
            }

            this.navigationService.goToWithFilter('/statistics/callsList', obj);
        };
    };

    public gotoCallPageFilteredByQuality = (viewName, eventName) => {
        const statuses = ['failed', 'success'];

        return (donutChart) => {
            const obj: any = {auxiliary: {filter: {Quality: {}}}};
            const aggregatedFilter = this.filterState.get(eventName);
            const category = statuses.indexOf(donutChart.category) !== -1 ? 'status' : 'quality';

            obj.auxiliary.filter.Quality[category] = [donutChart.category];
            obj.auxiliary.filter.sourceType = {types: [viewName]};

            if(donutChart?.data?.intervalStatistics?.length > 0){
                obj.auxiliary.filter.timeRange = {};
                const startDate = new Date(donutChart.data.intervalStatistics?.[0]?.interval);
                const endDate = new Date(donutChart.data.intervalStatistics?.[donutChart.data.intervalStatistics.length - 1]?.interval);
                this.setTimeFilter(obj, startDate, endDate);
            }

            this.addUnpinnedFilters(aggregatedFilter, obj);


            this.navigationService.goToWithFilter('/statistics/callsList', obj);
        };
    };

    public gotoCallPageFilteredByDateTime = (viewName, eventName) => (bar, intervalSize) => {
        const obj: any = {auxiliary: {filter: {timeRange: {}}}};
        const aggregatedFilter: any = this.filterState.get(eventName);
        const fieldName = bar.series.field;
        const category = this.statusFields.includes(fieldName) ? 'status' : this.qualityFields.includes(fieldName) ? 'quality' : '';
        // let stringDate = $filter('date')(bar.category, "yyyy-MM-ddTHH:mm:ss");

        const startDate = new Date(bar.category);
        const endDate = new Date(bar.category);

        this.intervalSize[intervalSize](startDate);

        if (intervalSize === 'FiveMinutes') {
            this.setTimeFilter(obj, startDate, endDate);
        } else {
            this.setTimeFilter(obj, endDate, startDate);
        }


        obj.auxiliary.filter.sourceType = {types: [viewName]};

        if (aggregatedFilter.Topology && aggregatedFilter.Topology.unpinned) {
            obj.auxiliary.filter.Topology = aggregatedFilter.Topology.unpinned;
        }

        if (category) {
            obj.auxiliary.filter.Quality = {};
            obj.auxiliary.filter.Quality[category] = [this.qualityCategory[fieldName]];
        }

        this.navigationService.goToWithFilter('/statistics/callsList', obj);
    };

    private networkFilteredByStatus = (url) => (status) => {
        status = status ? {statuses: [status]} : undefined;
        const aggregatedFilter = this.networkFilterFormatterService.getAggregatedFilter('NetworkFilter');
        const obj: any = {auxiliary: {filter: {}}};

        this.addUnpinnedFilters(aggregatedFilter, obj);
        this.goToPageWithFilter(url, obj, 'Status', status);
    };

    private networkFilteredByProductType = (url) => (selectedBar) => {
        const productType = _.find(this.audioCodesDevicesList, (deviceType) => deviceType.viewName === selectedBar.category);
        const aggregatedFilter = this.networkFilterFormatterService.getAggregatedFilter('NetworkFilter');
        const obj: any = {auxiliary: {filter: {moreFilters_network: {}}}};

        this.addUnpinnedFilters(aggregatedFilter, obj);
        obj.auxiliary.filter.moreFilters_network.productType = [productType.name];

        this.navigationService.goToWithFilter(url, obj);
    };

    private networkFilteredByLinkType = (url) => (selectedBar) => {
        const linkType = _.find(this.linkTypeList, (category: any) => category.viewName === selectedBar.category);
        const aggregatedFilter = this.networkFilterFormatterService.getAggregatedFilter('NetworkFilter');
        const obj: any = {auxiliary: {filter: {moreFilters_network: {}}}};

        this.addUnpinnedFilters(aggregatedFilter, obj);
        obj.auxiliary.filter.moreFilters_network.linkType = [linkType.name];

        this.navigationService.goToWithFilter(url, obj);
    };

    private addUnpinnedFilters = (aggregatedFilter, filterObj) => {
        Object.getOwnPropertyNames(aggregatedFilter).forEach((key) => {
            if (aggregatedFilter[key].unpinned) {
                filterObj.auxiliary.filter[key] = merge(aggregatedFilter[key].unpinned, filterObj.auxiliary.filter[key]);
            }
        });
    };

    private setTimeFilter = (obj, startDate, endDate) => {
        obj.auxiliary.filter.timeRange.from = startDate;
        obj.auxiliary.filter.timeRange.to = endDate;
    };

    goToPageWithFilter = (url, filterObj, fieldName?, value?) => {
        if (value) {
            filterObj.auxiliary.filter[fieldName] = value;
        }

        this.navigationService.goToWithFilter(url, filterObj);
    };

    private goToPageWithFilters = (url, filterObj, fields) => {
        if (Array.isArray(fields)) {
            fields.forEach((field) => {
                filterObj.auxiliary.filter[field.name] = field.value;
            });
        }

        this.navigationService.goToWithFilter(url, filterObj);
    };
}
