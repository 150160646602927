import {Component} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';

import {EXTERNAL_APPLICATIONS_STATE_TOKEN, ExternalApplicationState} from '../../../common/state/external-application.state';
import {AuthGroup} from '../../../common/utilities/session-helper.service';
import {AuthorizationService} from '../../../common/services/authorization.service';
import {SessionService} from '../../../common/services/session.service';
import {Widget} from '../../../common/widget/widget.modals';

@Component({
    selector: 'external-links-panel',
    templateUrl: './external-links-panel.component.html',
    styleUrls: ['./external-links-panel.component.less'],
})
export class ExternalLinksPanelComponent extends Widget {

    @Select(ExternalApplicationState) externalApplications$: Observable<any>;

    authorizedForSystemAdmin;
    links: any = {};

    constructor(private store: Store,
                private sessionService: SessionService,
                private authorizationService: AuthorizationService) {
        super();
        this.authorizedForSystemAdmin = this.authorizationService.validFor(AuthGroup.SYSTEM_ADMIN);
    }

    getApps = (apps: any[]) => {
        if (!this.authorizationService.securityLevel) {
            return [];
        }

        if(this.authorizationService.securityLevel.startsWith('TENANT')){
            apps = apps.filter((app) => app.id === 'IPPMANAGER');
        }
        const configData = this.widgetItem.widgetConfig?.data;
        return configData ? apps.filter(({id}) => configData[id]) : apps;
    };

    getWidgetConfigData(args): any {
        return this.getApps(this.store.selectSnapshot(EXTERNAL_APPLICATIONS_STATE_TOKEN));
    }
}




