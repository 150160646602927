import {Component, Inject} from '@angular/core';
import {AcDialog, DIALOG_CONFIG, DialogConfig} from 'ac-infra';

@AcDialog({
    id: 'select-tenant-dialog',
    submitButtonText: 'Select',
    cancelButtonText: 'Close',
    width: 420,
    overrideSystemViewMode: true
})
@Component({
    selector: 'select-tenant-dialog',
    templateUrl: './select-tenant-dialog.component.html',
})
export class SelectTenantDialogComponent {

    tenant: any = {};
    tenants: [];

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {}

    ngOnInit() {
        this.tenant =  this.dialogConfig.dialogData.entity;
        this.tenants =  this.dialogConfig.dialogData.tenants;
        this.dialogConfig.title = this.dialogConfig.dialogData.title || 'Select Tenant';
        this.dialogConfig.formSettings = {valid: true, touched: true};
    }
}


