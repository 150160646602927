import {Component, Inject} from '@angular/core';
import {AcDialog, DIALOG_CONFIG, DialogConfig, StringUtils} from 'ac-infra';
import {WidgetItem} from '../../common/widget/widget.modals';
import {WidgetService} from '../../common/widget/services/widget.service';


@AcDialog({
    id: 'add-widget-dialog',
    title: 'add widget',
    width: 420,
})
@Component({
    selector: 'add-widget-dialog',
    template: `
        <ac-input-container label="Widget Type">
            <ac-single-select [items]="widgetOptions"
                              [acModel]="widgetModel"
                              (acModelChange)="onSelectionChange($event)"
            ></ac-single-select>
        </ac-input-container>
    `
})
export class AddDashboardWidgetDialogComponent {

    widgetOptions;
    widgetModel;

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig,
                private widgetService: WidgetService,
    ) {
    }

    ngOnInit() {
        const {visibleDashboardItems, currentDashboardItems}: { [key: string]: WidgetItem[] } = this.dialogConfig.dialogData;

        this.widgetOptions = visibleDashboardItems.filter(({widget, type}: WidgetItem) => {
            return widget.multiple || !currentDashboardItems.find((currentDashboardItem) => type === currentDashboardItem.type);
        }).map((widgetItem) => {
            const title = this.widgetService.getWidgetTitle(widgetItem);
            const widgetType = widgetItem.type;
            return {
                value: widgetType,
                text: title || StringUtils.toTitleCase(widgetType, {onlyLead: false, delimiter: '-'})
            };
        });
        this.onSelectionChange(this.widgetOptions[0].value);
    }

    onSelectionChange($event: any) {
        this.dialogConfig.dialogData = this.widgetModel = $event;
    }
}


