import {isFunction, orderBy} from 'lodash';

export class ArrayUtil {
    static oneToMany<T>(item: any): Array<T | any> {
        if (typeof item === 'string') {
            return [item];
        }
        // tree nodes are observableArray, Array.isArray is false
        return item.concat && isFunction(item.concat) ? item.concat() : [item];
    }

    static arrayToObjTransform = (source: any[] | undefined, transformFunc: (acc, curr) => void, target = {}) => {
        return source?.reduce((acc, curr) => {
            transformFunc(acc, curr);
            return acc;
        }, target) || target;
    };

    static removeItemsFromIndex = (source: any[], removeIndex = 0, deleteCount = 1) => {
        return source?.splice(removeIndex, deleteCount);
    }

    static findInArrDelimitered(items: any[], key: string, terms: string) {
        const term_arr = terms.toLowerCase().split(' ').filter(term => !!term)
        if (term_arr.length === 0) {
            return items;
        }
        return items?.filter(item => {
            return term_arr.every(term => item[key]?.toLowerCase().includes(term));
        }) || [];
    }

    static sort(items: any[],key, dir: 'asc' | 'desc' = 'asc') {
        return orderBy(items, [item => item[key].toLowerCase()], [dir])
    }
}
