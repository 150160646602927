export const localize_en = (errors) => {
    if (!(errors && errors.length)) {
        return;
    }
    for (const error of errors) {
        let out;
        switch (error.keyword) {
            case 'pattern':
                let fieldName;
                if (error.parentSchema.propertyName) {
                    fieldName = error.parentSchema.propertyName;
                } else {
                    const fieldIsNotArray = error.instancePath.split('/')[error.instancePath.split('/').length - 1];
                    const fieldIsArray = error.instancePath.split('/').length > 1 ? error.instancePath.split('/')[error.instancePath.split('/').length - 2] : '';
                    fieldName = !isNaN(parseInt(fieldIsNotArray, 10)) ? fieldIsArray : fieldIsNotArray;
                }

                out = (fieldName === 'name' || fieldName === 'description') ? 'only letters and numbers are allowed' : 'Invalid "' + fieldName + '" format';
                break;
            case 'required':
            case 'requiredAux':
                out = 'Mandatory field';
                break;
            default:
                continue;
        }
        error.message = out;
    }
};
