
<div class="settings-option">
    <ac-checkbox-group class="settings-option"
                       checkboxGroupId="ac-table-columns-visibility"
                       [items]="columnsGroupItems"
                       [parentModel]="groupState"
                       [withoutHeader]="true"
                       (itemsChanged)="groupChange($event)"
    ></ac-checkbox-group>
</div>

<div class="settings-option" *acDialogAdditionalActions>
    <ac-button buttonType="stroked-button" class="full-width" (acClick)="resetClick()">Back To Default</ac-button>
</div>
