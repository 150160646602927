<ac-date-picker-container [disabled]="disabled" (onToggleClick)="openPicker()" (close)="refreshRef()">

    <input matInput [value]="date | acFunc: formatInput" readonly picker-input>

    <ac-date-calendar label="time"
                      [hourOnly]="hourOnly"
                      [minDate]="minDate"
                      [selectedDate]="date"
                      (cancel)="onCancel()"
                      (apply)="onApply($event)"
                      picker-calendar>
    </ac-date-calendar>

</ac-date-picker-container>


