import {Injectable} from '@angular/core';
import {AutoRefreshService, SessionStorageService} from 'ac-infra';
import {pickBy} from 'lodash';
import {FilterState} from '../components/ac-filter/services/ac-filter-state.service';
import {DateTime} from 'luxon';

@Injectable({providedIn: 'root'})
export class FilterObjectCacheService {

    filterObjectStore = 'filterObject_';
    filterIdObjectStore = 'filterIdObject_';
    private fiveMinutes = 1000 * 60 * 5;

    constructor() {
        this.clearOld();
        AutoRefreshService.systemRefresh$.subscribe(() => this.clearOld());
    }

    getFilterId = (filter) => this.testFiveMinutes(SessionStorageService.getData(this.filterObjectStore + filter));

    getFilterById = (filterId) => this.testFiveMinutes(SessionStorageService.getData(this.filterIdObjectStore + filterId));

    testFiveMinutes = (storedObj) => {
        const now = new Date().getTime();
        if (storedObj && ((storedObj.time + this.fiveMinutes - now) < 0)) {
            return null;
        }
        return storedObj && storedObj.obj;
    };

    setFilter = (filterObject) => {
        const now = new Date().getTime();
        SessionStorageService.setData(this.filterIdObjectStore + filterObject.id, {
            time: now,
            obj: filterObject.filter
        });
        SessionStorageService.setData(this.filterObjectStore + filterObject.filter, {time: now, obj: filterObject.id});
    };

    clearOld = () => {
        const object = pickBy(sessionStorage, (value, key) => key.startsWith(this.filterObjectStore) || key.startsWith(this.filterIdObjectStore));
        const timeNow = DateTime.now();
        Object.entries(object).forEach(([key, value]) => {
            value = JSON.parse(value);
            if (value?.time < timeNow) {
                sessionStorage.removeItem(key);
            }
        });
    }
}
