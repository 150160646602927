import {DateRange} from '@angular/material/datepicker';
import {DateTime} from 'luxon';
import {DateObjectUnits} from 'luxon/src/datetime';

export interface AcDateTimeRange {
    from?: DateTime;
    to?: DateTime;
}
export class AcDateRange extends DateRange<DateTime> {}

export const RESET_TIME: DateObjectUnits = {hour: 0, minute: 0 ,second: 0, millisecond: 0};
export const RESET_SEC_AND_MILLIS: DateObjectUnits = {second: 0, millisecond: 0};

Object.freeze(RESET_TIME);
Object.freeze(RESET_SEC_AND_MILLIS);

