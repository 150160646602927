import {Injectable} from '@angular/core';
import {EncryptionService} from '../../common/services/encryption.service';
import {Router} from '@angular/router';
import {Rest, RestResponseFailure} from '../../common/server-actions/rest';

@Injectable({
    providedIn: 'root'
})
export class LoginRestService extends Rest {

    encryptionService;
    router;

    constructor(encryptionService: EncryptionService,
                router: Router) {
        super({});

        this.encryptionService = encryptionService;
        this.router = router;
    }

    login = (username, password,body, success, failure) => {
        const hasedUserPass = this.encryptionService.hashCredentials(username, password);
        if (hasedUserPass) {
            this.add(success, failure, body, 'security/actions/login', hasedUserPass);
        } else {
            failure();
        }
    };

    logout = (success, failure) => {
        this.add(success, failure, undefined, 'security/actions/logout');
    };

    forceLogoutOperator = (operatorId, success, failure) => {
        this.add(success, failure, {operatorId}, 'security/actions/forceLogout');
    };

    lock = (success, failure) => {
        this.add(success, failure, undefined, 'security/actions/lock');
    };

    unlock = (username, password, success, failure) => {
        const unlockObject = {
            password
        };

        const onSuccess = () => {
            success();
            this.router.navigateByUrl('');
        };
        const onFailure = (error: RestResponseFailure) => {
            if (error.status === 400 && error.error && error.error.description === 'Session has been already unlocked before') {
                success();
            } else {
                failure(error);
            }
        };

        this.add(onSuccess, onFailure, unlockObject, 'security/actions/unlock');
    };

    updateOperatorPassword = (operatorDataTemplate, success, failure) => {
        const basicAuth = this.encryptionService.hashCredentials(operatorDataTemplate.operatorName, operatorDataTemplate.oldPassword);
        this.put(success, failure, operatorDataTemplate, 'security/settings/localAuthentication/changePassword', basicAuth);
    };
}
