<div class="user flexRowParent">
    <ac-svg class="user-icon" name="operator"></ac-svg>

    <span class="user-details flexItemBiggest flexColumnParent">
        <div class="title ellipsis flexItem flexRowParent">
            <div class="title-operator" acLabel>{{operator}}</div>
            <span class="title-separator">/</span>
            <div class="title-authentication-mode" *ngIf="operatorAuthenticationMode">{{operatorAuthenticationMode | titlecase}}</div>
        </div>

        <div class="description security-level flexItem">{{securityLevel | titlecase}}</div>

        <div class="sub-description"><ng-content></ng-content></div>
    </span>
</div>
