<tr>
    <th *ngFor="let column of columns; trackBy: acTableService.trackByField"
        [class.left]="column.stickyLeft"
        acChild>

        <div class="table-cell footer-table-cell"
             [style.width]="column.colWidth | acFunc : acTableService.widthToPixels : acTableComponent.minCellWidth"
             acLabel
             #footerCell>
            <ng-template [acTableCell]="footerCell"
                         [isHeader]="true"
                         [acTableCellTemplate]="defaultFooterCellTemplate"
                         [acTableCellFormatter]="column.footerFormatter"
                         [acTableCellColumn]="column">
            </ng-template>
        </div>
    </th>
</tr>

<ng-template let-column="column" #defaultFooterCellTemplate>{{column.title}}</ng-template>
