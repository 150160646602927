import {Injectable} from '@angular/core';

import {DeviceSSOActionsService} from '../../network/services/actions/device-SSO-actions.service';
import {MetadataService} from '../../metadata/metadata.service';

@Injectable({providedIn: 'root'})
export class DeviceDetailsService {

    constructor(private deviceSSOActionsService: DeviceSSOActionsService) {
    }

    startServletIfNeeded = (deviceDetails,partialUrl, success) => {
        if (!partialUrl.startsWith('http')) {
            this.deviceSSOActionsService.startEntityServlet(deviceDetails, (value) => {
                deviceDetails.servlet = value;
                success();
            });
        } else {
            success();
        }
    };

    cloudBondDevice = (deviceDetails) => {
        const productType = deviceDetails.productType;
        const productTypeObject = MetadataService.getType('AudioCodesDevices')[productType];

        return productTypeObject ? productTypeObject.familyType === 'CLOUDBOND' : false;
    };

    notOldVersion = (deviceDetails) => {
        const highVersion = this.getDeviceHighVersion(deviceDetails);

        return parseInt(highVersion, 10) > 6;
    };

    getDeviceHighVersion = (deviceDetails) => {
        const deviceSW = deviceDetails.swVersion || '';
        const deviceSwParts = deviceSW.split('.');

        return deviceSwParts[0] !== '' ? deviceSwParts[0] : 0;
    };
}
