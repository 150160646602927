<div class="ac-filter flexColumnParent" [class.readOnly]="readOnly">
    <div class="flexItem" *ngIf="!readOnly">
        <ac-drop-down (openStatusChanged)="onFilterDialogStatus($event)"
                      backdropClass="ac-filter-backdrop"
                      [parentClass]="'ac-filter ' + filterClass"
                      [upperCaseContent]="true"
                      [openDropDownAfterButton]="true"
                      class="ac-filter-dropdown"
                      #filterDropDown>


            <ac-button *ngIf="filterTypes.length > 0"
                       [buttonValue]="'Add Filters'"
                       color="green"
                       parentAfterButton
                       class="add-filter-button full-width ac-dropdown-toggle"
                       (acClick)="openFilter()">
            </ac-button>

            <div class="ac-filter-content flexColumnParent" tooltip>
                <div class="flexItemBiggest flexColumnParent ac-filter-type-container">
                    <ng-content select="ac-filter-type"></ng-content>
                </div>

                <div class="flexItem">
                    <ac-button (acClick)="applyFilterChanges(); filterDropDown?.close()"
                               [ngDisabled]="filterState.filterTypeChanged$ | async | acFunc: applyDisabled: types"
                               buttonValue="Apply Filters"
                               color="green"
                               class="add-filter-apply-button full-width">
                    </ac-button>
                </div>
            </div>
        </ac-drop-down>
    </div>


    <ng-content></ng-content>


    <div class="flexItemBiggest filter-items overflowAuto">
        <ul class="filter-item-list" #visibleFilterItems>
            <ng-container *ngFor="let type of filterTypesArr">

                <li *ngIf="filter[type.filterName] && (filter[type.filterName].pinned || checkIfItemIsNotEmpty(filter[type.filterName].unpinned, type))"
                    class="filter-item"
                    [class.both]="filter[type.filterName].pinned && checkIfItemIsNotEmpty(filter[type.filterName].unpinned, type)">
                    <ac-filter-item *ngIf="filter[type.filterName].pinned"
                                    (itemRemove)="removeItem($event.itemName, $event.itemType); filterDropDown?.close()"
                                    [itemName]="type.filterName"
                                    [filterObject]="type"
                                    itemType="pinned">
                    </ac-filter-item>

                    <ac-filter-item *ngIf="filter[type.filterName].unpinned | acFunc : checkIfItemIsNotEmpty : type"
                                    (itemRemove)="removeItem($event.itemName, $event.itemType); filterDropDown?.close()"
                                    [itemName]="type.filterName"
                                    [filterObject]="type"
                                    itemType="unpinned">
                    </ac-filter-item>
                </li>

            </ng-container>
        </ul>
    </div>

</div>
