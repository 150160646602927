<div class="info-panel-container">
    <div class="info-container flexRowParent">
        <div class="logo-container flexItem">
            <ac-svg class="logo" name="ovoc_logo_only" height="180px"></ac-svg>
        </div>
        <div class="flexItem chevronParent" [class.enabled]="infoPanel.currentReleaseHighlight>0"
             (click)="prev()">
            <ac-svg class="chevron" name="chevron" height="20px"
                    [fillColor]="(infoPanel.currentReleaseHighlight > 0) ? statusColors.text : statusColors.systemBorder">
            </ac-svg>
        </div>
        <div class="info-slider-container flexItemBiggest">
            <div class="flexColumnParent slider-text-container">
                <div class="flexItemBiggest flexRowParent text-container" [@slider]="infoPanel.currentReleaseHighlight" *ngIf="releaseHighlights.length > 0">

                    <ng-container *ngFor="let highlight of releaseHighlights index as index">
                        <div *ngIf="infoPanel.currentReleaseHighlight===index"
                             class="highlight-content flexItemBiggest flexRowParent">

                            <div class="iconContainer flexItem"
                                 [ngStyle]="{'background-color':  statusColors[highlight.Type]}">
                                <ac-svg [name]="highlight.Type" height="45px" width="45px"></ac-svg>
                            </div>
                            <div class="textContainer flexItemBiggest flexColumnParent">
                                <div class="flexItem title" [ngStyle]="{'color':  statusColors[highlight.Type]}">{{highlight.title}}</div>
                                <div class="flexItemBiggest content">{{highlight.content}}</div>
                                <div class="flexItem flexRowParent">
                                    <div class="new-update flexItemBiggest flexRowParent">
                                        <div *ngIf="highlight.lastUpdated | acFunc: isNewUpdate"
                                             class="flexItem flexRowParent">
                                            <span class="flexItem">
                                                <ac-svg name="calendar" width="16px"></ac-svg>
                                            </span>
                                            <span class="flexItemBiggest">New Update</span>
                                        </div>
                                    </div>
                                    <a class="discover-more flexItem" target="_blank" *ngIf="highlight.url"
                                       [href]="highlight.url">Discover More</a>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                </div>
            </div>
        </div>
        <div class="flexItem chevronParent"
             [class.enabled]="infoPanel.currentReleaseHighlight < (releaseHighlights.length - 1)"
             (click)="next()">
            <ac-svg class="chevron" name="chevron" height="20px" [mirrorHor]="true"
                    [fillColor]="infoPanel.currentReleaseHighlight < (releaseHighlights.length - 1) ? statusColors.text : statusColors.systemBorder">
            </ac-svg>
        </div>
    </div>
</div>
