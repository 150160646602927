import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {MatFormFieldControl} from '@angular/material/form-field';
import {MatCalendar} from '@angular/material/datepicker';
import {DateTime, Settings} from 'luxon';
import {AcDatePickerHeaderComponent} from '../../ac-date-picker-header/ac-date-picker-header.component';
import {RESET_SEC_AND_MILLIS} from '../../ac-date-picker.models';

Settings.throwOnInvalid = true;

@UntilDestroy()
@Component({
    selector: 'ac-date-calendar',
    styleUrls: ['./ac-date-calendar.component.less', '../calender.less'],
    templateUrl: './ac-date-calendar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcDateCalendarComponent {
    acDatePickerHeader = AcDatePickerHeaderComponent;
    @ViewChild(MatCalendar, {static: true}) private matCalendar: MatCalendar<DateTime>;
    @ViewChild(MatFormFieldControl, {static: true}) matFormFieldControl: MatFormFieldControl<any>;

    timeState;

    @Input() label = '';
    @Input() minTime: DateTime;
    @Input() minDate: DateTime;
    @Input() timeIncrement: number;
    @Input() hourOnly = false;

    selectedDate: DateTime;

    @Input('selectedDate') set setSelectedDate(date: DateTime) {
        date = date || this.minDate || DateTime.now();
        if (this.minDate && date < this.minDate) {
            date = this.minDate;
        }
        this.setTimeState(date);
        this.selectedDate = date;
    }

    @Output() selectedDateChange = new EventEmitter<DateTime>();
    @Output() cancel = new EventEmitter();
    @Output() apply = new EventEmitter<DateTime>();

    setTime(date: DateTime, timeSegment: DateTime): DateTime {
        if (!date || !timeSegment) {
            return date;
        }
        return date.set(this.getTimeSegment(timeSegment));
    }

    getTimeSegment(date: DateTime) {
        const {year, month, day, ...timeSegment} = date.toObject();
        return timeSegment;
    }

    setTimeState(date: DateTime) {
        this.timeState = date?.set(RESET_SEC_AND_MILLIS);
    }

    onSelect(date: DateTime): void {
        if (!this.timeState) {
            this.setTimeState((this.minDate && date < this.minDate) ? this.minDate : date);
        }
        date = this.setTime(date, this.timeState);
        this.selectedDate = date;
        this.selectedDateChange.emit(this.selectedDate);
    }


    focus() {
        this.matCalendar.focusActiveCell();
    }

    goToDate(date?: DateTime) {
        this.matCalendar.activeDate = date || this.selectedDate || DateTime.now();
    }

    changeSelectedDate = () => {
        this.selectedDate = this.setTime(this.selectedDate, this.timeState);

        this.selectedDateChange.emit(this.selectedDate);
    };

    onTimeChange(dateTime: DateTime) {
        this.timeState = dateTime;
        this.changeSelectedDate();
    }

    getMinDateTime = () => {
        if (!this.isSelectedAndMinDateSameDay()) {
            return;
        }
        return this.minDate;
    };

    private isSelectedAndMinDateSameDay = () => this.minDate && this.selectedDate?.startOf('day').equals(this.minDate.startOf('day'));
}

