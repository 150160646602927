import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TimeagoModule} from 'ngx-timeago';
import {AcListItemComponent} from './ac-list-item.component';
import {InfraDirectivesModule} from '../../directives/infra.directives.module';


@NgModule({
    declarations: [AcListItemComponent],
    imports: [
        CommonModule,
        TimeagoModule,
        InfraDirectivesModule,
    ],
    exports: [AcListItemComponent]
})
export class AcListItemModule {
}
