import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {AcButtonComponent} from './ac-button.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
    ],
    declarations: [
        AcButtonComponent,
    ],
    entryComponents: [
        AcButtonComponent,
    ],
    exports: [
        AcButtonComponent,
    ]
})
export class AcButtonModule {
}
