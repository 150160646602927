<ac-nav class="flexItem">
    <ng-content logo select="[logo]"></ng-content>
    <ng-content menu select="[menu]"></ng-content>

    <ac-nav-button #rlaF="routerLinkActive"
                   *ngFor="let nav of (acNavAutoService.navs[0] | acFunc : getChildren) | filterNavs:1: acNavAutoService.changes"
                   [attr.id]="'nav-first-'+nav.path"
                   [acDisableLink]="rlaF.isActive"
                   [routerLink]="nav.path | routeBuilder:true"
                   first
                   routerLinkActive="active"
    >{{nav.data?.text || nav.path}}</ac-nav-button>
    <ac-nav-button #rlaS="routerLinkActive"
                   *ngFor="let nav of (acNavAutoService.navs[1] | acFunc : getChildren) | filterNavs:2: acNavAutoService.changes"
                   [attr.id]="'nav-secondary-'+nav.path"
                   [acDisableLink]="rlaS.isActive || ((nav.children | filterNavs:3: acNavAutoService.changes)||[]).length>0 && !nav.data?.navAutoNoDropdown"
                   [dropdown]="((nav.children | filterNavs:3: acNavAutoService.changes) || []).length>0 && !nav.data?.navAutoNoDropdown"
                   [parentClass]="nav.path"
                   [routerLink]="acNavAutoService.navs[1]?.urlWithParams | routeBuilder:true:nav.path"
                   routerLinkActive="active"
                   secondary>{{nav.data?.text || nav.path}}

        <div *ngFor="let third of nav.children | filterNavs:3: acNavAutoService.changes"
             [attr.id]="'nav-third-'+third.path"
             [routerLink]="acNavAutoService.navs[1]?.routeConfig.path | routeBuilder:true: nav.path : third.path"
             dropdown-option
             routerLinkActive="active"
        >{{third['data'] && third.data.text || third.path}}</div>
    </ac-nav-button>

    <div *ngIf="acNavAutoService.navs[1]?.data.secondNavText" secondary>
        <div class="secondary-text">{{acNavAutoService.navs[1]?.data.secondNavText}}</div>
    </div>
</ac-nav>
