import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AcCheckboxGroupComponent} from './ac-checkbox-group.component';
import {VirtualScrollerModule} from 'ngx-virtual-scroller';
import {AcTextFieldModule} from '../ac-text-field/ac-text-field.module';
import {AcCheckboxModule} from '../ac-checkbox/ac-checkbox.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        VirtualScrollerModule,
        AcTextFieldModule,
        AcCheckboxModule
    ],
    declarations: [
        AcCheckboxGroupComponent,
    ],
    entryComponents: [
        AcCheckboxGroupComponent,
    ],
    exports: [
        AcCheckboxGroupComponent,
    ]
})
export class AcCheckboxGroupModule {
}
