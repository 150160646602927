import {ReportColumns} from './report-columns';

export class ReportEntity extends ReportColumns implements ReportColumns {
    name: string;

    constructor(entity: any, skipIdInit = false) {
        super(entity, skipIdInit);
        this.columnType = 'Entity';
    }

    isLocked = () => true;
}
