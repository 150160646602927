<ac-form *ngIf="dialogConfig.ready" [formSettings]="dialogConfig.formSettings">
    <ac-input-container [label]="entitySelectionObject.selectLabel">
        <ac-single-select [sortItems]="true"
                          bindLabel="name"
                          bindValue="id"
                          [items]="entitySelectionObject.items"
                          [acModel]="entitySelectionObject.entity.id"
                          (acModelChange)="entitySelectionObject.entity.id = $event">
        </ac-single-select>
    </ac-input-container>
</ac-form>
