import {Component, Inject} from '@angular/core';
import {AcDialog, DIALOG_CONFIG, DialogConfig, SchemaHelperService} from 'ac-infra';
import * as _ from 'lodash';

@AcDialog({
    id: 'update-password',
    title: 'Update Operator Password',
    cancelButtonText: 'Close',
    width: 550,
})
@Component({
    selector: 'update-password-dialog',
    templateUrl: './update-password-dialog.component.html',
    styleUrls: ['./update-password-dialog.component.less'],
})
export class UpdatePasswordDialogComponent {

    schema = {properties: {newPassword: {minLength: 8, maxLength: 30}}};
    operator: any = {};
    passwords;
    oldPassword = false;

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {
    }

    ngOnInit() {
        this.operator = this.dialogConfig.dialogData.entity;
        this.operator.oldPassword && (this.oldPassword = true);
        this.passwords = {confirmNewPassword: this.operator.confirmNewPassword};
        delete this.operator.confirmNewPassword;
    }

    validateAuxiliaryForm = (errors, auxModel, formModel) => {
        if (auxModel && formModel) {
            if (formModel.newPassword) {
                if (!_.isEqual(formModel.newPassword, auxModel.confirmNewPassword)) {
                    errors.push(SchemaHelperService.buildErrorItem({inputName: 'passwords.confirmNewPassword', keyword: 'confirmPassword'}));
                }
            }

            if ((formModel.oldPassword && formModel.oldPassword !== '') && (formModel.newPassword && formModel.newPassword !== '') && _.isEqual(formModel.newPassword, formModel.oldPassword)) {
                errors.push(SchemaHelperService.buildErrorItem({inputName: 'operator.oldPassword', keyword: 'equalPasswords'}));
                errors.push(SchemaHelperService.buildErrorItem({inputName: 'operator.newPassword', keyword: 'equalPasswords'}));
            }

            if (auxModel.confirmPassword && formModel.newPassword && auxModel.confirmPassword !== formModel.newPassword) {
                errors.push(SchemaHelperService.buildErrorItem({inputName: 'passwords.confirmNewPassword', keyword: 'confirmPassword'}));
            }
        }
    };
}


