import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {SvgModelName} from '../../ac-icons/ac-svg/ac-svg.models';

@UntilDestroy()
@Component({
    selector: 'ac-drag-and-drop-list',
    templateUrl: './ac-drag-and-drop-list.component.html',
    styleUrls: ['./ac-drag-and-drop-list.component.less'],
})
export class AcDragAndDropListComponent {

    @Output() listChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() sortableOrderArrayChanged: EventEmitter<any> = new EventEmitter<any>();
    @Input() sortableOrderArray: any = undefined;
    @Input() isViewOnly: boolean;
    @Input() displaySortableArrows = false;
    @Input() searchedValue = '';
    @Input() lockPropertyName = 'type';
    @Input() dndId: string;
    @Input() bindLabel: string;
    @Input() onRowClick: Function;
    @Input() inverseListName: string;
    @Input() inverseListAllowedTypes: any[] = undefined;
    @Input() updateColumnSortArray$: Observable<any>;
    svgModelName = SvgModelName;
    MAX_SORTABLE_ITEMS = 4;

    constructor(private cdRef: ChangeDetectorRef) {}

    _list: any;

    @Input() set list(list: any) {
        this._list = list;
        this.listChanged.emit(list);
    }

    ngOnInit(){
        this.updateColumnSortArray$?.pipe(untilDestroyed(this)).subscribe((emptySortableOrderArray) => {
            if (this.sortableOrderArray && emptySortableOrderArray) {
                this.sortableOrderArray = [];

                this._list.items.forEach((column) => {
                    if (column.orderObj) {
                        delete column.orderObj;
                    }
                });
            }
            this.updateColumnSortArray();
            this.cdRef.detectChanges();
        });
    }

    clickRow(item) {
        if (this.isViewOnly) {
            return;
        }

        if (this.onRowClick) {
            this.onRowClick(this.inverseListName);
        }
        item.selected = !item.selected;
    }

    moveSelectedToNextPosition = ({isDirectionDown}) => {// used by acDragAndDrop.ts
        if (isDirectionDown) {
            for (let i = this._list.items.length - 1; i >= 0; i--) {
                if (this._list.items[i].selected) {
                    if (i !== this._list.items.length - 1 && !this._list.items[i + 1].selected) {
                        const listTempItem = this._list.items[i];
                        this._list.items[i] = this._list.items[i + 1];
                        this._list.items[i + 1] = listTempItem;
                    }
                }
            }
        } else {
            for (let i = 0; i <= this._list.items.length - 1; i++) {
                if (this._list.items[i].selected) {
                    if (i !== 0 && !this._list.items[i - 1].selected) {
                        const listTempItem = this._list.items[i];
                        this._list.items[i] = this._list.items[i - 1];
                        this._list.items[i - 1] = listTempItem;
                    }
                }
            }
        }
    };

    findSortOrderOfItem = (orderObj) => this.sortableOrderArray.findIndex((item) => item.id === orderObj.id);

    displayOrderNumber = (item) => {
        if (!this.displaySortableArrows || !item.orderObj || !this.sortableOrderArray) {
            return false;
        }
        return item.orderObj && this.sortableOrderArray.length > 1 && this.findSortOrderOfItem(item.orderObj) !== -1;
    };

    orderChanged = (ev, selectedItem) => {
        if (!ev.ctrlKey) {
            this._list.items.forEach((item) => {
                if (selectedItem !== item) {
                    delete item.orderObj;
                }
            });

            this.sortableOrderArray = [];
            this.changeSelectedOrderType(selectedItem);
            this.addItemToSortArray(selectedItem);
        } else {
            this.changeSelectedOrderType(selectedItem, true);
            this.handleMultipleOrder(selectedItem);
        }

        let timeColumnUpdate = 'ASC';
        if (selectedItem.orderObj && selectedItem.orderObj.sortType === 'NONE') {
            this.sortableOrderArray.splice(this.sortableOrderArray.indexOf(selectedItem.orderObj), 1);
            delete selectedItem.orderObj;
            timeColumnUpdate = 'DESC';
        }

        this.updateColumnSortArray(timeColumnUpdate);
        this.sortableOrderArrayChanged.emit(this.sortableOrderArray);
    };

    createOrderObject = (item, timeColumnUpdate?) => {
        const sortType = item.restName === 'timeColumn' ? (timeColumnUpdate ? timeColumnUpdate : 'ASC') : 'DESC';
        item.orderObj = {
            id: item.id,
            sortType
        };
    };

    updateColumnSortArray = (timeColumnUpdate?) => {
        if (this.sortableOrderArray) {
            this._list.items.forEach((item) => {
                if (this.sortableOrderArray.length === 0 && item.columnType === 'Mandatory') {
                    this.sortableOrderArray = [];
                    this.createOrderObject(item, timeColumnUpdate);
                    this.addItemToSortArray(item);
                    this.updateSortOrder(item);
                } else if (this.sortableOrderArray.length > 0) {
                    if (item.orderObj) {
                        this.updateSortOrder(item);
                    }
                }
            });
        }
    };

    addItemToSortArray = (item) => {
        this.sortableOrderArray.push(item.orderObj);
    };

    updateSortOrder = (item) => {
        const index = this.sortableOrderArray.indexOf(item.orderObj);
        if (index > -1) {
            item.orderObj.sortOrder = index + 1;
        }
    };

    changeSelectedOrderType = (selectedItem, isMultiple = false) => {
        if (!selectedItem.orderObj) {
            if (!isMultiple || (isMultiple && this.sortableOrderArray.length !== this.MAX_SORTABLE_ITEMS)) {
                this.createOrderObject(selectedItem);
            }
        } else {
            selectedItem.orderObj.id = selectedItem.id;
            selectedItem.orderObj.sortType = selectedItem.orderObj.sortType === 'DESC' ? 'ASC' : 'NONE';
        }
    };

    handleMultipleOrder = (item) => {
        const inArray = this.sortableOrderArray.indexOf(item.orderObj) !== -1;
        if (this.sortableOrderArray.length < this.MAX_SORTABLE_ITEMS && !inArray) {
            this.addItemToSortArray(item);
        }
    };
}
