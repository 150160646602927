import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AcLineSeparatorComponent} from './ac-line-separator.component';
import {AcIconsModule} from '../ac-icons/ac-icons.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AcIconsModule,
    ],
    declarations: [
        AcLineSeparatorComponent,
    ],
    entryComponents: [
        AcLineSeparatorComponent,
    ],
    exports: [
        AcLineSeparatorComponent,
    ]
})
export class AcLineSeparatorModule {
}
