import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AcChartComponent} from './ac-chart.component';
import {InfraDirectivesModule} from '../../directives/infra.directives.module';
import {AcProgressModule} from '../ac-progress/ac-progress.module';


@NgModule({
    imports: [
        CommonModule,
        AcProgressModule,
        InfraDirectivesModule,
    ],
    declarations: [
        AcChartComponent,
    ],
    entryComponents: [
        AcChartComponent,
    ],
    exports: [
        AcChartComponent,
    ]
})
export class AcChartModule {
}
