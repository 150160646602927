import {Component, ElementRef, Host, Inject, Input, ViewChild} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {AC_TABLE_CONFIG, AcTableColumn, AcTableConfig} from '../ac-table.interface';
import {AcTableComponent} from '../ac-table.component';
import {AcTableService} from '../ac-table.service';


@UntilDestroy()
@Component({
    selector: '[ac-table-footer]',
    templateUrl: './ac-table-footer.component.html',
    styleUrls: ['./ac-table-footer.component.less'],
})
export class AcTableFooterComponent {
    @Input() columns: AcTableColumn[];

    constructor(public acTableComponent: AcTableComponent,
                public acTableService: AcTableService,
                @Inject(AC_TABLE_CONFIG) public acTableConfig: AcTableConfig) {
    }

}
