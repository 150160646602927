import {Component, ContentChildren, EventEmitter, Input, Optional, Output, QueryList, SkipSelf, TemplateRef, ViewChild} from '@angular/core';

import {MatTabChangeEvent, MatTabGroup} from '@angular/material/tabs';

import {GeneralService} from '../../services/general.service';
import {AcFormComponent} from '../ac-form/ac-form.component';
import {AcParentDirective} from '../../directives/ac-parent.directive';
import {Subject} from 'rxjs';
import {AcDialogComponent} from '../ac-dialog/ac-dialog.component';
import {AcTabDirective} from './ac-tab.directive';

@Component({
    selector: 'ac-tabs',
    templateUrl: './ac-tabs.component.html',
    styleUrls: ['./ac-tabs.component.less'],
})
export class AcTabsComponent {

    _defaultTabId: string = null;
    @Input() set defaultTabId(defaultTabId: string) {
        this._defaultTabId = defaultTabId;
        setTimeout(() => {
            this.setDefaultTabIndex();
        });
    }

    @ViewChild('matTabGroup', {static: false}) matTabGroup: MatTabGroup;

    @Input() tabsId: string = null;
    @Input() stretch = true;
    @Input() loading = false;
    @Input() keepOnlyActive = false;
    @Input() contentWrapperTemplate: TemplateRef<any>;
    @Input() emptyTemplate: TemplateRef<any>;
    @Input() singleView = false;
    @Output() onSelect: EventEmitter<AcTabDirective> = new EventEmitter<AcTabDirective>();
    @ContentChildren(AcParentDirective) tabs: QueryList<AcTabDirective>;
    activeTabIdx = 0;
    refreshSubject = new Subject();
    dispatchResizeEvent = GeneralService.dispatchResizeEvent;
    activeTab: AcTabDirective;

    constructor(@Optional() public acDialog: AcDialogComponent,
                @Optional() public formHost: AcFormComponent,
                public generalService: GeneralService) {
    }

    ngAfterViewInit() {
        this.defaultTabId = this._defaultTabId;
        this.onSelectedTabChanged({index: 0});
        this.setDefaultTabIndex();
    }

    onSelectedTabChanged(matTabChangeEvent: MatTabChangeEvent | any) {
        if (matTabChangeEvent.index < 0) {
            return;
        }
        this.activeTab = this.filterIfCondition(this.tabs)[matTabChangeEvent.index];
        this.onSelect.emit(this.activeTab);
    }

    filterIfCondition = (tabs): AcTabDirective[] => {
        return tabs?.filter(tab => tab._if) || [];
    };

    refresh = () => {
        this.refreshSubject.next([]);
    };

    private setDefaultTabIndex() {
        const activeTabs = this.filterIfCondition(this.tabs);
        this.activeTabIdx = activeTabs.findIndex(tab => tab.id === this._defaultTabId) || 0;
        this.matTabGroup.realignInkBar(); // faster animation
    }
}
