export enum reportGraphTypes {
    BAR = 'Bar',
    LINE = 'Line',
    PIE = 'Pie',
    STACKBAR = 'StackBar'
}

export class reportGraph {
    static idCounter = 0;
    id: number;
    graphName: string;
    graphType: reportGraphTypes;
    cols: number;
    rows: number;
    x: number;
    y: number;
    columns: any;

    constructor(graphName = '', graphType = undefined, x = 0, y = 0, cols = 4, rows = 1, columns = []) {
        // this.graphName = graphName;
        this.graphType = graphType;
        this.x = x;
        this.y = y;
        this.cols = cols;
        this.rows = rows;
        this.columns = columns;
        this.id = ++reportGraph.idCounter;
    }


}
