import {ArrayUtil} from "ac-infra";
import {FilterState} from "../../common/components/ac-filter/services/ac-filter-state.service";

export const version2000To3000Migration = (workspaceData) => {
    const filterName = FilterState.getStorageFilterName(workspaceData?.tenantScope);

    return ArrayUtil.arrayToObjTransform(Object.getOwnPropertyNames(workspaceData), (acc, curr) => {
        const oldData = workspaceData[curr];
        if(curr.includes('pinned')){
            acc[filterName][curr] = oldData;
        }else{
            acc[curr] = oldData;
        }
    }, {[filterName]: {}});
};
