import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AcDropDownComponent} from './ac-dropdown.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatMenuModule,
        MatButtonModule
    ],
    declarations: [
        AcDropDownComponent,
    ],
    entryComponents: [
        AcDropDownComponent,
    ],
    exports: [
        AcDropDownComponent,
    ]
})
export class AcDropdownModule {
}
