import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AcProgressComponent} from './ac-progress.component';
import {LottieModule} from 'ngx-lottie';

export interface AcProgressOptions {
    acProgressLoaderPath?: string;
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        LottieModule
    ],
    declarations: [
        AcProgressComponent,
    ],
    entryComponents: [
        AcProgressComponent,
    ],
    exports: [
        AcProgressComponent,
    ]
})
export class AcProgressModule {
}
