import {Component, Inject} from '@angular/core';

import {AcDialog, DIALOG_CONFIG, DialogConfig} from '../../ac-dialog.models';

@AcDialog({
    id: 'failed-action-dialog',
    title: 'Action Failed',
    cancelButtonText: 'Close',
    formSettings: {valid: true, touched: true},
    width: 450,
    colorPalette: 'red',
    multiInstanceAllowed: true
})
@Component({
    selector: 'failed-action-dialog',
    templateUrl: './failed-dialog.component.html',
})
export class FailDialogComponent {

    message;

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {
    }

    ngOnInit() {
        this.message = this.dialogConfig.dialogData.message;
        this.dialogConfig.ready = true;
    }
}


