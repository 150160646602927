import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {TreeModule} from '@circlon/angular-tree-component/';

import {AcTreeComponent} from './ac-tree.component';
import {AcTreeNavigationComponent} from './ac-tree-navigation/ac-tree-navigation.component';
import {AcTreeRouterComponent} from './ac-tree-router/ac-tree-router.component';

import {InfraDirectivesModule} from '../../directives/infra.directives.module';
import {AcProgressModule} from '../ac-progress/ac-progress.module';
import {InfraPipesModule} from '../../pipes/infra.pipes.module';
import {AcNavModule} from '../ac-nav/ac-nav.module';
import {AcIconsModule} from '../ac-icons/ac-icons.module';
import {AcSearchModule} from '../ac-search/ac-search.module';

@NgModule({
    imports: [
        CommonModule,
        TreeModule,
        InfraDirectivesModule,
        AcProgressModule,
        InfraPipesModule,
        AcSearchModule,
        AcNavModule,
        AcIconsModule,
    ],
    declarations: [
        AcTreeComponent,
        AcTreeNavigationComponent,
        AcTreeRouterComponent,
    ],
    entryComponents: [
        AcTreeComponent,
        AcTreeNavigationComponent,
        AcTreeRouterComponent,
    ],
    exports: [
        AcTreeComponent,
        AcTreeNavigationComponent,
        AcTreeRouterComponent,
        TreeModule
    ]
})
export class AcTreeModule {
}
