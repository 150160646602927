<div class="linksContainer flexColumnParent">
    <ng-container *ngFor="let app of (externalApplications$ | async) | acFunc: getApps: widgetItem.widgetConfig?.data; let last = last;">
        <external-link [hasConfig]="authorizedForSystemAdmin && !!app.configUrl"
                       [linkKey]="app.id | lowercase"
                       [attr.id]="'dashboard-external-link-' + app.id"
                       [linkValue]="app"
        ></external-link>

        <div *ngIf="!last" class="separator"></div>
    </ng-container>
</div>
