<network-tenant-region-selection *ngIf="dialogConfig.ready"
                                 [model]="tenant"
                                 (acTenantChanged)="tenant.tenantId = $event"
                                 [sortTenants]="true"
                                 [isEdit]="false"
                                 [tenants]="tenants"
                                 [overrideSystemViewMode]="true"
                                 [showRegion]="false">
</network-tenant-region-selection>

