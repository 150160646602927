import {Injectable} from '@angular/core';
import {SoftwareManagerRestService} from '../../../system/configuration/configuration-api/software-manager-rest.service';
import {PromiseService} from 'ac-infra';
import {RestResponseSuccess} from '../../server-actions/rest';

@Injectable({providedIn: 'root'})
export class LogoSelectionService {

    constructor(private softwareManagerRestService: SoftwareManagerRestService) {}

    public getLogoImage = (logoObject, logoPropertyName, getOnlyRequestUrl = false) => {
        const defer = PromiseService.defer();

        const onSuccessGetFileContent = (response: RestResponseSuccess) => {
            if(getOnlyRequestUrl){
                defer.resolve(response.data);
            }else {
                this.loadLogoImage(response, defer);
            }
        };

        const onFailureGetFileContent = () => {
            defer.resolve(-1);
        };

        if (logoObject[logoPropertyName] && logoObject[logoPropertyName] > -1) {
            this.softwareManagerRestService.getFileContent(onSuccessGetFileContent, onFailureGetFileContent, logoObject[logoPropertyName], getOnlyRequestUrl);
        } else {
            onFailureGetFileContent();
        }

        return defer.promise;
    };

    public handleLogoImage = (response) => {
        const defer = PromiseService.defer();
        this.loadLogoImage(response, defer);
        return defer.promise;
    };

    private loadLogoImage = (response, defer) => {
        const blob = new Blob([response.data], {type: 'image/jpg'});
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
            defer.resolve(reader.result);
        };
    };


}
