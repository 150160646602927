<div #statisticsPmParametersElement class="profile-content statistics-pm-parameters" [class.no-side-panel]="!sidePanel">

    <ac-layout-panel class="fullContainer parameters-header-layout">
        <ng-template #panelHeader>

            <ac-checkbox [labelOnly]="isSingleSelect"
                         class="parameters-header flexRowParent"
                         [acModel]="changeDetected | acFunc: isAllTopicsChecked"
                         (acModelChange)="topicsCheckboxClicked(); changeHappened()"
                         [ngDisabled]="isDisabled"
                         [labelText]="'Selected (' + (changeDetected | acFunc: getNumberOfSelectedParameters).polled+')'">
            </ac-checkbox>
            <ac-input-container [hideLabel]="true" [displayAsBasic]="true" class="parameters-select fullWidth">
                <ac-single-select [items]="parametersItems"
                           bindLabel="value"
                           [(acModel)]="parametersSelect"
                           (acModelChange)="buildTopics($event)">
                </ac-single-select>
            </ac-input-container>
        </ng-template>

        <ac-tree-navigation #navigationTree
                            disableUpperCase="true"
                            (activeChange)="onTopicNavigationTreeSelection($event);changeHappened()"
                            (initialized)="onInitialize()"
                            [treeNodes]="treeData"
                            class="navigation-tree"
                            displayField="text"
                            treeId="topic-navigation-tree"
        ></ac-tree-navigation>
    </ac-layout-panel>

    <ac-layout-panel class="fullContainer">
        <ng-template #panelHeader>
            <div class="flexRowParent">
                <label class="flexItemBiggest parameters-header">
                    <ac-checkbox [labelOnly]="isSingleSelect"
                                 labelText="Name"
                                 [acModel]="changeDetected | acFunc: isAllTopicParametersChecked"
                                 (acModelChange)="parametersCategoryCheckboxClicked(); changeHappened()"
                                 [ngDisabled]="isDisabled">
                    </ac-checkbox>
                </label>

                <div *ngFor="let fieldName of parametersOptionsLabels"
                     class="flexItem parameter-content">{{fieldName}}</div>
            </div>
        </ng-template>

        <div class="flexColumnParent overflowHidden">
            <div class="flexItemBiggest flexColumnParent right-lower-container inner-container">

                <div class="parent-groups-main-container" #parentGroupsMainContainer>
                    <div class="parent-groups-container">

                        <div *ngFor="let group of groupsArray | orderBy : '':false:false:sortGroupsByOrder"
                             class="flexItem">
                            <div class="profile-group-selection-title">
                                <ac-checkbox
                                    [labelOnly]="isSingleSelect"
                                    [attr.id]="group | acFunc : getGroupMibId"
                                    [acModel]="group | acFunc: isAllParametersCheckedFromCheckbox :changeDetected"
                                    (acModelChange)="parametersCategoryCheckboxClicked(group); changeHappened()"
                                    [ngDisabled]="isDisabled"
                                    [labelText]="(group[0] | acFunc: getFirstObjectKey).groupDisplayName"
                                    class="profile-group-selection">
                                </ac-checkbox>
                            </div>

                            <div *ngFor="let parameter of group | orderBy: '': false: false: sortParametersByOrder"
                                 class="flexRowParent">

                                <div class="flexItem parameter-img">
                                    <ac-svg name="resize" width="16px"
                                            [fillColor]="parameter | acFunc: getAggregatedTypeIconColor"
                                            [text]="parameter | acFunc : getAggregatedTypeIconTitle">
                                    </ac-svg>
                                </div>

                                <div class="flexItem parameter-img">
                                    <ac-svg [name]="parameter | acFunc: getTypeIconPath" width="16px"
                                            [text]="parameter | acFunc : getTypeIconTitle">
                                    </ac-svg>
                                </div>

                                <div class="param-display-name" acLabel>{{(parameter | acFunc : getFirstObjectKey).displayName}}</div>

                                <div *ngFor="let fieldName of parametersOptions"
                                     class="flexItem parameter-content">
                                    <ac-checkbox *ngIf="parameter[fieldName] as fieldParam"
                                                 [(acModel)]="fieldParam.isPolled"
                                                 (acModelChange)="onParameterClick(parameter, fieldName, $event);changeHappened()"
                                                 [attr.id]="fieldParam.mibName || (fieldParam.restData | acFunc : getRestName)"
                                                 [ngDisabled]="isDisabled">
                                    </ac-checkbox>

                                    <div *ngIf="!parameter[fieldName]" class="no-parameter">—</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ac-layout-panel>

    <statistics-pm-parameters-filter-tab *ngIf="sidePanel === 'index'"
                                         class="statistics-pm-filter"
                                         [isDisabled]="isDisabled"
                                         [selectedTopic]="selectedTopic">
    </statistics-pm-parameters-filter-tab>

    <statistics-pm-entities-filter-tab *ngIf="sidePanel === 'entities'"
                                       [deviceId]="deviceId"
                                       [profiles]="profileTopics"
                                       [selectedTopic]="selectedTopic"
                                       [isDisabled]="isDisabled"
                                       [changeHappened]="changeHappened"
                                       class="statistics-pm-filter">
    </statistics-pm-entities-filter-tab>
</div>
