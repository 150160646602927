import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'ac-menu-user-info',
    templateUrl: './ac-menu-user-info.component.html',
    styleUrls: ['./ac-menu-user-info.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcMenuUserInfoComponent {

    @Input() operator = 'username';
    @Input() securityLevel = '';
    @Input() operatorAuthenticationMode = '';

}
