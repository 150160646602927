<div class="active-alarms-container flexColumnParent" *ngIf="alarmFetchedObj">
    <div class="flexItem">
        <div *ngFor="let item of alarmsNames | keyvalue" class="alarm-severity flexRowParent {{item.key}}"
             (click)="gotoAlarmsPageFilteredBySeverityFunc([item.key])">
            <div class="flexRowParent">
                <div class="bell-container">
                    <ac-svg [fillColor]="item.value" name="bellRing"></ac-svg>
                </div>
                <span class="statisticsLabel" [style.color]="item.value">{{item.key}}</span>
            </div>
            <span class="statisticsNumber" [style.color]="item.value">{{alarmFetchedObj[item.key]}}</span>
        </div>
    </div>

    <div class="total-calls-container">
        <ac-display-box (acClick)='gotoAlarmsPageFilteredBySeverityFunc(["critical", "major", "minor", "warning"])'
                        [upperValue]="totalAlarms"
                        [showGreyBackground]="true"
                        upperSize="large"
                        lowerSize="extra-small"
                        lowerValue="Total active alarms">
        </ac-display-box>
    </div>
</div>
