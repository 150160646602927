import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class BuildSortService {
    public static buildSortQuery(sort) {
        if (!sort || sort.length < 1) {
            return null;
        }

        return this.isDescending(sort[0].dir) ? '-' + sort[0].field : sort[0].field;
    }

    static isDescending(direction) {
        return direction === 'desc';
    }
}
