<ac-form *ngIf="dialogConfig.ready"
         [formModel]="group"
         [formSchema]="schema"
         [isEdit]="isEdit"
         formModelName="group">

    <div id="group-details-dialog-content">
        <div class="flexRowParentEqualChildren">
            <ac-input-container formFieldName="group.name" label="Group Name">
                <ac-textfield [(acModel)]="group.name" textFieldId="group-name">
                </ac-textfield>
            </ac-input-container>

            <ac-input-container formFieldName="group.description" label="Description">
                <ac-textfield [(acModel)]="group.description"
                              textFieldId="group-description">
                </ac-textfield>
            </ac-input-container>
        </div>

        <network-tenant-region-selection [model]="group"
                                         [additionalSettings]="additionalSettings"
                                         (acTenantChanged)="group.tenantId = $event; treeViewPanelEl?.defaultAttachments()"
                                         tenantFormFieldName="group.tenantId"
                                         [ngDisabledTenant]="isEdit"
                                         [showRegion]="false">
        </network-tenant-region-selection>

        <ac-input-container formFieldName="group.ldapGroupName" label="AD Group" *ngIf="additionalSettings.type === 'ENDPOINT'">
            <ac-textfield [(acModel)]="group.ldapGroupName" textFieldId="group-ldapGroupName">
            </ac-textfield>
        </ac-input-container>

        <tree-view-panel [model]="group" *ngIf="additionalSettings.type === 'TOPOLOGY'" #treeViewPanel
                         [additionalSettings]="additionalSettings"
                         type="topology">
        </tree-view-panel>
    </div>
</ac-form>
