import {Pipe, PipeTransform} from '@angular/core';
import {Duration} from 'luxon';
import {DurationUnit} from 'luxon/src/duration';

@Pipe({name: 'duration'})
export class DurationPipe implements PipeTransform {

    units: DurationUnit[] = ['hours', 'minutes', 'seconds'];
    constructor() {
    }

    transform(time: number, fromType: DurationUnit = 'milliseconds'): string | null {
        if (!time) {
            return;
        }

        const dur = Duration.fromObject({[fromType]: time}).rescale();

        const units: DurationUnit[] = this.units.filter((unit: DurationUnit) => {
            return dur.get(unit) > 0;
        });

        return dur.shiftTo(...units).toHuman({unitDisplay: 'short'});
    }
}
