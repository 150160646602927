import {Injectable} from '@angular/core';
import {GeneralService} from 'ac-infra';

@Injectable({providedIn: 'root'})
export class EncryptionService {
    constructor(private generalService: GeneralService) {
    }

    hashCredentials = (username, password) => {
        try {
            return btoa(username + (password ? (':' + password) : ''));
        } catch (ex) {
            this.generalService.loginErrorMessage = 'username or password are invalid';
        }
    };

    hashValue = (value) => btoa(value);

    restoreHashedValue = (hashedValue) => atob(hashedValue);
}
