import {Component, Input} from '@angular/core';

import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Observable} from 'rxjs';
import {GeneralService} from 'ac-infra';

@UntilDestroy()
@Component({
    selector: 'statistics-summary',
    templateUrl: './statistics-summary.component.html',
    styleUrls: ['./statistics-summary.component.less'],
})
export class StatisticsSummaryComponent {

    @Input() isLinksMode = false;
    @Input() isSingleLine = false;
    @Input() limitTableWidth = false;
    @Input() noMaxConcurrent = false;
    @Input() hideStatisticTable = false;
    @Input() largeTitle = false;

    @Input() height = 220;

    @Input() displayType = '';
    @Input() refresh$: Observable<any>;

    @Input() onClick: Function | boolean;
    @Input() fetchFunction: Function;
    @Input() showInLegend = false;

    qualityDistributionChart: any = {};
    statisticsSummary: any[];
    successFailedChart: any = {};
    statusColorsList: any;
    unitType: any;
    data;

    ngOnInit() {
        this.statusColorsList = GeneralService.statusColors;
        this.unitType = this.isLinksMode ? 'Streams' : 'Calls';

        this.initChartsOptions();

        this.doRefresh();
        this.refresh$ && this.refresh$.pipe(untilDestroyed(this)).subscribe(this.doRefresh);
    }

    buildChartOptions = () => {
        const options: any = {
            chart: {type: 'pie'},
            legend: {
                floating: true
            },
            plotOptions: {
                pie: {
                    size: '80%',
                    innerSize: '55%',
                    showInLegend: this.showInLegend,
                    allowPointSelect: true,
                    dataLabels: {
                        enabled: !this.showInLegend,
                        softConnector: true,
                        distance: 5
                    },
                }
            },
            series: [{name: this.unitType, data: []}]
        };
        if (this.onClick !== false) {
            options.plotOptions.pie.events = {
                click: (e) => {
                    let category = e.point.name.toLowerCase();
                    if (category === 'successful') {
                        category = 'success';
                    }
                    (this.onClick as Function)({category, data: this.data});
                }
            };
        }
        return options;
    };

    doRefresh = () => {
        const onSuccess = (value) => {
            this.data = value.data;
            this.initChartsOptions();
            this.initSummaryStatistics(value && (value.summaryStatistics || (value.data && value.data.summaryStatistics)));
        };

        this.fetchFunction(onSuccess, () => {});
    };

    initChartsOptions = () => {
        this.statisticsSummary = [];

        this.successFailedChart = {
            title: 'Successful/Failed ' + (this.isLinksMode ? 'Streams' : 'Calls'),
            options: this.buildChartOptions(),
        };

        this.qualityDistributionChart = {
            title: 'Quality Distribution',
            options: this.buildChartOptions(),
        };
    };

    initSummaryStatistics = (summaryStatistics) => {
        const options = {
            title: [
                {text: 'Total ' + this.unitType, value: summaryStatistics.totalCalls || 0},
            ],
            content: [
                {text: 'MOS', value: summaryStatistics.avgMos || 0},
                {text: 'Jitter', value: summaryStatistics.avgJitter || 0},
                {text: 'Delay', value: summaryStatistics.avgDelay || 0},
                {text: 'P.Loss', value: summaryStatistics.avgPacketLoss || 0}
            ]
        };


        if (!this.noMaxConcurrent) {
            options.title.push({
                text: 'Max Concurrent ' + this.unitType,
                value: summaryStatistics.maxConcurrentCalls || 0
            });
        }

        this.statisticsSummary.push(options);

        this.initialSuccessFailChart(summaryStatistics);
        this.initialQualityDistribution(summaryStatistics);
    };

    initialSuccessFailChart = (summaryStatistics) => {
        if (summaryStatistics.successCalls > 0) {
            this.successFailedChart.options.series[0].data.push({
                name: 'Successful',
                y: summaryStatistics.successCalls,
                color: this.statusColorsList.greenStatus
            });
        }

        if (summaryStatistics.failedCalls > 0) {
            this.successFailedChart.options.series[0].data.push({
                name: 'Failed',
                y: summaryStatistics.failedCalls,
                color: this.statusColorsList.redStatus
            });
        }
    };

    initialQualityDistribution = (summaryStatistics) => {
        if (summaryStatistics.goodQualityCalls > 0) {
            this.qualityDistributionChart.options.series[0].data.push({
                name: 'Good',
                y: summaryStatistics.goodQualityCalls,
                color: this.statusColorsList.greenStatus
            });
        }

        if (summaryStatistics.fairQualityCalls > 0) {
            this.qualityDistributionChart.options.series[0].data.push({
                name: 'Fair',
                y: summaryStatistics.fairQualityCalls,
                color: this.statusColorsList.yellowStatus
            });
        }

        if (summaryStatistics.poorQualityCalls > 0) {
            this.qualityDistributionChart.options.series[0].data.push({
                name: 'Poor',
                y: summaryStatistics.poorQualityCalls,
                color: this.statusColorsList.redStatus
            });
        }
    };
}
