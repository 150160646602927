<ng-template
        [ngTemplateOutletContext]="{ $implicit: {value: fromRange, id: 'range-from-' + type, leftArrow: '#26BC06', rightArrow: '#FF9C08'}}"
        [ngTemplateOutlet]="range"
></ng-template>

<ng-template
        [ngTemplateOutletContext]="{ $implicit: {value: toRange, id: 'range-to-' + type, leftArrow: '#FF9C08', rightArrow: '#f05656'}}"
        [ngTemplateOutlet]="range"
></ng-template>


<span *ngIf="hasIconPlaceholder" class="postfix-icon flexItem">
    <ac-svg *ngIf="hasIcon" fillColor="#ef5350" name="bell"></ac-svg>
</span>


<ng-template #range let-range>
    <span class="range-item flexItemBiggest flexRowParent">
        <div class="range-item-arrow flexItem"><ac-svg name="right-arrow" [fillColor]="range.leftArrow" width="8px" height="8px"></ac-svg></div>

        <span class="range-item-value flexItemBiggest" [id]="range.id">
            <div acLabel parentSelectorForTitle=".range-item" class="text-center">{{range.value}}</div>
        </span>

        <div class="range-item-arrow flexItem"><ac-svg name="right-arrow" [fillColor]="range.rightArrow" width="8px" height="8px"></ac-svg></div>
    </span>
</ng-template>
