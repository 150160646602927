import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AcActionButtonComponent} from './ac-action-button.component';
import {AcButtonModule} from '../ac-button/ac-button.module';
import {AcDropdownMenuModule} from '../ac-dropdown-menu/ac-dropdown-menu.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AcButtonModule,
        AcDropdownMenuModule
    ],
    declarations: [
        AcActionButtonComponent,
    ],
    entryComponents: [
        AcActionButtonComponent,
    ],
    exports: [
        AcActionButtonComponent,
    ]
})
export class AcActionButtonModule {
}
