import {ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef, ViewChild} from '@angular/core';
import {AcTrackerService} from '../../services/utilities/ac-tracker.service';
import {AcDropdownMenuTriggerContentDirective} from './ac-dropdown-menu-trigger-content.directive';
import {ButtonColor, ButtonType} from '../ac-button/ac-button.component';

export interface AcDropdownChild {
    displayName: string;
    enabled?: boolean;
    onClick?: Function;
    doesntRequireSelection?: boolean;
    children?: AcDropdownChild[];
    icon?: string;
    svgName?: string;
    template?: TemplateRef<any>;
    id?: string;
    templateData?: any;
}

@Component({
    selector: 'ac-drop-down-menu',
    templateUrl: './ac-dropdown-menu.component.html',
    styleUrls: ['./ac-dropdown-menu.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcDropDownMenuComponent {
    @ContentChild(AcDropdownMenuTriggerContentDirective) triggerContent: AcDropdownMenuTriggerContentDirective;
    @Input() items: AcDropdownChild[];
    @Input() buttonText = '';
    @Input() buttonClasses = '';
    @Input() path = '';
    @Input() dropdownId;
    @Input() icon;
    @Input() isMainButton = true;
    @Input() buttonDefaultStyle = true;
    @Input() ngDisabled = false;
    @Input() buttonType: ButtonType = 'flat-button';
    @Input() buttonColor: ButtonColor = 'primary';
    @ViewChild('childMenu', {static: true}) public childMenu;

    constructor(private acTrackerService: AcTrackerService) {
    }

    atLeastOneChildIsEnabled = (children) => children.some((child) => (child.enabled === undefined || child.enabled === true));

    onClick(child: AcDropdownChild) {
        this.acTrackerService.trackEvent('menuAction', this.buttonText + this.path + '/' + child.displayName);
    }
}
