import {Injectable} from '@angular/core';
import {dateTimeFormatter, FormatterType} from 'ac-infra';
import * as _ from 'lodash';
import {AcFilterType, FilterTypeSelectionFields} from '../modals/ac-filter-type.class';
import {MetadataService} from '../../../../metadata/metadata.service';
import {AcFilterItemComponent} from '../ac-filter-item/ac-filter-item.component';

@Injectable({providedIn: 'root'})
export class FilterItemService {

    getTextFromSelectionFields = (selectionFields: FilterTypeSelectionFields, fieldName, entity) => {
        const mapper = selectionFields[fieldName].listName ? MetadataService.getType(selectionFields[fieldName].listName) : (selectionFields[fieldName].list || undefined);
        if (mapper) {
            const prefixList = selectionFields[fieldName].prefixList;
            const prefix = prefixList ? prefixList[mapper[entity].type] : '';
            return prefix ? prefix + ': ' + mapper[entity].viewName : (mapper[entity]?.viewName || entity);
        }
    };

    getItemTitle = async (acFilterType: AcFilterType, fieldName, filterValue) => {
        if (acFilterType.getTitleByField) {// custom function
            const customText = await acFilterType.getTitleByField(fieldName, {[fieldName]: filterValue});
            if (customText || customText === '') {
                return customText;
            }
        }

        if (Array.isArray(filterValue) && filterValue.length > 0) {// array
            if (acFilterType.checkboxGroups) {// checkbox group filters -> devide by commas
                return this.mapperForCheckboxGroupsKeyToText(filterValue, acFilterType, fieldName).join(', ');
            }

            return filterValue.length === 1 ? this.getTitleWithSingleValue(acFilterType, filterValue, fieldName) : this.getTitleWithMultipleValues(acFilterType, filterValue, fieldName);
        } else if (_.isString(filterValue)) {// string
            return this.getFilterTextFromString(filterValue, fieldName);
        }

        return '';
    };

    getFilterTextFromString = (value, headLine) => {
        if (headLine === 'from' || headLine === 'to') {
            return dateTimeFormatter(value, FormatterType.dateAndTimeNoSec);
        }

        return value;
    };

    getTitleWithSingleValue = (acFilterType, filterValue, fieldName) => {
        const entity = filterValue[0];
        let entityName: string;

        if (acFilterType.selectionFields) {// ac-select filters -> more filters
            entityName = this.getTextFromSelectionFields(acFilterType.selectionFields, fieldName, entity);
        } else if (entity.viewName || entity.name || entity.text) {// by property
            entityName = entity.viewName || entity.name || (entity.prefix ? entity.prefix + ': ' + entity.text : entity.text);
        }

        return entityName || (_.isString(entity) && entity) || '';
    };

    getFilterHeadLine = (key, acFilterType) => acFilterType.fieldNameMapper && acFilterType.fieldNameMapper[key] ? acFilterType.fieldNameMapper[key] : key;

    getTitleWithMultipleValues = (acFilterType, filterValue, fieldName) => {
        const headLine = this.getFilterHeadLine(fieldName, acFilterType);// show length + filter type

        return filterValue.length + ' ' + headLine;
    };
    mapperForCheckboxGroupsKeyToText = (array, acFilterType, headLine) => {
        const arrayAfterMapping = [];

        array.forEach((item) => {
            arrayAfterMapping.push(acFilterType.checkboxGroups[headLine].map[item]);
        });

        return arrayAfterMapping;
    };
}
