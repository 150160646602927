import {Directive, ElementRef} from '@angular/core';

@Directive({
    selector: '[acLayoutSectionChild]'
})
export class AcLayoutSectionChildDirective {

    constructor(public elementRef?: ElementRef) {
    }

    ngOnInit() {
    }
}
