import {Injectable, isDevMode} from '@angular/core';
import {CommunicationConfigurationService} from '../communication/communication-configuration.service';

@Injectable({
    providedIn: 'root'
})
export class RestServerUrlService {

    static devModeProxyServer;
    constructor(private communicationConfigurationService: CommunicationConfigurationService) {
        this.init();
    }

    public init() {
        this.communicationConfigurationService.setProtocol(window.location.protocol);
    }

    public getRestServerURL() {
        return !this.communicationConfigurationService.getRestServerURL() ? window.location.host + '/ovoc/v1/' : this.communicationConfigurationService.getRestServerURL();
    }

    public getWebSocketServerURL() {
        let webSocketURL = this.communicationConfigurationService.getWebSocketServerURL() ? 'ws://' + this.communicationConfigurationService.getWebSocketServerURL() : undefined;
        if (!webSocketURL) {
            webSocketURL = 'wss://' + window.location.host + this.communicationConfigurationService.webSocketURLName;
        }
        return webSocketURL;
    }

    static getHostURL() {
        return isDevMode() ? ("https://" + this.devModeProxyServer) : (window.location.protocol + '//' + window.location.host);
    }

    public getServletURL(addSlashToHost = true) {
        const hostURL = window.location.protocol + '//' + window.location.host + (addSlashToHost ? '/' : '');

        return !this.communicationConfigurationService.getRestServerURL() ? hostURL : this.communicationConfigurationService.getRestServerURL();
    }
}
