import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SessionStorageService {
    private static _setData = new Subject<void>();
    static setData$: Observable<any> = SessionStorageService._setData.asObservable();

    private static SAVED_ON_CLEAR = ['communication.protocol'];

    constructor() {
    }

    static notify() {
        SessionStorageService._setData.next(null);
    }

    public static setData(key: string, data: any, notify = true) {
        SessionStorageService.setDataPrivate(key, data);
        notify && this.notify();
    }

    public static getData(key: string) {
        try {
            return JSON.parse(sessionStorage.getItem(key));
        } catch (e) {
        }
    }

    public static resetDataByPostfix(postfix: string, dataProperty: string) {
        const storageItemsCount = sessionStorage.length;

        for (let i = 0; i < storageItemsCount; i++) {
            if (sessionStorage.key(i).endsWith(postfix)) {
                const key = sessionStorage.key(i);
                const item = SessionStorageService.getData(key);

                if (item && item[dataProperty]) {
                    delete item[dataProperty];
                    SessionStorageService.setDataPrivate(key, item);
                }
            }
        }
    }

    public static clearAllData() {
        const old_data = {};
        this.SAVED_ON_CLEAR.forEach((key) => {
            old_data[key] = sessionStorage.getItem(key);
        });

        sessionStorage.clear();

        this.SAVED_ON_CLEAR.forEach((key) => {
            sessionStorage.setItem(key, old_data[key]);
        });
    }

    public static removeData(key: string, notify = false) {
        sessionStorage.removeItem(key);
        notify && this.notify();
    }

    private static setDataPrivate(key: string, data: any) {
        try {
            sessionStorage.setItem(key, JSON.stringify(data));
        } catch (e) {
        }
    }
}
