<widget-layout class="dashboard"
               [editMode]="isEditMode"
               [widgetItems]="dashboardItems"
               [options]="options"
               [registerActions]="dashboardService.registerDropdownActionsToWidget"
               #widgetLayout>

    <ng-container *ngIf="!acDialogComponent else dialogActionsTemplate" widget-layout-actions>

        <ac-button *ngIf="!isEditMode else editActionsTemplate"
                   buttonId="edit-dashboard"
                   buttonType="icon-button"
                   color="primary"
                   class="dashboard-action edit"
                   [ngDisabled]="!dashboardItems"
                   (acClick)="toggleEditMode()">
            <ac-svg class="edit-icon" name="edit" fillColor="white"></ac-svg>
        </ac-button>
    </ng-container>
</widget-layout>

<ng-template let-submitted="submitted" #editActionsTemplate>
    <ac-button [ngDisabled]="submitted" buttonId="dashboard-action-cancel" class="dashboard-action" buttonType="stroked-button"
               (acClick)="cancelEditMode()">Cancel
    </ac-button>
    <ac-button class="dashboard-action"
               buttonType="stroked-button"
               buttonId="dashboard-action-back-to-default"
               [ngDisabled]="submitted || (dashboardItems | acFunc: isItemsDefault: (widgetLayout.itemChanged | async))"
               (acClick)="onBackToDefault()">
        Back to Default
    </ac-button>
    <ac-button [ngDisabled]="submitted" buttonId="dashboard-action-add" class="dashboard-action" color="green"
               (acClick)="dashboardService.addWidget()">Add
    </ac-button>
    <ac-button [ngDisabled]="submitted" buttonId="dashboard-action-save" class="dashboard-action"
               (acClick)="saveDashboardState()">Save
    </ac-button>
</ng-template>

<ng-template #dialogActionsTemplate>
    <ng-template *ac-dialog-actions
                 [ngTemplateOutlet]="editActionsTemplate"
                 [ngTemplateOutletContext]="{submitted: dialogConfig.submitButtonProcessing}"
    ></ng-template>
</ng-template>
