<div class="ac-input-container-box flexRowParent">
    <ng-template *ngIf="preTemplate" class="flexItemBiggest" [ngTemplateOutlet]="preTemplate"></ng-template>

    <mat-form-field [appearance]="'outline'"
                    floatLabel="always"
                    class="ac-input-container"
                    [attr.id]="acInputContainerId ? acInputContainerId + '-field' : null"
                    [class.mat-focused]="listOfNonMaterialComponents.includes(elementType) && isFocused"
                    [class.mat-mdc-form-field-invalid]="((formHost?.formSettings?.touched && error) || customError)"
                    [class.readOnly]="readOnly"
                    [class.upper-padding]="upperPadding"
                    [class.border-appearance]="true"
                    [class.display-only-error]="displayOnlyError"
                    [class.display-as-basic]="displayAsBasic"
                    [class.display-as-info]="displayAsInfo"
                    [class.hide-label]="!inputText || inputText === '' || hideLabel"
                    [class.material]="!listOfNonMaterialComponents.includes(elementType) && isMaterial">

        <mat-label class="mat-mdc-label"
                   [attr.id]="acInputContainerId ? acInputContainerId + '-label' : null"
                   [class.visibilityHidden]="!inputText || inputText === '' || hideLabel"
                   [class.bold]="labelBold">
            <div class="flexRowParent">
                <div acLabel class="input-label-text">{{inputText}}</div>
                <span>{{isRequired()}}</span>
                <i *ngIf="infoIconText" class="fa fa-info-circle info-icon" [matTooltip]="infoIconText"></i>
            </div>
        </mat-label>

        <div #acInputChildElement class="ac-content-container" [attr.id]="acInputContainerId ? acInputContainerId + '-content': null">
            <ng-content></ng-content>
        </div>

        <ng-container *ngIf="beforeViewInit">
            <input matInput class="input-for-initialize" [disabled]="isDisabled"/>
        </ng-container>

        <mat-hint class="mat-mdc-error" acLabel
                  *ngIf="formHost?.formSettings?.touched || alwaysShowError || customError">{{error}}</mat-hint>
    </mat-form-field>

    <ng-template *ngIf="postTemplate" [ngTemplateOutlet]="postTemplate"></ng-template>
</div>

