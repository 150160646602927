export type VoiceQualityType = 'mos' | 'delay' | 'packetLoss' | 'jitter' | 'echo';

export interface QoeVoiceParams {
    name: string;
    text: string;
    maxRange: number;
    step: number;
    isFloat?: boolean;
    reverseRange?: boolean;
}

export interface QoeParameters {
    voice: Record<VoiceQualityType, QoeVoiceParams>;
    [key: string]: any;
}

export const qoeParameters: QoeParameters = {
    voice: {
        mos: {name: 'mos', text: 'MOS (0-5)', maxRange: 5, step: 0.1, isFloat: true, reverseRange: true},
        delay: {name: 'delay', text: 'Delay (msec)', maxRange: 10000, step: 10},
        packetLoss: {name: 'ploss', text: 'Packet Loss (%)', maxRange: 100, step: 0.1, isFloat: true},
        jitter: {name: 'jitter', text: 'Jitter (msec)', maxRange: 10000, step: 10},
        echo: {name: 'echo', text: 'Echo (dB)', maxRange: 10000, step: 10, reverseRange: true}
    },
    video: {
        video: {
            text: 'Video',
            avgVideoFrameLossPercentage: {
                name: 'videoPloss',
                text: 'Avg Video Frame Loss Percentage (%)',
                maxRange: 100,
                step: 0.1,
                isFloat: true
            },
            avgVideoFrameRate: {name: 'videoFRate', text: 'Avg Video Frame Rate (fps)', maxRange: 10000, step: 1},
            postForwardErrorCorrectionPacketLossRate: {
                name: 'videoFECPLR',
                text: 'Post Forward Error Correction Packet Loss Rate',
                maxRange: 1,
                step: 0.01,
                isFloat: true
            }
        },
        screen_sharing: {
            text: 'Screen Sharing',
            avgVideoFrameLossPercentage: {
                name: 'vbssPloss',
                text: 'Avg Video Frame Loss Percentage (%)',
                maxRange: 100,
                step: 0.1,
                isFloat: true
            },
            avgVideoFrameRate: {name: 'vbssFRate', text: 'Avg Video Frame Rate (fps)', maxRange: 10000, step: 1},
            postForwardErrorCorrectionPacketLossRate: {
                name: 'vbssFECPLR',
                text: 'Post Forward Error Correction Packet Loss Rate',
                maxRange: 1,
                step: 0.01,
                isFloat: true
            }
        }
    }
};
