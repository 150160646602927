<ng-container *ngIf="(tabs | acFunc : filterIfCondition : (refreshSubject | async)) as filteredTabs">
    <mat-tab-group [class.stretched]="stretch"
                   #matTabGroup
                   animationDuration="0ms"
                   [(selectedIndex)]="activeTabIdx"
                   [disablePagination]="true"
                   [class.single-view]="singleView && filteredTabs?.length === 1"
                   [class.is-in-dialog]="acDialog"
                   [class.activeTabHasError]="activeTab && (!activeTab.isValid ||(formHost?.formSettings?.touched && activeTab.hasErrors))"
                   (selectedTabChange)="onSelectedTabChanged($event)"
                   (animationDone)="dispatchResizeEvent()"
                   [hidden]="!tabs.length || loading"
                   [id]="tabsId">

        <ng-container *ngFor="let tab of filteredTabs; index as i">
            <mat-tab *ngIf="tab._if" [bodyClass]="tab.id" labelClass="{{(!tab.isValid || (formHost?.formSettings.touched && tab.hasErrors)) ? 'hasError' : ''}}">
                <ng-template mat-tab-label>
                    <span>{{tab.title}}</span>
                </ng-template>

                <ng-container *ngIf="!keepOnlyActive || i === activeTabIdx">
                    <div *ngIf="!contentWrapperTemplate" [attr.id]="tab.id" [style.height]="tab.height">
                        <ng-template [ngTemplateOutlet]="tab.templateRef"></ng-template>
                    </div>

                    <ng-template [ngTemplateOutlet]="contentWrapperTemplate"
                                 [ngTemplateOutletContext]="{$implicit: tab}"></ng-template>
                </ng-container>
            </mat-tab>
        </ng-container>

    </mat-tab-group>

    <div *ngIf="!loading && !filteredTabs?.length && emptyTemplate" class="ac-tabs-no-data">
        <ng-template [ngTemplateOutlet]="emptyTemplate"></ng-template>
    </div>
</ng-container>
<ac-progress *ngIf="!tabs.length || loading" [center]="true" size="small"></ac-progress>
