import {Injectable} from '@angular/core';
import {ConfirmDialogData, PromiseService} from 'ac-infra';
import {GroupsRestService} from '../apis/groups-rest.service';
import * as _ from 'lodash';
import {TenantsRestService} from '../apis/tenants-rest.service';
import {DevicesRestService} from '../apis/devices-rest.service';
import {DeviceSSOActionsService} from './device-SSO-actions.service';
import {SelectTenantDialogComponent} from '../../dialogs/select-tenant-dialog/select-tenant-dialog.component';
import {RestResponseSuccess} from '../../../common/server-actions/rest';
import {Actions} from '../../../common/server-actions/actions';
import {LinksRestService} from '../apis/links-rest.service';
import {EntitySelectionDialogComponent, EntitySelectionDialogObject} from '../../dialogs/entity-selection-dialog/entity-selection-dialog.component';
import {ChannelsRestService} from '../apis/channels-rest.service';
import {ServerConfigurationRestService} from '../../../system/administration/administration-api/server-configuration-rest.service';
import {ExternalApplicationsActionsService} from '../../../system/configuration/configuration-actions/external-applications-actions.service';
import {ExternalApplicationsRestService} from '../../../system/configuration/configuration-api/external-applications-rest.service';
import {SessionService} from '../../../common/services/session.service';
import {AuthorizationService} from '../../../common/services/authorization.service';
import {NetworkGroupDialogComponent} from '../../dialogs/network-group-dialog/network-group-dialog.component';
import {NetworkCustomerDialogComponent} from '../../dialogs/network-customer-dialog/network-customer-dialog.component';
import {NavigationService} from "../../../common/utilities/navigation.service";
import {ServerInfoService} from "../../../common/services/server-info.service";

@Injectable({providedIn: 'root'})
export class GroupsActions extends Actions {

    visibleFieldsForEndpointsGroupInfo = ['description', 'tenantId', 'ldapGroupName', 'elementsCount'];

    visibleFieldsForTopologyGroupInfo = ['description', 'tenantId', 'elementsCount'];

    visibleFieldsForCustomerGroupInfo = ['customerName', 'status', 'applicationsStatus.management.customerDeployStatus', 'fullName', 'customerSource', 'licenseType', 'cacProfile', 'carrierName', 'channelName', 'customerMsTenantId',
        'usersCount', 'isEnabled', 'disabledTime', 'customerProviderDetails', 'customerTeamsDetails',
        'lastBackupTime', 'totalDIDs', 'usedDIDs', 'freeDIDs', 'deviceManagerParams.customerDeviceUrl', 'deviceManagerParams.maxDevicesMeeting',
        'deviceManagerParams.maxDevicesPersonal', 'deviceManagerParams.devicesCount.personal', 'deviceManagerParams.devicesCount.meeting'];

    visibleFieldsForSmartTapCustomer = [
        'applicationsStatus.management.customerDeployStatus',
        'fullName',
        'usersCount',
        'smartTapParams.licenseType',
        'smartTapParams.storage',
        'smartTapParams.licenseCount',
    ];

    pmStatusMap = {
        Fail: 'status-error',
        Initializing: 'status-unmonitored',
        'Not Polling': 'status-unmonitored',
        Success: 'status-ok',
        'Not Supported': 'status-unmonitored'
    };

    deployStatusMap = {
        DRAFT: 'status-unmonitored',
        UNKNOWN: 'status-unmonitored',
        WARNING: 'status-warning',
        SUBMITTED: 'status-warning',
        DEPLOYING: 'status-warning',
        IN_PROGRESS: 'status-warning',
        INPROGRESS_RESOURCE_ALLOCATION: 'status-warning',
        INPROGRESS_ACCOUNT_CREATION: 'status-warning',
        INPROGRESS_AWAIT_CONCENT: 'status-warning',
        WAIT_FOR_CUSTOMER: 'status-warning',
        WAIT_FOR_PROVIDER: 'status-warning',
        READY_FOR_DEPLOYMENT: 'status-warning',
        READY_FOR_REMOVE: 'status-warning',
        REMOVING: 'status-warning',
        DELETING: 'status-warning',
        REMOVED: 'status-warning',
        ERROR: 'status-error',
        FAILED: 'status-error',
        CREATE_FAILED: 'status-error',
        DELETE_FAILED: 'status-error',
        NOT_EXIST: 'status-error',
        CONNECTION_ERROR: 'status-error',
        DEPLOYED: 'status-ok',
        READY: 'status-ok',
        COMPLETED: 'status-ok',
        CREATED: 'status-ok',
        DELETED: 'status-ok',
    };

    constructor(private groupsRestService: GroupsRestService,
                private devicesRestService: DevicesRestService,
                private deviceSSOActionsService: DeviceSSOActionsService,
                private linksRestService: LinksRestService,
                private channelsRestService: ChannelsRestService,
                private sessionService: SessionService,
                private navigationService: NavigationService,
                private serverConfigurationRestService: ServerConfigurationRestService,
                private externalApplicationsRestService: ExternalApplicationsRestService,
                private authorizationService: AuthorizationService,
                private tenantsRestService: TenantsRestService) {
        super({entityName: 'group', entityService: groupsRestService, isWsEntity: true});
    }

    editGroup = (group) => {
        const success = (response: RestResponseSuccess) => this.openGroupDetailsDialog(response.data);
        const failure = () => {};
        this.groupsRestService.getById({success, failure, id: group.id});
    };

    openGroupDetailsDialog = (group, type?) => {
        const tenants = this.tenantsRestService.getAllEntities();
        if (tenants === undefined || (tenants && tenants.length === 0)) {
            this.acDialogService.info('Can not add groups when there are no tenants.');
            return;
        }

        const isEdit = !_.isEqual(group, {});
        const id = group.id;

        const emptyGroup: any = {name: '', type};
        if (type === 'TOPOLOGY') {
            emptyGroup.attachedDevices = [];
            emptyGroup.attachedLinks = [];
            emptyGroup.attachedSites = [];
        }

        const endpointsGroupToSend = isEdit ? _.cloneDeep(group) : emptyGroup;

        if (isEdit) {
            this.cleanEmptyValues(endpointsGroupToSend);
        }

        const dialogData = {entity: endpointsGroupToSend, isEdit};
        const serverCallback = (onSuccess, onFailure) => {
            if (isEdit) {
                this.groupsRestService.edit(onSuccess, onFailure, dialogData.entity, id);
            } else {
                this.groupsRestService.add(onSuccess, onFailure, dialogData.entity);
            }
        };
        this.genericAction({
            dialogComponentType: NetworkGroupDialogComponent,
            serverCallback,
            dialogData,
        });
    };

    editCustomer = (customer, pageName = undefined, tenant = undefined) => {
        if ((customer && customer.isEnabled !== undefined) || customer === undefined) {
            this.getCustomerTenant(tenant ? [tenant] : undefined, customer, (tenantId) => {
                this.getUMPDeviceAndOpenSSOConnection('ovoc_ovl_edit', tenantId, customer, pageName);
            });
        }
    };

    editCustomerSettings = (customer) => {
        const customerURL = 'topology/customers';
        const success = (response: RestResponseSuccess) => {
            customer = response.data;

            const id = customer.id;

            const dialogData = {entity: customer};

            const serverCallback = (onSuccess, onFailure) => {
                if (ServerInfoService.serverInfo.oneLiveMode && !ServerInfoService?.serverInfo?.meteringEnabled) {
                    delete dialogData.entity?.meteringTag;
                }
                this.groupsRestService.edit(onSuccess, onFailure, dialogData.entity, id, customerURL);
            };
            const dialogComponentType = NetworkCustomerDialogComponent;

            this.genericAction({serverCallback, dialogData, dialogComponentType});
        };
        this.groupsRestService.getById({url: customerURL, success, extendParameters: true, skipPopulateFilter: true, id: customer.id});
    };

    deleteCustomers = (selection) => {
        this.delete({
            entityArray: selection, isDeleteSingle: false,
            confirmMessage: 'This operation will delete customer’s entire statistics history and it is non - reversible. Are you sure?',
            url: 'topology/customers'
        });
    };

    deleteCustomerProvider = (customer) => {
        const siteLocations = this.linksRestService.getAllEntities(customer.attachedLinks);
        const dialogData: EntitySelectionDialogObject = {
            entity: {id: -1, name: ''},
            items: siteLocations,
            selectLabel: 'Site Location'
        };

        if (!siteLocations || siteLocations.length === 0) {
            this.acDialogService.info('Selected customer doesn\'t have any site locations');
        } else {
            this.acDialogService.open(EntitySelectionDialogComponent, {
                onSubmit: () => {
                    const selectedSiteLocation = dialogData.entity?.id > 0 && this.linksRestService.getEntityById(dialogData.entity.id);
                    if (selectedSiteLocation?.customerInfo?.umpSiteLocationId) {
                        this.groupsRestService.deleteById({
                            id: dialogData.entity.id,
                            url: 'topology/customers/' + customer.id + '/customerSiteLocations'
                        });
                    }
                },
                dialogData,
                id: 'select-customer-location-dialog',
                title: 'Select Customer Provider'
            });
        }
    };

    deleteCustomerTeams = (customer) => {
        const teams = customer.attachedDevices && customer.attachedDevices.length > 0 ? this.devicesRestService.getEntityById(customer.attachedDevices[0]) : undefined;

        if (!teams) {
            this.acDialogService.info('Selected customer doesn\'t have any teams attached');
        } else if (teams) {
            this.groupsRestService.remove(() => {
            }, () => {
            }, 'topology/customers/' + customer.id + '/customerTeamsDevice');
        }
    };

    openSelectChannelDialog = (customer, isFeatureEnabled?: 'deviceManagerEnabled' | 'zoomEnabled' | 'operatorConnectEnabled') => {
        const channels = this.channelsRestService.getAllEntities().filter((channel) => channel.tenantId === customer.tenantId && (isFeatureEnabled ? (channel[isFeatureEnabled] === true) : true));

        const dialogData: EntitySelectionDialogObject = {
            entity: {id: -1, name: ''},
            items: channels,
            selectLabel: 'Channels'
        };
        this.acDialogService.open(EntitySelectionDialogComponent, {
            onSubmit: ({entity}) => {
                entity?.id && this.updateCustomerChannel(customer, entity.id);
            },
            dialogData,
            id: 'select-channel-dialog',
            title: 'Select Channel'
        });
    };

    updateCustomerChannel = (customer, channelId) => {
        this.groupsRestService.edit(() => {
        }, () => {
        }, {channelId}, customer.id, 'topology/customers');
    };

    getCustomerTenant = (tenants, customer, callback) => {
        tenants = tenants || this.tenantsRestService.getAllEntities();
        if (tenants.length === 0) {
            this.acDialogService.info('No compatible tenants found.');
            return;
        }

        if (customer || tenants.length === 1) {
            callback(customer && customer.tenantId || tenants[0].id);
        } else {
            this.openTenantDialog(tenants, callback);
        }
    };

    openTenantDialog = (tenants, callback) => {
        const dialogData = {entity: {tenantId: undefined}, tenants};
        this.acDialogService.open(SelectTenantDialogComponent, {
            onSubmit: () => {
                callback(dialogData.entity.tenantId);
            },
            dialogData
        });
    };

    getUMPDeviceAndOpenSSOConnection = (actionName, selectedTenantId, customer?, pageName = undefined) => {
        const filter = {familyType: ['UMP'], tenantId: [selectedTenantId]};
        this.getDeviceWithFilter(filter).then((UMPDevice: any) => {
            if (UMPDevice) {
                this.deviceSSOActionsService.startEntityServlet(UMPDevice, (value) => {
                    let partialUrl = UMPDevice.ssoUrl + (actionName || '') + '/';
                    const params: any = [];

                    customer?.customerId && params.push('customerId=' + customer.customerId);
                    pageName && params.push('page=' + pageName);

                    partialUrl = params.length > 0 ? (partialUrl + '?' + params.join('&')) : partialUrl;

                    const url = this.deviceSSOActionsService.buildSSOUrl(partialUrl);
                    this.deviceSSOActionsService.openEntityInNewTab(url);
                });
            } else {
                this.acDialogService.info('No UMP Device found for this tenant');
            }
        });
    };

    getDeviceWithFilter = (filter) => {
        const deferred = PromiseService.defer();

        const onSuccess = (response: RestResponseSuccess) => {
            deferred.resolve(response.data.devices[0]);
        };

        this.devicesRestService.getEntitiesWithFilter(onSuccess, () => {
            deferred.resolve();
        }, {filter});

        return deferred.promise;
    };


    customerServiceAction({customer = undefined, page = 'create', type}: { customer: any; page: 'create' | 'overview' | 'edit' | 'delete' | 'management' | 'customer'; type: 'MEETING_INSIGHTS' | 'STNG'}) {
        this.getCustomerTenant(undefined, customer, (tenantId) => {
            const filter = {productType: [type], tenantId: [tenantId]};
            this.getDeviceWithFilter(filter).then((device: any) => {
                const onSuccess = (response: RestResponseSuccess) => {
                    if(device){
                        const access_token = response.data.accessToken;
                        const refresh_token = response.data.refreshToken;
                        const code = this.sessionService.activeSession.sessionId;
                        let parameters: any = {access_token, refresh_token, code};

                        let url = device.ssoUrl;

                        if(page !== 'customer'){
                            url = url + 'tenant-management'
                        }

                        if(['overview', 'edit', 'delete'].includes(page)){
                            url = url + '/' + customer.customerId + '/' + page;
                        }else if(page === 'create'){
                            url = url + '/' + page;
                        }

                        this.navigationService.openNewWindow(url, parameters);
                    }else{
                        this.acDialogService.info('No Device found for this tenant');
                    }
                };

                this.externalApplicationsRestService.getIAMToken(onSuccess, () => null, type);
            });
        });
    }

    openGeneralDeviceManagerPage(customer = undefined) {
        this.deviceManagerCustomerAction({customer, page: 'general'});
    }

    deviceManagerCustomerAction({customer = undefined, page = 'create_customer', channelMsTenantId = undefined}:
    { customer?: any; page: 'create_customer' | 'edit_customer' | 'general'; channelMsTenantId?: string }) {
        if (page === 'general') {
            const tenants = this.tenantsRestService.getAllEntities();
            const customers = this.groupsRestService.getAllEntities(undefined, false, 'customers');

            const tenantId = (tenants?.length === 1 ? tenants[0].id : undefined);
            const customerId = customer?.customerId || (customers?.length === 1 ? customers[0].customerId : undefined);

            this.openDeviceManagerPage('general', tenantId, customerId);
        } else {
            const filteredTenants = this.tenantsRestService.getAllEntities().filter(tenant => tenant.deviceManagerEnabled);
            this.getCustomerTenant(filteredTenants, customer, (tenantId) => {
                this.openDeviceManagerPage(page, tenantId, customer?.customerId, channelMsTenantId);
            });
        }
    }

    openDeviceManagerPage = (page, tenantId = undefined, customerId = undefined, channelMsTenantId = undefined) => {
        const deviceManagerURL = ExternalApplicationsActionsService.getDeviceManagerURL();

        const params: any = [
            'page=' + page,
            'code=' + this.sessionService.activeSession.sessionId
        ];

        tenantId && params.push('tenantId=' + tenantId);
        customerId && params.push('customerId=' + customerId);
        channelMsTenantId && params.push('channelMsTenantId=' + channelMsTenantId);

        window.open(deviceManagerURL + params.join('&'));
    };

    openZoomPage = (pageName = 'leads', customer = undefined) => {
        let channelId;
        const filteredTenants = this.tenantsRestService.getAllEntities().filter(tenant => tenant.zoomEnabled);
        this.getCustomerTenant(filteredTenants, customer, (tenantId) => {
            this.externalApplicationsRestService.getZoomConfiguration((response) => {
                const zoomApp = response.data.applicationStatus;
                // const sessionId = this.sessionService.activeSession.sessionId;
                let url = zoomApp.ssoUrl;// '/sso/' + zoomApp?.nodeId + '-' + sessionId + '/' + zoomApp?.webUrl;
                const params: any = [
                    // 'code=' + sessionId
                ];

                pageName && params.push('page=' + pageName);
                tenantId && params.push('tenantId=' + tenantId);
                customer?.customerId && params.push('customerId=' + customer.customerId);

                if (this.authorizationService.validForChannel()) {
                    const channels = this.channelsRestService.getAllEntities();
                    channelId = channels.length === 1 ? channels[0]?.id : undefined;
                    channelId && params.push('channelId=' + channelId);
                }

                url = params.length > 0 ? (url + '&' + params.join('&')) : url;
                this.deviceSSOActionsService.openEntityInNewTab(url);

            }, () => {
            });

        });
    };

    disableCustomer = (customer) => {
        const confirmMessage = 'Endpoints will no longer be able to be assigned to a disabled customer, Are you sure?';

        const onSuccessCallback = (response) => {
            if (response.status === 202) {
                this.acDialogService.info(response.data.description);
            }
        };

        const serverCallback = (onSuccess, onFailure) => {
            this.groupsRestService.customerAction(onSuccess, onFailure, [customer], 'disableCustomer');
        };
        const dialogData: ConfirmDialogData = {entitiesArray: [customer], confirmMessage, onSuccessCallback};
        this.genericConfirmAction({serverCallback, dialogData, dialogConfig: {submitButtonText: 'disable'}});
    };
}
