import {merge} from 'lodash';
import {DateTime} from 'luxon';
import {ArrayUtil} from './array-util';
import {isDevMode} from '@angular/core';

export class HashedObjectMap {

    private entityMap: any = {};
    private filteredEntitiesIds: any = {};
    private addEntityType: boolean;

    constructor({addEntityType = true}) {
        this.addEntityType = addEntityType;
    }

    // difference = (objectA, baseA) => {
    //     const changes = (object: any, base: any) => _.transform(object, function(result, value, key) {
    //         if (!_.isEqual(value, base[key])) {
    //             result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
    //         }
    //     });
    //     return changes(objectA, baseA);
    // };

    setData = (paramName, entities, fieldName, partialUpdate?) => {
        entities = ArrayUtil.oneToMany(entities);
        this.entityMap[paramName] = this.entityMap[paramName] || {};
        entities.forEach((entity: any) => {
            if (this.addEntityType) {
                entity.entityType = paramName.slice(0, -1);
            }

            if (
                entity.lastChanged &&
                this.entityMap[paramName][entity[fieldName]] &&
                this.entityMap[paramName][entity[fieldName]].lastChanged &&
                DateTime.fromISO(this.entityMap[paramName][entity[fieldName]]?.lastChanged) > DateTime.fromISO(entity.lastChanged)
            ) {
                return;
            }

            // if (this.entityMap[paramName][entity[fieldName]]) {
            //     console.log(paramName, this.difference(this.entityMap[paramName][entity[fieldName]], entity));
            // }


            // if(isDevMode()) { // TODO: RON
            //     Object.freeze(entity)
            // }

            if (partialUpdate) {
                merge(this.entityMap[paramName][entity[fieldName]] || {}, entity);
            } else {
                this.entityMap[paramName][entity[fieldName]] = entity;
            }
        });
    };

    protected deleteEntities(entityType, entitiesIds: any[]) {
        entitiesIds.forEach((id: any) => {
            delete this.entityMap[entityType][id];
        });
    }

    getEntitiesArray = (entityType, ids: any = null, sorted=false) => {
        if (!this.entityMap[entityType]) {
            return [];
        }

        if (ids === null) {
            return Object.values(this.entityMap[entityType]);
        } else if (!ids) {
            ids = [];
        }

        ids = Array.isArray(ids) ? ids : [ids];
        return ids.map((id) => this.entityMap[entityType][id]).filter((entity) => !!entity);
    };

    getEntitiesHashed = (entityType, ids: number | number[]) => ArrayUtil.oneToMany<number>(ids).reduce((acc, cur) => {
        const entity = this.entityMap[entityType][cur];
        if (entity) {
            acc[cur] = entity;
        }
        return acc;
    }, {});

    getEntities = (entityType) => {
        return this.entityMap[entityType] || {};
    };

    getEntity = (entityType, id: any) => {
        return (this.entityMap[entityType] || {})[id];
    };

    getAnyEntitiesByIds = (ids: any, hashed = false, entityType?) => {
        if (!Array.isArray(ids)) {
            ids = [ids];
        }
        const entitiesObj = {};
        const entitiesArr = [];

        const paramNames = entityType ? [entityType] : Object.getOwnPropertyNames(this.entityMap);
        for (let i = 0; i < ids.length; i++) {
            for (let j = 0; j < paramNames.length; j++) {
                if (this.entityMap[paramNames[j]][ids[i]]) {
                    if (hashed) {
                        entitiesObj[ids[i]] = this.entityMap[paramNames[j]][ids[i]];

                        if (Object.keys(entitiesObj).length === ids.length) {
                            return entitiesObj;
                        }
                    } else {
                        entitiesArr.push(this.entityMap[paramNames[j]][ids[i]]);
                        if (entitiesArr.length === ids.length) {
                            return entitiesArr;
                        }
                    }
                }
            }
        }

        return hashed ? entitiesObj : entitiesArr;
    };

    setFilteredIds = (paramName, entities, fieldName) => {
        this.filteredEntitiesIds[paramName] = [];
        entities.forEach((entity: any) => {
            this.filteredEntitiesIds[paramName].push(entity[fieldName]);
        });
    };

    updateFilteredIds = (paramName, entities, fieldName, originalIdList) => {
        this.filteredEntitiesIds[paramName] = this.filteredEntitiesIds[paramName] || [];
        const arrayToRemove = originalIdList.filter((originaId) => !entities.some((entity) => entity[fieldName] === originaId));
        arrayToRemove.forEach((idToRemove) => {
            const idx = this.filteredEntitiesIds[paramName].indexOf(idToRemove);
            if (idx > -1) {
                this.filteredEntitiesIds[paramName].splice(idx, 1);
            }
        });

        const arrayToAdd = originalIdList.filter((originaId) => this.filteredEntitiesIds[paramName].indexOf(originaId) === -1 && entities.some((entity) => entity[fieldName] === originaId));
        this.filteredEntitiesIds[paramName] = this.filteredEntitiesIds[paramName].concat(arrayToAdd);
    };

    getFilteredIds = (paramName) => {
        return this.filteredEntitiesIds[paramName] || [];
    };

    getFilteredEntitiesHashed = (paramName, ids) => {
        if (!ids) {
            ids = this.filteredEntitiesIds[paramName];
        }

        const hashed = {};
        ids?.forEach((id) => {
            if (this.filteredEntitiesIds[paramName].includes(id)) {
                hashed[id] = this.entityMap[paramName][id];
            }
        });
        return hashed;
    };

    reset = (paramNames?) => {
        if (paramNames) {
            const params = Array.isArray(paramNames) ? paramNames : [paramNames];
            params.forEach((param) => {
                delete this.entityMap[param];
            });
        } else {
            this.entityMap = {};
        }
    };

}
