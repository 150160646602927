import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';

import * as _ from 'lodash';

import {PMProfilesService} from '../../services/PMProfilesService';
import {AcTreeNavigationComponent, GeneralService, SessionStorageService, SvgModelName} from 'ac-infra';


@Component({
    selector: 'statistics-pm-parameters',
    templateUrl: './statistics-pm-parameters.component.html',
    styleUrls: ['./statistics-pm-parameters.component.less'],
})
export class StatisticsPmParametersComponent {

    @ViewChild('navigationTree', {static: true}) ACTreeNavigationComponent: AcTreeNavigationComponent;

    @Input() profileTopics: any;
    @Input() isSingleSelect: boolean;
    @Input() sidePanel: any;
    @Input() isSingular: boolean;
    @Input() isDisabled: boolean;
    @Input() deviceId: any;
    @Input() isDialog: boolean;
    @ViewChild('statisticsPmParametersElement', {static: true}) element: ElementRef;
    @ViewChild('parentGroupsMainContainer', {static: true}) parentGroupsMainContainer: ElementRef;
    @Output() profileTopicsChanged = new EventEmitter();

    changeDetected = 0;

    treeData: any;
    selectedTopic: any;
    parametersItems = [
        {value: 'All'},
        {value: 'SW 7.2 & below'},
        {value: 'SW 7.4 & above'}
    ];
    parametersSelect;
    parametersOptions = ['Min', 'Max', 'Average', 'Value'];
    parametersOptionsLabels = ['Min', 'Max', 'Avg', 'Val'];
    selectedItems;
    groupsArray = [];
    statusColorsList;

    constructor(private pmProfilesService: PMProfilesService) {
        this.statusColorsList = GeneralService.statusColors;
        this.parametersSelect = SessionStorageService.getData('pmSWSelection') || this.parametersItems[0].value;
    }

    getGroupMibId = (group) => {
        const param = this.getFirstObjectKey(group[0]);
        return this.selectedItems.title + '_' + param.groupDisplayName.split(' ').join('');
    };

    ngOnInit() {
        this.buildTopics();
    }

    resetPMTopicSelection = (isEdit) => {
        this.setActiveItems(this.getFromTreeData(isEdit));
        this.updateTextForNodesFunction();
    };

    setActiveItems(selectedItems) {
        this.ACTreeNavigationComponent.setActive(selectedItems);
    }

    topicNavigationFetchFunction = () => {
        _.forOwn(this.treeData, (item) => {
            item.id = item.id || item.title;
            item.text = this.buildItemTitle(item.title);
        });
    };

    onTopicNavigationTreeSelection = (topicSelections) => {

        if ((!this.selectedItems && topicSelections[0]) || (this.selectedItems.id !== topicSelections[0].id)) {
            this.selectedItems = topicSelections[0];

            this.selectedTopic = this.profileTopics.find(topic => topic.displayName === this.selectedItems.id);

            this.pmProfilesService.buildProfileDataObject(this.selectedTopic, this.groupsArray);

            if(this.parentGroupsMainContainer){
                this.parentGroupsMainContainer.nativeElement.scrollIntoView();
            }
        }
    };

    updateTextForNodesFunction = () => {
        this.treeData.forEach((node) => {
            node.text = this.buildItemTitle(node.title);
        });
    };

    getNumberOfSelectedParameters = () => {
        const result = {polled: 0, total: 0};

        _.forOwn(this.profileTopics, (profile) => {
            _.forOwn(profile.parameters, (parameter) => {
                if (parameter.isPolled) {
                    result.polled++;
                }
                result.total++;
            });
        });

        return result;
    };

    parametersCategoryCheckboxClicked = (group?) => {
        const byGroupName = group && this.getFirstObjectKey(group[0]).groupDisplayName;
        const isAllParametersChecked = this.isAllParametersChecked(this.selectedTopic.parameters, byGroupName) === true;

        Object.getOwnPropertyNames(this.selectedTopic.parameters).forEach((parameter) => {

            if (this.isValidParameter(this.selectedTopic.parameters[parameter], byGroupName)) {
                this.selectedTopic.parameters[parameter].isPolled = !isAllParametersChecked;
            }
        });
        this.updateTextForNodesFunction();
    };

    isAllTopicParametersChecked = () => {
        if (!this.selectedTopic) {
            return false;
        }

        return this.isAllParametersChecked(this.selectedTopic.parameters);
    };


    orderGroups = (group) => {
        const firstGroupProperties = Object.getOwnPropertyNames(group[0]);

        return group[0][firstGroupProperties[0]].order;
    };

    sortGroupsByOrder = (group, oldParameter) => this.sortParametersByOrder(group[0], oldParameter[0]);

    sortParametersByOrder = (parameter, oldParameter) => {
        const a = this.getFirstObjectKey(parameter);
        const b = this.getFirstObjectKey(oldParameter);
        return a.order > b.order ? 1 : (b.order > a.order ? -1 : 0);
    };

    isAllTopicsChecked = () => {
        const result = this.getNumberOfSelectedParameters();

        return result.polled === 0 ? false :
            result.polled === result.total ? true :
                'indeterminate';
    };

    topicsCheckboxClicked = () => {
        const isAllTopicsChecked = this.isAllTopicsChecked() === true;

        _.forOwn(this.profileTopics, (profile) => {
            _.forOwn(profile.parameters, (parameter) => {
                parameter.isPolled = !isAllTopicsChecked;
            });
        });
        this.updateTextForNodesFunction();
    };

    isAllParametersChecked = (parameters, byGroupName?) => {
        const counter = {
            false: 0,
            true: 0
        };

        Object.getOwnPropertyNames(parameters).forEach((parameter) => {
            if (this.isValidParameter(parameters[parameter], byGroupName)) {
                counter[parameters[parameter].isPolled]++;
            }
        });

        return counter.false === 0 ? true :
            counter.true === 0 ? false :
                'indeterminate';
    };


    getFirstObjectKey = (object) => {
        return object[this.parametersOptions[0]] ||
            object[this.parametersOptions[1]] ||
            object[this.parametersOptions[2]] ||
            object[this.parametersOptions[3]];

    }

    onParameterClick = (parameter, fieldName, $event) => {
        if (!this.isDialog && !this.deviceId && this.isSingular) {
            _.forOwn(this.profileTopics, (topic) => {
                _.forOwn(topic.parameters, (topicParameter) => {
                    topicParameter.isPolled = false;
                });
            });
        }

        parameter[fieldName].isPolled = $event;

        this.updateTextForNodesFunction();
    };

    getTypeIconPath = (parameter) => {
        return this.getTypeIconTitle(parameter) === 'Counter' ? SvgModelName.Counter : SvgModelName.Monitoring;
    };

    getAggregatedTypeIconColor = (parameter) => {
        const isAggregated = this.getFirstObjectKey(parameter).isAggregated;
        return isAggregated ? this.statusColorsList.blueStatus : this.statusColorsList.textTitle;
    };

    getTypeIconTitle = (parameter) => this.getFirstObjectKey(parameter).type;

    getAggregatedTypeIconTitle = (parameter) => {
        const isAggregated = this.getFirstObjectKey(parameter).isAggregated;

        return isAggregated ? 'Aggregated Parameter' : 'Singular Parameter';
    };

    onInitialize() {
        this.setActiveItems(this.getFromTreeData());
    }

    private returnProfileTopics = () => {
        let resultTopics = this.profileTopics || [];

        if (!this.isDialog) {
            resultTopics = resultTopics.filter((topic) => this.isSingular || !this.hasAggregatedParams(topic));
        }

        const restIsSelected = this.parametersSelect === this.parametersItems[2].value;
        const allIsSelected = this.parametersSelect === this.parametersItems[0].value;
        if (!allIsSelected) {
            resultTopics = resultTopics.filter((x) => (x.rest ? restIsSelected : !restIsSelected));
        }

        return resultTopics.sort((topicA, topicB) => topicA.order - topicB.order).map((topic) => ({title: topic.displayName}));
    };

    private hasAggregatedParams = (topic) => topic.parameters.findIndex((parameter) => parameter.isAggregated);

    private getFromTreeData = (isEdit = false) => this.treeData && this.treeData.length > 0 && this.treeData[0];

    private buildItemTitle = (title) => title + ' (' + this.getSelectedParameters(title) + ')';

    private getSelectedParameters = (displayName) => {
        const topic = this.getTopicByName(displayName);

        return this.getTopicPolledParameters(topic);
    };

    private getTopicByName = (displayName) => this.profileTopics.find((topic) => topic.displayName === displayName) || {parameters: []};

    private getTopicPolledParameters = (topic) => topic.parameters.filter((parameter) => parameter.isPolled).length;

    private isValidParameter = (parameter, byGroupName) => !_.isNil(parameter.groupDisplayName) &&
        (!byGroupName || parameter.groupDisplayName === byGroupName);

    getRestName = (paramRestData) => [paramRestData.application, paramRestData.group, paramRestData.element, paramRestData.restName].join('_');

    isAllParametersCheckedFromCheckbox = (group) => {
        return this.isAllParametersChecked(this.selectedTopic.parameters, this.getFirstObjectKey(group[0]).groupDisplayName);
    };

    public buildTopics($event?) {
        if ($event) {
            this.parametersSelect = $event;
            SessionStorageService.setData('pmSWSelection', $event);
        }
        this.treeData = this.returnProfileTopics();
        this.topicNavigationFetchFunction();
    }

    changeHappened = () => {
        this.changeDetected++;
        this.profileTopicsChanged.emit();
    }
}



