import {Injectable} from '@angular/core';
import {GroupsActions} from './actions/groups-actions.service';
import {GroupsRestService} from './apis/groups-rest.service';
import {GeneralService} from 'ac-infra';
import {TenantsRestService} from './apis/tenants-rest.service';
import {ChannelsRestService} from './apis/channels-rest.service';
import {AuthorizationService} from '../../common/services/authorization.service';

@Injectable({providedIn: 'root'})
export class CustomerActionsMenuService {

    isSystemAdminOrOperator;
    isServiceProvider;
    isEndCustomerOperator;
    isMonitor;
    isChannelOrEndCustomerOperator;
    editButtonText;
    editText;
    actionForLiveTeamCloudCustomerOnly = false;
    actionsForSingleSelection = false;
    oneIsSmartTap = false;
    selection;
    notEssentialAndNotOCBasic;
    isDeployStatusAllowed;

    constructor(private groupsActions: GroupsActions,
                private groupsRestService: GroupsRestService,
                private tenantsRestService: TenantsRestService,
                private channelsRestService: ChannelsRestService,
                private generalService: GeneralService,
                private authorizationService: AuthorizationService) {
        this.updatePermissions();
    }

    updatePermissions = () => {
        this.isSystemAdminOrOperator = this.authorizationService.isSystemAdminOrOperatorLevel;
        this.isServiceProvider = this.authorizationService.validForServiceProvider;
        this.isEndCustomerOperator = this.authorizationService.validForEndCustomer;
        this.isMonitor = this.authorizationService.isMonitor;
        this.isChannelOrEndCustomerOperator = () => {
            return this.authorizationService.validForChannel() || this.authorizationService.validForEndCustomer();
        };
        this.editButtonText = (this.generalService.isViewMode ? 'View' : 'Edit') + ' Customer';
        this.editText = (this.generalService.isViewMode ? 'View' : 'Edit') + ' Direct Routing Customer';
    }

    updateCustomerSelection = (selection) => {
        this.selection = selection;
        const deployStatus = this.selection?.length === 1 && this.selection[0]?.applicationsStatus?.management?.customerDeployStatus;
        this.isDeployStatusAllowed = deployStatus && !['INPROGRESS_RESOURCE_ALLOCATION', 'INPROGRESS_ACCOUNT_CREATION', 'INPROGRESS_AWAIT_CONCENT', 'DELETING'].includes(deployStatus);
    };

    getCustomerServiceItems = (type) => {
        this.updatePermissions();
        return [
            {
                displayName: 'Add Customer',
                enabled: !this.isEndCustomerOperator() && !this.isMonitor(),
                onClick: () => this.openCustomerServicePage({page: 'create', type})
            },
            {
                displayName: this.editButtonText,
                enabled: !this.generalService.isViewMode && !this.isEndCustomerOperator() && this.selection?.length === 1 && this.selection[0].customerSource === type && this.isDeployStatusAllowed,
                onClick: () => this.openCustomerServicePage({customer: this.selection[0], page: 'edit', type})
            },
            {
                displayName: 'View Customer',
                enabled: !this.isEndCustomerOperator() && this.selection?.length === 1 && this.selection[0].customerSource === type && !this.generalService.isViewMode,
                onClick: () => this.openCustomerServicePage({customer: this.selection[0], page: 'overview', type})
            },
            {
                displayName: 'Open Management Portal',
                enabled: !this.isEndCustomerOperator() && (!this.selection ||  this.selection?.length === 0  || (this.selection?.length === 1 && this.selection[0].customerSource === type)),
                onClick: () => this.openCustomerServicePage({customer: this.selection[0], page: 'management', type})
            },
            {
                displayName: 'Open Customer Portal',
                enabled: this.selection?.length === 1 && this.selection[0].customerSource === type && this.isDeployStatusAllowed,
                onClick: () => this.openCustomerServicePage({customer: this.selection[0], page: 'customer', type})
            }
        ];
    };

    getDirectRoutingItems = (actionsForSingleSelection) => {
        this.updatePermissions();
        this.oneIsSmartTap = this.selection?.some((customer) => customer.customerSource === 'STNG');
        // this.actionsForMultipleSelection = EntityActionsService.isAllCustomersEnabled(this.selection);;
        this.actionsForSingleSelection = actionsForSingleSelection;

        this.actionForLiveTeamCloudCustomerOnly = this.actionsForSingleSelection ? (this.selection[0].licenseType !== 'OC_BASIC' && this.selection[0].licenseType !== 'ZOOM') : false;
        this.notEssentialAndNotOCBasic = this.actionsForSingleSelection ? this.selection[0].licenseType !== 'OC_BASIC' && this.selection[0].licenseType !== 'ESSENTIAL' : false;
        return [
            {
                displayName: 'Add Customer',
                enabled: !this.isEndCustomerOperator() && !this.isMonitor(),
                onClick: this.addCustomer
            },
            {
                displayName: 'Open UMP Page',
                enabled: this.isServiceProvider(),
                onClick: this.openUMPPage
            },
            {
                displayName: this.editButtonText,
                enabled: !this.oneIsSmartTap && this.actionsForSingleSelection && this.selection[0].customerSource === 'LTC' && this.actionForLiveTeamCloudCustomerOnly,
                onClick: () => this.editCustomer(this.selection[0])
            },
            {
                displayName: this.editButtonText + ' DIDs',
                enabled: !this.oneIsSmartTap && this.isEndCustomerOperator() && this.actionsForSingleSelection && this.actionForLiveTeamCloudCustomerOnly,
                onClick: () => this.editCustomer(this.selection[0], 'did')
            },
            {
                enabled: !this.oneIsSmartTap && this.actionsForSingleSelection && this.selection[0].customerSource === 'LTC' && this.actionForLiveTeamCloudCustomerOnly && !this.isChannelOrEndCustomerOperator() && !this.isMonitor(),
                displayName: 'Change Customer Channel',
                onClick: () => this.changeCustomerChannel(this.selection[0])
            },
            {
                displayName: 'Remove Attached Channel',
                enabled: !this.oneIsSmartTap && this.actionsForSingleSelection && this.selection[0].customerSource === 'LTC' && this.actionForLiveTeamCloudCustomerOnly && !this.isChannelOrEndCustomerOperator() && !this.isMonitor(),
                onClick: () => this.removeAttachedChannel(this.selection[0])
            },
            {
                displayName: 'Backup Customer',
                enabled: !this.oneIsSmartTap && this.isSystemAdminOrOperator() && this.actionsForSingleSelection && this.selection?.[0].customerSource === 'LTC' && this.notEssentialAndNotOCBasic,
                onClick: () => this.backupCustomers()
            },
            {
                displayName: 'Restore Customer',
                enabled: !this.oneIsSmartTap && this.isSystemAdminOrOperator() && this.actionsForSingleSelection && this.selection?.[0].customerSource === 'LTC' && this.notEssentialAndNotOCBasic,
                onClick: () => this.restoreCustomers()
            },
            {
                displayName: 'Delete Customer Teams',
                enabled: !this.oneIsSmartTap && !this.isEndCustomerOperator() && !this.isMonitor() && this.actionsForSingleSelection && this.selection[0].customerSource === 'LTC' && this.actionForLiveTeamCloudCustomerOnly,
                onClick: () => this.deleteCustomerTeams()
            },
            {
                displayName: 'Delete Customer Provider',
                enabled: !this.oneIsSmartTap && !this.isEndCustomerOperator() && !this.isMonitor() && this.actionsForSingleSelection && this.selection[0].customerSource === 'LTC' && this.actionForLiveTeamCloudCustomerOnly,
                onClick: () => this.deleteCustomerProvider()
            },
        ];
    };

    getDeviceManagerItems = (actionsForSingleSelection) => {
        this.actionsForSingleSelection = actionsForSingleSelection;
        this.updatePermissions();
        return [
            {// shay requested to change name from configure endpoints to Open Management Page and to be first.... gal approved
                displayName: 'Open Management Page',
                enabled: true,
                onClick: () => this.configureEndpointsForDeviceManagerCustomer(this.selection?.length === 1 && this.selection[0].customerSource === 'DM' && this.selection[0])
            },
            {
                displayName: 'Add Customer',
                enabled: !this.isMonitor() && !this.isEndCustomerOperator(),
                onClick: () => this.addEditDeviceManagerCustomer()
            },
            {
                displayName: 'Edit Customer',
                enabled: !this.isMonitor() && !this.isEndCustomerOperator() && this.selection?.length === 1 && this.selection[0].customerSource === 'DM',
                onClick: () => this.addEditDeviceManagerCustomer(this.selection[0])
            },
            {
                displayName: 'Disable Customer',
                enabled: !this.isMonitor() && !this.isEndCustomerOperator() && this.actionsForSingleSelection && this.selection[0].customerSource === 'DM',
                onClick: () => this.disableCustomers(this.selection[0])
            },
            {
                displayName: 'Change Customer Channel',
                enabled: !this.isMonitor() && !this.isChannelOrEndCustomerOperator() && this.selection?.length === 1 && this.selection[0].customerSource === 'DM',
                onClick: () => this.changeCustomerChannel(this.selection[0], 'deviceManagerEnabled')
            },
            {
                displayName: 'Remove Attached Channel',
                enabled: !this.isMonitor() && !this.isChannelOrEndCustomerOperator() && this.selection?.length === 1 && this.selection[0].customerSource === 'DM',
                onClick: () => this.removeAttachedChannel(this.selection[0])
            }
        ];
    };

    getZoomItems = () => {
        this.updatePermissions();
        return [
            {
                displayName: 'Open Qualified Leads Page',
                onClick: () => this.openZoomPage('leads')
            },
            {
                displayName: 'Open Number Management Page',
                onClick: () => this.openZoomPage('numbers', (this.selection?.length === 1 && this.selection[0].customerSource === 'ZOOM' ? this.selection[0] : undefined))
            },
            {
                displayName: 'Change Customer Channel',
                enabled: !this.isChannelOrEndCustomerOperator() && this.selection?.length === 1 && this.selection[0].customerSource === 'ZOOM',
                onClick: () => this.changeCustomerChannel(this.selection[0], 'zoomEnabled')
            },
            {
                displayName: 'Remove Attached Channel',
                enabled: !this.isChannelOrEndCustomerOperator() && this.selection?.length === 1 && this.selection[0].customerSource === 'ZOOM',
                onClick: () => this.removeAttachedChannel(this.selection[0])
            },
            {
                displayName: 'Delete Customer Provider',
                enabled: !this.oneIsSmartTap && !this.isEndCustomerOperator() && !this.isMonitor() && this.actionsForSingleSelection && this.selection[0].customerSource === 'ZOOM',
                onClick: () => this.deleteCustomerProvider()
            }
        ];
    };

    openUMPPage = () => {
        this.groupsActions.getCustomerTenant(undefined, undefined, (tenantId) => {
            this.groupsActions.getUMPDeviceAndOpenSSOConnection('EMS_login', tenantId);
        });
    };

    addCustomer = () => {
        this.groupsActions.getCustomerTenant(undefined, undefined, (tenantId) => {
            this.groupsActions.getUMPDeviceAndOpenSSOConnection('ovoc_ovl_create', tenantId);
        });
    };

    editCustomer = (customer, pageName = undefined) => {
        this.groupsActions.editCustomer(customer, pageName);
    };

    deleteCustomerProvider = () => {
        this.groupsActions.deleteCustomerProvider(this.selection[0]);
    };

    deleteCustomerTeams = () => {
        this.groupsActions.deleteCustomerTeams(this.selection[0]);
    };

    backupCustomers = () => {
        this.groupsRestService.customerAction(() => {
        }, () => {
        }, this.selection, 'backupCustomer');
    };

    restoreCustomers = () => {
        this.groupsRestService.customerAction(() => {
        }, () => {
        }, this.selection, 'restoreCustomer');
    };

    removeAttachedChannel = (customer) => {
        this.groupsActions.updateCustomerChannel(customer, null);
    };

    changeCustomerChannel = (customer, isFeatureEnabled?: 'deviceManagerEnabled' | 'zoomEnabled' | 'operatorConnectEnabled') => {
        this.groupsActions.openSelectChannelDialog(customer, isFeatureEnabled);
    };

    openCustomerServicePage({customer = undefined, page, type}) {
        this.groupsActions.customerServiceAction({customer, page, type});
    }

    private openZoomPage(pageName = 'leads', customer = undefined) {
        this.groupsActions.openZoomPage(pageName, customer);
    }

    public addEditDeviceManagerCustomer(customer = undefined) {
        const page = customer ? 'edit_customer' : 'create_customer';
        let channelMsTenantId;
        if (page === 'create_customer' && this.authorizationService.validForChannel()) {
            const channels = this.channelsRestService.getAllEntities();
            channelMsTenantId = channels.length === 1 ? channels[0]?.msTenantId : undefined;
        }
        this.groupsActions.deviceManagerCustomerAction({customer, page, channelMsTenantId});
    }

    private disableCustomers = (customer) => {
        this.groupsActions.disableCustomer(customer);
    };

    private configureEndpointsForDeviceManagerCustomer(customer = undefined) {
        this.groupsActions.openGeneralDeviceManagerPage(customer);
    }
}
