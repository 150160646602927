import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {RefreshTableProperties, WSMessage} from 'ac-infra';
import {ServerConfigurationRestService} from '../administration-api/server-configuration-rest.service';
import {Actions} from '../../../common/server-actions/actions';
import {LogLevelsDialogComponent} from '../../dialogs/log-levels-dialog/log-levels-dialog.component';
import {Subject} from 'rxjs';
import {WsEntitiesService} from '../../../common/services/communication/ws-entities.service';


@UntilDestroy()
@Injectable({providedIn: 'root'})
export class ServerConfigurationActionService extends Actions {

    meteringConfiguration: any;
    private refreshLogLevelsTableSubject: Subject<RefreshTableProperties> = new Subject();
    refreshLogLevelsTable$ = this.refreshLogLevelsTableSubject.asObservable();

    constructor(public wsEntitiesService: WsEntitiesService,
                private serverConfigurationRestService: ServerConfigurationRestService) {
        super({});
        this.wsEntitiesService.unhandledWsMessage$.pipe(untilDestroyed(this)).subscribe(({messageType}: WSMessage) => {
            if (messageType === 'MeteringSync') {
                this.getMeteringConfiguration();
            }
        });
    }

    refreshLogLevelsTable = () => {
        this.refreshLogLevelsTableSubject.next({});
    };

    getMeteringConfiguration = () => {
        this.serverConfigurationRestService.getMeteringConfiguration().then((meteringConfiguration) => {
            this.meteringConfiguration = _.cloneDeep(meteringConfiguration);
        });
    };

    openLogLevelsDialog = (logLevels) => {
        const dialogData = {logLevels: _.cloneDeep(logLevels), logLevel: {value: undefined}};

        const serverCallback = (onSuccess, onFailure) => {
            dialogData.logLevels.forEach((logLevel) => {
                delete logLevel.name;
                logLevel.value = dialogData.logLevel.value;
            });

            const toServer = {debugLevels: dialogData.logLevels};
            const success = (response) => {
                onSuccess(response);
                this.refreshLogLevelsTable();
            };
            this.serverConfigurationRestService.editServerLogLevels(success, onFailure, toServer);
        };

        this.genericAction({
            dialogComponentType: LogLevelsDialogComponent,
            serverCallback,
            dialogData,
        });
    };
}
