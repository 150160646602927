<div class="ac-form-group-container {{size}}" [class.isClickable]="isClickable" [class.borderMode]="borderMode" [class.subTitleMode]="subTitleMode">
    <div class="title-container">
        <ng-container *ngIf="titles">
            <div class="title-box" (click)="acClick.emit()" *ngFor="let title of titles; let last = last">
                <div class="flexRowParent">
                    <span class="text">{{title.text + (title.value !== undefined ? ':  ' : '')}}</span>
                    <span *ngIf="title.value !== undefined" [innerHTML]="title.value | numberToK" class="value"></span>
                </div>
            </div>
        </ng-container>

        <div *ngIf="titleText" (click)="acClick.emit()" class="title-box">
            <div>{{titleText}}</div>
        </div>
    </div>

    <div class="content-container" [class.noSideGap]="noSideGap" [class.flexRowParent]="!contentAsColumn" [class.flexColumnParent]="contentAsColumn">
        <ng-content></ng-content>
    </div>
</div>
