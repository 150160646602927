import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'ac-nav',
    templateUrl: './ac-nav.component.html',
    styleUrls: ['./ac-nav.component.less']
})
export class AcNavComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }
}
