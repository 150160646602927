import {ComponentRef, InjectionToken, Injector, TemplateRef, ViewRef} from '@angular/core';
import {AcDialogComponent} from './ac-dialog.component';
import {Observable} from 'rxjs';

export const DIALOG_CONFIG = new InjectionToken<DialogConfig>('DialogConfig');
export const DIALOG_DATA = new InjectionToken<DialogData>('DialogData');

export const AC_DIALOG_ANNOTATION = '__ac_dialog_default_config__';

export function AcDialog(defaultDialogConfig: DialogConfig = {}) {
    return function(compType: any) {
        compType[AC_DIALOG_ANNOTATION] = Object.freeze(defaultDialogConfig);
    };
}

export class AcDialogRef {
    private _componentRef: ComponentRef<AcDialogComponent>;

    close(data?: DialogData) {
        this.dialogInstance?.close(data);
    }

    clear() {
        delete this._componentRef;
    }

    get dialogConfig(): DialogConfig {
        return this._componentRef?.instance.dialogConfig;
    }

    get onClose$(): Observable<DialogData> {
        return this._componentRef?.instance.closeDialog$;
    }

    get dialogInstance(): AcDialogComponent {
        return this._componentRef?.instance;
    }

    get dialogHostView(): ViewRef {
        return this._componentRef?.hostView;
    }

    get dialogInjector(): Injector {
        return this._componentRef?.injector;
    }

    get componentRef(): ComponentRef<AcDialogComponent> {
        console.warn('use it for debugging only');
        return this._componentRef;
    }

    set componentRef(componentRef: ComponentRef<AcDialogComponent>) {
        this._componentRef = componentRef;
    }
}

export type DialogColorPalette = 'red' | 'blue' | 'clear';

export interface DialogElementsColorPalette {
    titleColor?: DialogColorPalette;
    submitButtonColor?: DialogColorPalette;
    cancelButtonColor?: DialogColorPalette;
}


export type DialogData = any;

export interface DialogConfig {
    name?: string;
    colorPalette?: DialogColorPalette;
    elementsColorPalette?: DialogElementsColorPalette;
    ready?: boolean;
    id?: string;
    title?: string;
    userState?: any;

    injector?: Injector;

    titleTemplate?: TemplateRef<any>;
    /**
     * @deprecated use titleTemplate instead
     */
    headerPrefixTemplateRef?: TemplateRef<any>;

    preSubmit?: (dialogData?: DialogData, ...args) => Promise<any> | any;
    onSubmit?: (dialogData?: DialogData, ...args) => Promise<any> | any;
    onCancel?: (dialogData?: DialogData, ...args) => Promise<any> | any;
    onClose?: (dialogData?: DialogData, ...args) => void;
    dialogData?: any;
    formSettings?: any;
    keepState?: boolean;
    multiInstanceAllowed?: boolean;
    noCancelButton?: boolean;
    noSubmitButton?: boolean;
    reverseButtonsOrder?: boolean;
    overrideSystemViewMode?: boolean;

    submitButtonText?: string;
    cancelButtonText?: string;

    submitButtonDisabled?: boolean;
    submitButtonProcessing?: boolean;
    preventEscape?: boolean;

    customClasses?: { dialog?: string; header?: string; content?: string; footer?: string };
    height?: number;
    width?: number;
    actionsTemplate?: TemplateRef<any>;
    additionalActionsTemplate?: TemplateRef<any>;
}


// export interface DialogStyle {
//     height?: number;
//     width?: number;
//     padding?: string;
// }
//
// export interface Button {
//     text: string;
//     callback: () => void;
//     disabled?: boolean;
//     validate?: boolean;
//     class?: string;
//     style?: string;
// }
//
// export interface DialogConfig {
//     id?: string;
//     title?: string;
//     data?: any;
//     ready?: boolean;
//     processing?: boolean;
//
//     formSettings?: any;
//
//     onSubmit?: (entity?: any) => void;
//     onCancel?: () => void;
//
//     submitButtonText?: string;
//     cancelButtonText?: string;
//
//     submitButtonDisabled?: boolean;
//     submitButtonProcessing?: boolean;
//
//     submit: Button;
//     cancel: Button;
//
//     actions: Button[];
//     style?: DialogStyle;
// }
