<ac-layout-panel class="widget-item-panel"
                 [panelTitle]="widgetItem | acFunc: widgetService.getWidgetTitle"
                 [panelClass]="widgetItem | acFunc: widgetService.getWidgetClass"
                 [contentCustomClass]="editMode ? 'flexItemBiggest pointerEventsNone' : 'flexItemBiggest'"
                 [class.no-padding]="widgetItem.widget?.noPadding"
                 [loading]="widgetItem.widget?.showLoader">

    <ng-template #panelActions>
        <ng-template *ngComponentOutlet="widgetItem.widget?.panelActionsComponent"></ng-template>
        <ac-drop-down-menu *ngIf="editMode && widgetItem.widget?.dropdownActions"
                           buttonType="icon-button"
                           [buttonDefaultStyle]="false"
                           [items]="widgetItem.widget?.dropdownActions">
            <ac-svg class="menu-button" name="menuButton" *AcDropdownMenuTriggerContent></ac-svg>
        </ac-drop-down-menu>
    </ng-template>

    <ng-template *ngComponentOutlet="widgetItem.widget?.component"></ng-template>

</ac-layout-panel>
