import {getActionTypeFromInstance} from '@ngxs/store';

import {ClearState} from '../actions/session.actions';


export function clearStatePlugin(state, action, next) {
    if (getActionTypeFromInstance(action) === ClearState.type) {
        const clearState = {};
        Object.getOwnPropertyNames(state).forEach((property) => {
            clearState[property] = Array.isArray(state[property]) ? [] : {};
        });
        return next(clearState, action);
    }

    return next(state, action);
}
