<div *ngIf="!openDropDownAfterButton"
        #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="menu"
        (menuOpened)="menuOpened()"
        (menuClosed)="menuClosed()"
        [id]="matTriggerId"
        class="ac-dropdown-button" [class.upperCaseContent]="upperCaseContent">
    <div #parent>
        <ng-content select="[parent]"></ng-content>
    </div>
</div>

<div *ngIf="openDropDownAfterButton" class="flexRowParent">
    <div (click)="open()" class="ac-dropdown-button" [class.upperCaseContent]="upperCaseContent">
        <div #parentAfterButton>
            <ng-content select="[parentAfterButton]"></ng-content>
        </div>
    </div>

    <div #menuTrigger="matMenuTrigger"
         [matMenuTriggerFor]="menu"
         (menuOpened)="menuOpened()"
         (menuClosed)="menuClosed()"
         class="hidden-dropdown-menu">
    </div>
</div>

<mat-menu #menu="matMenu" class="ac-drop-down {{parentClass}}" [backdropClass]="backdropClass"
          [overlapTrigger]="(overlapTrigger !== undefined && overlapTrigger) || openDropDownAfterButton">
    <div #tooltip (click)="$event.stopPropagation()" class="ac-drop-down-content">
        <ng-content select="[tooltip]"></ng-content>
    </div>
</mat-menu>




