<div class="choose-customer-menu-container flexColumnParent">
    <ac-search *ngIf="entities.length > 3"
               (searchChange)="searchEvent($event)"
               class="flexItem"
               componentId="search-field">
    </ac-search>

    <div class="flexItemBiggest flexColumnParent">
        <ng-container *ngFor="let entity of (searchedEntities || entities)">
            <mat-card (click)="entitySelectionChanged(entity)">
                <div  class="flexRowParent card-container">
                    <div class="logo-container">
                        <img *ngIf="logos && logos[entity.customerId || entity.channelId] !== -1" [src]="logos[entity.customerId || entity.channelId]"/>

                        <ac-svg *ngIf="!logos || logos[entity.customerId || entity.channelId] === -1" name="customers"></ac-svg>
                    </div>

                    <div class="flexColumnParent card-details">
                        <div class="card-header">{{entity.customerName || entity.channelName}}</div>
                        <div class="card-footer flexColumnParent">
                            <span>{{'Service Provider Name: ' + entity.tenantName}}</span>
                            <span *ngIf="entity.customerSourceName">{{'Live Cloud Connectivity: ' + entity.customerSourceName}}</span>
                        </div>
                    </div>
                </div>
            </mat-card>
        </ng-container>
    </div>
</div>
