import {Component, Input, ViewChild} from '@angular/core';

import * as _ from 'lodash';

import {ReportRestService} from '../../../services/report-rest.service';
import {DevicesRestService} from '../../../../network/services/apis/devices-rest.service';
import {SitesRestService} from '../../../../network/services/apis/sites-rest.service';
import {LinksRestService} from '../../../../network/services/apis/links-rest.service';
import {GeneralService} from 'ac-infra';
import {ConstantStringsService} from '../../../../common/utilities/constant-strings.service';
import {AuthGroup} from '../../../../common/utilities/session-helper.service';
import {ReportEntitiesService} from '../../../reports/services/report-entities.service';
import {Observable} from 'rxjs';
import {TenantsRestService} from '../../../../network/services/apis/tenants-rest.service';
import {RegionsRestService} from '../../../../network/services/apis/regions-rest.service';
import {TopologyTreeSelectComponent} from '../../../../network/components/topology-tree/topology-tree-select/topology-tree-select.component';
import {AuthorizationService} from '../../../../common/services/authorization.service';
import {DateTime} from 'luxon';
import {WsEntitiesService} from '../../../../common/services/communication/ws-entities.service';
import {ServerInfoService} from "../../../../common/services/server-info.service";

@Component({
    selector: 'report-filter-tab',
    templateUrl: './report-filter-tab.component.html',
    styleUrls: ['./report-filter-tab.component.less']
})
export class ReportFilterTabComponent {

    @ViewChild('topologyTreeSelect', {static: false}) topologyTreeSelect: TopologyTreeSelectComponent;

    @Input() report: any;
    @Input() additionalSettings: any;
    @Input() isEdit: any;
    @Input() isViewOnly = false;
    @Input() getGroup$: Observable<any>;
    @Input() handleColumnsList: Function;
    checkedItemsObject: any;
    entitiesNamesToDisplayOnTopoTree;
    authorizedForMonitorLink;
    authorizedToDoOnlyLinkActions;
    linksList: any;
    showTreeView = true;
    lastXTypeList = [{text: 'Hours', value: 'HOURS'}, {text: 'Days', value: 'DAYS'}, {text: 'Weeks', value: 'WEEKS'}, {text: 'Months', value: 'MONTH'}];
    entityTypeList: any;
    treeDetailsList;
    reportIntervalList = [{text: '5 Minutes', value: 'FiveMinutes'}, {text: 'Hourly', value: 'Hour'}, {
        text: 'Daily',
        value: 'Day'
    }, {text: 'Weekly', value: 'Week'}, {text: 'Monthly', value: 'Month'}];
    oneLiveEntityTypes = ['Device', 'Link', 'User', 'URI', 'Location'];
    oneLiveMode = ServerInfoService.serverInfo.oneLiveMode;
    entities = ['Device', 'Link', 'Site', 'Endpoint', 'User', 'URI', 'Location'];
    entityToReportEntitiesFilter = {
        tenant: 'tenants',
        region: 'regions',
        device: 'devices',
        link: 'links',
        site: 'sites',
        ad: 'ads',
    };
    groupsList;
    serverInfoService = ServerInfoService;

    constructor(public reportRestService: ReportRestService,
                public reportEntitiesService: ReportEntitiesService,
                private wsEntitiesService: WsEntitiesService,
                private devicesRestService: DevicesRestService,
                private sitesService: SitesRestService,
                private tenantsRestService: TenantsRestService,
                private regionsRestService: RegionsRestService,
                private linksRestService: LinksRestService,
                public generalService: GeneralService,
                private authorizationService: AuthorizationService) {
        this.authorizedForMonitorLink = this.authorizationService.validFor(AuthGroup.TENANT_MONITORING_LINKS);
        this.authorizedToDoOnlyLinkActions = this.authorizationService.validForMonitorLinkOrOneLiveTenantUserOrGroupUsers();
    }


    @Input() set isActive(isActive: boolean) {
        isActive && this.topologyTreeSelect && this.topologyTreeSelect.resizeTree();
    }

    ngOnInit() {
        if (this.authorizedToDoOnlyLinkActions) {
            this.linksList = this.linksRestService.getAllEntities(undefined, true);// https://acjira/browse/OVOC-12991
            if (ServerInfoService.serverInfo && ServerInfoService.serverInfo.oneLiveMode) {
                this.report.entityType = this.additionalSettings.entityType = this.oneLiveEntityTypes.includes(this.additionalSettings.entityType) ? this.additionalSettings.entityType : 'Link';
            } else {
                this.additionalSettings.entityType = 'Link';
            }

            this.showTreeView = false;
        }

        this.generateEntityList();

        if (this.additionalSettings.entitiesFilter === null) {
            this.resetTreeArrays();
            this.additionalSettings.entitiesFilter = _.cloneDeep(this.report.entitiesFilter);
        }

        this.checkedItemsObject = {
            tenant: this.tenantsRestService.getAllEntities(this.additionalSettings.entitiesFilter.tenants),
            region: this.regionsRestService.getAllEntities(this.additionalSettings.entitiesFilter.regions),
            device: this.devicesRestService.getAllEntities(this.additionalSettings.entitiesFilter.devices),
            link: this.linksRestService.getAllEntities(this.additionalSettings.entitiesFilter.links),
            site: this.sitesService.getAllEntities(this.additionalSettings.entitiesFilter.sites),
            ad: this.wsEntitiesService.getEntitiesArray('ads', this.additionalSettings.entitiesFilter.ads)
        };

        if (this.report.timeFilter.filterType === 'EXPLICIT') {
            this.report.timeFilter.from = DateTime.fromISO(this.report.timeFilter.from);
            this.report.timeFilter.to = DateTime.fromISO(this.report.timeFilter.to);
        }

        this.entityTypeChanged();
        this.getGroupList();

        this.getGroup$.subscribe(()=> {
            this.getGroupList();

            if (!this.needRegex(this.additionalSettings.entityType)){
                if(this.report.groupsFilter && this.report.groupsFilter.length > 0){
                    const type = this.getGroupType();
                    const groups = [];

                    this.report.groupsFilter.forEach((groupId) => {
                        const group = this.wsEntitiesService.getEntity(ServerInfoService.serverInfo.oneLiveMode ? 'customers' : 'groups', groupId);
                        if(group && group.type === type && (this.report.tenantId < 0 ? true : this.report.tenantId === group.tenantId)){
                            groups.push(groupId);
                        }
                    });

                    this.report.groupsFilter = groups;
                }else{
                    this.report.groupsFilter = [];
                }
            }
        });
    }

    treeScopeChanged = () => {
        this.topologyTreeSelect && this.topologyTreeSelect.initialize();
    };

    generateEntityList = () => {
        this.entityTypeList = [];
        this.entities.forEach((entity) => {
            let addEntity = false;
            let alternativeName;
            switch (entity) {
                case 'Site':
                case 'Endpoint':
                    if (!this.authorizedToDoOnlyLinkActions) {
                        addEntity = true;
                    }
                    break;
                case 'Link':
                case 'Device':
                    addEntity = true;
                    break;
                case 'Location':
                    alternativeName = 'AD Location';
                case 'User':
                case 'URI':
                    if (!(this.generalService.enforcePrivacyMode || (this.authorizedToDoOnlyLinkActions && !ServerInfoService.serverInfo.oneLiveMode))) {
                        addEntity = true;
                    }
                    break;
            }

            if(ServerInfoService.serverInfo.oneLiveMode && (entity === 'Device' || entity === 'Link')){
                alternativeName = entity === 'Device' ? ConstantStringsService.deviceOrCustomerTeams : ConstantStringsService.linkOrCustomerProvider;
            }

            addEntity && this.addEntityToList(entity, alternativeName);
        });
    };

    addEntityToList = (entity, alternativeName?) => {
        this.entityTypeList.push({text: alternativeName || entity, value: entity});
    };

    resetTreeArrays = () => {
        this.report.entitiesFilter = {};
        this.report.entitiesFilter.tenants = [];
        this.report.entitiesFilter.regions = [];
        this.report.entitiesFilter.devices = [];
        this.report.entitiesFilter.links = [];
        this.report.entitiesFilter.sites = [];
        this.report.entitiesFilter.ads = [];
    };

    selectAllTreeEntities = () => {
        if (!this.additionalSettings.selectAllTreeEntities) {
            this.checkedItemsObject = {tenant: [], region: [], device: [], link: [], site: [], ad: []};
            this.topologyTreeSelect.select([], false);
        }

        this.additionalSettings.entitiesFilter = _.cloneDeep(this.report.entitiesFilter);
    };

    filterTypeChanged = () => {
        if (this.report.timeFilter.filterType === 'EXPLICIT') {
            delete this.report.timeFilter.lastXValue;
            delete this.report.timeFilter.lastXType;
            this.report.timeFilter.from = DateTime.now();
            this.report.timeFilter.to = DateTime.now();
        } else {
            delete this.report.timeFilter.from;
            delete this.report.timeFilter.to;
            this.report.timeFilter.lastXValue = this.report.timeFilter.lastXValue || 1;
            this.report.timeFilter.lastXType = 'MONTH';
        }
    };

    entityTypeChanged = () => {
        this.defaultTreeDetailsList();

        this.additionalSettings.entityType = this.report.entityType || this.additionalSettings.entityType;
        if (!this.needRegex(this.additionalSettings.entityType)) {
            delete this.report.regExFilter;
            this.report.groupsFilter = this.report.groupsFilter || [];
        } else {
            delete this.report.groupsFilter;
        }

        this.reportEntitiesService.executeUpdateTopType();
        let entitiesNames;
        switch (this.additionalSettings.entityType) {
            case 'Device':
                entitiesNames = ['tenants', 'regions', 'devices'];
                break;
            case 'Link':
                entitiesNames = ['tenants', 'regions', 'links'];
                break;
            case 'Site':
                entitiesNames = ['tenants', 'regions', 'sites'];
                break;
            case 'Endpoint':
                entitiesNames = ['tenants', 'regions', 'sites'];
                break;
            case 'User':
                entitiesNames = ServerInfoService.serverInfo.oneLiveMode ? ['tenants', 'regions', 'links'] : ['tenants', 'ads'];
                break;
            case 'URI':
            case 'Location':
                entitiesNames = ['tenants'];
                break;
        }

        Object.getOwnPropertyNames(this.report.entitiesFilter).forEach((prop) => {
            if (!entitiesNames.includes(prop)) {
                this.checkedItemsObject[prop.slice(0, -1)] = [];
            } else {
                this.checkedItemsObject[prop.slice(0, -1)] = this.wsEntitiesService.getEntitiesArray(prop, this.report.entitiesFilter[prop]);
            }
        });

        this.entitiesNamesToDisplayOnTopoTree = entitiesNames;

        if (this.additionalSettings.selectAllTreeEntities) {
            this.selectAllTreeEntities();
        }

        this.handleColumnsList();

        this.getGroupList();
    };

    filterDevicesByProductTypeAndLinksBySameTenantForSrcDeviceAndDstDevice = (entities, entityName) => {
        if (entityName === 'devices') {
            return entities.filter((device) => device.familyType === 'ACL' || device.familyType === 'LYNC' || device.familyType === 'TEAMS');
        }

        if (entityName === 'links' && this.report.tenantId > -1) {
            const devices = this.devicesRestService.getAllEntitiesHashed();
            const sites = this.sitesService.getAllEntitiesHashed();
            return entities.filter((link) => {
                const srcTenantId = (devices[link.linkSrcId] && devices[link.linkSrcId].tenantId) || (sites[link.linkSrcId] && sites[link.linkSrcId].tenantId);
                const dstTenantId = (devices[link.linkDstId] && devices[link.linkDstId].tenantId) || (sites[link.linkDstId] && sites[link.linkDstId].tenantId);
                return this.report.tenantId === srcTenantId && srcTenantId === dstTenantId;
            });
        }

        return entities;
    };

    defaultTreeDetailsList() {
        this.treeDetailsList = {tenant: 0, region: 0, device: 0, link: 0, site: 0, ad: 0};
    }

    fillReportEntitiesFilterBySelection(topologySelection: any[]) {
        topologySelection.forEach((node) => {

            const entityReportEntitiesFilter = this.entityToReportEntitiesFilter[node.entity];

            if (entityReportEntitiesFilter) {
                this.report.entitiesFilter[entityReportEntitiesFilter].push(node.id);
                this.treeDetailsList[node.entity]++;
            }
        });
    }

    onTopologyCheck = (topologyTreeSelection) => {
        this.defaultTreeDetailsList();
        this.report.entitiesFilter = {tenants: [], regions: [], devices: [], links: [], sites: [], ads: []};
        this.fillReportEntitiesFilterBySelection(topologyTreeSelection);

        if ((this.report.reportType || this.additionalSettings.reportType) === this.reportRestService.reportTypes.TREND) {
            this.handleColumnsList();
        }
    };

    getGroupList = () => {
        const type = this.getGroupType();

        this.groupsList = this.wsEntitiesService.getEntitiesArray(ServerInfoService.serverInfo.oneLiveMode ? 'customers' : 'groups').filter((group) =>
            group && group.type === type && (this.report.tenantId < 0 ? true : this.report.tenantId === group.tenantId)
        );
    };

    getGroupType = () => {
        if(ServerInfoService.serverInfo.oneLiveMode){
            return 'CUSTOMER';
        }else{
            return this.additionalSettings.entityType === 'Endpoint' ? 'ENDPOINT' : 'TOPOLOGY';
        }
    };

    isGroupVisible = (entityType) => {
        if(this.oneLiveMode && entityType === 'Endpoint'){
            return false;
        }

        return !this.needRegex(entityType);
    };

    needRegex = (entityType: string) => {
        return ['URI', 'User', 'Location'].includes(entityType);
    };

    onTimeFilterChange({from, to}: any) {
        this.report.timeFilter.from = from;
        this.report.timeFilter.to = to;
    }
}


