import {Injectable} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';

export interface ServerInfo {
    appVersion: string;
    branding?: string;
    oneLiveMode: boolean;
    saasMode: boolean;
    meteringEnabled: boolean;
    operatorAuthenticationMode: string;
    ovocMode: string;
    ovocVersion: string;
    restVersion: string;
    status: string;
    systemType: string;
    allowIntegrationWithUxApp?: boolean;
    whiteLabeling?: any;
    devMode?: boolean;
    then?: any;
}

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class ServerInfoService {

    static serverInfoPromise: any;
    static serverInfo: ServerInfo;
}
