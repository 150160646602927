
export namespace ExternalApplication {

    export class Set {
        static readonly type = '[External Application] Set';
        constructor(public applications: any[]) {}
    }

    export class FetchWS {
        static readonly type = '[External Application WS] Fetch';
    }

    export class FetchService {
        static readonly type = '[External Application Service] Fetch';
    }
}
