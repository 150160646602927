import {Component, Inject, ViewChild} from '@angular/core';

import * as _ from 'lodash';
import {AcDialog, DIALOG_CONFIG, DialogConfig} from 'ac-infra';
import {TreeViewPanelComponent} from '../../components/tree-view-panel/tree-view-panel.component';

@AcDialog({
    id: 'group-details-dialog',
    title: 'Group Details',
    cancelButtonText: 'Close',
    width: 520,
})
@Component({
    selector: 'network-group-dialog',
    templateUrl: './network-group-dialog.component.html',
})
export class NetworkGroupDialogComponent {

    @ViewChild('treeViewPanel', {static: false}) treeViewPanelEl: TreeViewPanelComponent;

    group: any = {};
    additionalSettings: any = {};
    schema: any = {};
    isEdit: boolean;
    groupAddSchema = require('../../../../schema/post/groupsprocessor.json');
    groupEditSchema = require('../../../../schema/put/groupsprocessor.json');

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {}

    ngOnInit() {
        this.schema = this.dialogConfig.dialogData.isEdit ? _.cloneDeep(this.groupEditSchema) : _.cloneDeep(this.groupAddSchema);
        this.group = this.dialogConfig.dialogData.entity;
        this.additionalSettings = _.cloneDeep(this.group);
        this.isEdit = this.dialogConfig.dialogData.isEdit;
    }
}





