import {Component, ElementRef, EventEmitter, Input, Optional, Output, ViewChild} from '@angular/core';
import {AcFormComponent} from '../ac-form/ac-form.component';
import {AcInputContainerComponent} from '../ac-input-container/ac-input-container.component';

import {MatFormFieldControl} from '@angular/material/form-field';

@Component({
    selector: 'ac-textfield',
    templateUrl: './ac-text-field.component.html',
    styleUrls: ['./ac-text-field.component.less'],
})
export class AcTextFieldComponent {

    @Input() acModel: string;
    @Output() acModelChange = new EventEmitter<string>();
    @Input() textFieldId = '';
    @Input() maxLength: string;
    @Input() placeholderText: string;
    @Input() ngDisabled: boolean;
    @Input() readOnly;
    @Input() focusOnInput: boolean;
    @Input() autocomplete = null;
    @ViewChild('textFieldInput', {static: true}) textFieldInputElement: ElementRef;
    @ViewChild(MatFormFieldControl) matFormFieldControl;

    constructor(@Optional() public acInputContainerComponent: AcInputContainerComponent,
                @Optional() public acFormComponent: AcFormComponent) {
    }

    ngAfterViewInit(){
        this.textFieldId = this.textFieldId || (this.acInputContainerComponent?.acInputContainerId + '-textfield');
        if(this.acInputContainerComponent){
            this.acInputContainerComponent.initializeMaterialField(this.matFormFieldControl);
            this.acInputContainerComponent.readOnly = this.readOnly !== undefined ? this.readOnly : this.acInputContainerComponent.readOnly;
        }
    }

    ngOnInit() {
        if(this.acFormComponent && this.acFormComponent.isViewMode){
            this.ngDisabled = true;
        }

        if (this.acModel !== undefined) {
            this.acModelChange.emit(this.acModel);
        }

        setTimeout(() => {
            if (this.textFieldInputElement && this.focusOnInput) {
                this.textFieldInputElement.nativeElement.focus();
            }
        });
    }

    textFieldChanged($event) {
        this.acModelChange.emit($event);
    }
}



