import {Component, forwardRef, Input} from '@angular/core';

import * as _ from 'lodash';

import {GeneralService} from 'ac-infra';
import {AcFilterComponent} from '../../../common/components/ac-filter/ac-filter.component';
import {AcFilterType} from '../../../common/components/ac-filter/modals/ac-filter-type.class';
import {WsEntitiesService} from '../../../common/services/communication/ws-entities.service';
import {ServerInfoService} from "../../../common/services/server-info.service";

@Component({
    selector: 'network-groups-filter',
    templateUrl: './network-groups-filter.component.html',
    providers: [{provide: AcFilterType, useExisting: forwardRef(() => NetworkGroupsFilterComponent)}]
})
export class NetworkGroupsFilterComponent extends AcFilterType {

    @Input() pageName: string;

    filterName = 'Groups';

    topologyGroups = {options: [], value: []};

    endpointsGroups = {options: [], value: []};

    customerGroups = {options: [], value: []};

    fieldNameMapper = {
        endpointsGroups: 'endpoints groups',
        topologyGroups: 'topology groups',
        customerGroups: 'customers',
    };

    oneLiveMode = ServerInfoService.serverInfo.oneLiveMode;

    constructor(private wsEntitiesService: WsEntitiesService, public acFilterComponent: AcFilterComponent, private generalService: GeneralService) {
        super();

        if (this.oneLiveMode) {
            this.filterName = 'Customers';
            this.lastItem_default = {customerGroups: []};
        } else {
            this.lastItem_default = {endpointsGroups: [], topologyGroups: []};
        }

        this.showPinnable = true;
        this.pinnable = true;
        this.pinned = true;
    }

    hasChanged = () => {
        let hasChanged;

        if (this.oneLiveMode) {
            hasChanged = !_.isEqual(this.customerGroups.value, this.lastItem.customerGroups);
        } else {
            hasChanged = !_.isEqual(this.endpointsGroups.value, this.lastItem.endpointsGroups) || !_.isEqual(this.topologyGroups.value, this.lastItem.topologyGroups);
        }

        return hasChanged;
    };

    createItem = () => {
        let item;

        if (this.oneLiveMode) {
            item = {customerGroups: this.customerGroups.value};
        } else {
            item = {endpointsGroups: this.endpointsGroups.value, topologyGroups: this.topologyGroups.value};
        }

        return _.isEqual(item, this.lastItem_default) ? undefined : item;
    };

    setStateForItem = (item) => {

        if (this.oneLiveMode) {
            this.customerGroups.value = item && item.customerGroups ? item.customerGroups : [];

            this.lastItem = {customerGroups: this.customerGroups.value.slice(),};
        } else {
            this.topologyGroups.value = item && item.topologyGroups ? item.topologyGroups : [];
            this.endpointsGroups.value = item && item.endpointsGroups ? item.endpointsGroups : [];

            this.lastItem = {
                endpointsGroups: this.endpointsGroups.value.slice(),
                topologyGroups: this.topologyGroups.value.slice(),
            };
        }

    };

    getTitleByField = (field, filter) => {
        if (filter[field]?.length === 1 && ['endpointsGroups', 'topologyGroups', 'customerGroups'].includes(field)) {
            return this.wsEntitiesService.getEntity(this.oneLiveMode ? 'customers' : 'groups', filter[field][0]).name;
        }
    };
}
