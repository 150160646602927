import {ArrayUtil} from 'ac-infra';

export const oldSystemMigration = (workspaceData) => {
    return ArrayUtil.arrayToObjTransform(Object.getOwnPropertyNames(workspaceData), (acc, curr) => {
        const {ver, ...oldData} = workspaceData[curr];
        switch (curr) {
            case 'tableState': {
                Object.assign(acc, ver === 1 ? oldTableMigration(oldData) : oldData);
                break;
            }
            case 'infoPanel':
                break;
            default:
                Object.assign(acc, oldData);
        }
    }, {});
};

const oldTableMigration = (tableState) => {
    return {
        tableState: Object.getOwnPropertyNames(tableState).reduce((migratedTableState, tableId) => {
            const columnsWidth = tableState[tableId].columnOptions?.reduce((acc, curr) => (acc[curr.field || curr.title] = curr.width, acc), {});
            const paging = {
                page: tableState[tableId].pagingOptions.page,
                size: tableState[tableId].pagingOptions.pageSize
            };
            const scrollPosition = tableState[tableId].lastScrollPosition;
            const sorting = tableState[tableId].lastSortedFields;
            migratedTableState[tableId.replace('.acTable.stateAndConfigs', '')] = {
                columnsWidth,
                scrollPosition,
                sorting,
                paging
            };
            return migratedTableState;
        }, {})
    };
}
