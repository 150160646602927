import {Injectable} from '@angular/core';

import * as _ from 'lodash';
import {FilterFormatter} from '../../common/filters/filter-formatter';
import {GeneralService} from "ac-infra";
import {ServerInfoService} from "../../common/services/server-info.service";

@Injectable({providedIn: 'root'})
export class NetworkFilterFormatterService extends FilterFormatter {

    private deviceMappedProps: Array<string>;
    private siteIdCallingApiList: Array<string>;
    private topologyTypes: any;

    constructor(private generalService: GeneralService) {
        super({
            setFilter: (resultFilterObject, filterObject, callingApi) => {
                this.setFilter(resultFilterObject, filterObject, callingApi);
            },
            setFilterForDynamicTab: (resultFilterObject, filterObject) => {
                this.addTimeAndDateFilter(filterObject, resultFilterObject);
            }
        });
        this.deviceMappedProps = [
            'devicesCallsStatistics',
            'devicesTopologyStatistics',
            'deviceFloatingLicense',
            'backupSummary',
            'backupSummaryFiles',
            'licensePool',
            'devices',
            'networkAlarmsSummary',
            'siteAlarmsSummary',
            'calls',
            'active',
            'history'
        ];

        this.topologyTypes = {
            endpointsMonitorStatistics: 'Endpoint',
            devicesMonitorStatistics: 'Device',
            sitesMonitorStatistics: 'Site',
            linksMonitorStatistics: 'Link'
        };

        this.siteIdCallingApiList = ['endpointsTopologyStatistics', 'endpointsMonitorStatistics', 'endpointsCallsStatistics', 'endpoints'];
    }

    public getTimeFilter = (filterObject) => {
        const resultFilterObject = {};
        filterObject = this.initFilterObject(filterObject);

        this.addTimeAndDateFilter(filterObject, resultFilterObject);

        return this.checkIfResultFilterIsEmptyObject(resultFilterObject);
    };

    setFilter = (resultFilterObject, filterObject, callingApi) => {
        if (!['devicesForDynamicTab', 'tenantDeviceBackup', 'tenantAllocations', 'tenantCallStorageTable'].includes(callingApi)) {
            this.addTopologyOrCustomerGroupIdsFilter(filterObject, resultFilterObject, callingApi);
            this.addGroupEndpointsIdsFilter(filterObject, resultFilterObject, callingApi);
        }

        if (['calls'].includes(callingApi)) {
        } else if (['active', 'history'].includes(callingApi)) {// agreed with Aliya - if user removes or not selecting any sourceType all sources types will be sent if alarms contains network filter
            if(_.isEmpty(resultFilterObject.unitType) && (filterObject.moreFilters_network || (filterObject.Status && Object.getOwnPropertyNames(filterObject).length > 0))){
                resultFilterObject.unitType = ['Device', 'Site', 'Link', 'Endpoint', 'Arm'];

                if(ServerInfoService.serverInfo.oneLiveMode){
                    resultFilterObject.unitType.push('Customer');
                }
            }
        } else {
            this.addTopologyFilter(filterObject, resultFilterObject, callingApi);
        }
        if (['tenantDeviceBackup', 'tenantAllocations', 'networkTenants', 'tenantCallStorageTable'].includes(callingApi)) {
            return;
        }

        this.addChannelFilter(filterObject, resultFilterObject, callingApi);

        if (callingApi === 'topologyGroups' || callingApi === 'endpointGroups' || callingApi === 'channel') {
            return;
        }

        if (!['calls', 'active', 'history'].includes(callingApi)) {
            const propertyName = ['backupSummary', 'backupSummaryFiles'].includes(callingApi) ? 'lastPerformedBackupTime' : 'timestamp';
            this.addTimeAndDateFilter(filterObject, resultFilterObject, propertyName);

        }
        if (callingApi === 'devicesForDynamicTab') {
            return;

        }

        this.addStatusFilter(filterObject, resultFilterObject, callingApi);
        this.addCLMStatusFilter(filterObject, resultFilterObject, callingApi);
        this.addStatisticsTopologyTypeFilter(resultFilterObject, callingApi);
        this.addManagedFilter(filterObject, resultFilterObject, callingApi);
        this.addFamilyTypeFilter(filterObject, resultFilterObject, callingApi);
        this.addProductTypeFilter(filterObject, resultFilterObject, callingApi);
        this.addLinkTypeFilter(filterObject, resultFilterObject, callingApi);
        this.addCustomerSourceFilter(filterObject, resultFilterObject, callingApi);
    };

    private addTopologyFilter = (filterObject: any, resultFilterObject: any, callingApi: string) => {
        if (callingApi.endsWith('MonitorStatistics')) {
            return;
        }

        let fieldName = 'id';
        if (this.siteIdCallingApiList.includes(callingApi) || callingApi === 'siteAlarmsSummary') {
            fieldName = 'siteId';
        } else if (callingApi === 'networkAlarmsSummary') {
            fieldName = 'unitId';
        }

        const topologyFilter = filterObject.Topology;

        if (topologyFilter && !['topologyGroups', 'endpointGroups', 'channel', 'tenantDeviceBackup', 'tenantAllocations', 'networkTenants', 'tenantCallStorageTable'].includes(callingApi)) {
            !callingApi.includes('customer') && this.assignToFieldIfArray({
                resultFilterObject,
                field: 'regionId',
                array: topologyFilter.region
            });// customer has no region
            this.assignToFieldIfArray({resultFilterObject, field: 'tenantId', array: topologyFilter.tenant});

            ['device', 'link', 'site'].forEach((entityType) => {
                if (topologyFilter[entityType] && topologyFilter[entityType].length > 0) {
                    if (callingApi === 'backupSummaryFiles' || (callingApi === 'customerGroups' && entityType !== 'site')) {
                        fieldName = entityType + 'Id';
                    }

                    resultFilterObject[fieldName] = resultFilterObject[fieldName] || [];
                    resultFilterObject[fieldName].push.apply(resultFilterObject[fieldName], topologyFilter[entityType].map((entity) => entity.id));

                    if (entityType === 'site' && callingApi === 'siteAlarmsSummary') {// OVOC-2877
                        resultFilterObject.siteId = resultFilterObject.siteId || [];
                        resultFilterObject.siteId.push.apply(resultFilterObject.siteId, topologyFilter[entityType].map((entity) => entity.id));
                    }
                }
            });
        } else if (topologyFilter) {
            const field = callingApi === 'tenantAllocations' ? 'id' : 'tenantId';
            this.assignToFieldIfArray({resultFilterObject, field, array: topologyFilter.tenant});
        }
    };


    private addStatusFilter = (filterObject: any, resultFilterObject: any, callingApi: string) => {
        if (callingApi.endsWith('MonitorStatistics') || ['backupSummary', 'backupSummaryFiles', 'deviceFloatingLicense'].includes(callingApi)) {
            return;
        }

        this.assignToServerFilter({resultFilterObject, restName: 'status', dataArray: filterObject.Status?.statuses});
    };

    private addCLMStatusFilter = (filterObject: any, resultFilterObject: any, callingApi: string) => {
        if (callingApi !== 'deviceFloatingLicense') {
            return;
        }

        if (filterObject.floatingLicense) {
            this.assignToServerFilter({
                resultFilterObject,
                restName: 'clmStatus',
                dataArray: filterObject.floatingLicense.clmStatus
            });
            this.assignToServerFilter({
                resultFilterObject,
                restName: 'clmDeviceStatus',
                dataArray: filterObject.floatingLicense.clmDeviceStatus
            });
            this.assignToServerFilter({
                resultFilterObject,
                restName: 'clmReportStatus',
                dataArray: filterObject.floatingLicense.clmReportStatus
            });
            this.assignToServerFilter({
                resultFilterObject,
                restName: 'clmConfigStatus',
                dataArray: filterObject.floatingLicense.clmConfigStatus
            });
            this.assignToServerFilter({
                resultFilterObject,
                restName: 'clmOperationalState',
                dataArray: filterObject.floatingLicense.clmOperationalState
            });
        }
    };

    private addStatisticsTopologyTypeFilter = (resultFilterObject, callingApi) => {
        if (callingApi.endsWith('MonitorStatistics')) {
            resultFilterObject.topology = this.topologyTypes[callingApi.replace('SingleSelection', '')];
        }
    };


    // Network More Filters Section
    private addManagedFilter = (filterObject: any, resultFilterObject: any, callingApi: string) => {
        if (callingApi.endsWith('MonitorStatistics') || callingApi !== 'licensePool') {
            return;
        }

        const managedFilter = filterObject.moreFilters_network && filterObject.moreFilters_network.managed;

        if (_.isString(managedFilter) && ['Managed', 'Not managed'].includes(managedFilter)) {
            resultFilterObject.managed = (managedFilter === 'Managed');
        }
    };

    private addChannelFilter = (filterObject: any, resultFilterObject: any, callingApi: string) => {
        if (!['customerGroups', 'channel', 'customersTopologyStatistics'].includes(callingApi)) {
            return;
        }

        const serverFieldName = callingApi === 'channel' ? 'name' : 'channelName';

        this.addStringFilterParameter(filterObject, 'channel', resultFilterObject, serverFieldName, 'moreFilters_network');
    };

    private addFamilyTypeFilter = (filterObject: any, resultFilterObject: any, callingApi: string) => {
        if (callingApi.endsWith('MonitorStatistics') || !this.deviceMappedProps.includes(callingApi)) {
            return;
        }

        const familyTypeFilter = filterObject.moreFilters_network && filterObject.moreFilters_network.familyType;
        this.assignToServerFilter({resultFilterObject, restName: 'familyType', dataArray: familyTypeFilter});
    };

    private addGroupEndpointsIdsFilter = (filterObject: any, resultFilterObject: any, callingApi: string) => {
        if (['endpointGroups', 'endpoints', 'endpointsCallsStatistics', 'endpointsTopologyStatistics', 'networkAlarmsSummary', 'MonitorStatistics'].includes(callingApi)) {
            const groupIdsFilter = filterObject.Groups && filterObject.Groups.endpointsGroups;
            if (groupIdsFilter && groupIdsFilter.length > 0) {
                resultFilterObject[callingApi === 'endpointGroups' ? 'id' : 'endpointGroupId'] = groupIdsFilter;
            }
        }
    };

    private addProductTypeFilter = (filterObject: any, resultFilterObject: any, callingApi: string) => {
        if (callingApi.endsWith('MonitorStatistics') || !this.deviceMappedProps.includes(callingApi)) {
            return;
        }

        const productTypeFilter = filterObject.moreFilters_network && filterObject.moreFilters_network.productType;
        this.assignToServerFilter({resultFilterObject, restName: 'productType', dataArray: productTypeFilter});
    };

    private addLinkTypeFilter = (filterObject: any, resultFilterObject: any, callingApi: string) => {
        if (callingApi.endsWith('MonitorStatistics') ||
            !['calls', 'active', 'history', 'linksCallsStatistics', 'linksTopologyStatistics', 'links', 'networkAlarmsSummary'].includes(callingApi)) {
            return;
        }

        const linkTypeFilter = filterObject.moreFilters_network && filterObject.moreFilters_network.linkType;
        this.assignToServerFilter({resultFilterObject, restName: 'categoryType', dataArray: linkTypeFilter});
    };

    private addCustomerSourceFilter = (filterObject: any, resultFilterObject: any, callingApi: string) => {
        if (!['customerGroups', 'customersTopologyStatistics', 'networkAlarmsSummary'].includes(callingApi)) {
            return;
        }

        const customerSourceFilter = filterObject.moreFilters_network && filterObject.moreFilters_network.customerSource;
        this.assignToServerFilter({resultFilterObject, restName: 'customerSource', dataArray: customerSourceFilter});
    };


    // Common
    private assignToServerFilter = ({resultFilterObject, restName, fieldName = undefined, dataArray = []}) => {
        if (_.isArray(dataArray) && dataArray.length > 0) {
            resultFilterObject[restName] = dataArray.map((value) => fieldName ? value[fieldName] : value.toUpperCase());
        }
    };

}
