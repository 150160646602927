import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AcDragAndDropComponent} from './ac-drag-and-drop.component';
import {DragulaModule} from 'ng2-dragula';
import {AcDragAndDropListModule} from './ac-drag-and-drop-list/ac-drag-and-drop-list.module';
import {AcActionButtonModule} from '../ac-action-button/ac-action-button.module';
import {AcButtonModule} from '../ac-button/ac-button.module';
import {AcIconsModule} from '../ac-icons/ac-icons.module';
import {AcSearchModule} from '../ac-search/ac-search.module';
import {InfraPipesModule} from '../../pipes/infra.pipes.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AcButtonModule,
        AcSearchModule,
        AcIconsModule,
        AcDragAndDropListModule,
        DragulaModule,
        AcActionButtonModule,
        InfraPipesModule
    ],
    declarations: [
        AcDragAndDropComponent
    ],
    entryComponents: [
        AcDragAndDropComponent
    ],
    exports: [
        AcDragAndDropComponent
    ]
})
export class AcDragAndDropModule {
}
