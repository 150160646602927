import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class ConstantStringsService {
    static linkOrCustomerProvider = 'link';
    static deviceOrCustomerTeams = 'device';

    static get linkOrCustomerProviderMany() {
        return ConstantStringsService.linkOrCustomerProvider.replace(' Side', '') + 's';
    }

    static get deviceOrCustomerTeamsMany() {
        const entityName = ConstantStringsService.deviceOrCustomerTeams.replace(' Side', '');
        return entityName + (entityName.toLowerCase() === 'teams' ? '' : 's');
    }
}

