import {Injectable} from '@angular/core';

import {dateTimeFormatter, FormatterType, GeneralService, SessionStorageService} from 'ac-infra';

import {DynamicTabsFilterFormatterService} from '../../utilities/dynamic-tabs-filter-formatter.service';
import {UsersFilterFormatterService} from '../../../users/services/users-filter-formatter.service';
import {DeviceDetailsStatisticsComponent} from '../../../dynamic-tab/device-details/device-details-statistics/device-details-statistics.component';
import {NavigationService} from '../../utilities/navigation.service';
import {NetworkFilterFormatterService} from '../../../network/services/network-filter-formatter.service';
import {PowerBiReport} from '../../../statistics/reports/external-reports/power-bi/power-bi.component';

@Injectable({providedIn: 'root'})
export class PageService {

    constructor(private navigationService: NavigationService,
                private networkFilterFormatterService: NetworkFilterFormatterService,
                private usersFilterFormatterService: UsersFilterFormatterService,
                private dynamicTabsFilterFormatterService: DynamicTabsFilterFormatterService,
                private generalService: GeneralService) {
    }

    openDevicePage = (device) => {
        const deviceTabFilter = this.getDeviceDynamicTabFilterObject();
        const key = 'device_' + device.id + '_dynamicTab';
        SessionStorageService.removeData(DeviceDetailsStatisticsComponent.FILTER_SESSION_KEY + device.id);
        SessionStorageService.setData(key, deviceTabFilter);
        this.navigationService.goToDynamicTab('/deviceDetails/' + device.id, device.name, 'device');
    };

    openLinkPage = (id, name) => {
        const linkTabFilter = this.getLinkDynamicTabFilterObject();
        const key = 'link_' + id + '_dynamicTab';

        SessionStorageService.setData(key, linkTabFilter);
        this.navigationService.goToDynamicTab('/linkDetails/' + id, name, 'link');
    };

    openReportResultPage = (reportResult) => {
        this.navigationService.goToDynamicTab('/reportDetails/' + reportResult.id, reportResult.name, 'report');
    };

    openExternalReport = ({id, name}: PowerBiReport) => {
        this.navigationService.goToDynamicTab('/externalReportDetails/' + id + '/powerBi', name);
    };

    openUserPage = (id, name) => {
        const userTabFilter = this.getUserDynamicTabFilterObject();
        const key = 'users_' + id + '_dynamicTab';

        SessionStorageService.setData(key, userTabFilter);
        this.navigationService.goToDynamicTab('/userDetails/' + id, name, 'user');
    };

    openCallPage = ({calls}) => {
        calls.forEach((callItem) => {
            const callEndTime = callItem.callEndTime ? dateTimeFormatter(callItem.callEndTime, FormatterType.dateAndTime) : callItem.callEndTime;
            const tabTitle = this.generalService.enforcePrivacyMode ? 'Call: ' + callEndTime : callItem.caller;
            this.navigationService.goToDynamicTab('/callDetails/' + callItem.id, tabTitle, 'call');
        });
    };

    getUserDynamicTabFilterObject = () => this.dynamicTabsFilterFormatterService.buildFilterForDynamicTab(this.usersFilterFormatterService.getAggregatedFilter('UsersFilter'), [
        {filterApi: 'NetworkFilterFormatter', filterMethod: 'dynamicTab', fieldName: 'statistics'},
        {filterApi: 'UsersFilterFormatter', filterMethod: 'userStatistics', fieldName: 'users'},
        {filterApi: 'CallsFilterFormatter', filterMethod: 'dynamicTab', fieldName: 'calls'}
    ]);

    getDeviceDynamicTabFilterObject = () => this.dynamicTabsFilterFormatterService.buildFilterForDynamicTab(this.networkFilterFormatterService.getAggregatedFilter('NetworkFilter'), [
        {filterApi: 'NetworkFilterFormatter', filterMethod: 'dynamicTab', fieldName: 'statistics'},
        {filterApi: 'AlarmsFilterFormatter', filterMethod: 'dynamicTabActive', fieldName: 'active'},
        {filterApi: 'AlarmsFilterFormatter', filterMethod: 'dynamicTabJournal', fieldName: 'journal'},
        {filterApi: 'CallsFilterFormatter', filterMethod: 'dynamicTab', fieldName: 'calls'}
    ]);

    getLinkDynamicTabFilterObject = () => this.dynamicTabsFilterFormatterService.buildFilterForDynamicTab(this.networkFilterFormatterService.getAggregatedFilter('NetworkFilter'), [
        {filterApi: 'NetworkFilterFormatter', filterMethod: 'dynamicTab', fieldName: 'statistics'},
        {filterApi: 'AlarmsFilterFormatter', filterMethod: 'dynamicTabActive', fieldName: 'active'},
        {filterApi: 'AlarmsFilterFormatter', filterMethod: 'dynamicTabJournal', fieldName: 'journal'},
        {filterApi: 'CallsFilterFormatter', filterMethod: 'dynamicTab', fieldName: 'calls'}
    ]);

}
