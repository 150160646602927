import {Injectable} from '@angular/core';
import {BuildUrlsService} from 'ac-infra';
import {Rest} from '../../../common/server-actions/rest';

@Injectable({providedIn: 'root'})
export class ServerInfoRestService extends Rest {

    constructor(buildURLsService: BuildUrlsService) {
        super({url: 'status/server'});

    }

    public getServerInfo = (success, failure = () => null) => {
        const onSuccess = (value) => {
            success(this.extractObject(value));
        };
        this.get({skipPopulateFilter: true, build204JSON: false}).then(onSuccess).catch(failure);
    };
}
