import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AcExpansionPanelComponent} from './ac-expansion-panel.component';
import {MatExpansionModule} from '@angular/material/expansion';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatExpansionModule
    ],
    declarations: [
        AcExpansionPanelComponent,
    ],
    entryComponents: [
        AcExpansionPanelComponent,
    ],
    exports: [
        AcExpansionPanelComponent,
    ]
})
export class AcExpansionPanelModule {
}
