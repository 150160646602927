import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import * as $ from 'jquery';
import {GeoSelectionService} from '../../../maps/services/geo-selection.service';

@Injectable()
export class AcGeoEventsService {

    private static closeOpenTooltipsOnMapSubject: Subject<any> = new Subject();
    static closeOpenTooltipsOnMap$ = AcGeoEventsService.closeOpenTooltipsOnMapSubject.asObservable();

    geoEventsShapesOptions = {};
    geoEventsLinksOptions = {};

    constructor(private geoSelectionService: GeoSelectionService) {}

    static executeCloseOpenTooltipsOnMap = () => {
        AcGeoEventsService.closeOpenTooltipsOnMapSubject.next(null);
    };

    setDataItemToShapeMapper = (geoEventsOptionsObject) => {
        this.geoEventsShapesOptions = geoEventsOptionsObject;
    };

    setDataItemToLinkMapper = (geoEventsOptionsObject) => {
        this.geoEventsLinksOptions = geoEventsOptionsObject;
    };

    mouseClickHandler = (event, item) => {
        const isSingleSelection = event.originalEvent && !event.originalEvent.ctrlKey;

        if (isSingleSelection) {
            this.geoSelectionService.clearSelection(true);
        }

        this.onSelect(item);
    };

    mapClickHandler = (event) => {
        const isMap = $(event.originalEvent.target).parents('.leaflet-container').length>0;
        if (isMap) {
            this.geoSelectionService.clearSelection();
        }
    };

    /** :: HELPER FUNCTION HERE :: **/

    onSelect = (value) => {
        const itemData = this.geoSelectionService.checkItem(value);
        const methodName = (itemData.selected ? 'unSelect'  : 'select') + itemData.type;

        this.geoSelectionService[methodName](value);
    };
}
