import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'ac-layout-section-header',
    templateUrl: './ac-layout-section-header.component.html',
    styleUrls: ['./ac-layout-section-header.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcLayoutSectionHeaderComponent {

    @Input() sectionTitle = '';
    @Input() postfixTitle = '';
}
