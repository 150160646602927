import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FilterUtilsService} from '../../services/filter-utils.service';
import {TenantsRestService} from '../../services/apis/tenants-rest.service';
import {RegionsRestService} from '../../services/apis/regions-rest.service';

@Component({
    selector: 'network-tenant-region-selection',
    templateUrl: './network-tenant-region-selection.component.html'
})
export class NetworkTenantRegionSelectionComponent {
    @Input() ngDisabledTenant = false;
    @Input() overrideSystemViewMode = false;
    @Input() ngDisabledRegion = false;
    @Input() showRegion = true;
    @Input() isEdit: boolean;
    @Input() ignoreTreeSelection = false;
    @Input() regionFormFieldName: string;
    @Input() tenantFormFieldName: string;
    @Input() tenantLabel: string = undefined;
    @Input() additionalSettings: any = {};
    @Input() selectedTopologyItem: any;
    @Input() model: any;
    @Input() tenants: any = undefined;
    @Input() sortTenants = true;
    @Input() horizontalView = false;

    @Output() acTenantChanged = new EventEmitter<any>();
    @Output() acRegionChanged = new EventEmitter<any>();

    regions;
    regionsByTenant;

    constructor(private tenantsRestService: TenantsRestService,
                private regionsRestService: RegionsRestService,
                private filterUtilsService: FilterUtilsService) {}

    ngOnInit(){
        this.tenants = this.tenants || this.tenantsRestService.getAllEntities();
        this.selectedTopologyItem = this.selectedTopologyItem || this.filterUtilsService.getSelectedTopologyItem();
        if(this.showRegion){
            this.regions = this.regionsRestService.getAllEntities();

            if (this.isEdit) {
                this.additionalSettings.tenantId = this.regionsRestService.getEntityById(this.model.regionId).tenantId;
                this.fillRegionsByTenantId();
            } else if (this.selectedTopologyItem && !this.ignoreTreeSelection) {
                this.initializeTopologySelection(this.selectedTopologyItem);
            }
        }else{
            if (!this.isEdit && !this.ignoreTreeSelection && this.selectedTopologyItem) {
                this.initializeTopologySelection(this.selectedTopologyItem);
            }else{
                this.model.tenantId = this.model.tenantId || this.additionalSettings.tenantId;
            }
        }

        if(this.model.tenantId || this.additionalSettings.tenantId){
            this.acTenantChanged.emit(this.model.tenantId || this.additionalSettings.tenantId);
        }
    }

    initializeTopologySelection = (topologyEntity) => {
        if (topologyEntity.tenantId) {
            this.additionalSettings.tenantId = topologyEntity.tenantId;

            if(this.showRegion){
                const region = this.regionsRestService.getEntityById(topologyEntity.id);
                this.fillRegionsByTenantId();
                if (region) {
                    this.model.regionId = region.id;
                }
            }
        } else if (topologyEntity.id) {
            this.additionalSettings.tenantId = topologyEntity.id;
            if(this.showRegion){
                this.model.regionId = undefined;// ac-select will choose the first item after sort
                this.fillRegionsByTenantId();
            }
        }
    };

    fillRegionsByTenantId = (tenantId?) => {
        this.regionsByTenant = this.regions && this.regions.filter(region => region.tenantId === (tenantId || this.additionalSettings.tenantId)) || [];
    };

    onTenantChange = ($event) => {
        this.model.tenantId = this.additionalSettings.tenantId = $event;
        if(this.showRegion){
            this.fillRegionsByTenantId();
            setTimeout(()=>{
                this.model.regionId = this.additionalSettings.regionId = undefined;// ac-select will choose the first item after sort
            });
        }

        this.acTenantChanged.emit($event);
    };
}
