import {NgModule} from '@angular/core';

import {acDisableLinkDirective} from './ac-disable-link.directive';
import {AcLabelDirective} from './ac-label.directive';
import {AcParentDirective} from './ac-parent.directive';
import {AcChildDirective} from './ac-child.directive';
import {AcDisableDirective} from './ac-disable.directive';

const directives = [
    AcDisableDirective,
    AcChildDirective,
    acDisableLinkDirective,
    AcLabelDirective,
    AcParentDirective
];

@NgModule({
    declarations: [...directives],
    exports: [...directives],
    providers: [...directives],
})
export class InfraDirectivesModule {
}
