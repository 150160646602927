import {Component, Inject} from '@angular/core';
import {AcDialog, ArrayUtil, DIALOG_CONFIG, DialogConfig} from 'ac-infra';


@AcDialog({
    id: 'external-link-config-dialog',
    title: 'external link config',
    width: 320,
})
@Component({
    selector: 'external-link-config-dialog',
    template: `
        <widget-dialog-container>
            <ac-checkbox-group checkboxGroupId="external-link-config"
                               [parentModel]="checkboxModel"
                               [items]="checkboxItems"
                               [withoutHeader]="true"
                               (itemsChanged)="onChanged($event)"
            ></ac-checkbox-group>
        </widget-dialog-container>
    `,
})
export class ExternalLinksConfigDialogComponent {
    checkboxModel;
    checkboxItems;

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {}

    ngOnInit() {
        const {data, widgetConfig} = this.dialogConfig.dialogData;

        this.checkboxItems = data.map(({id, name}) => ({name: id, text: name}));
        this.checkboxModel = ArrayUtil.arrayToObjTransform(this.checkboxItems, (acc, {name}) => {
            acc[name] = !widgetConfig.data || widgetConfig.data[name];
        });
    }

    onChanged({name, value}: any) {
        this.checkboxModel[name] = value;
        this.dialogConfig.dialogData.widgetConfig.data = !this.isDataDefault(this.checkboxModel) ? this.checkboxModel : undefined;
    }

    isDataDefault = (data) => {
        return Object.values(data).reduce((acc, curr) => acc && curr, true);
    };
}


