import {Injectable} from '@angular/core';
import {isEqual} from 'lodash';
import {AcDeferredPromise} from 'ac-infra';

@Injectable({
    providedIn: 'root'
})
export class OutgoingConnectionsService {
    list = [];

    delete = (url, data, method) => {
        const requestIndex = this.findByIndex({url, data, method});
        if (requestIndex >= 0) {
            this.list.splice(requestIndex, 1);
        }
    };

    find(url: any, data: any, method) {
        return this.list[this.findByIndex({url, data, method})];
    }

    add(url: any, data: any, method, defer: AcDeferredPromise) {
        this.list.push({url, data, method, defer});
    }

    private findByIndex = (item) => {
        return this.list.findIndex(({defer, ...listItem}) => {
            return isEqual(item, listItem);
        });
    };
}
