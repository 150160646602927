import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MatTabsModule} from '@angular/material/tabs';


import {InfraDirectivesModule} from '../../directives/infra.directives.module';
import {AcTabsComponent} from './ac-tabs.component';
import {AcProgressModule} from '../ac-progress/ac-progress.module';
import {AcTabDirective} from './ac-tab.directive';
import {InfraPipesModule} from '../../pipes/infra.pipes.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatTabsModule,
        InfraPipesModule,
        InfraDirectivesModule,
        AcProgressModule
    ],
    declarations: [
        AcTabsComponent,
        AcTabDirective
    ],
    entryComponents: [
        AcTabsComponent,
    ],
    exports: [
        AcTabsComponent,
        AcTabDirective
    ]
})
export class AcTabsModule {
}
