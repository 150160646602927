import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {PromiseService} from 'ac-infra';

@Injectable({
    providedIn: 'root'
})
export class MetadataService {

    static HardwareList = require('./jsons/HardwareList.json');
    static VersionList = require('./jsons/VersionList.json');
    static NtpList = require('./jsons/NtpList.json');
    static NetworkList = require('./jsons/NetworkList.json');
    static SecurityList = require('./jsons/SecurityList.json');
    static SecurityListValues = require('./jsons/SecurityListValues.json');
    static ProcessStatusList = require('./jsons/ProcessStatusList.json');
    static AlarmsColumnsNameMap = require('./jsons/AlarmsColumnsNameMap.json');
    static AttributeList = require('./jsons/AttributeList.json');
    static SWManagerFileTypes = require('./jsons/SWManagerFileTypes.json');
    static ProductsGroupType = require('./jsons/ProductsGroupType.json');
    static LicenseOrder = require('./jsons/LicenseOrder.json');
    static LicenseAllocations = require('./jsons/LicenseAllocations.json');
    static CategoryValues = require('./jsons/CategoryValues.json');
    static SubCategoryValues = require('./jsons/SubCategoryValues.json');
    static DeviceProductTypeMapper = require('./jsons/DeviceProductTypeMapper.json');
    static MediaDecompositionDevices = require('./jsons/MediaDecompositionDevices.json');
    static ProductTypeActionsMap = require('./jsons/ProductTypeActionsMap.json');
    static ListOfDevicesThatCanOpenDevicePage = require('./jsons/ListOfDevicesThatCanOpenDevicePage.json');
    static UMPUserDetailsTypes = require('./jsons/UMPUserDetailsTypes.json');
    static types;

    public static getMetadataTypes = (BuildURLs, Connection) => {
        const defer = PromiseService.defer();
        const url = BuildURLs.buildURL('settings/ui/metadata/types');

        const onSuccess = (response) => {
            MetadataService.types = _.cloneDeep(response.data);

            _.forOwn(MetadataService.types, (type) => {
                _.forOwn(type, (obj, key) => {
                    obj.name = key;
                });
            });

            defer.resolve();
        };

        Connection.get({uri: url}).then(onSuccess);
        return defer.promise;
    };

    public static getType(type, internal = undefined) {
        if (internal) {
            return _.cloneDeep(this[type]);
        }

        return _.cloneDeep(this.types[type]);
    }

    public static setType(type, data) {
        if (!this.types[type]) {
            this.types[type] = data;
        } else {
            _.extend(this.types[type], data);
        }
    }

    public static resolveValue(typeName, key, paramName = 'viewName') {
        const type = this.types[typeName];
        if (type && type[key]) {
            return type[key][paramName];
        }
    }
}
