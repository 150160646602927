import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output, ViewChild} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {MatFormFieldControl} from '@angular/material/form-field';
import {DateRange, DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY, MatCalendar} from '@angular/material/datepicker';
import {DateTime} from 'luxon';
import {AcDatePickerHeaderComponent} from '../../ac-date-picker-header/ac-date-picker-header.component';
import {RESET_SEC_AND_MILLIS} from '../../ac-date-picker.models';

@UntilDestroy()
@Component({
    selector: 'ac-range-calendar',
    styleUrls: ['./ac-range-calendar.component.less', '../calender.less'],
    templateUrl: './ac-range-calendar.component.html',
    providers: [
        {provide: MAT_DATE_RANGE_SELECTION_STRATEGY, useClass: DefaultMatCalendarRangeStrategy},
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcRangeCalendarComponent {
    acDatePickerHeader = AcDatePickerHeaderComponent;
    @ViewChild(MatCalendar, {static: true}) private matCalendar: MatCalendar<DateTime>;
    @ViewChild(MatFormFieldControl, {static: true}) matFormFieldControl: MatFormFieldControl<any>;

    _selectedRange = new DateRange<DateTime>(null, null);
    _startTimeState;
    _endTimeState;

    @Input() set selectedRange(selectedRange: DateRange<DateTime>) {
        this.setTimeState(selectedRange);
        this._selectedRange = selectedRange;
    }

    @Output() selectedRangeChange = new EventEmitter<DateRange<DateTime>>();
    @Output() cancel = new EventEmitter();
    @Output() apply = new EventEmitter<DateRange<DateTime>>();

    constructor(@Inject(MAT_DATE_RANGE_SELECTION_STRATEGY) public dateAdapter: DefaultMatCalendarRangeStrategy<DateTime>) {
    }

    setTime(date: DateTime, timeState: DateTime): DateTime {
        if (!date || !timeState) {
            return date;
        }
        return date.set(this.getTimeSegment(timeState));
    }

    getTimeSegment(date: DateTime) {
        const {year, month, day, ...timeSegment} = date.toObject();
        return timeSegment;
    }

    setTimeState(dateRange: DateRange<DateTime>) {
        this._startTimeState = dateRange.start?.set(RESET_SEC_AND_MILLIS);
        this._endTimeState = dateRange.end?.set(RESET_SEC_AND_MILLIS);
    }

    onSelect(date: DateTime): void {
        const timeState = (date >= this._selectedRange.start && !this._selectedRange.end) ? this._endTimeState : this._startTimeState;
        date = this.setTime(date, timeState);
        this._selectedRange = this.dateAdapter.selectionFinished(date, this._selectedRange);
        this.hasStartAndEnd(this._selectedRange) && this.selectedRangeChange.emit(this._selectedRange);
    }


    focus() {
        this.matCalendar.focusActiveCell();
    }

    goToDate(date: DateTime | 'start' | 'end' = 'start') {
        if (typeof date === 'string') {
            date = this._selectedRange[date];
        }

        this.matCalendar.activeDate = date || DateTime.now();
    }

    isMinDateNeeded = (minTimeState: DateTime) => {
        if (!this._selectedRange.end || !this.isSameDate(this._selectedRange)) {
            return;
        }
        return minTimeState;
    };

    isSameDate = ({start, end}: DateRange<DateTime>): boolean => {
        return !end || start?.startOf('day').equals(end?.startOf('day'));
    };

    hasStartAndEnd = (dateRange: DateRange<DateTime>): boolean => !!(dateRange.start && dateRange.end);

    setSelectedRange = (emit = true) => {
        const start = this.setTime(this._selectedRange.start, this._startTimeState);
        const end = this.setTime(this._selectedRange.end || this._selectedRange.start, this._endTimeState);

        this._selectedRange = new DateRange(start, end);
        emit && this.selectedRangeChange.emit(this._selectedRange);
    };

    onStartTimeChange(dateTime: DateTime) {
        this._startTimeState = dateTime;
        if (this.isSameDate(this._selectedRange) && this._startTimeState > this._endTimeState) {
            this._endTimeState = dateTime;
        }

        this.setSelectedRange(this.hasStartAndEnd(this._selectedRange));
    }

    onEndTimeChange(dateTime: DateTime) {
        this._endTimeState = dateTime;
        this.setSelectedRange(this.hasStartAndEnd(this._selectedRange));
    }
}

