import {ChangeDetectionStrategy, Component, EventEmitter, Input, Optional, Output, ViewChild} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {MatFormFieldControl} from '@angular/material/form-field';
import {DateRange} from '@angular/material/datepicker';
import {DateTime} from 'luxon';
import {AcDateRange} from '../ac-date-picker.models';
import {AcInputContainerComponent} from '../../ac-input-container/ac-input-container.component';
import {AcRangeCalendarComponent} from '../calender/ac-range-calendar/ac-range-calendar.component';
import {AcDatePickerHeaderComponent} from '../ac-date-picker-header/ac-date-picker-header.component';
import {AcDatePickerContainerComponent} from '../ac-date-picker-container/ac-date-picker-container.component';

@UntilDestroy()
@Component({
    selector: 'ac-date-range-picker',
    templateUrl: './ac-date-range-picker.component.html',
    styleUrls: ['./ac-date-range-picker.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcDateRangePickerComponent {
    acDatePickerHeader = AcDatePickerHeaderComponent;
    @ViewChild(MatFormFieldControl, {static: true}) matFormFieldControl: MatFormFieldControl<any>;
    @ViewChild(AcRangeCalendarComponent, {static: true}) acRangeCalendar: AcRangeCalendarComponent;
    @ViewChild(AcDatePickerContainerComponent, {static: true}) pickerContainer: AcDatePickerContainerComponent;

    @Input() disabled = false;
    @Input() startFieldKey = 'from';
    @Input() endFieldKey = 'to';
    @Output() dateRangeChange = new EventEmitter();

    constructor(@Optional() private acInputContainerComponent: AcInputContainerComponent) {
    }

    _dateRange = new AcDateRange(null, null);

    @Input() set dateRange(timeRange: AcDateRange | any) {
        if (timeRange instanceof AcDateRange) {
            this._dateRange = timeRange;
        }

        this._dateRange = new AcDateRange(timeRange[this.startFieldKey], timeRange[this.endFieldKey]);
    }

    ngOnInit() {
        this.acInputContainerComponent?.initializeMaterialField(this.matFormFieldControl);
    }


    openPicker() {
        const acDropDown = this.pickerContainer.acDropDown;
        if (acDropDown.isOpen) {
            return;
        }

        this.acRangeCalendar.focus();
        this.acRangeCalendar.goToDate();
        acDropDown.open();
    }

    refreshRef() {
        setTimeout(() => {
            this._dateRange = new AcDateRange(this._dateRange.start, this._dateRange.end);
        });
    }

    onApply(timeRange: DateRange<DateTime>) {
        this._dateRange = timeRange;
        this.dateRangeChange.emit({
            [this.startFieldKey]: timeRange.start,
            [this.endFieldKey]: timeRange.end,
        });
        this.pickerContainer.acDropDown.close();
    }

     onCancel() {
         this.pickerContainer.acDropDown.close();
     };
}

