import {ChangeDetectorRef, Component, Input} from '@angular/core';
import * as _ from 'lodash';

import {Observable} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AcLayoutPanelComponent, ArrayUtil, AutoRefreshService, GeneralService} from 'ac-infra';
import {DrillDownFiltersService} from '../../../common/services/drill-down-filters.service';
import {LicenseRestService} from '../../../network/services/apis/license-rest.service';
import {AlarmsStatisticsRestService} from '../../../alarms/apis/alarms-statistics-rest.service';
import {StatisticsRestService} from '../../../statistics/services/statistics-rest.service';
import {RestResponseSuccess} from '../../../common/server-actions/rest';
import {Widget} from '../../../common/widget/widget.modals';
import {FilterState} from "../../../common/components/ac-filter/services/ac-filter-state.service";

@UntilDestroy()
@Component({
    selector: 'active-alarms-panel',
    templateUrl: './active-alarms-panel.component.html',
    styleUrls: ['./active-alarms-panel.component.less'],
})
export class ActiveAlarmsPanelComponent extends Widget {

    readonly ALARMS_STATUS_TO_COLOR_MAP = {
        critical: GeneralService.statusColors.redStatus,
        major: GeneralService.statusColors.orangeStatus,
        minor: GeneralService.statusColors.yellowStatus,
        warning: GeneralService.statusColors.blueStatus
    };

    @Input() refreshDashboardData$: Observable<any> = AutoRefreshService.systemRefresh$;
    alarmFetchedObj = undefined;

    totalAlarms;
    gotoAlarmsPageFilteredBySeverity;
    statusColorsList;
    alarmsNames;

    constructor(private drillDownFiltersService: DrillDownFiltersService,
                private licenseRestService: LicenseRestService,
                private statisticsRestService: StatisticsRestService,
                private alarmsStatisticsRestService: AlarmsStatisticsRestService,
                private cdRef: ChangeDetectorRef,
                private filterState: FilterState,
                private acLayoutPanel: AcLayoutPanelComponent) {
        super();
        this.alarmsNames = this.ALARMS_STATUS_TO_COLOR_MAP;
    }

    ngOnInit() {
        this.getAllActiveAlarmsBySeverity();
        this.gotoAlarmsPageFilteredBySeverity = this.drillDownFiltersService.gotoAlarmsPageFilteredBySeverity('active', 'AlarmsFilter');

        this.refreshDashboardData$.pipe(untilDestroyed(this)).subscribe(() => {
            this.getAllActiveAlarmsBySeverity();
        });
        this.updateWidgetConfigData();
    }

    gotoAlarmsPageFilteredBySeverityFunc = (item) => {
        this.filterState.deleteAllFilterStatesFromStorage();

        const fields = [
            {name: 'Severity', value: {severities: item}},
            {name: 'moreFilters_alarms', value: {alarmsType: 'Only Alarms'}}
        ];
        this.gotoAlarmsPageFilteredBySeverity(undefined, fields);
    };

    getAllActiveAlarmsBySeverity = (updateData = true) => {
        const wrappedParameters = {filter: {isEvent: 0}};

        const success = ({data}: Partial<RestResponseSuccess>) => {
            this.alarmFetchedObj = data;
            this.totalAlarms = 0;
            _.forOwn(this.alarmFetchedObj, (value, key) => {
                if (key !== 'indeterminate' && this.alarmsNames[key]) {
                    this.totalAlarms += value;
                }
            });
            this.acLayoutPanel.loading = false;
            this.cdRef.detectChanges();
        };

        if (!updateData) {
            return success({data: this.alarmFetchedObj});
        }

        this.alarmsStatisticsRestService.getAllActiveAlarmsBySeverity(success, () => {
        }, wrappedParameters);
    };

    getWidgetConfigData = () => this.ALARMS_STATUS_TO_COLOR_MAP;

    updateWidgetConfigData = () => {
        const configData = this.widgetItem.widgetConfig?.data;
        this.alarmsNames = configData ? ArrayUtil.arrayToObjTransform(Object.entries(configData), (acc, [alarmStatus, isShown]) => {
            if (isShown) {
                acc[alarmStatus] = this.ALARMS_STATUS_TO_COLOR_MAP[alarmStatus];
            }
        }) : this.ALARMS_STATUS_TO_COLOR_MAP;
        this.getAllActiveAlarmsBySeverity(false);
    };
}




