import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AcFormGroupComponent} from './ac-form-group.component';
import {InfraPipesModule} from '../../pipes/infra.pipes.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        InfraPipesModule
    ],
    declarations: [
        AcFormGroupComponent,
    ],
    entryComponents: [
        AcFormGroupComponent,
    ],
    exports: [
        AcFormGroupComponent,
    ]
})
export class AcFormGroupModule {
}
