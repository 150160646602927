import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {cloneDeep, isFunction} from 'lodash';
import {AcDialogRef, CommonNotifiersService, CommunicationConfigurationService, GeneralService, HTTPMethods, PromiseService, RequestOptions} from 'ac-infra';
import {SessionService} from '../session.service';
import {ExceptionService} from '../errors/exception.service';
import {OutgoingConnectionsService} from './outgoing-connections.service';
import {Subject, takeUntil} from 'rxjs';
import {filter} from 'rxjs/operators';
import {AuthorizationService} from "../authorization.service";

@Injectable({
    providedIn: 'root'
})
export class ConnectionService {

    singletonConnections = {};

    constructor(private http: HttpClient,
                private communicationConfigurationService: CommunicationConfigurationService,
                private outgoingConnectionsService: OutgoingConnectionsService,
                private sessionService: SessionService,
                private exceptionService: ExceptionService,
                private generalService: GeneralService) {
    }

    addProtocolToUri(uri) {
        const protocolPrefix = this.communicationConfigurationService.getProtocol() + '//';

        return uri.indexOf('://') < 0 ? protocolPrefix + uri : uri;
    }

    abortRequestSubject = new Subject<string>();

    createHTTPRequest = (
        {
            defer,
            url,
            method,
            data,
            responseType,
            basicAuth = '',
            success = undefined,
            failure = undefined,
            abortRequestId = undefined,
            cancelLast = false,
            singletonGroupName = undefined,
            authMandatory = true,
            skipErrorHandler = false,
            contentTypeNeeded = true,
            skipDefaultErrorOnSpecificStatusCodes = [],
        }: RequestOptions) => {

        if (this.outgoingConnectionsService.find(url, data, method)) {
            return defer.promise = this.outgoingConnectionsService.find(url, data, method).defer.promise;
        }
        this.outgoingConnectionsService.add(url, data, method, defer);

        const currentSession = this.sessionService.activeSession;

        const onSuccess = (response) => {
            this.outgoingConnectionsService.delete(url, data, method);
            if (defer.isCanceled) {
                return;
            }
            response.data = response.body;

            success && success(response);
            defer.resolve(response);
        };
        const onFailure = (response) => {
            this.outgoingConnectionsService.delete(url, data, method);
            if (response.status === 410) {
                CommonNotifiersService.updateHttpError({response, url, method, defer});
                return;
            }
            if (skipErrorHandler) {
                defer.resolve(response);
            } else {
                this.handleException(defer, response, url, method, skipDefaultErrorOnSpecificStatusCodes);
            }

            failure && failure(response);
        };

        let auth;
        if (basicAuth) {
            auth = 'Basic ' + basicAuth;
        } else if (currentSession && currentSession.sessionId) {
            auth = 'SessionID:' + currentSession.sessionId;
        }

        if (!auth && authMandatory) {
            this.outgoingConnectionsService.delete(url, data, method);
            return;
        }

        let headers = new HttpHeaders().set('Accept', 'application/json; charset=utf-8').set('OVOC-Web-UI', 'true');

        if (this.generalService.PDFMode && this.sessionService.activeSession.PDFSaasUserPass) {
            headers = headers.set('Authorization', 'Basic ' + this.sessionService.activeSession.PDFSaasUserPass);
        }

        if (!this.generalService.PDFMode && (auth || authMandatory)) {
            headers = headers.set('Authorization', auth);
        }

        if (contentTypeNeeded) {
            headers = headers.set('Content-Type', 'application/json; charset=utf-8');
        }

        if (this.sessionService.activeSession && this.sessionService.activeSession.mocks) {
            const requestsMocks = this.sessionService.activeSession.mocks.requests;
            const shortUrl = Object.getOwnPropertyNames(requestsMocks).find((name) => {
                let mockURL = name.replace("%scopeId%", AuthorizationService.isGlobalScope() ? '' : 'scopeId%3D' + AuthorizationService.tenantScope);
                return url.endsWith(mockURL);
            });
            if (shortUrl) {
                onSuccess({body: cloneDeep(requestsMocks[shortUrl])});
                return;
            }
        }

        const connection = this.http.request(method, url, {body: data, headers, responseType, observe: 'response'});

        if (singletonGroupName) {
            if (this.singletonConnections[singletonGroupName]) {
                this.singletonConnections[singletonGroupName].isCanceled = true;
                this.singletonConnections[singletonGroupName].reject();
            }
            this.singletonConnections[singletonGroupName] = defer;
        }
        connection.pipe(
            takeUntil(this.abortRequestSubject.pipe(filter(abortId => {
                const needAbort = cancelLast && abortRequestId && (abortId === abortRequestId);

                needAbort && this.outgoingConnectionsService.delete(url, data, method);
                return needAbort;
            }))),
        ).subscribe({
            next: onSuccess,
            error: onFailure,
        });
    };

    handleException = (defer, response, url, method, skipDefaultErrorOnSpecificStatusCodes) => {
        if (defer.isCanceled) {
            return;
        }

        if (response.status <= 0 && !url.endsWith('security/actions/login')) {
            console.error('Unexpected communication error');
            return defer.reject(response);
        }

        let promise: Promise<any>;

        if (url.endsWith('/status/rest')) {
        } else if (url.endsWith('/settings/logger')) {
            return;
        } else {
            if (skipDefaultErrorOnSpecificStatusCodes?.includes(response.status) || this.handleLoginRelatedErrorFromServer(response, url)) {
                return defer.reject(response);
            }
            promise = this.handleErrorFromServer(response);
        }

        this.rejectByDialog(promise, () => {
            defer.reject(response);
        });
    };

    private rejectByDialog = (promise: Promise<AcDialogRef>, callback: () => void) => {
        if (!promise?.then) {
            return callback();
        }
        promise.then((dialogRef: AcDialogRef) => {
            if (!dialogRef || !dialogRef.onClose$) {
                return callback();
            }
            dialogRef.onClose$.subscribe(() => {
                callback();
            });
        });
    };


    handleLoginRelatedErrorFromServer = (response, url) => {
        const exceptionType = CommunicationConfigurationService.communicationExceptionType[response.status] || CommunicationConfigurationService.communicationExceptionType.default;
        const changePass = 'security/settings/localAuthentication/changePassword';

        const message = exceptionType.message ? exceptionType.message : this.returnServerDescription(response);
        const allowed401 = ['security/actions/login', changePass];

        if (response.status === 401 || [...allowed401, 'security/actions/unlock'].some((allowed) => url.endsWith(allowed))) {
            if (!url.endsWith(changePass)) {
                this.generalService.loginErrorMessage = response.error && response.error.description || 'Communication Error';
            }
            if (!allowed401.some((allowed) => url.endsWith(allowed)) && response.status === 401) {
                this.exceptionService.reportException({
                    message,
                    type: exceptionType.type,
                    data: response.error,
                });
                return this.sessionService.endSession();
            }
            return !url.endsWith(changePass);
        }
        return false;
    };

    handleErrorFromServer = (response) => {
        const message = this.returnServerDescription(response);

        const reportExceptionArgs = {message, data: response.error};

        const defer = PromiseService.defer();

        if (response?.error instanceof Blob) {
            const reader = new FileReader();
            reader.readAsText(response.error, 'data:text/json;charset=utf-8');

            reader.onload = () => {
                try {
                    const text = JSON.parse((reader as any).result);
                    reportExceptionArgs.message = text?.error || text?.description || message;
                } catch (err) {
                }
                defer.resolve(this.exceptionService.reportException(reportExceptionArgs));
            };
        } else {
            defer.resolve(this.exceptionService.reportException(reportExceptionArgs));
        }
        return defer.promise;
    };

    returnServerDescription = (response) => {
        if (response && (response.error || response.description)) {
            if (typeof response.error === 'string' || typeof response.description === 'string') {
                try {
                    return (JSON.parse(response.error)).description || JSON.parse(response.description);
                } catch (ex) {
                    return response.error || response.description;
                }
            } else {
                return response.error.description ? response.error.description : (response.description || 'error');
            }
        }

        return CommunicationConfigurationService.communicationExceptionType[response.status].message || 'error';
    };

    withUrlPromise = (defer, url, method, data) => {
        const onSuccess = (value) => {
            this.createHTTPRequest({
                defer,
                url: value,
                method,
                data,
                responseType: 'json'
            });
        };
        const onFailure = (error) => {
            defer.reject({data: error, status: 400});
        };
        const shortenURLFn = this.communicationConfigurationService.getShortenURLFn();

        if (isFunction(shortenURLFn)) {
            shortenURLFn(url).then(onSuccess).catch(onFailure);
        } else {
            defer.reject({data: {}, status: 400});
        }
    };

    doConnect(
        {
            method,
            uri,
            data = undefined,
            authMandatory = true,
            ...requestOptions
        }: RequestOptions
    ) {
        const url = this.addProtocolToUri(uri);

        const defer = PromiseService.defer();

        if (url.length > 1500) {
            this.withUrlPromise(defer, url, method, data);
        } else {
            this.createHTTPRequest({
                defer,
                url,
                method,
                data,
                authMandatory,
                ...requestOptions
            });
        }

        return defer.promise;
    }

    doUploadConnect(
        {
            method,
            uri,
            model = undefined,
            files,
            isTextFilesWithData,
            responseType,
            success = undefined,
            failure = undefined
        }) {
        const defer = PromiseService.defer();

        const url = this.addProtocolToUri(uri);

        const formData: FormData = new FormData();
        if (isTextFilesWithData) {
            formData.append('model', JSON.stringify(model));

            for (let i = 0; i < files.length; i++) {
                formData.append('file' + i, files[i]);
            }
        } else {
            formData.append('file', files[0]);
        }

        this.createHTTPRequest({
            defer,
            url,
            method,
            data: formData,
            contentTypeNeeded: false,
            responseType,
            success,
            failure,
        });
    }

    public add({uri, data = undefined, basicAuth = undefined}) {
        return this.doConnect({method: HTTPMethods.ADD, uri, data, basicAuth});
    }

    public update({uri, data, basicAuth = undefined, ...args}) {
        return this.doConnect({
            method: HTTPMethods.UPDATE,
            uri,
            data,
            basicAuth,
            ...args,
        });
    }

    public get({authMandatory = true, ...requestOptions}: RequestOptions) {
        return this.doConnect({
            method: HTTPMethods.GET,
            authMandatory,
            ...requestOptions
        });
    }

    public remove(uri) {
        return this.doConnect({method: HTTPMethods.REMOVE, uri});
    }

    public download(isPut = false, {uri, data = undefined, responseType = 'text'}: RequestOptions) {
        const method = isPut ? HTTPMethods.UPDATE : HTTPMethods.ADD;
        return this.doConnect({method, uri, data, responseType});
    }

    public upload(
        {
            uri,
            model = undefined,
            files,
            responseType = 'text',
            isTextFilesWithData = true,
            success = undefined,
            failure = undefined
        }) {
        const method = HTTPMethods[isTextFilesWithData ? 'UPLOAD' : 'ADD'];
        responseType = isTextFilesWithData ? 'json' : responseType;

        this.doUploadConnect({method, uri, model, files, isTextFilesWithData, responseType, success, failure});
    }
}
