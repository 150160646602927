<div class="graphs-container flexColumnParent">
    <div class="flexItem">
        <div class="explanation-label">Define the graph report view by adding rows</div>
        <div class="explanation-label">and “drag and drop” to organize</div>
    </div>

    <div class="add-graph-button-line-container flexRowParent flexItem">
        <div class="flexItem add-graph-label">Add Graph</div>
        <div class="add-graph-line flexItemBiggest"></div>
        <ac-action-button type="custom" icon="fa-plus" (acClick)="addItem()"></ac-action-button>
    </div>

    <div class="graph-grid-container flexItemBiggest">
        <gridster #gridsterElement [options]="options" class="flexColumnParent">
            <gridster-item *ngFor="let graph of report.graphs ; let i=index"
                           [item]="graph"
                           [attr.smallItemDisplay]="isSmallItemDisplay(i)"
                           class="flexColumnParent">

                <div class="graph-grid-name-type">
                    <ac-input-container label="Name Of Graph" class="graph-name flexItem"
                                        [formFieldName]="'report.graphs.' + i + '.graphName'">
                        <ac-textfield (mousedown)="$event.stopPropagation()"
                                      [(acModel)]="graph.graphName"
                                      placeholderText=""
                                      textFieldId="graph-name">
                        </ac-textfield>
                    </ac-input-container>

                    <ac-input-container class="graph-type-select flexItem" label="Graph Type"
                                        [formFieldName]="'report.graphs.' + i + '.graphType'">
                        <ac-single-select [(acModel)]="graph.graphType"
                                   (acModelChange)="graphTypeChanged(graph)"
                                   (onOpen)="handleGraphTypeDisabledOptions(graph.columns)"
                                   [bindLabel]="'value'"
                                   [bindValue]="'value'"
                                   [displayImage]="true"
                                   [items]="graphTypeList"
                                   [optionTitle]="'One of the selected columns does not support this graph type'">
                        </ac-single-select>
                    </ac-input-container>
                </div>

                <div class="graph-column-select fullHeight flexItemBiggest">
                    <ac-input-container [formFieldName]="'report.graphs.' + i + '.columns'" label="Columns">
                        <ac-select [(acModel)]="graph.columns"
                                   (onOpen)="handleColumnsDisabledOptions(graph.graphType)"
                                   [bindLabel]="'displayName'"
                                   [bindValue]="'id'"
                                   [items]="columnList"
                                   [maxSelectedItems]="graph.graphType === graphTypes.Pie || graph.graphType === graphTypes.StackBar ? 1 : 10"
                                   [optionTitle]="'Not valid for ' + graph.graphType + ' graph type'"
                                   [updateItemsObservable]="updateColumnList$">
                        </ac-select>
                    </ac-input-container>
                </div>

                <div (click)="removeItem(graph);" (mousedown)="$event.stopPropagation()" *ngIf="!isViewOnly"
                     class="remove-graph-button-container">
                    <i class="fa fa-times"></i>
                </div>
            </gridster-item>
        </gridster>
    </div>

</div>
