import {Pipe, PipeTransform} from '@angular/core';
import * as IPv6Utils from 'ip-num/IPv6Utils';
import {Validator} from 'ip-num/Validator';

@Pipe({name: 'IPDisplayPipe'})
export class IPDisplayPipe implements PipeTransform {

    transform(value: any): string {
        if(Validator.IPV6_RANGE_PATTERN.test(value)){
            return IPv6Utils.collapseIPv6Number(value);
        }

        return value;
    }
}
