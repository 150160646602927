import {Component, ElementRef, EventEmitter, HostBinding, Input, Optional, Output, ViewChild} from '@angular/core';
import {AcFormComponent} from '../ac-form/ac-form.component';
import {AcTrackerService} from '../../services/utilities/ac-tracker.service';
import {GeneralService} from '../../services/general.service';

export type ButtonColor = 'primary' | 'green' | 'red' | 'yellow';

export type ButtonType = 'button' |
    'raised-button' |
    'flat-button' |
    'stroked-button' |
    'icon-button' |
    'fab' |
    'mini-fab';

const HOST_SELECTOR_MDC_CLASS_PAIR: { [key in ButtonType]: string[] } = {
    'button': ['mdc-button', 'mat-mdc-button'],
    'flat-button': ['mdc-button', 'mdc-button--unelevated', 'mat-mdc-unelevated-button'],
    'raised-button': ['mdc-button', 'mdc-button--raised', 'mat-mdc-raised-button'],
    'stroked-button': ['mdc-button', 'mdc-button--outlined', 'mat-mdc-outlined-button'],
    'fab': ['mdc-fab', 'mat-mdc-fab'],
    'mini-fab': ['mdc-fab', 'mdc-fab--mini', 'mat-mdc-mini-fab'],
    'icon-button': ['mdc-icon-button', 'mat-mdc-icon-button'] ,
};

@Component({
    selector: 'ac-button',
    templateUrl: './ac-button.component.html',
    styleUrls: ['./ac-button.component.less'],
})
export class AcButtonComponent {
    classesByType = HOST_SELECTOR_MDC_CLASS_PAIR;
    @Output() acClick: EventEmitter<any> = new EventEmitter<any>();
    buttonId = '';
    @Input() type = 'button';
    @Input() transparentMode = false;
    @Input() buttonType: ButtonType = 'flat-button';
    @Input() color: ButtonColor = 'primary';
    @Input() PointerEventDefault = 'auto';
    @HostBinding('style.pointer-events') pointerEvents = this.PointerEventDefault;
    @ViewChild('contentWrapper') content: ElementRef;

    constructor(
        private generalService: GeneralService,
        @Optional() public acFormComponent: AcFormComponent,
        private acTrackerService: AcTrackerService,
    ) {
    }

    @Input('buttonId') set _buttonId(buttonId) {
        this.buttonId = this.generalService.generateIdForText(buttonId || '');
    }

    _buttonValue = '';
    @Input()
    set buttonValue(buttonValue) {
        this._buttonValue = buttonValue;

        if(this._buttonValue.toLowerCase() === 'delete'){
            this.color = 'red';
        }
    }

    _ngDisabled = null;

    @Input()
    set ngDisabled(state) {
        this._ngDisabled = state;
        this.pointerEvents = state ? 'none' : this.PointerEventDefault;
    }

    ngOnInit() {
        if (this._ngDisabled === null && this.acFormComponent?.isViewMode) {
            this._ngDisabled = true;
        }
    }

    onClick = ($event) => {
        const where = this.acFormComponent?.formName || this.acFormComponent?.formModelName || location.href;
        const text = this.buttonId || this._buttonValue || this.content?.nativeElement.textContent.trim();

        this.acTrackerService.trackEvent('button', '(' + (where) + ') ' + text);
        this.acClick.emit($event);
    };
}



