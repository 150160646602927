import {Injectable} from '@angular/core';
import {cloneDeep} from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class BuildFilterService {
    onlyNumberRegEx = /^[-+]?\d+$/;
    wrappedInSingleQuotesRegEx = /^'.+'$/;

    public buildFilterQuery(filter) {
        if (!filter) {
            return null;
        }

        const filterParameter = [];
        for (const prop in filter) {
            if (filter.hasOwnProperty(prop)) {
                this.addFilterParameter(filterParameter, filter, prop);
            }
        }

        return encodeURIComponent('(' + filterParameter.join(',') + ')');
    }


    addFilterParameter(resultFilterArray, filterObject, property) {

        if (this.isValuePropertyDefined(filterObject, property)) {
            this.pushFilterValue(resultFilterArray, filterObject, property);
        } else if (this.isValuesPropertyDefined(filterObject, property)) {
            this.pushFilterValues(resultFilterArray, filterObject, property);
        } else {
            this.pushFilter(resultFilterArray, filterObject, property);
        }
    }

    pushFilterValue(resultFilterArray, filterObject, property) {
        const operator = this.getFilterOperator(filterObject, property);
        const value = this.buildFilterValue(filterObject[property].value);

        resultFilterArray.push(property + operator + value);
    }

    pushFilterValues(resultFilterArray, filterObject, property) {
        const operators = cloneDeep(filterObject[property].operators);
        const values = cloneDeep(filterObject[property].values);
        const valuesCount = filterObject[property].values.length;

        for (let i = 0; i < valuesCount; i++) {
            resultFilterArray.push(property + operators[i] + this.buildFilterValue(values[i]));
        }
    }

    pushFilter(resultFilterArray, filterObject, property) {
        const operator = this.getFilterOperator(filterObject, property);
        const value = this.buildFilterValue(filterObject[property]);

        resultFilterArray.push(property + operator + value);
    }


    isValuePropertyDefined(filter, prop) {
        return filter[prop] && filter[prop].hasOwnProperty('value');
    }

    isValuesPropertyDefined(filter, prop) {
        return filter[prop] && filter[prop].hasOwnProperty('values');
    }

    getFilterOperator(filter, prop) {
        if (filter[prop] && filter[prop].hasOwnProperty('operator')) {
            switch (filter[prop].operator) {
                case ':' :
                    return '~';
                default  :
                    return filter[prop].operator;
            }
        }
        return '=';
    }

    buildFilterValue(filter) {
        return Array.isArray(filter) ?
            this.wrapWithQuotesAndJoin(filter) :
            (typeof filter === 'boolean' ? filter :
                this.wrapWithQuotes(filter));
    }

    wrapWithQuotesAndJoin(filter) {
        const filterCount = filter.length;

        for (let i = 0; i < filterCount; i++) {
            filter[i] = this.wrapWithQuotes(filter[i]);
        }

        return filter.join(';');
    }

    wrapWithQuotes(filter) {
        return this.onlyNumberRegEx.test(filter) || this.wrappedInSingleQuotesRegEx.test(filter) ? filter : '\'' + filter + '\'';
    }
}
