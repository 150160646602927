import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AcGeoLocationComponent} from './ac-geo-location.component';
import {AcTextFieldModule} from '../ac-text-field/ac-text-field.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AcTextFieldModule
    ],
    declarations: [
        AcGeoLocationComponent,
    ],
    entryComponents: [
        AcGeoLocationComponent,
    ],
    exports: [
        AcGeoLocationComponent,
    ]
})
export class AcGeoLocationModule {
}
