<ac-search *ngIf="hasSearchBar"
           [(search)]="search"
           [componentId]="treeId + '-search'"
           [placeHolder]="searchPlaceHolder"
           class="flexItem">
</ac-search>

<ac-tree #acTree
             class="flexItemBiggest ac-tree"
             [customTreeNodeWrapperTemplate]="customTemplate || flatNavigationTemplate"
             [extendOptions]="extendTreeOptions"
             [search]="search"
             [treeId]="treeId"
             [doSort]="doSort"
             [sortCompare]="sortCompare"
             [treeNodes]="treeNodes"
             (activeChange)="onActiveNodes($event)"
             (initialized)="onInitialized($event)"
             (updateData)="onUpdateData($event)"
></ac-tree>


<ng-template #flatNavigationTemplate let-index="index" let-node>
    <div (click)="node.mouseAction('click', $event);"
         [class.node-content-wrapper-active]="node.isActive"
         [class.disable-upper-case]="disableUpperCase"
         [class.hasError]="node?.data?.isValid === false"
         [id]="treeId + '-node-' + (node.data.id | acFunc : replaceSpaces)"
         class="node-wrapper flexRowParent parent parent-slim">

        <div class="node-content-wrapper flexItemBiggest">
            <div acLabel>
                <tree-node-content [index]="index" [node]="node"></tree-node-content>
            </div>
        </div>
    </div>
</ng-template>
