import * as _ from 'lodash';

export interface ReportColumns {
    isLocked: Function;
}

export class ReportColumns {
    static idCounter = 0;
    columnBaseId: number;
    columnType: string;
    displayName: string;
    orderObj: any;
    relevantForEntityType: any[];
    relevantForReportType: any[];
    private id: any;

    constructor(column: any, skipIdInit: boolean) {

        _.assign(this, column);

        if (!skipIdInit && this.columnBaseId === undefined) {
            this.columnBaseId = this.id;
            this.generateId();
        }
    }

    static incrementIdCounter = () => ++ReportColumns.idCounter;

    generateId = () => {
        this.id = ReportColumns.incrementIdCounter();
    };
}
