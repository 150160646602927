import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AcFilePickerComponent} from './ac-file-picker.component';
import {AcTextFieldModule} from '../ac-text-field/ac-text-field.module';
import {AcButtonModule} from '../ac-button/ac-button.module';
import {AcIconsModule} from '../ac-icons/ac-icons.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AcTextFieldModule,
        AcIconsModule,
        AcButtonModule
    ],
    declarations: [
        AcFilePickerComponent,
    ],
    entryComponents: [
        AcFilePickerComponent,
    ],
    exports: [
        AcFilePickerComponent,
    ]
})
export class AcFilePickerModule {
}
