<ac-form *ngIf="dialogConfig.ready"
         [auxiliaryModel]="additionalSettings"
         [formModel]="entity"
         [isEdit]="true"
         [ignoreViewMode]="true"
         [requiredsAuxiliaryForm]="requiredsAuxiliaryForm"
         formModelName="tenant-scope">
    <ac-radio-buttons-group *ngIf="globalMode"
                            [(acModel)]="additionalSettings.isGlobal"
                            class="scope-radio-button"
                            (acModelChange)="isGlobalChanged()"
                            [items]="[{text: 'Global Scope', value: true, class: 'global'}, {text: 'Tenant Scope', value: false, class: 'tenant'}]">
    </ac-radio-buttons-group>

    <network-tenant-region-selection *ngIf="dialogConfig.ready"
                                     (click)="additionalSettings.isGlobal = false; isGlobalChanged()"
                                     [ngDisabledTenant]="additionalSettings.isGlobal"
                                     [model]="entity"
                                     (acTenantChanged)="entity.tenantId = $event"
                                     tenantFormFieldName="entity.tenantId"
                                     [sortTenants]="sortTenants"
                                     [isEdit]="false"
                                     [tenants]="tenants"
                                     [overrideSystemViewMode]="true"
                                     [showRegion]="false">
    </network-tenant-region-selection>
</ac-form>

