import {ChangeDetectorRef, Component, Input, ViewChild} from '@angular/core';
import * as _ from 'lodash';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {qoeParameters} from 'src/app/calls/models/qoe-parameters.model';
import {AutoRefreshService, SvgModelName} from 'ac-infra';
import {DrillDownFiltersService} from '../../../common/services/drill-down-filters.service';
import {NavigationService} from '../../../common/utilities/navigation.service';
import {StatisticsRestService} from '../../../statistics/services/statistics-rest.service';
import {RestResponseSuccess} from '../../../common/server-actions/rest';
import {StatisticsSummaryComponent} from '../../../statistics/components/statistics-summary/statistics-summary.component';
import {Widget} from '../../../common/widget/widget.modals';
import {FilterState} from "../../../common/components/ac-filter/services/ac-filter-state.service";

@UntilDestroy()
@Component({
    selector: 'calls-statistic-panel',
    templateUrl: './calls-statistic-panel.component.html',
    styleUrls: ['./calls-statistic-panel.component.less'],
})
export class CallsStatisticPanelComponent extends Widget {

    @ViewChild(StatisticsSummaryComponent, {static: false}) statisticsSummaryComponent: StatisticsSummaryComponent;

    @Input() refreshDashboardData$ = AutoRefreshService.systemRefresh$;
    statisticObjIsEmpty: boolean;
    statisticFetchedObj = undefined;
    svgModelName = SvgModelName;

    gotoCallPageFilteredByQuality;
    advancedLicenseIsMissing = false;

    qoeParameters = qoeParameters;

    callsStatisticNeededList = {
        avgJitter: this.qoeParameters.voice.jitter.text,
        avgDelay: this.qoeParameters.voice.delay.text,
        avgPacketLoss: this.qoeParameters.voice.packetLoss.text
    };

    emptyCallsStats = {avgJitter: 0, avgDelay: 0, avgMos: 0, avgPacketLoss: 0};

    constructor(private drillDownFiltersService: DrillDownFiltersService,
                private statisticsRestService: StatisticsRestService,
                private navigationService: NavigationService,
                private filterState: FilterState,
                private cdRef: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        this.gotoCallPageFilteredByQuality = this.drillDownFiltersService.gotoCallPageFilteredByQuality('device', 'QOEFilter');

        this.refreshDashboardData$.pipe(untilDestroyed(this)).subscribe(() => {
            this.deviceFetchFunction(() => null, () => null);
        });
        this.deviceFetchFunction(() => null, () => null);
    }

    deviceFetchFunction = (success, failure) => {
        const onSuccess = (value: RestResponseSuccess) => {
            this.statisticFetchedObj = value.data.summaryStatistics || this.emptyCallsStats;
            this.statisticObjIsEmpty = true;

            _.forOwn(this.statisticFetchedObj, (item, key) => {
                if (key !== 'interval' && item !== 0) {
                    this.statisticObjIsEmpty = false;
                    success(value);
                }
            });
            this.cdRef.detectChanges();
        };

        this.statisticsRestService.getAllDeviceStatistics(onSuccess, failure);
    };

    goTo = (state, clearFilters) => {
        clearFilters && this.filterState.deleteAllFilterStatesFromStorage();

        this.navigationService.goTo(state);
    };

    getWidgetConfigData() {
        return {
            'statistics-data': !!this.statisticFetchedObj,
            'statistics-charts': !!this.statisticsSummaryComponent
        };
    }
}




