import {Pipe, PipeTransform} from '@angular/core';
import {AuthorizationService} from '../services/authorization.service';

@Pipe({name: 'authorization'})
export class AuthorizationPipe implements PipeTransform {
    constructor(private authorizationService: AuthorizationService) {
    }

    transform(securityGroups: string | string[]): boolean {
        return this.authorizationService.validFor(securityGroups);
    }
}
