import {Injectable} from '@angular/core';
import {DevicesRestService} from '../apis/devices-rest.service';
import {BuildUrlsService, RestServerUrlService} from 'ac-infra';
import * as _ from 'lodash';
import {EncryptionService} from '../../../common/services/encryption.service';
import {SessionService} from '../../../common/services/session.service';

@Injectable({providedIn: 'root'})
export class DeviceSSOActionsService {

    OVOC_SECURITY_LEVELS = {
        TENANT_MONITORING: 'monitor',
        TENANT_OPERATOR: 'admin',
        TENANT_ADMIN: 'sec-admin',
        SYSTEM_MONITORING: 'monitor',
        SYSTEM_OPERATOR: 'admin',
        SYSTEM_ADMIN: 'sec-admin'
    };

    EMS_SECURITY_LEVELS = {
        TENANT_MONITORING: '50',
        TENANT_OPERATOR: '100',
        TENANT_ADMIN: '200',
        SYSTEM_MONITORING: '50',
        SYSTEM_OPERATOR: '100',
        SYSTEM_ADMIN: '200'
    };

    constructor(private devicesRestService: DevicesRestService,
                private buildURLsService: BuildUrlsService,
                private sessionService: SessionService,
                private encryptionService: EncryptionService) {
    }

    startEntityServlet = (entity, callbackFn) => {
        const url = 'startServlet/' + entity.id;

        const onSuccess = () => {
            this.callCallback(entity, callbackFn);
        };

        this.devicesRestService.startServlet(onSuccess, () => {}, url);
    };

    public openEntityInNewTab = (url) => {
        if (!url.startsWith('http')) {
            url = 'http://' + url;
        }
        const browserWindow = window.open(url, '_blank');

        if (!_.isNil(browserWindow)) {
            browserWindow.focus();
        }
    };

    public buildSSOUrl = (partialUrl) => {
        return partialUrl.startsWith('http') ? partialUrl : RestServerUrlService.getHostURL() +  (partialUrl ? partialUrl : '');
    };

    private callCallback = (device, callbackFn) => {
        const loggedUser = this.sessionService.activeSession;

        callbackFn({
            isFirst: true,
            loggedUser,
            hashedUserName: this.encryptionService.hashValue(loggedUser.username),
            ovocSecurityLevel: this.encryptionService.hashValue(this.OVOC_SECURITY_LEVELS[loggedUser.securityLevel]),
            emsSecurityLevel: this.encryptionService.hashValue(this.EMS_SECURITY_LEVELS[loggedUser.securityLevel])
        });
    };
}
