<ac-button *ngIf="!isHidden"
           class="ac-toolbar-action"
           (acClick)="acClick.emit(_isViewMode)"
           [PointerEventDefault]="PointerEventDefault"
           [ngDisabled]="ngDisabled === null ? true : ngDisabled"
           [buttonId]="actions[actionType].text + '_button'"
           [color]="buttonColor"
           [buttonType]="buttonType">
    <i *ngIf="actions[actionType].icon" class="fa {{actions[actionType].icon}} action-icon"></i>
    <span *ngIf="actions[actionType].text && actions[actionType].text !== ''">{{actions[actionType].text}}</span>
</ac-button>
