<div class="picker-container flexRowParent">

    <ng-content select="[picker-input]"></ng-content>

    <span class="range-toggle">
        <ac-button class="medium range-picker-toggle" buttonType="icon-button"
                   buttonId="ac-date-range-picker-toggle"
                   [ngDisabled]="disabled"
                   (acClick)="!disabled && onToggleClick.emit($event)">
            <ac-svg class="icon" name="calendar"></ac-svg>
        </ac-button>
    </span>
</div>

<ac-drop-down backdropClass="ac-date-picker-container" (openStatusChanged)="!$event && close.emit()">
    <ng-content select="[picker-calendar]" tooltip></ng-content>
</ac-drop-down>

