import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {Subject} from 'rxjs';
import {AcToastService, ClearState, GeneralService, SessionStorageService, StringUtils} from 'ac-infra';
import {AuthorizationService} from './authorization.service';
import {AuthGroup} from '../utilities/session-helper.service';

const MILLISECONDS_IN_HOUR = 60 * 60 * 1000;

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    static activeSession;
    set activeSession(value) {
        SessionService.activeSession = value;
        this.authorizationService.securityLevel = value?.securityLevel;
        this.acToastService.setNotificationDuration(SessionService.activeSession ? SessionService.activeSession.sessionNotificationDuration * 1000 : undefined);
    }

    get activeSession() {
        return SessionService.activeSession;
    }

    inActivityPath;
    sessionLeasingPath;

    private lastActivity;
    private timeoutForInactivityTime;
    private timeoutForLeasingTime;
    private serverVersion;

    private sessionTerminatedSubject: Subject<any> = new Subject<any>();
    sessionTerminated$ = this.sessionTerminatedSubject.asObservable();

    private sessionLockedSubject: Subject<any> = new Subject<any>();
    sessionLocked$ = this.sessionLockedSubject.asObservable();

    constructor(private store: Store,
                private generalService: GeneralService,
                private acToastService: AcToastService,
                private authorizationService: AuthorizationService
    ) {
        this.updateLastActivity();
    }

    setServerVersion = (version) => {
        if (!this.serverVersion) {
            this.serverVersion = version;
        } else if (this.serverVersion !== version) {
            window.location.reload();
        }
    };

    startSession = (activeSession) => {
        this.updateLastActivity();

        this.activeSession = activeSession;
        this.enrichSessionObject(this.activeSession);
        this.activeSession.sessionStartTime = Date.now();
        this.saveSession();
        this.startTimeout();
    };

    endSession = () => {
        if (this.activeSession !== undefined) {
            this.stopTimeoutInactivity();
            this.stopTimeoutLeasing();

            SessionStorageService.clearAllData();

            this.sessionTerminatedSubject.next(null);
            this.store.dispatch(new ClearState());
            this.activeSession = undefined;
        }
    };

    lockSession = () => {
        this.activeSession.locked = true;
        this.saveSession();
        this.stopTimeoutInactivity();
        this.sessionLockedSubject.next(null);
    };

    unlockSession = () => {
        this.updateLastActivity();
        this.stopTimeoutInactivity();
        this.stopTimeoutLeasing();
        this.activeSession.locked = false;
        this.saveSession();
        this.startTimeout();
    };

    restoreSession = () => {
        if (this.activeSession) {
            return;
        }

        this.activeSession = SessionStorageService.getData('session');
        if (this.activeSession) {
            this.enrichSessionObject(this.activeSession);
            this.saveSession();
            this.startTimeout();
        }
    };


    assignToSession = (assigned: { [key: string]: any }) => {
        Object.assign(this.activeSession, assigned);
        this.saveSession();
    };

    private enrichSessionObject = (activeSession) => {
        if (activeSession) {
            GeneralService.testMode = activeSession.testMode;
            if (!activeSession.enriched) {
                activeSession.enriched = true;
                activeSession.username = activeSession.name;

                const operatorType = activeSession.isSystemOperator ? 'SYSTEM' : 'TENANT';

                activeSession.originalSecurityLevel = activeSession.securityLevel;
                activeSession.securityLevel = (activeSession.operatorType || operatorType) + '_' + activeSession.securityLevel;

                activeSession.sessionLeasingTimeStamp = 0;
                if (activeSession.sessionLeasingDurationHours > 0) {
                    activeSession.sessionLeasingTimeStamp = Date.now() + (activeSession.sessionLeasingDurationHours * MILLISECONDS_IN_HOUR);
                }
            }
        }
        this.activeSession = activeSession;
        this.generalService.enforcePrivacyMode = activeSession.privacyMode && !this.authorizationService.validFor([AuthGroup.SYSTEM_ADMIN, AuthGroup.TENANT_ADMIN]);
    };

    private saveSession = () => {
        SessionStorageService.setData('session', this.activeSession);
    };

    private registerMouseEvents = () => {
        this.unRegisterMouseEvents();
        document.addEventListener('mousemove', this.updateLastActivity);
        document.addEventListener('mousedown', this.updateLastActivity);
        document.addEventListener('keydown', this.updateLastActivity);
    };
    private unRegisterMouseEvents = () => {
        document.removeEventListener('mousemove', this.updateLastActivity);
        document.removeEventListener('mousedown', this.updateLastActivity);
        document.removeEventListener('keydown', this.updateLastActivity);
    };
    private updateLastActivity = () => {
        this.lastActivity = Date.now();
    };

    private startTimeout = () => {
        if (this.inActivityPath) {
            this.registerMouseEvents();
            this.doTimeoutCycleInactivity();
        }

        if (this.sessionLeasingPath) {
            this.doTimeoutCycleLeasing();
        }
    };

    private stopTimeoutInactivity = () => {
        // Ron\Miki did this so it will be more clear to the user, we are aware it is similar to stopTimeoutLeasing\doTimeoutCycleLeasing with small changes
        this.unRegisterMouseEvents();
        if (this.timeoutForInactivityTime) {
            clearTimeout(this.timeoutForInactivityTime);
        }
    };

    private stopTimeoutLeasing = () => {
        if (this.timeoutForLeasingTime) {
            clearTimeout(this.timeoutForLeasingTime);
        }
    };

    private doTimeoutCycleInactivity = () => {
        if (this.timeoutForInactivityTime) {
            clearTimeout(this.timeoutForInactivityTime);
        }

        this.timeoutForInactivityTime = setTimeout(() => {
            const isInactivityTimeEnded = this.inActivityPath && this.checkTimeLeft(this.getPeriodFromActiveSession(this.inActivityPath), this.lastActivity);

            if (isInactivityTimeEnded) {
                if(this.activeSession.operatorAuthenticationMode === 'AAD'){
                    this.endSession();
                }else{
                    this.lockSession();
                }
            } else {
                this.doTimeoutCycleInactivity();
            }
        }, 10000);
    };

    private doTimeoutCycleLeasing = () => {
        if (this.timeoutForLeasingTime) {
            clearTimeout(this.timeoutForLeasingTime);
        }

        this.timeoutForLeasingTime = setTimeout(() => {
            const isLeasingTimeEnded = this.sessionLeasingPath && this.checkTimeLeft(this.getPeriodFromActiveSession(this.sessionLeasingPath), this.activeSession.sessionStartTime);

            if (isLeasingTimeEnded) {
                this.endSession();
            } else {
                this.doTimeoutCycleLeasing();
            }
        }, 10000);
    };

    private checkTimeLeft = (period, timestamp) => {
        if (period === 0) {
            return false;
        }

        const timeStampWithPeriod = timestamp + period;
        const timeLeft = timeStampWithPeriod - Date.now();
        return timeLeft <= 0;
    };
    private getPeriodFromActiveSession = (path) => (this.activeSession && StringUtils.byString(this.activeSession, path) || 0) * 60 * 1000;
}
