import {Injectable} from '@angular/core';

import {Rest, RestResponseSuccess} from '../../common/server-actions/rest';
import * as _ from 'lodash';
import {WsEntitiesService} from '../../common/services/communication/ws-entities.service';


@Injectable({
    providedIn: 'root'
})
export class ActiveDirectoriesUsersLocationsRestService extends Rest {

    constructor(public wsEntitiesService: WsEntitiesService) {
        super({url: 'callsMonitor/activeDirectoriesUsersLocations', entityType: 'locations'});
    }

    getUnique(): Promise<string[]> {
        return this.get({addScopeIdFilter: true}).then((value: RestResponseSuccess) => {
            return _.uniq(value.data.locations.map(x => x.location));
        });
    }

}
