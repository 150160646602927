import {Component, Input} from '@angular/core';

import * as _ from 'lodash';

import {PMRestService} from '../../services/pm-rest.service';
import {RestResponseSuccess} from '../../../common/server-actions/rest';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {FilterState} from '../../../common/components/ac-filter/services/ac-filter-state.service';


@UntilDestroy()
@Component({
    selector: 'statistics-pm-entities-filter-tab',
    templateUrl: './statistics-pm-entities-filter-tab.component.html',
})
export class StatisticsPmEntitiesFilterTabComponent {
    @Input() profiles: any;
    @Input() deviceId: any;
    @Input() isDisabled: boolean;
    @Input() selectedTopic: any;
    @Input() changeHappened: Function;

    entitiesFilter: any = {};
    profilesOptionsObject = {};

    constructor(private performanceMeasurementsService: PMRestService,
                private filterState: FilterState) {}

    ngOnInit() {
        if (this.deviceId) {
            this.entitiesFilter.Topology = {
                device: [{id: this.deviceId}]
            };
        }

        this.filterState.timeRangeFilterChanged$.pipe(untilDestroyed(this)).subscribe((timeRange) => {
            if (timeRange && timeRange.from && timeRange.to) {
                this.entitiesFilter.timeRange = timeRange;
            } else {
                this.resetFilter('timeRange');
            }

            this.getEntities();
        });

        this.filterState.topologyFilterChanged$.pipe(untilDestroyed(this)).subscribe((topology) => {
            if (!_.isEmpty(topology)) {
                this.entitiesFilter.Topology = topology;
            } else {
                this.resetFilter('Topology');
            }

            this.getEntities();
        });
    }

    shouldContainEntitiesIndexes = () => this.selectedTopic && this.selectedTopic.type !== 'SingleParameters';

    private resetFilter = (fieldName) => {
        delete this.entitiesFilter[fieldName];

        // this.resetSelectedTopics(['indexes', 'options'], []);
    };

    private resetSelectedTopics = (selectedTopicsKeys: string | string [], defaultValue: any = undefined) => {

        if (!this.selectedTopic) {
            return;
        }

        const keys: string[] = _.concat([], selectedTopicsKeys);

        keys.forEach((key) => {
            this.selectedTopic[key] = defaultValue;
        });
    };

    private getEntities = () => {
        if (this.filterState.filterOpenedBefore || this.deviceId) {
            _.forOwn(this.profiles, (profile) => {
                this.extendProfile(profile, this.entitiesFilter);
            });
        }
    };

    private extendProfile = (profile, parameters) => {
        if(!this.profilesOptionsObject[profile.id]){
            this.profilesOptionsObject[profile.id] = {};
        }

        if (profile && profile.type !== 'SingleParameters') {
            this.profilesOptionsObject[profile.id].fetch = true;


            if (parameters.Topology) {
                parameters.topicId = profile.id;

                const onSuccess = (response: RestResponseSuccess) => {
                    if (this.profilesOptionsObject[profile.id].fetch === null) {
                        return;
                    }

                    this.extendProfileOptions(profile, response.data);
                    this.extendProfileIndexes(profile);

                    setTimeout(() => {
                        if (response.data.topics.length === 0) {
                            this.profilesOptionsObject[profile.id].fetch = null;
                        } else {
                            this.profilesOptionsObject[profile.id].fetch = false;
                        }
                    }, 0);
                };

                this.performanceMeasurementsService.getEntities(onSuccess, () => {
                    this.profilesOptionsObject[profile.id].fetch = null;
                }, parameters);
            } else {
                profile.indexes = [];
                this.profilesOptionsObject[profile.id].options = [];
                this.profilesOptionsObject[profile.id].fetch = null;

            }
        }


    };

    extendProfileOptions = (profile, data) => {
        const options = [];

        _.forOwn(data.topics, (topic) => {
            _.forOwn(topic.entities, (entity) => {
                const entityName = entity.entityName? ': ' + entity.entityName : '';
                options.push({text: entity.entityIdx + entityName, value: entity.entityIdx});
            });
        });

        this.profilesOptionsObject[profile.id].options = options;
    };

    extendProfileIndexes = (profile) => {
        if (profile.indexes && profile.indexes.length > 0) {
            profile.indexes = profile.indexes.filter((oldIndex) => this.profilesOptionsObject[profile.id].options.find((option) => option.value === oldIndex));
        }
    };
}



