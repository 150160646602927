import {Injectable} from '@angular/core';
import {GridsterItem} from 'angular-gridster2';
import {SessionService} from '../../common/services/session.service';
import {AuthorizationService} from '../../common/services/authorization.service';
import {EntitiesSummaryComponent} from '../components/entities-summary/entities-summary.component';
import {EntitiesSummaryConfigDialogComponent} from '../components/entities-summary/entities-summary-config-dialog.component';
import {ExternalLinksPanelComponent} from '../components/external-links-panel/external-links-panel.component';
import {ExternalLinksConfigDialogComponent} from '../components/external-links-panel/external-links-config-dialog.component';
import {ActiveAlarmsPanelComponent} from '../components/active-alarms-panel/active-alarms-panel.component';
import {ActiveAlarmsConfigDialogComponent} from '../components/active-alarms-panel/active-alarms-config-dialog.component';
import {CallsStatisticPanelComponent} from '../components/calls-statistic-panel/calls-statistic-panel.component';
import {CallsStatisticConfigDialogComponent} from '../components/calls-statistic-panel/calls-statistic-config-dialog.component';
import {InfoPanelComponent} from '../components/info-panel/info-panel.component';
import {DashboardAuthGroupConfigurationsService} from './dashboard-auth-group-configurations.service';
import {IWidget, WidgetItem} from '../../common/widget/widget.modals';

@Injectable()
export class DashboardConfigurationsService {
    readonly DEFAULT_WIDGET_PROPS: IWidget = {
        multiple: true
    };

    getSummaryTitle = (postfix = 'Summary') => {
        const isOneLiveServiceProvider = this.authorizationService.validForServiceProvider();
        const isOneLiveChannel = this.authorizationService.validForChannel();
        const isOneLiveEndCustomer = this.authorizationService.validForEndCustomer();
        if (!(isOneLiveServiceProvider || isOneLiveChannel || isOneLiveEndCustomer)) {
            return `Network ${postfix}`;
        }
        let oneLiveGroupType = 'Customer';

        if (isOneLiveServiceProvider) {
            oneLiveGroupType = 'Service Provider';
        } else if (isOneLiveChannel) {
            oneLiveGroupType = 'Channel';
        }

        return `${oneLiveGroupType} ${postfix}`;
    };

    readonly DEFAULT_DASHBOARD_ITEM_MAP: { [key: string]: Omit<WidgetItem, 'type'> } = {
        'entities-summary': {
            grid: {cols: 6, rows: 1, y: 0, x: 0},
            widget: {
                ...this.DEFAULT_WIDGET_PROPS,
                title: this.getSummaryTitle,
                component: EntitiesSummaryComponent,
                noPadding: true,
                configDialog: EntitiesSummaryConfigDialogComponent,
            }
        },
        'external-links': {
            grid: {
                cols: 2, rows: 3, y: 0, x: 6,
                minItemCols: 2,
                minItemRows: 1,
            },
            widget: {
                ...this.DEFAULT_WIDGET_PROPS,
                title: 'OVOC External Applications',
                component: ExternalLinksPanelComponent,
                configDialog: ExternalLinksConfigDialogComponent,
            }
        },
        'active-alarms': {
            grid: {
                cols: 2, rows: 2, y: 1, x: 0,
                minItemRows: 2,
            },
            widget: {
                ...this.DEFAULT_WIDGET_PROPS,
                title: 'Active Alarms',
                showLoader: true,
                component: ActiveAlarmsPanelComponent,
                configDialog: ActiveAlarmsConfigDialogComponent,
            }
        },
        'calls-statistics': {
            grid: {
                cols: 4, rows: 2, y: 1, x: 2,
                minItemCols: 2,
                minItemRows: 2,
            },
            widget: {
                ...this.DEFAULT_WIDGET_PROPS,
                title: 'Devices Calls Statistics (Real Time)',
                component: CallsStatisticPanelComponent,
                configDialog: CallsStatisticConfigDialogComponent,
            }
        },
        'info-panel': {
            grid: {
                cols: 8, rows: 1, y: 3, x: 0,
                minItemCols: 6,
                maxItemRows: 1,
            },
            widget: {
                ...this.DEFAULT_WIDGET_PROPS,
                component: InfoPanelComponent,
                multiple: false,
                noPadding: true,
            }

        },
    };

    constructor(
        private dashboardAuthGroupConfigurationsService: DashboardAuthGroupConfigurationsService,
        private sessionService: SessionService,
        private authorizationService: AuthorizationService,
    ) {
    }

    getDefaultAuthGroupConfigByType = (type: string): Partial<GridsterItem> => {
        const securityLevel = this.authorizationService.securityLevel || this.sessionService.activeSession?.securityLevel;
        const authGroupConfig = this.dashboardAuthGroupConfigurationsService.getAuthGroupConfig();

        return authGroupConfig[securityLevel] ? authGroupConfig[securityLevel][type] : undefined;
    };

    getDefaultDashboardItemByType = (type: string): WidgetItem => {
        const {grid, widget} = this.DEFAULT_DASHBOARD_ITEM_MAP[type];
        const defaultConfig = this.getDefaultAuthGroupConfigByType(type);

        return {
            type,
            grid: {...grid, ...defaultConfig},
            widget: {...widget},
        };
    };

    getWidgetByType = (type: string): IWidget => ({...this.DEFAULT_DASHBOARD_ITEM_MAP[type].widget});
}
