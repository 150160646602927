import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {DragulaModule} from 'ng2-dragula';
import {AcDragAndDropListComponent} from './ac-drag-and-drop-list.component';

import {AcButtonModule} from '../../ac-button/ac-button.module';
import {AcIconsModule} from '../../ac-icons/ac-icons.module';
import {AcSearchModule} from '../../ac-search/ac-search.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AcButtonModule,
        AcSearchModule,
        AcIconsModule,
        DragulaModule
    ],
    declarations: [
        AcDragAndDropListComponent
    ],
    entryComponents: [
        AcDragAndDropListComponent
    ],
    exports: [
        AcDragAndDropListComponent
    ]
})
export class AcDragAndDropListModule {
}
