import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {AcTreeService, ArrayUtil, ThrottleClass, WSMessage} from 'ac-infra';
import * as _ from 'lodash';

import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TenantsRestService} from '../../services/apis/tenants-rest.service';
import {WsEntitiesService} from '../../../common/services/communication/ws-entities.service';

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class TopologyTreeService {

    treeNodesUpdate$;
    throttledUpdate$;
    private updateNodesQueue = [];
    private flatTreeMap: any;
    private treeNodesUpdateSubject = new Subject();

    constructor(private acTreeService: AcTreeService,
                private tenantsRestService: TenantsRestService,
                private wsEntitiesService: WsEntitiesService) {

        this.treeNodesUpdate$ = this.treeNodesUpdateSubject.asObservable().pipe(untilDestroyed(this));
        this.throttledUpdate$ = new ThrottleClass({
            callback: () => {
                this.treeNodesUpdateSubject.next(this.updateNodesQueue);
                this.updateNodesQueue = [];
            },
            destroyComponentOperator: untilDestroyed(this),
            maxRecurrentTime: 3000,
            debounce: 200,
            maxDebounceTime: 600
        });

        this.wsEntitiesService.WSEntitiesUpdateFinished$.pipe(untilDestroyed(this)).subscribe((message: WSMessage) => {
            if (!['tenant', 'region', 'device', 'link', 'site'].includes(message.entityType?.toLowerCase())) {
                return;
            }
            this.flatTreeMap && this.onWSEntitiesUpdate(message);
        });
    }

    get initialized(): boolean {
        return !!this.flatTreeMap;
    }

    recreateNodeChildren(node, oldNode) {
        if (this.hasChildren(oldNode, false) && this.hasChildren(node)) {
            const oldChildrenHash = oldNode.children.reduce((acc, cur) => (acc[cur.id] = cur, acc), {});

            node.children = this.getEntityChildren(node.id);
            node.children.forEach(child => this.recreateNodeChildren(child, oldChildrenHash[child.id]));
        }
    }

    updateTreeTenant(topologyTreeData: any, updatedTenantId) {
        const tenantIndex = topologyTreeData.findIndex(treeTenant => treeTenant.id === updatedTenantId);
        const oldTenant = topologyTreeData[tenantIndex];

        if (!oldTenant) { // new tenant
            this.addNodeToOrder(topologyTreeData, _.cloneDeep(this.flatTreeMap[updatedTenantId].originalEntity));
            return;
        }

        const flatTenant = this.flatTreeMap[oldTenant.id];
        if (!flatTenant) {
            topologyTreeData.splice(tenantIndex, 1);
            return;
        }

        const newTenant = _.cloneDeep(this.flatTreeMap[oldTenant.id].originalEntity);
        this.recreateNodeChildren(newTenant, oldTenant);
        topologyTreeData[tenantIndex] = newTenant;
    }

    getEntityChildren(entityId) {
        const flatNode = this.flatTreeMap[entityId];
        if (flatNode) {
            return flatNode.originalEntity.artificial ? flatNode.children : _.cloneDeep(flatNode.children);
        }
    }

    buildFlatTree(entities, flatMap) {
        if (!entities) {
            return;
        }

        ArrayUtil.oneToMany<any>(entities).forEach(entity => {
            flatMap[entity.id] = {originalEntity: entity};

            if (this.hasChildren(entity, false)) {
                flatMap[entity.id].originalEntity.hasChildren = true;
                flatMap[entity.id].children = entity.children;

                this.acTreeService.sortNodes(flatMap[entity.id].children);
                this.buildFlatTree(entity.children, flatMap);
            }
            delete entity.children;
        });
    }

    fillLazyHashedChildren(lazyHash, entityId) {
        if (lazyHash[entityId]) {
            return;
        }
        lazyHash[entityId] = this.getEntityChildren(entityId);
    }

    getTreeNodes(topologySelection) {
        if (!this.flatTreeMap) {
            return;
        }
        const tenantIds = Object.keys(this.tenantsRestService.getAllEntitiesHashed());

        const lazyTenantsChildren = {};
        const lazyRegionsChildren = {};
        const lazyArtificialChildren = {};
        _.forOwn(topologySelection, (topologyNodes: any[], entityType: string) => {
            if (entityType === 'tenant') {
                return;
            }

            topologyNodes.forEach(node => {
                if (node.artificial) {
                    node = node.children[0];
                }

                this.fillLazyHashedChildren(lazyTenantsChildren, node.tenantId);

                if (node.regionId) { // device site or link
                    this.fillLazyHashedChildren(lazyRegionsChildren, node.regionId);

                    const artificialId = entityType + 's' + node.regionId;
                    this.fillLazyHashedChildren(lazyArtificialChildren, artificialId);
                }
            });
        });

        Object.values(lazyTenantsChildren).forEach((lazyTenant: any) => {
            lazyTenant.forEach((region) => {
                if (lazyRegionsChildren[region.id]) {
                    region.children = lazyRegionsChildren[region.id];

                    region.children.forEach((artificial) => {
                        if (lazyArtificialChildren[artificial.id]) {
                            artificial.children = lazyArtificialChildren[artificial.id];
                        }
                    });
                }
            });
        });

        return tenantIds.map(tenantId => {
            const newTenant = _.cloneDeep(this.flatTreeMap[tenantId].originalEntity);
            newTenant.children = lazyTenantsChildren[newTenant.id];
            return newTenant;
        });
    }

    setTreeNodes(treeNodes: any[]) {
        if (!treeNodes || this.flatTreeMap) {
            return;
        }
        this.flatTreeMap = {};
        this.buildFlatTree(treeNodes, this.flatTreeMap);
        this.emitTreeNodesUpdate();
    }

    clearTreeNodes() {
        this.updateNodesQueue = [];
        this.flatTreeMap = null;
    }

    private getArtificialNodeId = (entity) => {
        if (!entity) {
            return;
        }
        return entity.entity + 's' + entity.regionId;
    };

    private findNodeIndex(nodes, entity) {
        const index = nodes.findIndex((node) => (node.name || node.text) >= (entity.name || entity.text));
        return index === -1 ? nodes.length + 1 : index;
    }

    private addNodeToOrder(nodes: any[], node: any) {
        const index = this.findNodeIndex(nodes, node);
        nodes.splice(index, 0, node);
    }

    private onWSEntitiesUpdate(message: WSMessage) {
        const entityType = message.entityType.toLowerCase();
        switch (message.messageType) {
            case 'Create': {
                this.createFlatNodes(message.entitiesIds, message.entityTypeName, entityType);
                break;
            }
            case 'Update': {
                this.updateFlatNodes(message.entitiesIds, message.entityTypeName, entityType);
                break;
            }
            case 'Delete': {
                this.deleteFlatNodes(message.entitiesIds);
                break;
            }
            default : {
                return;
            }
        }
        this.emitTreeNodesUpdate();
    }

    private createDefaultArtificial(entityType, regionId) {
        entityType = entityType + 's';
        const artificialNode = {
            artificial: true,
            entity: entityType,
            id: entityType + regionId,
            name: entityType,
        };
        this.buildFlatTree(artificialNode, this.flatTreeMap);

        const flatRegion = this.flatTreeMap[regionId];
        this.addNodeToFlatChildren(flatRegion, artificialNode);

        return this.flatTreeMap[artificialNode.id];
    }

    private createFlatNodes(entitiesIds: any[], entityTypeName, entityType) {
        const newNodes = _.cloneDeep(this.wsEntitiesService.getEntitiesArray(entityTypeName, entitiesIds));
        newNodes.forEach(newNode => {
            newNode.entity = entityType;
            this.buildFlatTree(newNode, this.flatTreeMap);

            switch (entityType) {
                case 'region': {
                    const flatTenant = this.flatTreeMap[newNode.tenantId];

                    this.addNodeToFlatChildren(flatTenant, newNode);
                    break;
                }
                case 'site':
                case 'device':
                case 'link': {
                    let flatArtificial = this.flatTreeMap[this.getArtificialNodeId(newNode)];

                    if (!flatArtificial) {
                        flatArtificial = this.createDefaultArtificial(entityType, newNode.regionId);
                    }
                    this.addNodeToFlatChildren(flatArtificial, newNode);
                    break;
                }
            }
            this.updateNodesQueue.push(newNode.tenantId || newNode.id);
        });
    }

    private addNodeToFlatChildren(flatNode, node) {
        flatNode.children = flatNode.children || [];
        flatNode.originalEntity.hasChildren = true;
        this.addNodeToOrder(flatNode.children, node);
    }

    private updateFlatNodes(entitiesIds: any[], entityTypeName, entityType) {
        if (!['tenant', 'region'].includes(entityType)) {
            this.deleteFlatNodes(entitiesIds);
            this.createFlatNodes(entitiesIds, entityTypeName, entityType);
            return;
        }

        const newNodes = _.cloneDeep(this.wsEntitiesService.getEntitiesArray(entityTypeName, entitiesIds));
        newNodes.forEach((newNode) => {
            const oldNode = this.flatTreeMap[newNode.id]?.originalEntity;

            if (!oldNode) {
                return;
            }

            newNode.entity = entityType;
            newNode.hasChildren = oldNode.hasChildren;

            Object.getOwnPropertyNames(oldNode).forEach((prop) => {
                delete oldNode[prop];
            });
            Object.assign(oldNode, newNode);

            this.updateNodesQueue.push(newNode.tenantId || newNode.id);
        });
    }

    private propagateDelete(idToDelete, parentFlatNode) {
        if (!idToDelete || !parentFlatNode || !parentFlatNode.children) {
            return;
        }
        const index = parentFlatNode.children.findIndex(child => child.id === idToDelete);
        parentFlatNode.children.splice(index, 1);

        if (parentFlatNode.children.length === 0) {
            delete parentFlatNode.children;
            parentFlatNode.originalEntity.hasChildren = false;

            if (parentFlatNode.originalEntity.artificial) {
                delete this.flatTreeMap[parentFlatNode.originalEntity.id];
                const regionId = parentFlatNode.originalEntity.id.replace(parentFlatNode.originalEntity.entity, '');
                this.propagateDelete(parentFlatNode.originalEntity.id, this.flatTreeMap[regionId]);
            }
        }
    }

    private deleteFlatNodes(deletedIds: number[], nodesForUpdate: any = undefined) {
        deletedIds.forEach((id) => {
            if (!this.flatTreeMap[id]) {
                return;
            }
            const flatNode = {...this.flatTreeMap[id]};
            delete this.flatTreeMap[id];
            const tenantId = flatNode.originalEntity.tenantId || flatNode.originalEntity.id;

            if (!this.updateNodesQueue.includes(tenantId)) {
                this.updateNodesQueue.push(tenantId);
            }

            if (flatNode.originalEntity.regionId) { // site, device, link
                const artificialNodeId = this.getArtificialNodeId(flatNode.originalEntity);
                this.propagateDelete(flatNode.originalEntity.id, this.flatTreeMap[artificialNodeId]);
            } else if (flatNode.originalEntity.tenantId) { // region
                this.propagateDelete(flatNode.originalEntity.id, this.flatTreeMap[flatNode.originalEntity.tenantId]);
            }
        });
    }

    private emitTreeNodesUpdate = () => this.throttledUpdate$.tick();

    private hasChildren(entity, flat = true) {
        if (!entity) {
            return false;
        }
        if (flat) {
            return !!(this.flatTreeMap[entity.id] && this.flatTreeMap[entity.id].children);
        }
        return entity.children && entity.children.length > 0;
    }
}
