import {Component, Input} from '@angular/core';
import {GeneralService} from 'ac-infra';

@Component({
    selector: 'entities-tree-details',
    templateUrl: './entities-tree-details.component.html',
    styleUrls: ['./entities-tree-details.component.less'],
})
export class EntitiesTreeDetailsComponent {

    @Input() list: any;
    @Input() postfix = '';

    constructor(public generalService: GeneralService) {
    }
}



