import {Component, Input} from '@angular/core';
import {GeneralService} from 'ac-infra';
import {NavigationService} from '../../../../common/utilities/navigation.service';
import {DrillDownFiltersService} from '../../../../common/services/drill-down-filters.service';
import {GroupsActions} from '../../../../network/services/actions/groups-actions.service';
import {WsEntitiesService} from '../../../../common/services/communication/ws-entities.service';
import {FilterState} from "../../../../common/components/ac-filter/services/ac-filter-state.service";
import {CustomerActionsMenuService} from "../../../../network/services/customer-actions-menu.service";

@Component({
    selector: 'entity-details',
    templateUrl: './entity-details.component.html',
    styleUrls: ['./entity-details.component.less'],
})
export class EntityDetailsComponent {

    @Input() entity: any;
    @Input() attachedCustomer: any;
    @Input() hasIcon = true;

    colors = GeneralService.statusColors;
    private gotoDevicePageFilteredByFamilyType: Function;

    constructor(private navigationService: NavigationService,
                private groupsActions: GroupsActions,
                private wsEntitiesService: WsEntitiesService,
                private filterState: FilterState,
                private customerActionsMenuService: CustomerActionsMenuService,
                private drillDownFiltersService: DrillDownFiltersService) {
    }

    ngOnInit() {
        this.gotoDevicePageFilteredByFamilyType = this.drillDownFiltersService.goToDevicePageFilteredByFamilyType();
    }

    goTo() {
        this.filterState.deleteAllFilterStatesFromStorage();

        if (this.entity.type === 'device') {
            this.gotoDevicePageFilteredByFamilyType(this.entity.familyType);
        } else if (this.entity.type === 'endCustomer') {
            if (this.attachedCustomer.customerSource === 'DM' || this.attachedCustomer.customerSource === 'OC') {
                this.navigationService.goTo(this.entity.state);
            } else if(this.attachedCustomer.customerSource === 'MEETING_INSIGHTS'){
                this.customerActionsMenuService.openCustomerServicePage({customer: this.attachedCustomer, page: 'customer', type: 'MEETING_INSIGHTS'})
            }else {
                this.editCustomer();
            }
        } else if (this.entity.type === 'endCustomerAction') {
            this.editCustomer(this.entity.ssoPageName);
        } else if (this.entity.type === 'deviceManager') {
            this.groupsActions.openGeneralDeviceManagerPage();
        } else {
            this.navigationService.goTo(this.entity.state);
        }
    }

    editCustomer(pageName = undefined) {
        if (pageName === 'did' && !this.attachedCustomer) {
            return;
        }

        if (pageName === 'zoomLeads') {
            this.groupsActions.openZoomPage('leads', this.attachedCustomer);
        } else {
            this.groupsActions.editCustomer(this.attachedCustomer, pageName);
        }
    }

    isArray = (count) => {
        return Array.isArray(count);
    };
}
