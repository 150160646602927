import {inject} from '@angular/core';
import {Rest, RestResponseSuccess} from './rest';
import * as _ from 'lodash';
import {NetworkFilterFormatterService} from '../../network/services/network-filter-formatter.service';
import {SessionService} from '../services/session.service';
import {AuthorizationService} from '../services/authorization.service';
import {RequestOptions} from 'ac-infra';
import {WsEntitiesService} from '../services/communication/ws-entities.service';

export class NetworkRest extends Rest {

    protected wsEntitiesService = inject(WsEntitiesService);
    protected sessionService = inject(SessionService);
    private networkFilterFormatter = inject(NetworkFilterFormatterService);

    setURL = (url) => {
        this.url = url;
    };

    getFilteredEntitiesForTable = (requestOptions: RequestOptions, success, failure, entityType?) => {
        const type = entityType || this.entityType;
        const onSuccess = (response) => {
            if (response.status === 200) {
                response.data && response.data[type] && response.data[type].forEach((entity, idx) => {
                    const wsEntity = _.cloneDeep(this.wsEntitiesService.getEntity(type, entity.id));
                    response.data[type][idx] = _.extend(wsEntity, entity);
                });
            }
            success(response);
        };
        this.get({...requestOptions, extendParameters: true}).then(onSuccess).catch(failure);
    };

    getEntitiesWithFilter = (success, failure, parameters) => {
        this.get({parameters, extendParameters: true, skipPopulateFilter: true}).then(success).catch(failure);
    };

    // WS
    getAllEntities = (ids?, forceGlobalScope = false, entityType = undefined) => {
        const entities = this.wsEntitiesService.getEntitiesArray(entityType || this.entityType, ids || null);
        if (forceGlobalScope || AuthorizationService.isGlobalScope()) {
            return entities;
        } else if (this.entityType === 'tenants') {
            return AuthorizationService.tenantScope && [this.getEntityById(AuthorizationService.tenantScope)];
        } else {
            return entities.filter((entity) => entity.tenantId === AuthorizationService.tenantScope);
        }
    };

    getAllEntitiesHashed = () => {
        const entities = this.wsEntitiesService.getEntities(this.entityType);

        return AuthorizationService.isGlobalScope() ? entities : this.getHashedEntitiesByScope(AuthorizationService.tenantScope, entities);
    };

    getHashedEntitiesByScope = (tenantScope, entities) => {
        if (this.entityType === 'tenants') {
            return {[tenantScope]: this.getEntityById(tenantScope)};
        } else {
            const result = {};

            _.forOwn(entities, (value, key) => {
                if (value.tenantId === AuthorizationService.tenantScope) {
                    result[key] = value;
                }
            });

            return result;
        }
    };

    getFilterIds = () => this.wsEntitiesService.getFilteredIds(this.entityType);

    getFilteredEntities = () => {
        const filteredIds = this.wsEntitiesService.getFilteredIds(this.entityType);

        return this.getEntitiesByIds(filteredIds);
    };

    getEntitiesByIds = (listOfIds) => this.wsEntitiesService.getEntitiesArray(this.entityType, listOfIds);

    getEntityById = (entityId) => this.wsEntitiesService.getEntity(this.entityType, entityId);

    getFilteredEntitiesHashed = (ids?) => this.wsEntitiesService.getFilteredEntitiesHashed(this.entityType, ids);

    // Statistics
    getMonitorStatisticsByUnitID = ({success, failure = () => null, ...requestOptions}: RequestOptions) => {
        this.get({
            ...requestOptions,
            url: 'callsMonitor/statistics/entities',
            extendParameters: true,
            addScopeIdFilter: true,
            requestAPI: 'entities',
            useCachedConnection: true
        }).then(success).catch(failure);
    };

    getMonitorStatisticsByFilter = (success, failure, parameters?) => {
        this.get({
            parameters,
            url: 'callsMonitor/statistics/' + this.entityType + 'Entities',
            extendParameters: true,
            addScopeIdFilter: true,
            requestAPI: 'entities',
            useCachedConnection: true
        }).then(success).catch(failure);
    };

    getTopologyStatistics = (success, failure, parameters?, entityType?, filteredEntity?) => {
        this.get({
            parameters,
            url: 'topology/statistics/' + (entityType || this.entityType),
            extendParameters: true,
            filteredEntity,
            requestAPI: 'entities',
            useCachedConnection: true
        }).then(success).catch(failure);
    };

    getEntitiesStatisticsForDynamicTab = (success, failure, parameters) => {
        this.get({
            parameters,
            url: 'callsMonitor/statistics/entities',
            extendParameters: true,
            addScopeIdFilter: true,
            requestAPI: 'entities',
            useCachedConnection: true,
            skipPopulateFilter: true
        }).then(success).catch(failure);
    };

    public getActiveAlarmsSeveritySummaryForNetwork = (success, failure, parameters, isSite) => {//  OVOC-2877
        const onSuccess = (response: RestResponseSuccess) => {
            success(response.status === 204 ? {data: {}} : response);
        };

        this.get({
            parameters,
            url: 'alarms/statistics/active/severitySum',
            filteredEntity: isSite ? 'siteAlarmsSummary' : 'networkAlarmsSummary',
            build204JSON: false
        }).then(onSuccess).catch(failure);
    };

    // LockedURL
    getLockedURL = (uri = undefined) => (ids, requestFiltered, fields?) => {
        const parameters = this.createParams(ids, requestFiltered, fields);
        const query: any = this.buildURLs.restructureParamsObject(parameters);

        if (!query.filter && requestFiltered) {
            return null;
        }

        return this.addFilterToURL(parameters, uri);
    };

    createParams(ids: any, requestFiltered: any, fields?: any, limit?: number) {
        const parameters: any = {detail: 1, limit: limit || 100000};
        if (fields) {
            parameters.fields = fields;
        }
        const timeFilter: any = this.networkFilterFormatter.getTimeFilter(this.networkFilterFormatter.getAggregatedFilter('NetworkFilter'));

        if (timeFilter !== null && this.entityType !== 'channels') {
            parameters.filter = parameters.filter || {};
            parameters.filter = timeFilter;
        }

        if (ids) {
            parameters.filter = parameters.filter || {};
            if (this.entityType === 'groups') {
                parameters.filter.id = ids;
            } else {
                parameters.filter.specificId = ids;
            }
        }

        if (requestFiltered && this.entityType !== 'tenants' && this.entityType !== 'regions') {
            parameters.fields = ['id'];
            this.populateFilterPropertyIfNeeded(parameters, this.entityType);
        }
        return parameters;
    }
}
