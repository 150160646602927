import {Injectable} from '@angular/core';
import {Event, NavigationEnd, Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {GeneralService} from './general.service';

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class AutoRefreshService {

    refreshInterval;
    static systemRefresh$: Observable<any> = new Observable<any>();
    private systemRefresh: Subject<any> = new Subject();
    constructor(private generalService: GeneralService,
                private router: Router) {
        AutoRefreshService.systemRefresh$ = this.systemRefresh.asObservable();

        router.events.pipe(untilDestroyed(this)).subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.start();
            }
        });
    }

    start = () => {
        this.stop();
        this.refreshInterval = setInterval(() => {
            this.systemRefresh.next(null);
        }, 30000);
    };

    stop = () => {
        clearInterval(this.refreshInterval);
    };

}
