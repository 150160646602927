<div class="calls-statistic-panel-container flexRowParent">

    <div class="callsStatisticsBox flexRowParent">


        <div *ngIf="!widgetItem.widgetConfig?.data || widgetItem.widgetConfig?.data['statistics-data'] as configData"
             [class.statistics-for-charts]="configData"
             class="callsStatisticsDataContainer flexColumnParent flexItemBiggest"
        >
            <ng-container *ngIf="statisticFetchedObj else progress">

                <div class="callsStatisticsDataInner flexItem">
                    <div>
                        <div class="lineTitleValue">
                            <span class="statisticsLabel">{{qoeParameters.voice.mos.text}}</span>
                            <span class="statisticsNumber">{{statisticFetchedObj['avgMos']}}</span>
                        </div>

                        <div class="rangeContainer">
                            <span class="pointerContainer">
                                <span class="pointer"
                                      [ngStyle]="{'left': statisticFetchedObj['avgMos'] * 20 + '%'}"
                                ></span>
                            </span>

                            <ac-svg [name]="svgModelName.Range" width="183px" height="46px"></ac-svg>
                        </div>

                        <div *ngFor="let item of callsStatisticNeededList | keyvalue" class="lineTitleValue">
                            <span class="statisticsLabel">{{item.value}}</span>
                            <span class="statisticsNumber">{{statisticFetchedObj[item.key]}}</span>
                        </div>
                    </div>
                </div>
                <div class="total-calls-container">
                    <ac-display-box [upperValue]="statisticFetchedObj.totalCalls"
                                    lowerValue="Total calls"
                                    [showGreyBackground]="true"
                                    upperSize="large"
                                    lowerSize="extra-small"
                                    (acClick)="goTo('/statistics/qoeStatistics/devices', true)"
                    ></ac-display-box>
                </div>
            </ng-container>
            <ng-template #progress>
                <ac-progress size="small"></ac-progress>
            </ng-template>
        </div>

        <div *ngIf="!widgetItem.widgetConfig?.data || widgetItem.widgetConfig?.data['statistics-data'] && widgetItem.widgetConfig?.data['statistics-charts']"
             class="separator flexItem"
        ></div>

        <ng-container *ngIf="!widgetItem.widgetConfig?.data || widgetItem.widgetConfig?.data['statistics-charts']">
            <statistics-summary *ngIf="!statisticObjIsEmpty"
                                class="flexItemBiggest"
                                [fetchFunction]="deviceFetchFunction"
                                [hideStatisticTable]="true"
                                [isSingleLine]="true"
                                [height]="300"
                                [largeTitle]="true"
                                [onClick]="gotoCallPageFilteredByQuality"
                                [refresh$]="refreshDashboardData$"
                                [showInLegend]="true"
                                displayType="DASHBOARD"
            ></statistics-summary>

            <div *ngIf="statisticObjIsEmpty" class="noDataForPieChart">
                No Statistics Available
            </div>
        </ng-container>
    </div>
</div>

