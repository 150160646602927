<ac-form-group *ngIf="treeMode" titleText="Attachments">
    <entities-tree-details [list]="treeDetailsList"></entities-tree-details>

    <ac-drop-down (openStatusChanged)="onTopologyDropDownOpen($event)">

        <a class="ac-dropdown-toggle" parent>View</a>
        <div class="qoe-dropdown" tooltip>
            <topology-tree-select #topologyTreeSelect
                                  (selectEntityChange)="qoeOnCheckFn($event)"
                                  [entitiesNameList]="['tenants', 'regions', 'devices', 'links', 'sites']"
                                  [initialTopologySelection]="checkedItemsObject"
                                  [filterTreeEntitiesCallback]="filterTreeEntitiesCallback"
                                  [ngDisabled]="isMonitor"
                                  [searchBarPadding]="true"
                                  [tenantId]="additionalSettings.tenantId || model.tenantId">
            </topology-tree-select>
        </div>
    </ac-drop-down>
</ac-form-group>

<div *ngIf="!treeMode && (type === 'threshold' || type === 'alarmRule')">
    <ac-input-container [formFieldName]="type + '.attachedLinks'" [label]="linkOrCustomerProvider + ':'">
        <ac-select [(acModel)]="model.attachedLinks"
                   [items]="linksList"
                   bindLabel="name"
                   bindValue="id">
        </ac-select>
    </ac-input-container>

    <ac-input-container [formFieldName]="type + '.attachedDevices'" [label]="deviceOrCustomerTeams + ':'">
        <ac-select [(acModel)]="model.attachedDevices"
                   [items]="devicesList"
                   bindLabel="name"
                   bindValue="id">
        </ac-select>
    </ac-input-container>
</div>


