import {classColorsMap, GeneralService} from 'ac-infra';

export const STATUS_MAP: any = {
    CLM_CONFIG_ERROR: {class: classColorsMap.red, color: GeneralService.statusColors.redStatus, text: 'Config Error'},
    CLM_ERROR: {class: classColorsMap.red, color: GeneralService.statusColors.redStatus, text: 'Error'},
    NOT_CONNECTED: {class: classColorsMap.red, color: GeneralService.statusColors.redStatus, text: 'Not Connected'},
    REJECTED: {class: classColorsMap.red, color: GeneralService.statusColors.redStatus, text: 'Rejected'},
    TEMPORARY_DISCONNECTED: {class: classColorsMap.red, color: GeneralService.statusColors.redStatus, text: 'Temporary Disconnected'},
    FAILURE: {class: classColorsMap.red, color: GeneralService.statusColors.redStatus, text: 'Failed'},
    FAIL: {class: classColorsMap.red, color: GeneralService.statusColors.redStatus, text: 'Failed'},
    NOT_REGISTERED: {class: classColorsMap.red, color: GeneralService.statusColors.redStatus, text: 'Not Registered'},
    CLM_UNMANAGED: {class: classColorsMap.gray, color: GeneralService.statusColors.grayStatus, text: 'Unmanaged'},
    NOT_APPLICABLE: {class: classColorsMap.gray, color: GeneralService.statusColors.grayStatus, text: 'Not Applicable'},
    UNMONITORED: {class: classColorsMap.gray, color: GeneralService.statusColors.grayStatus, text: 'Unmonitored'},
    CLM_OK: {class: classColorsMap.green, color: GeneralService.statusColors.greenStatus, text: 'OK'},
    CONNECTED: {class: classColorsMap.green, color: GeneralService.statusColors.greenStatus, text: 'Connected'},
    SUCCESS: {class: classColorsMap.green, color: GeneralService.statusColors.greenStatus, text: 'Successful'},
    OK: {class: classColorsMap.green, color: GeneralService.statusColors.greenStatus, text: 'OK'},
    OK_OVER_LICENSE: {class: classColorsMap.yellow, color: GeneralService.statusColors.yellowStatus, text: 'Over License'},
    FAIL_OVER_LICENSE: {class: classColorsMap.red, color: GeneralService.statusColors.redStatus, text: 'Failed & Over License'},
    ENABLED: {class: classColorsMap.green, color: GeneralService.statusColors.greenStatus, text: 'Yes'},
    DISABLED: {class: classColorsMap.red, color: GeneralService.statusColors.redStatus, text: 'No'},
    LP_STATUS_EXPIRED: {class: classColorsMap.red, color: GeneralService.statusColors.redStatus, text: 'Expired'},
    LP_STATUS_OK: {class: classColorsMap.green, color: GeneralService.statusColors.greenStatus, text: 'OK'},
    LP_STATUS_FAIL: {class: classColorsMap.red, color: GeneralService.statusColors.redStatus, text: 'Fail'},
    LP_STATUS_UNMANAGED: {class: classColorsMap.gray, color: GeneralService.statusColors.grayStatus, text: 'Unmanaged'},
    LP_STATUS_RESET_NEEDED: {class: classColorsMap.orange, color: GeneralService.statusColors.orangeStatus, text: 'Reset Needed'},
    LP_STATUS_APPLY_NEEDED: {class: classColorsMap.orange, color: GeneralService.statusColors.orangeStatus, text: 'Apply Needed'},
    LP_STATUS_APPLY_INPROGRESS: {class: classColorsMap.orange, color: GeneralService.statusColors.orangeStatus, text: 'Apply in Progress'},
    LP_STATUS_OUT_OF_SYNC: {class: classColorsMap.red, color: GeneralService.statusColors.redStatus, text: 'Out Of Sync'},
    LP_STATUS_CONFIG_ERROR: {class: classColorsMap.red, color: GeneralService.statusColors.redStatus, text: 'Configuration Failed'},
    NOT_DEFINED: {class: classColorsMap.gray, color: GeneralService.statusColors.grayStatus, text: 'Not Requested'},
    MANAGED: {class: classColorsMap.green, color: GeneralService.statusColors.greenStatus, text: 'Managed'},
    UNMANAGED: {class: classColorsMap.gray, color: GeneralService.statusColors.grayStatus, text: 'Unmanaged'},
    NOT_SENDING_SIP_MESSAGES: {class: classColorsMap.red, color: GeneralService.statusColors.redStatus, text: 'Not Sending SIP Messages'},
    SENDING_SIP_MESSAGES: {class: classColorsMap.green, color: GeneralService.statusColors.greenStatus, text: 'Sending SIP Messages'},
    UNDEFINED: {class: classColorsMap.gray, color: GeneralService.statusColors.grayStatus, text: 'Undefined'},
    ERROR: {class: classColorsMap.red, color: GeneralService.statusColors.redStatus, text: 'Error'},
    WARNING: {class: classColorsMap.orange, color: GeneralService.statusColors.orangeStatus, text: 'Warning'},
    CLEAR: {class: classColorsMap.green, color: GeneralService.statusColors.greenStatus, text: 'Clear'},
    MAJOR: {class: classColorsMap.orange, color: GeneralService.statusColors.orangeStatus, text: 'Major'},
    CRITICAL: {class: classColorsMap.red, color: GeneralService.statusColors.redStatus, text: 'Critical'},
    LOCKED: {class: classColorsMap.gray, color: GeneralService.statusColors.grayStatus, text: 'Locked'},
    SHUTTINGDOWN: {class: classColorsMap.orange, color: GeneralService.statusColors.orangeStatus, text: 'Shutting Down'},
    UNLOCKED: {class: classColorsMap.green, color: GeneralService.statusColors.greenStatus, text: 'Unlocked'},
    CLEARED: {class: classColorsMap.green, color: GeneralService.statusColors.greenStatus, text: 'Cleared'},
    INDETERMINATE: {class: classColorsMap.gray, color: GeneralService.statusColors.grayStatus, text: 'Indeterminate'},
    MINOR: {class: classColorsMap.orange, color: GeneralService.statusColors.orangeStatus, text: 'Minor'},
    INITIAL: {class: classColorsMap.gray, color: GeneralService.statusColors.grayStatus, text: 'Initial'}
};

