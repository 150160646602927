<div class="widget-layout-container flexItemBiggest" [class.edit-mode]="editMode">

    <gridster class="widget-layout" *ngIf="widgetItems; else loadingTemplate" [options]="options">
        <gridster-item *ngFor="let widgetItem of widgetItems"
                       class="widget-item-container"
                       [item]="widgetItem.grid">
            <widget-item [editMode]="editMode" [widgetItem]="widgetItem" [templates]="templates"></widget-item>
        </gridster-item>
    </gridster>
</div>

<div class="widget-layout-actions flexItem">
    <ng-content select="[widget-layout-actions]"></ng-content>
</div>


<ng-template #loadingTemplate>
    <ac-progress></ac-progress>
</ng-template>
