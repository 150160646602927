import {inject, TemplateRef, Type} from '@angular/core';
import {GridsterItem} from 'angular-gridster2';
import {AcDropdownChild, SessionStorageService} from 'ac-infra';
import {WidgetItemComponent} from './components/widget-item/widget-item.component';
import {WidgetLayoutComponent} from './components/widget-layout/widget-layout.component';
import {BehaviorSubject} from 'rxjs';
import {cloneDeep, isEqual} from 'lodash';
import {untilDestroyed} from '@ngneat/until-destroy';
import {SaveDashboardItem} from '../../dashboard/dashboard.modals';

export interface WidgetTemplates {
    [key: string]: TemplateRef<any>;
}

export class WidgetConfig<T = any> {
    title?: string;
    data?: T;

    static isEmpty(widgetConfig) {
        return !widgetConfig.title && !widgetConfig.data;
    }
}

export interface WidgetItem<C = any, W = any> {
    grid: GridsterItem;
    type: string;
    widgetConfig?: WidgetConfig<C>;
    widget?: IWidget<W>;
}

export interface IWidget<T = any> {
    title?: string | ((...args) => string);
    class?: string | ((...args) => string);
    component?: Type<any>;
    configDialog?: Type<any>;
    panelActionsComponent?: Type<any>;
    showLoader?: boolean;
    noPadding?: boolean;
    multiple?: boolean;
    dropdownActions?: AcDropdownChild[];

    [key: string]: any;
}

export abstract class Widget {
    private widgetLayoutComponent = inject(WidgetLayoutComponent, {skipSelf: true});
    private widgetItemComponent = inject(WidgetItemComponent, {skipSelf: true});
    widgetItem = this.widgetItemComponent.widgetItem;

    protected constructor() {
        this.widgetItem.widget.dropdownActions = this.widgetLayoutComponent.registerActions?.(this.widgetItem, this);
    }

    get widgetConfig() {
        return this.widgetItem.widgetConfig;
    }


    abstract getWidgetConfigData(...args): any;
}

export interface UpdateWidgetStateArgs {
    updateSaved?: boolean;
    updateCurrent?: boolean;
}

export class WidgetsState {

    protected savedStateWidgetItems: Array<WidgetItem>;
    protected currentWidgetItemsSubject = new BehaviorSubject<WidgetItem[]>(null);
    widgetItems$ = this.currentWidgetItemsSubject.asObservable().pipe(untilDestroyed(this));

    get currentWidgetItemsSnapshot() {
        return cloneDeep(this.currentWidgetItemsSubject.getValue());
    }

    saveItemsState = (sessionKey: string, ItemsToSave) => {
        this.createSavedSnapshotFromCurrent();
        if (!ItemsToSave) {
            SessionStorageService.removeData(sessionKey, true);
        } else {
            SessionStorageService.setData(sessionKey, ItemsToSave);
        }
    };

    restoreSavedItems() {

        const current = this.removeWidgetFromItems(this.currentWidgetItemsSnapshot);
        const saved = this.removeWidgetFromItems(this.savedStateWidgetItems);

        !isEqual(current, saved) && this.updateItems(this.savedStateWidgetItems);
    }

    createSavedSnapshotFromCurrent = () => {
        this.updateItems(this.currentWidgetItemsSnapshot, {updateSaved: true, updateCurrent: false});
    }

    protected updateItems(widgetItems: WidgetItem[], {updateCurrent = true, updateSaved = false}: UpdateWidgetStateArgs = {}) {
        if (updateSaved) {
            this.savedStateWidgetItems = cloneDeep(widgetItems);
        }
        updateCurrent && this.currentWidgetItemsSubject.next(widgetItems);
    };

    protected updateCurrentItems = (dashboardItem: WidgetItem, newItem?: WidgetItem) => {

        const currentDashboardItems = this.currentWidgetItemsSubject.getValue();
        const index = currentDashboardItems.findIndex((currentDashboardItem) => dashboardItem === currentDashboardItem);

        if (newItem) {
            currentDashboardItems.splice(index, 1, newItem);
        } else {
            currentDashboardItems.splice(index, 1);
        }
        this.updateItems(currentDashboardItems);
    };

    protected addWidgets(newWidgetItem: WidgetItem[], updateWidgetState?: UpdateWidgetStateArgs) {
        const widgetItems = this.currentWidgetItemsSubject.getValue() || [];

        this.updateItems([...widgetItems, ...newWidgetItem], {updateSaved: false, ...updateWidgetState});
    }

    protected removeWidgetFromItems = (dashboardItems: WidgetItem[]): SaveDashboardItem[] => {
        return dashboardItems.map(({widget, ...dashboardItem}) => dashboardItem);
    };
}
