import {Rest, RestResponseSuccess} from '../../common/server-actions/rest';
import {RequestOptions} from 'ac-infra';
import {PmFilterFormatter} from './pmFilterFormatter';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class PMRestService extends Rest {

    constructor(public pmFilterFormatter: PmFilterFormatter) {
        super({
            url: 'performanceMeasurements/profiles',
            entityFilterFormatter: pmFilterFormatter, filterEventName: 'PMFilter'
        });
    }

    getDictionaries = (success?, failure?) => {
        return this.get({
            url: 'performanceMeasurements/dictionaries',
            skipPopulateFilter: true,
            extendParameters: true,
            build204JSON: false
        }).then(
            (data: any) => {
                data.data.dictionaries[0].dictionaryTopics.forEach((dict) => {
                    if (dict.displayName.endsWith('(REST)')) {
                        dict.displayName = dict.displayName.replace('(REST)', '(SW 7.4 & above)');
                        dict.rest = true;
                    }
                });
                success && success(data);
                return data;
            }
        ).catch(failure);
    };

    getProfiles = ({success, failure = () => null, ...requestOptions}: RequestOptions) => {
        const onSuccess = (response) => {
            success(this.extractPerformanceMeasurementsProfile(response));
        };

        this.get({
            ...requestOptions,
            skipPopulateFilter: true,
            extendParameters: true,
            build204JSON: false
        }).then(onSuccess).catch(failure);
    };

    getEntities = (success, failure, params) => {
        const parameters = this.getEntitiesFilter(params);

        const onSuccess = (response) => {
            success(this.extractPerformanceMeasurementsEntities(response));
        };

        this.get({
            parameters,
            url: 'performanceMeasurements/dataEntities',
            skipPopulateFilter: true,
            build204JSON: false
        }).then(onSuccess).catch(failure);
    };

    getData = (topic, callingApi) => {
        const parameters = this.buildPMStatisticsParams(topic, callingApi);

        return this.get({
            parameters,
            url: 'performanceMeasurements/data',
            skipPopulateFilter: true,
            build204JSON: false
        })
            .then((response: RestResponseSuccess) => this.extractPerformanceMeasurementsData(response));
    };

    getDataForDynamicTab = (storeKey, tenantId, topic) => {
        const parameters = this.buildPMStatisticsParamsForDynamicTab(storeKey, topic);

        if(!parameters.filter){
            parameters.filter = {};
        }

        return this
            .get({
                parameters,
                url: 'performanceMeasurements/data',
                skipPopulateFilter: true,
                build204JSON: false,
                skipDefaultErrorOnSpecificStatusCodes: [413]
            })
            .then((response: RestResponseSuccess) => {
                return this.extractPerformanceMeasurementsData(response);
            });
    };

    getPMChartsDataForDynamicTab = (success, failure, parameters) => {
        const onSuccess = (response) => {
            success(this.extractPerformanceMeasurementsData(response));
        };

        this.get({
            url: 'performanceMeasurements/data',
            parameters: {filter: parameters, limit: 1000},
            skipPopulateFilter: true,
            build204JSON: false
        }).then(onSuccess).catch(failure);
    };

    getProfileById = (profileId, success?, failure?) => {
        return this.getById({
            success,
            failure,
            id: profileId,
            skipPopulateFilter: true,
            build204JSON: false,
        });
    };

    exportDevicePMData = (success, failure, dataObject) => {
        this.download(success, failure, dataObject, 'performanceMeasurements/actions/exportPmData');
    };

    private buildPMStatisticsParams = (topic, callingApi) => {
        let parameters: any = this.populatePMFilterPropertyIfNeeded(topic, callingApi);

        parameters = Object.assign((parameters || {}), {detail: 1});
        if (parameters && parameters.filter && parameters.filter.limit) {
            parameters.limit = parameters.filter.limit;
            delete parameters.filter.limit;
        }

        return parameters;
    };

    private buildPMStatisticsParamsForDynamicTab = (storeKey, topic) => {
        const filter = this.pmFilterFormatter.getFilterFromState(storeKey, topic);
        return {filter, limit: 10000};
    };

    private populatePMFilterPropertyIfNeeded = (topic, callingApi) => {
        const aggregatedFilter: any = this.pmFilterFormatter.getAggregatedFilter('PMFilter');
        const filter = this.pmFilterFormatter.getPMDataFilter(topic, callingApi, aggregatedFilter);

        return filter ? {filter} : {};
    };

    private getEntitiesFilter = (params) => {
        const filter = this.pmFilterFormatter.formatEntitiesFilter(params);
        return this.addTopicIdToFilter(filter, params);
    };

    private addTopicIdToFilter = (filter, parameters) => {
        const query = filter ? {filter} : {};

        if (parameters && parameters.topicId) {
            query.filter = query.filter || {};
            query.filter.topicId = parameters.topicId;
        }

        return query;
    };

    private extractPerformanceMeasurementsProfile = (response) => response.status === 204 ? {
        data: {
            profiles: [],
            pages: {size: 0, total: 0, current: 0, totalElements: 0}
        }
    } : response;

    private extractPerformanceMeasurementsEntities = (response) => response.status === 204 ? {
        data: {
            topics: [],
            pages: {size: 0, total: 0, current: 0, totalElements: 0}
        }
    } : response;


    private extractPerformanceMeasurementsData = (response) => response.status === 204 ? {data: {pmData: []}} : response;

}
