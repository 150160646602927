import {Component, Input} from '@angular/core';

import * as _ from 'lodash';

class Summary {
    title: Array<Inner>;
    content: Array<Inner>;
}

class Inner {
    text: string;
    value: string;
    color: string;
}

@Component({
    selector: 'summary-values',
    templateUrl: './summary-values.component.html',
    styleUrls: ['./summary-values.component.less'],

})

export class SummaryValuesComponent {
    @Input() summaries: Array<Summary> = [];
    @Input() onClick: Function;
    @Input() isTitleClickable = false;


    innerOnClick = (content?) => {
        if (_.isFunction(this.onClick)) {
            const contentText = content && content.text ?
                content.text.toLowerCase() : null;

            this.onClick(contentText);
        }
    };
}
