import {Injectable} from '@angular/core';

export interface AcDeferredPromise {
    resolve: (...args) => any;
    reject: (...args) => any;
    promise: Promise<any>;
    deferView: any;
    isCanceled?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class PromiseService {

    constructor() {
    }

    static defer(): AcDeferredPromise {


        let resolve;
        let reject;
        let data;
        let resolved = false;

        const promise = new Promise((res, rej) => {
            resolve = res;
            reject = rej;
        });

        const deferView = {
            promise,
            resolved,
            data
        };

        promise.then(value => {
            resolved = deferView.resolved = true;
            data = deferView.data = value;
        });


        return {
            promise,
            resolve,
            reject,
            deferView,
        };
    }
}
