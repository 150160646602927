import {Injectable} from '@angular/core';
import {
    AcToastService,
    SessionStorageService,
    Toast,
    toastStatus,
    ToastType,
    WSMessage
} from 'ac-infra';
import {TasksRestService} from '../../../system/tasks/tasks-rest.service';
import * as _ from 'lodash';
import {throttle} from 'rxjs/operators';
import {interval, Observable, Subject} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {SessionService} from '../session.service';
import {WsEntitiesService} from "../communication/ws-entities.service";

@UntilDestroy()
@Injectable({providedIn: 'root'})
export class WebSocketNotification {

    private WSNotificationArrived_Tasks: Subject<any> = new Subject<any>();
    private clearOldInterval;
    WSNotificationArrived_Tasks$ = this.WSNotificationArrived_Tasks.asObservable();

    WSNotificationArrived: Subject<any> = new Subject<any>();
    WSNotificationArrived$: Observable<any> = this.WSNotificationArrived.asObservable();

    notifications: Toast[];
    tempNotificationsPopupEnabled: boolean;

    saveSubject = new Subject();
    save$;

    public unread: number;
    public mostSevereUnread: toastStatus;
    types = {
        Alarms: ToastType.alarm,
        Events: ToastType.event,
    };

    constructor(private acToastService: AcToastService,
                private tasksRestService: TasksRestService,
                private wsEntitiesService: WsEntitiesService,
                private sessionService: SessionService) {
        this.notifications = SessionStorageService.getData('notifications') || [];


        // *** TEST NOTIFICATIONS *** //

        // *** COLORS AND ICONS *** //

        // const ToastTypes = [ToastType.alarm, ToastType.event, ToastType.failure, ToastType.task, ToastType.success];
        // const toastStatuses = [
        //     toastStatus.critical, toastStatus.cleared, toastStatus.failure,
        //     toastStatus.indeterminate, toastStatus.minor, toastStatus.none,
        //     toastStatus.success, toastStatus.task, toastStatus.warning
        // ];
        //
        // ToastTypes.forEach(type => {
        //    toastStatuses.forEach(status => {
        //        this.notifications.push(new Toast(type + ' ' + status.color, type, status.color, type, status, new WSMessage()));
        //    });
        // });

        // *** OVERHEAT *** //

        // for (let i = 0; i < 100; i++) {
        //     this.notifications.push(new Toast(i, 'asdasd', 'xcvxcv', ToastType.alarm, toastStatus.critical, new WSMessage()));
        // }

        // *** TEST NOTIFICATIONS ENDS *** //

        this.save$ = this.saveSubject.pipe(throttle(() => interval(5000), {
            leading: true,
            trailing: true
        })).subscribe(() => this.doSave());
        this.clearOld();
        this.updateMostSevereUnread();

        this.WSNotificationArrived$.pipe(untilDestroyed(this)).subscribe((wsMessage: WSMessage) => {
            const toastInstance = new Toast();
            toastInstance.originalMessage = wsMessage;

            let shouldShowToast = true;
            if (wsMessage.module === 'Tasks') {
                this.WSNotificationArrived_Tasks.next(wsMessage);
                shouldShowToast = this.Tasks(toastInstance, wsMessage);
            } else {
                this.AlarmsEvents(toastInstance, wsMessage);
            }

            this.wsEntitiesService.updateWSMessageByTenantScope(wsMessage);
            if (wsMessage.entities.length === 0) {
                shouldShowToast = false;
            }

            if (shouldShowToast) {
                if (toastInstance.type === ToastType.failure || toastInstance.type === ToastType.success) {
                    const oldNotification = this.notifications.find((notification: Toast) => (notification.type === ToastType.task && notification.id === toastInstance.id));
                    if (oldNotification) {
                        _.assign(oldNotification, toastInstance);
                        oldNotification.read = false;
                    }
                } else {
                    this.notifications.unshift(_.cloneDeep(toastInstance));
                }

                if (this.tempNotificationsPopupEnabled) {
                    this.acToastService.showById(toastInstance);
                }
            }

            this.updateMostSevereUnread();
            this.save();
        });

        this.clearOldInterval = setInterval(() => {
            this.clearOld();
        }, 10000);
    }

    init = () => {
        const sessionNotificationDuration = SessionStorageService.getData('sessionNotificationDuration');
        if (sessionNotificationDuration === null) {
            if (this.sessionService.activeSession && this.sessionService.activeSession.sessionNotificationDuration === 0) {
                this.tempNotificationsPopupEnabled = null;
            } else {
                this.tempNotificationsPopupEnabled = true;
            }
        } else {
            this.tempNotificationsPopupEnabled = sessionNotificationDuration;
        }

        this.notifications.forEach((notification: Toast) => {
            if (notification.type === ToastType.task) {
                this.tasksRestService.getTaskById(
                    notification.id,
                    (task) => {
                        this.updateTypeAndStatus(task.data, notification);
                        this.save();
                    },
                    (err) => {
                        this.delete(notification);
                    });
            }
            this.updateMostSevereUnread();
        });
    };

    delete(notification) {
        this.notifications.splice(this.notifications.indexOf(notification), 1);
        this.updateMostSevereUnread();
        this.save();
    }

    clearAll() {
        this.acToastService.CloseAll();
        this.notifications = [];
        this.updateMostSevereUnread();
        this.save();
    }

    clearOld() {
        this.notifications = this.notifications.filter((notification: Toast) => notification.originalMessage.arrivalTime - Date.now() + (1000 * 60 * 20) > 0);
        this.updateMostSevereUnread();
    }

    HideAll() {
        this.acToastService.CloseAll();
    }

    Tasks(toastInstance: Toast, wsMessage: WSMessage) {
        const taskName = wsMessage.description || 'Unknown';
        toastInstance.title = taskName;
        toastInstance.content = taskName;
        toastInstance.id = wsMessage.entitiesIds[0];

        return this.updateTypeAndStatus(wsMessage, toastInstance);
    }

    AlarmsEvents(toastInstance: Toast, wsMessage: WSMessage) {
        toastInstance.title = wsMessage.name;
        toastInstance.content = wsMessage.description;
        toastInstance.id = wsMessage.entitiesIds[0];
        toastInstance.type = this.types[wsMessage.module];
        toastInstance.status = toastStatus[wsMessage.status];
    }

    ngOnDestroy() {
        clearInterval(this.clearOldInterval);
        this.save$.unsubscribe();
    }

    save() {
        this.saveSubject.next(null);
    }

    doSave() {
        SessionStorageService.setData('notifications', this.notifications);
    }

    markToastsAsRead(toasts: Array<Toast>) {
        toasts.forEach((toast: Toast) => {
            toast.read = true;
        });
        this.save();
        this.updateMostSevereUnread();
    }

    private updateTypeAndStatus(wsMessage: WSMessage, toastInstance: Toast) {
        if (wsMessage.status.toString() === '202') {
            toastInstance.type = ToastType.task;
            toastInstance.status = toastStatus.task;
            toastInstance.content = toastInstance.title + ' Started';
        } else if (wsMessage.status.toString() === '206') { // Sub-Task Updated.
            return false;
        } else if (wsMessage.status.toString()[0] === '2') {
            toastInstance.type = ToastType.success;
            toastInstance.status = toastStatus.success;
            toastInstance.content = toastInstance.title + ' Succeeded';
        } else if (wsMessage.status.toString() === '410') {
            const task = this.findNotificationByIdAndModule(toastInstance.id, toastInstance.originalMessage.module);
            if (task) {
                this.delete(task);
            }
            return false;
        } else {
            toastInstance.type = ToastType.failure;
            toastInstance.status = toastStatus.failure;
            toastInstance.content = toastInstance.title + ' Failed';
        }
        return true;
    }

    private updateMostSevereUnread() {
        this.unread = this.notifications.reduce((acc, cur) => !cur.read ? ++acc : acc, 0);
        this.mostSevereUnread = this.notifications.reduce(
            (previousValue: toastStatus, currentValue: Toast) => {
                if (!currentValue.read && currentValue.status && (previousValue.severityIdx < currentValue.status.severityIdx)) {
                    return currentValue.status;
                }
                return previousValue;
            }, toastStatus.none
        );
    }

    private findNotificationByIdAndModule(id: string, module: string): Toast {
        return this.notifications.find((notification) => notification.id === id && notification.originalMessage.module === module);
    }
}
