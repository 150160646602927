import {ReportColumns} from './report-columns';

export class ReportMetric extends ReportColumns implements ReportColumns {
    constructor(metric: any, skipIdInit = false) {
        super(metric, skipIdInit);
        this.columnType = 'Metric';
    }

    isLocked = () => false;
}
