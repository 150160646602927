import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Host,
    HostBinding,
    Input,
    Optional,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';

import $ from 'jquery';
import {isNil} from 'lodash';

import {AcFormComponent} from '../ac-form/ac-form.component';
import {AcTabDirective} from '../ac-tabs/ac-tab.directive';
import {MatFormField} from '@angular/material/form-field';
import {GeneralService} from '../../services/general.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ChildFormsErrorComponent} from '../ac-form/forms-has-error/child-forms-error.component';

@UntilDestroy()
@Component({
    selector: 'ac-input-container',
    templateUrl: './ac-input-container.component.html',
    styleUrls: ['./ac-input-container.component.less']
})
export class AcInputContainerComponent {
    @Input() upperPadding = true;
    @Input() displayAsInfo = false;
    @Input() displayAsBasic = false;// without all the error paddings and the upperPaddings
    @Input() displayOnlyError = false;// will show only error if true - field will not be displayed
    @Input() hideLabel = false;
    @Input() isMaterial = true;
    @Input() alwaysShowError = false;
    @Input() labelBold = false;
    @Input() editModePrefix = '';
    @Input() preventLabelClick = false;
    @Input() ignoreRequired = false;
    @Input() fullWidthContent = false;
    @Input() infoIconText;
    @Output() acChanged: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('acInputChildElement', {static: false}) acInputChildElementObj: ElementRef;
    @ViewChild('acFormField', {static: false}) acFormField: ElementRef;
    @Input() preTemplate: TemplateRef<any>;
    @Input() postTemplate: TemplateRef<any>;
    @ViewChild(MatFormField) _matFormField: MatFormField;
    @Input() isValid = false;
    @Input() readOnly = false;
    @Input() required = false;
    @Input() customError;
    @Input() elementType;
    @Output() isValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() sideMargin = true;
    @HostBinding('style.margin') margin;


    matFormFieldControl;
    componentName = '';
    isDisabled = false;
    error = '';
    inputText = '';
    beforeViewInit = true;
    listOfNonMaterialComponents = ['ac-select'];
    showLabel = false;
    isFocused = false;

    private _formFieldName;
    acInputContainerReady = false;
    acInputContainerId = '';

    _label;
    @Input() set label(label) {
        this._label = label;
        this.updateLabel();
    }

    @Input() set formFieldName(formFieldName) {
        this._formFieldName = formFieldName;
        this.updateForm();
    }
    ;
    constructor(@Optional() public formHost: AcFormComponent,
                @Optional() private acTabDirective: AcTabDirective,
                @Optional() private childFormsErrorComponent: ChildFormsErrorComponent,
                public cdRef: ChangeDetectorRef, private generalService: GeneralService) {

    }

    ngOnInit() {
        if (this.sideMargin === false) {
            this.margin = '0';
        }

        if (this.displayAsBasic) {
            this.upperPadding = false;
        }

        if (this._formFieldName) {
            this.acInputContainerId = this.generalService.generateId(this._formFieldName);
        }
    }

    ngAfterViewInit() {
        if(this.formHost?.parentAcFormComponent){
            this.formHost?.parentAcFormComponent?.formValidationFinished$.pipe(untilDestroyed(this)).subscribe((cycle) => {
                this.showError(cycle);
            });
        }

        this.formHost?.formValidationFinished$.pipe(untilDestroyed(this)).subscribe((cycle) => {
            this.showError(cycle);
        });

        this.updateLabel();

        if (this._matFormField && this.matFormFieldControl && this.beforeViewInit) {
            this.initializeMaterialField(this.matFormFieldControl);
        }

        this.updateForm();
    }

    updateLabel = () => {
        const editPrefix = this.formHost?.isEdit && this.editModePrefix ? this.editModePrefix + ' ' : '';
        this.inputText = this.hideLabel || !this._label ? '' : editPrefix + this._label;
    };

    initializeMaterialField = (matFormFieldControl) => {
        this.matFormFieldControl = matFormFieldControl;
        if (this._matFormField && matFormFieldControl) {
            this._matFormField._control = matFormFieldControl;
            this.matFormFieldControl._isInFormField = true;
            this.beforeViewInit = false;
            this.cdRef.detectChanges();
            this._matFormField.ngAfterContentInit();
        }
    };

    showError = (cycle) => {
        if (this.customError) {
            return this.customError;
        }

        this.error = '';

        if (isNil(this.componentName) || this.componentName === '') {
            return;
        }

        if (this.formHost && this.formHost.formValidator && this.formHost.formValidator[this.componentName] && Object.keys(this.formHost.formValidator[this.componentName].errors).length > 0) {
            this.error = this.formHost.formValidator[this.componentName].errors.message;
            this.isValid = false;
        } else {
            this.isValid = true;
        }

        this.acTabDirective?.setError(this.componentName, !!this.formHost.formValidator[this.componentName]?.errors?.message);
        if(cycle){
            this.childFormsErrorComponent?.setError(cycle, this.componentName, !!this.formHost.formValidator[this.componentName]?.errors?.message);
        }
        this.isValidChange.emit(this.isValid);
    }

    preventLabelClickFunc(event) {
        if (this.preventLabelClick) {
            event.preventDefault();
        }
    }

    isRequired = () => {
        return ((this.formHost?.formValidator?.[this.componentName]?.isRequired || this.required) && this.ignoreRequired !== true ? '*' : '');
    };

    private updateForm = () => {
        const children = this.acInputChildElementObj && this.acInputChildElementObj.nativeElement.children;

        if (!children || children.length <= 0) {
            return;
        }

        const alternativeName = $(this.acInputChildElementObj.nativeElement).children().attr('alternativeName') || $(this.acInputChildElementObj.nativeElement).children().children().attr('alternativeName');
        this.componentName = this._formFieldName || alternativeName;

        this.elementType = this.elementType || children[0].nodeName.toLowerCase();

        if (this.formHost) {
            this.registerForm();
        }

        this.cdRef.detectChanges();

        this.acInputContainerReady = true;
    };


    private registerForm = () => {
        this.isDisabled = this.isDisabled || !this.formHost.isAuthorized;

        if (this.formHost.formValidator && this.componentName && this.componentName !== '') {
            this.formHost.formValidator[this.componentName] = this.formHost.formValidator[this.componentName] || {};
            this.formHost.formValidator[this.componentName].type = this.elementType;
        }
    };
}



