import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {formatNumber, TitleCasePipe} from '@angular/common';
import {Duration} from 'luxon';
import * as _ from 'lodash';
import {PageService} from '../services/routing/page.service';
import {AcRangeComponent, AcSvgComponent, AcTableCell, DateTimeFormatterPipe, GeneralService, StringUtils} from 'ac-infra';
import {RemoveUnderscorePipe} from '../pipes/remove-underscore.pipe';
import {Router} from '@angular/router';
import {FilterState} from '../components/ac-filter/services/ac-filter-state.service';
import {IconAndTextTemplateComponent} from '../table-templates/icon-and-text-template/icon-and-text-template.component';
import {BarChartComponent} from '../components/bar-chart/bar-chart.component';
import {IconGroupTemplateComponent, IconsGroup} from '../table-templates/icon-group-template/icon-group-template.component';
import {DomSanitizer} from '@angular/platform-browser';
import {AcTableService, ComponentInputs} from '../../../../projects/infra/src/components/ac-table/ac-table.service';

@Injectable({providedIn: 'root'})
export class CellTemplatesService {

    constructor(@Inject(LOCALE_ID) private locale: string,
                private pageService: PageService,
                private dateTimeFormatterPipe: DateTimeFormatterPipe,
                private generalService: GeneralService,
                private acTableService: AcTableService,
                private titleCasePipe: TitleCasePipe,
                private filterState: FilterState,
                private router: Router,
                private domSanitizer: DomSanitizer,
                private removeUnderscorePipe: RemoveUnderscorePipe) {

    }

    // ONLY TEXT TEMPLATES
    nameTemplateFromService = (
        {
            restService,
            getByIdFunc = 'getEntityById',
            valueName = 'name',
            nullValue = undefined
        }) => (cell) => {
        let text = '';
        const cellValue = cell.getValue();
        const emptyValue = _.isFunction(nullValue) ? nullValue(cellValue) : (nullValue ? nullValue : '');

        if (_.isNil(cellValue)) {
            text = emptyValue;
        } else {
            const cellArray = Array.isArray(cellValue) ? cellValue : [cellValue];
            const result = [];
            cellArray.forEach((value) => {
                const objectFromService = restService[getByIdFunc](value);
                const val = objectFromService && objectFromService[valueName] ? objectFromService[valueName] : emptyValue;
                result.push(this.generalService.HTMLDecode(val));
            });

            text = result.join(', ');
        }

        return this.onlyTextTemplate(text);
    };

    formattedNumberTemplateFn = (additionalText?, listOfIgnoredValues?) => (cell: AcTableCell) => {
        let text = '';
        const cellValue = cell.getValue();
        const formatterNumber = (_.isNil(cellValue) || listOfIgnoredValues?.includes(cellValue)) ? '' : formatNumber(cellValue, this.locale);

        text = (additionalText ? additionalText : '') + formatterNumber;
        return this.onlyTextTemplate(text);
    };

    timeAndDateTemplateFn = () => (cell) => {
        return this.onlyTextTemplate(this.dateTimeFormatterPipe.transform(cell.getValue()));
    };

    timeDurationTemplateFn = () => (cell) => {
        const {hours, minutes, seconds} = Duration.fromObject({seconds: cell.getValue()}).shiftTo('hours', 'minutes', 'seconds');
        const durationStr = this.addParameter(hours, 'h ') + this.addParameter(minutes, 'm ') + this.addParameter(seconds, 's');

        return this.onlyTextTemplate(durationStr);
    };

    // ONLY ICON TEMPLATES
    SVGTemplate = (svgMap: any, titleObj?, iconProperties: ComponentInputs<AcSvgComponent> = {
        width: '18px',
        height: '18px',
    }) => (cell) => {
        iconProperties.updatable = iconProperties.updatable || false;
        return this.onlySVGTemplate({svgMap, titleObj, cell, iconProperties});
    };

    iconAndTextTemplateWithMapper = ({mapper = undefined, componentInputs}) => (cell) => {
        const value = cell.getValue();
        const componentInputsProperties: ComponentInputs<IconAndTextTemplateComponent> = {
            iconTitle: componentInputs.iconTitle || ((mapper && value) ? (mapper[value].viewName || mapper[value]) : ''),
            text: componentInputs.text || ((mapper && value) ? (mapper[value].viewName || mapper[value]) : ''),
            iconName: componentInputs.iconName || ((mapper && value) && mapper[value].name),
            textClass: (componentInputs.textClass || '') + (value || ''),
            titleOfText: componentInputs.titleOfText,
        };

        return this.createIconAndTextTemplate(componentInputsProperties);
    };

    createIconAndTextTemplate = (componentInputsProperties: ComponentInputs<IconAndTextTemplateComponent>) => {
        return this.acTableService.createComponentRef(IconAndTextTemplateComponent, componentInputsProperties);
    };

    booleanTemplateFn = () => (cell) => {
        const booleanValue = this.tryParseString(cell.getValue());
        return this.fontAwesomeIconTemplate(cell, '', booleanValue ? 'check' : 'times');
    };

    statusTemplateFn = (statusMapper) => (cell) => {
        const severityName = this.removeUnderscorePipe.transform(this.titleCasePipe.transform(cell.getValue()));
        const severityValue = statusMapper ? statusMapper[cell.getValue()] : cell.getValue();

        return this.fontAwesomeIconTemplate(cell, severityName, 'circle ' + (severityValue || 'status-color'));
    };

    prefixIconTemplateFn = (compareField) => (cell) => {
        const tooltipPrefixIcon = cell.getRow()[compareField] ? '&#x2691' : '&#x1f514;';
        const tooltip = tooltipPrefixIcon + '&nbsp;' + cell.getValue();

        return this.fontAwesomeIconTemplate(cell, tooltip, cell.getRow()[compareField] ? 'flag' : 'bell', '&nbsp;' + cell.getValue());
    };

    differentNameTemplateFn = (byStringName: string, formatterFunction: Function, ...formatterFunctionArgs) => {
        const originalFormatter = formatterFunction(...formatterFunctionArgs);

        return (cell) => {
            const cellData = StringUtils.byString(cell.getRow(), byStringName);
            cell.getValue = () => cellData;

            return originalFormatter(cell);
        };
    };

    complexDictionaryTemplateFn = (dictionaryMapper, fieldTextName, fieldValueName?) => (cell) => {
        const value = cell.getValue();
        const templateHTML = dictionaryMapper[value]?.[fieldTextName] ||
            (_.isArray(dictionaryMapper) ? dictionaryMapper.filter((item) => item[fieldValueName] === value)?.[0]?.[fieldTextName] : value);

        return this.onlyTextTemplate(templateHTML);
    };

    coloredTextTemplateFn = (colorMap) => (cell) => {
        const coloredTextObject = colorMap[cell.getValue()] || {};

        return this.onlyTextTemplate(coloredTextObject.text, coloredTextObject.textClass);
    };

    strikethroughLineTemplateFn = (criteriaFn, templateFn, templateFnData?: any) => (cell) => {
        const cellTemplateElement = templateFn ? templateFn(templateFnData)(cell) : this.onlyTextTemplate(cell.getValue());

        return '<span class="' + (criteriaFn(cell.getRow()) ? 'strikethrough-line' : '') + '">' + cellTemplateElement + '</span>';
    };

    visualBarTemplateFn = (fieldsMap) => (cell: AcTableCell) => {
        const cellData = cell.getValue();
        const rowData = cell.getRow();

        return this.acTableService.createComponentRef(BarChartComponent, {
            barsInfo: Object.getOwnPropertyNames(cellData || fieldsMap).map(field => {
                return {
                    title: fieldsMap[field].title || fieldsMap[field].displayName,
                    color: fieldsMap[field].color,
                    value: (cellData || rowData)[field]
                };
            })
        });
    };

    iconListTemplateFn = (iconOrderMap: string[], iconsGroup: IconsGroup) => {
        return this.acTableService.createComponentRef(IconGroupTemplateComponent, {
            iconOrder: iconOrderMap,
            icons: iconsGroup || {}
        } as IconGroupTemplateComponent);
    };


    numberRangeTemplateFn = (withBellIcon = false) => (cell) => {

        const enableGenerateAlarm = cell.getValue().enableGenerateAlarm;

        if (!withBellIcon && !enableGenerateAlarm) {
            return '';
        }

        let fairRange = formatNumber(cell.getValue()[withBellIcon ? 'majorThreshold' : 'fairPoorThreshold'], this.locale);
        let goodRange = formatNumber(cell.getValue()[withBellIcon ? 'criticalThreshold' : 'goodFairThreshold'], this.locale);

        if (!withBellIcon) {
            // SWAP a <===> b
            [fairRange, goodRange] = [goodRange, fairRange];
        }

        return this.acTableService.createComponentRef(AcRangeComponent, {
            hasIconPlaceholder: withBellIcon,
            smallTemplate: true,
            fromRange: fairRange,
            toRange: goodRange,
            type: cell.getField(),
            hasIcon: withBellIcon && enableGenerateAlarm,
        });
    };

    stackManagerSerialTemplate = (serialToDeviceIdMap, onSerialLink: (...args) => void) => (cell) => {
        const cellValue = cell.getValue();
        if (!serialToDeviceIdMap[cellValue]) {
            return cellValue;
        }

        return this.acTableService.createComponentRef(IconAndTextTemplateComponent, {
            linkText: cellValue,
            onClickLink: () => onSerialLink(cell)
        });
    };

    newSvgTemplate = (svgName, title = '', iconProperties: ComponentInputs<AcSvgComponent>) => {
        return this.acTableService.createComponentRef(AcSvgComponent, svgName ? {...iconProperties, name: svgName} : {}, {title});
    };

    onlySVGTemplate = (
        {
            svgMap = undefined,
            titleObj = undefined,
            cell = undefined,
            alternativeValue = undefined,
            iconProperties,
        }: { [key: string]: any; iconProperties: ComponentInputs<AcSvgComponent> }
    ) => {

        const value = alternativeValue || this.tryParseString(cell.getValue());
        const title = titleObj ? (titleObj[value].viewName || titleObj[value]) : '';

        const svgName = (svgMap?.[value] || value);
        const componentInputs: ComponentInputs<AcSvgComponent> = svgName ? {...iconProperties, name: svgName} : {};

        return this.acTableService.createComponentRef(AcSvgComponent, componentInputs, {title});
    };

    private onlyTextTemplate = (text, additionalClasses = '') => {
        text = (!_.isNil(text) && text) || '';

        if (additionalClasses && text) {
            return '<span class="' + additionalClasses + '">' + text + '</span>';
        }
        return text;
    };

    private fontAwesomeIconTemplate = (cell, title, iconClasses, additionalText = '') => {
        if (_.isNil(cell.getValue())) {
            return '';
        }
        const icon = '<i class="fa fa-' + iconClasses + '"></i>';
        if (!additionalText) {
            return '<div>' + icon + additionalText + '</div>';
        }

        return '<span>' + icon + additionalText + '</span>';
    };

    private addParameter = (value, unit) => value > 0 ? (value < 10 ? '0' + value : value) + unit : '';

    private tryParseString = (value) => {
        if (value === 'ENABLED' || value === 'true') {
            return true;
        }
        if (value === 'DISABLED' || value === 'false') {
            return false;
        }

        return value;
    };
}
