import {Component, ContentChild, ContentChildren, Input, QueryList, TemplateRef} from '@angular/core';

import {AcLayoutSectionComponent} from './ac-layout-section/ac-layout-section.component';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'ac-layout',
    templateUrl: './ac-layout.component.html',
    styleUrls: ['./ac-layout.component.less', './ac-layout.styles.less'],
    host: {
        class: 'flexColumnParent',
    }
})
export class AcLayoutComponent {

    @Input() behaviour = '';
    @Input() layoutTitle = '';
    @Input() postfixTitle = '';
    @ContentChildren(AcLayoutSectionComponent) sections: QueryList<AcLayoutSectionComponent>;
    @ContentChild('layoutHeader') layoutHeaderTemplate: TemplateRef<any>;

    constructor() {
    }

    ngAfterViewInit() {
        if (this.sections.length === 1) {
            this.sections.first.collapseDir = '';
        }
        if (this.behaviour === 'accordion') {
            this.setAccordionBehaviour();
        }
    }

    setAccordionBehaviour = () => {
        this.sections.forEach((section, index) => {
            section.collapse
                .pipe(untilDestroyed(this))
                .subscribe((isSectionCollapse) => {
                    if (isSectionCollapse === true) {
                        this.accordionBehaviourHandler(index);
                    }
                });

        });
    };

    accordionBehaviourHandler = (emittedSectionIndex: number) => {
        this.sections.forEach((section, index) => {
            if (emittedSectionIndex !== index && section.isSectionCollapsed()) {
                section.toggleCollapseState(false);
            }
        });
    };
}
