import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AcToastComponent} from './ac-toast.component';
import {AcListItemModule} from '../ac-list-item/ac-list-item.module';
import {AcIconsModule} from '../ac-icons/ac-icons.module';

@NgModule({
    declarations: [AcToastComponent],
    imports: [
        CommonModule,
        AcListItemModule,
        AcIconsModule
    ],
    exports: [AcToastComponent]
})
export class AcToastModule {
}
