import {Injector, Pipe, PipeTransform} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Route, Router, Routes} from '@angular/router';
import {ArrayUtil} from '../../../utils/array-util';

@Pipe({
    name: 'filterNavs'
})
export class FilterNavsPipe implements PipeTransform {

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector: Injector) {
    }


    transform(routes: Routes, deep: number, ...args: any[]): any {
        if (!routes) {
            return;
        }

        return ArrayUtil.oneToMany<Route>(routes).filter((route: Route) => {
            const hideNav = (route.path === '**' || route.data && route.data.navAutoHidden);
            const allowed = (route.data && (route.data.autoNavIgnoreGuard || route.data.navAutoHidden)) || !route.canActivate || route.canActivate.every((guard: CanActivate) => {
                const tGuard = this.injector.get(guard);
                return tGuard.canActivate(this.getFromRoot(this.router.routerState.snapshot.root, deep), this.router.routerState.snapshot, route);
            });
            return !hideNav && allowed;
        });
    }

    private getFromRoot(root: ActivatedRouteSnapshot, deep: number): ActivatedRouteSnapshot {
        if (deep < 0) {
            return root;
        } else {
            return root && root.children && this.getFromRoot(root.children[0], --deep);
        }
    }
}
