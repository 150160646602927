import {inject} from '@angular/core';
import {DIALOG_CONFIG, GeneralService} from 'ac-infra';
import {AuthorizationService} from '../../../../common/services/authorization.service';
import {OnyLiveDashboardEntity} from '../entities-summary.modals';
import {ChannelsRestService} from '../../../../network/services/apis/channels-rest.service';
import {TenantsRestService} from '../../../../network/services/apis/tenants-rest.service';
import {GroupsRestService} from '../../../../network/services/apis/groups-rest.service';


export class GroupsDashboardSummary {
    private authorizationService = inject(AuthorizationService);
    private channelsRestService = inject(ChannelsRestService);
    private tenantsRestService = inject(TenantsRestService);
    private groupsRestService = inject(GroupsRestService);
    private dialogConfig = inject(DIALOG_CONFIG, {optional: true});

    model: { [key: string]: OnyLiveDashboardEntity } = {
        customers: {
            key: 'customers',
            state: 'network/customers',
            isReady: false,
            validFor: [
                this.authorizationService.validForChannel,
                this.authorizationService.validForServiceProvider
            ],
            count: 0,
        },
        endCustomer: {
            key: 'customers',
            type: 'endCustomer',
            state: 'network/customers',
            isReady: false,
            validFor: [
                this.authorizationService.validForEndCustomer
            ]
        },
        channels: {
            key: 'channels',
            state: '/network/channels',
            isReady: false,
            validFor: [
                this.authorizationService.validForServiceProvider
            ],
            count: -1,
        },
        users: {
            key: 'users',
            title: 'users count',
            state: '/users/users',
            isReady: false,
            count: 0,
        },
        DIDs: {
            key: 'DIDs',
            type: 'endCustomerAction',
            ssoPageName: 'did',
            title: 'DIDs (total/used/unused)',
            isReady: false,
            count: [],
        },

        userLocation: {
            key: 'userLocation',
            icon: 'user_location',
            title: 'AD User Locations',
            state: 'statistics/qoeStatistics/userLocations',
            isReady: false,
            count: 0,
        },
        zoomQualifyLeads: {
            key: 'zoomQualifyLeads',
            icon: 'ZOOM',
            type: 'endCustomerAction',
            ssoPageName: 'zoomLeads',
            title: 'Qualify Leads (new/active)',
            isReady: false,
            validFor: [
                () => {
                    if (!this.authorizationService.validForServiceProvider()) {
                        return false;
                    }
                    return this.isZoomEnabled();
                },
            ],
            count: [],
        },
        ocNumberManagement: {
            key: 'ocNumberManagement',
            icon: 'TEAMS',
            borderColor: GeneralService.statusColors.teams,
            type: 'endCustomerAction',
            ssoPageName: 'ocNumberManagement',
            title: 'Number Management',
            isReady: false,
            validFor: [
                () => {
                    if (!this.authorizationService.validForServiceProvider()) {
                        return false;
                    }
                    return this.isOperatorConnectEnabled();
                },
            ]
        },
        ocQualifyLeads: {
            key: 'ocQualifyLeads',
            icon: 'TEAMS',
            borderColor: GeneralService.statusColors.teams,
            type: 'endCustomerAction',
            ssoPageName: 'ocQualifyLeads',
            title: 'Qualify Leads (new/active)',
            isReady: false,
            validFor: [
                () => {
                    if (!this.authorizationService.validForServiceProvider()) {
                        return false;
                    }
                    return this.isOperatorConnectEnabled();
                },
            ],
            count: [],
        },
        deviceManagerEndpoints: {
            key: 'deviceManager',
            type: 'deviceManager',
            title: 'DM Endpoints (Disconnected/Total)',
            isReady: true,
            validFor: [
                () => {
                    return this.authorizationService.validForServiceProvider() && this.isDeviceManagerEnabled();
                },
                () => {
                    return this.authorizationService.validForChannel() && this.isChannelDeviceManagerEnabled();
                },
                () => {
                    return this.authorizationService.validForEndCustomer() && this.isCustomerSourceDM();
                },
            ],
            count: [6],
        }
    };

    isZoomEnabled = () => {
        const tenants = this.tenantsRestService.getAllEntities();
        const spTenant = tenants.length === 1 ? tenants[0] : undefined;

        return spTenant?.zoomEnabled || !!this.dialogConfig;
    }

    isOperatorConnectEnabled = () => {
        const tenants = this.tenantsRestService.getAllEntities();
        const spTenant = tenants.length === 1 ? tenants[0] : undefined;

        return spTenant?.operatorConnectEnabled || !!this.dialogConfig;
    }

    isDeviceManagerEnabled = () => {
        const tenants = this.tenantsRestService.getAllEntities();
        const spTenant = tenants.length === 1 ? tenants[0] : undefined;

        return spTenant?.deviceManagerEnabled || !!this.dialogConfig;
    }

    isChannelDeviceManagerEnabled = () => {
        const channels = this.channelsRestService.getAllEntities();
        return (channels.length === 1 ? channels[0]?.deviceManagerEnabled : false) || !!this.dialogConfig;
    }

    isCustomerSourceDM = () => {
        const customers = this.groupsRestService.getAllEntities(undefined, false, 'customers');
        return customers && customers.length === 1 && (customers[0].customerSource === 'DM') || !!this.dialogConfig;
    }
}
