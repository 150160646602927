import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StatusColorPipe} from './status-color.pipe';
import {TaskNamePipe} from './task-name.pipe';
import {RemoveUnderscorePipe} from './remove-underscore.pipe';
import {InitialismPipe} from './initialism.pipe';
import {StatusToClassPipe} from '../services/status-to-class.pipe';
import {FloatingLicenseModePipe} from '../../network/views/devices/floating-license/floating-license-mode.pipe';
import {AuthorizationPipe} from './authorization.pipe';
import {DurationPipe} from './duration.pipe';

const PIPES = [
    TaskNamePipe,
    AuthorizationPipe,
    RemoveUnderscorePipe,
    StatusColorPipe,
    InitialismPipe,
    StatusToClassPipe,
    FloatingLicenseModePipe,
    DurationPipe
];


@NgModule({
    declarations: [...PIPES],
    imports: [
        CommonModule
    ],
    providers: [
        ...PIPES
    ],
    exports: PIPES
})
export class PipesModule {
}
