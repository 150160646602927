import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {GeneralService} from '../../services/general.service';
import {ButtonColor, ButtonType} from "../ac-button/ac-button.component";

const AcActionButtonTypes = ['custom', 'add', 'edit', 'view', 'delete', 'refresh', 'save', 'show', 'actions', 'register', 'restore', 'sync', 'email'];

@Component({
    selector: 'ac-action-button',
    templateUrl: './ac-action-button.component.html',
    styleUrls: ['./ac-action-button.component.less'],
})
export class AcActionButtonComponent {

    @Output() acClick = new EventEmitter<any>();
    @Input() icon;
    @Input() PointerEventDefault = 'auto';
    @Input() args: any;
    @Input() buttonColor: ButtonColor = 'primary';
    @Input() buttonType: ButtonType = 'stroked-button';
    isAuthorizedWasInitialized = false;
    actionType: string;
    actions = {
        custom: {text: '', icon: undefined},
        add: {text: 'Add'},
        edit: {text: 'Edit'},
        view: {text: 'View'},
        delete: {text: 'Delete'},
        refresh: {text: 'Refresh'},
        save: {text: 'Save'},
        show: {text: 'Show'},
        actions: {text: 'Actions'},
        restore: {text: 'Restore'},
        sync: {text: 'Sync'},
        register: {text: 'Register'},
        email: {text: 'Email'}
    };
    isHidden = false;

    @Input() ngDisabled = false;

    _isAuthorized: boolean;
    @Input() set isAuthorized(isAuthorized: any) {
        this.isAuthorizedWasInitialized = true;
        this._isAuthorized = isAuthorized;
        this.updateIsAuthorized();
    }

    _isViewMode: boolean;

    @Input() set isViewMode(isViewMode: any) {
        this._isViewMode = isViewMode;
        this.updateType();
    }

    _type: string;

    @Input() set type(type: string) {
        if (!AcActionButtonTypes.includes(type)) {
            alert('wrong type');
        }

        if(type === 'add'){
           this.buttonColor = "green";
           this.buttonType = "flat-button";
        }

        if(type === 'delete'){
            this.buttonColor = 'red';
        }

        this._type = type;
        this.updateType();
    }

    _text: string;

    @Input() set text(text: string) {
        this._text = text;
        this.updateText();
    }

    constructor(private generalService: GeneralService) {
        if (this._isViewMode === undefined && this.generalService.isViewMode) {
            this._isViewMode = true;
        }
    }

    ngOnInit() {
        this.updateText();

        if (this.icon) {
            this.actions[this._type].icon = this.icon;
        }

        this.updateIsAuthorized();
    }

    private updateType = () => {
        this.actionType = this._type === 'edit' && this._isViewMode ? 'view' : this._type;
        this.updateIsAuthorized();
    };

    private updateText = () => {
        if (this._text) {
            this.actions[this._type].text = this._text;
        }
    };

    private updateIsAuthorized = () => {
        if (this.isAuthorizedWasInitialized) {
            this.isHidden = (this._type === 'edit' ? (!this._isAuthorized && !this._isViewMode) : !this._isAuthorized);
        }
    };
}





