import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {AcToastService, Toast} from './ac-toast.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {GeneralService} from '../../services/general.service';


@Component({
    selector: 'ac-toast',
    templateUrl: './ac-toast.component.html',
    styleUrls: ['./ac-toast.component.less'],
    animations: [
        trigger('toasts', [
            // cubic-bezier for a tiny bouncing feel
            transition(':enter', [
                style({opacity: 0, height: '0'}),
                animate('0.5s ease',
                    style({opacity: 1, height: '*'}))
            ]),
            transition(':leave', [
                style({transform: 'scale(1)', opacity: 1, height: '*'}),
                animate('0.3s ease',
                    style({transform: 'scale(0.7)', opacity: 0, height: '0'}))
            ]),
        ])
    ],
})

export class AcToastComponent {

    @Output() toastMouseUp = new EventEmitter<{ event: MouseEvent; toast: Toast }>();

    @Input() toastName = 'slideToast';
    @Input() toastId = 'slideToastId';
    @Input() limit = 8;

    @Input() topOffsetStyle = '';

    @Input() toastContentTemplate: TemplateRef<any>;
    toasts: Toast[] = [];

    constructor(private acToastService: AcToastService, public generalService: GeneralService) {
    }

    show(toast: Toast, notificationDuration?: number) {
        if (this.toasts.length < this.limit) {
            this.addTimeout(toast, notificationDuration);
            this.toasts.push(toast);
        }
    }

    close = (toast: Toast) => {
        clearTimeout(toast && toast.timeout);
        this.toasts.splice(this.toasts.indexOf(toast), 1);
    };

    closeAll = () => {
        while (this.toasts.length > 0) {
            this.close(this.toasts[0]);
        }
    };

    enter = (toast: Toast) => {
        clearTimeout(toast.timeout);
    };

    leave = (toast: Toast) => {
        this.addTimeout(toast, 5000);
    };

    mouseUp = (event, toast: Toast) => {
        this.toastMouseUp.emit({event, toast});
        if (event.button === 1) {
            this.close(toast);
        }
    };

    addTimeout = (toast: Toast, notificationDuration: number) => {
        clearTimeout(toast.timeout);
        toast.timeout = setTimeout(() => {
            this.close(toast);
        }, notificationDuration || this.acToastService.notificationDuration);
    };

    ngOnDestroy() {
        this.acToastService.unRegister(this);
    }
}



