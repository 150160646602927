import {Pipe, PipeTransform} from '@angular/core';
import {dateTimeFormatter, FormatterType} from '../utils/date-time-formatter';

@Pipe({name: 'dateTimeFormatter'})
export class DateTimeFormatterPipe implements PipeTransform {

    constructor() {
    }

    transform(value: string, format: FormatterType = FormatterType.dateAndTime): string | null {
        if (!value) {
            return;
        }

        return dateTimeFormatter(value, format);
    }
}
