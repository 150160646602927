<div class="ac-logo-container">

        <div class="logo-type-container flexColumnParent" *ngIf="isLogoTypeIncluded">
            <div>{{logoObject.title}}</div>

            <ac-radio-buttons-group [(acModel)]="logoObject[logoTypeName]"
                                    (acModelChange)="logotypeChanged.emit($event);typeChanged()"
                                    [ngDisabled]="ngDisabled"
                                    [items]="logoTypeGroupItems"
                                    class="vertical">
            </ac-radio-buttons-group>
        </div>

        <div *ngIf="!logoIsReady" class="ac-logo-in-progress">
            <ac-progress [center]="false"></ac-progress>
        </div>

        <div *ngIf="logoIsReady" class="logo-box-container">
            <div [class.isLogoTypeIncluded]="isLogoTypeIncluded" class="flexRowParent logo-actions-container">
                <ac-input-container formFieldName="logoObject.logoId" *ngIf="logoIsReady && (!isLogoTypeIncluded || (isLogoTypeIncluded && logoObject[logoTypeName] === 'Custom'))"
                                    [label]="label" class="flexItemBiggest" [sideMargin]="false">
                    <ac-single-select  [acModel]="logoObject.logoId"
                                (acModelChange)="logoObject.logoId = $event;logoIdChanged.emit($event);readImage()"
                                (click)="$event.preventDefault();$event.stopPropagation()"
                                [bindLabel]="'name'"
                                [bindValue]="'id'"
                                [items]="logoObject.files"
                                [class.allowUpload]="allowUpload"
                                [ngDisabled]="ngDisabled">
                    </ac-single-select>
                </ac-input-container>

                <ac-file-picker (acChanged)="onFileChange($event)" *ngIf="allowUpload"
                                [fileTypeAllowed]="['png','jpeg', 'jpg']"
                                [ngDisabledColor]="ngDisabledColor"
                                [ngDisabled]="ngDisabled"
                                [onlyIcon]="true"
                                class="upload-icon flexItem">
                </ac-file-picker>
            </div>

            <div class="image-container">
                <img *ngIf="logoPath" [src]="logoPath"/>
                <div *ngIf="!logoPath" class="empty-logo"></div>
            </div>
        </div>
</div>
