import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';

import * as _ from 'lodash';

import {EncryptionService} from './encryption.service';
import {WebSocketService} from './ws/ws.service';
import {MetadataService} from '../../metadata/metadata.service';
import {ReportRestService} from '../../statistics/services/report-rest.service';
import {ReportEntitiesService} from '../../statistics/reports/services/report-entities.service';
import {ReportActionsService} from '../../statistics/actions/report-actions.service';
import {TenantsRestService} from '../../network/services/apis/tenants-rest.service';
import {RegionsRestService} from '../../network/services/apis/regions-rest.service';
import {ExternalApplicationsRestService} from '../../system/configuration/configuration-api/external-applications-rest.service';
import {DevicesRestService} from '../../network/services/apis/devices-rest.service';
import {LinksRestService} from '../../network/services/apis/links-rest.service';
import {SitesRestService} from '../../network/services/apis/sites-rest.service';
import {ConstantStringsService} from '../utilities/constant-strings.service';
import {AuthGroup, SessionHelperService} from '../utilities/session-helper.service';
import {ExternalApplicationsActionsService} from '../../system/configuration/configuration-actions/external-applications-actions.service';
import {WebSocketNotification} from './ws/ws.notification.service';
import {ServerDownDialogComponent} from '../../dialogs/server-down-dialog/server-down-dialog.component';
import {GroupsRestService} from '../../network/services/apis/groups-rest.service';
import {WorkspaceManagerService} from '../../workspace/workspace-manager.service';

import {LogoDisplayService} from './logo-display.service';
import {LogoSelectionService} from '../components/logo-selection/logo-selection.service';
import {
    AC_PROGRESS_LOADER_PATH,
    AcDialogRef,
    AcDialogService,
    AcTrackerService,
    AutoRefreshService,
    BuildUrlsService,
    GeneralService,
    RestServerUrlService, SessionStorageService,
} from 'ac-infra';
import {BehaviorSubject} from 'rxjs';
import {ChannelsRestService} from '../../network/services/apis/channels-rest.service';
import {ConnectionService} from './communication/connection.service';
import {CachedConnection} from './communication/cached-connection.service';
import {SessionService} from './session.service';
import {ExceptionService} from './errors/exception.service';
import {AuthorizationService} from './authorization.service';
import {DashboardAuthGroupConfigurationsService} from '../../dashboard/services/dashboard-auth-group-configurations.service';
import {TenantScopeService} from './tenant-scope.service';
import {Title} from '@angular/platform-browser';
import {ServerConfigurationActionService} from '../../system/administration/administration-actions/server-configuration-action.service';
import {WsEntitiesService} from './communication/ws-entities.service';
import {ServerInfo, ServerInfoService} from "./server-info.service";
import { FilterState } from '../components/ac-filter/services/ac-filter-state.service';


@Injectable({
    providedIn: 'root'
})
export class InitService {
    MFAInProgress = false;
    private serverDownDialogRef: AcDialogRef;
    private retrying;

    constructor(private buildURLsService: BuildUrlsService,
                private connection: ConnectionService,
                private encryptionService: EncryptionService,
                private webSocketService: WebSocketService,
                private exceptionService: ExceptionService,
                private sessionService: SessionService,
                private reportRestService: ReportRestService,
                private reportEntitiesService: ReportEntitiesService,
                private reportActionsService: ReportActionsService,
                private externalApplicationsActionsService: ExternalApplicationsActionsService,
                private cachedConnection: CachedConnection,
                private router: Router,
                private tenantsRestService: TenantsRestService,
                private tenantScopeService: TenantScopeService,
                private regionsRestService: RegionsRestService,
                private autoRefreshService: AutoRefreshService,
                private webSocketNotification: WebSocketNotification,
                private externalApplicationsRestService: ExternalApplicationsRestService,
                private devicesRestService: DevicesRestService,
                private sitesRestService: SitesRestService,
                private groupsRestService: GroupsRestService,
                private channelsRestService: ChannelsRestService,
                private linksRestService: LinksRestService,
                private generalService: GeneralService,
                private restServerURLService: RestServerUrlService,
                private logoDisplayService: LogoDisplayService,
                private logoSelectionService: LogoSelectionService,
                private wsEntitiesService: WsEntitiesService,
                private acDialogService: AcDialogService,
                private titleService: Title,
                private serverConfigurationActionService: ServerConfigurationActionService,
                private workspaceManagerService: WorkspaceManagerService,
                private acTrackerService: AcTrackerService,
                private authorizationService: AuthorizationService,
                private dashboardAuthGroupConfigurationsService: DashboardAuthGroupConfigurationsService,
                @Inject(AC_PROGRESS_LOADER_PATH) private progressLoaderPath: BehaviorSubject<string>,
    ) {
        this.setLocalizeAcFormErrorObject();
    }

    systemInit = async (toState?, params?) => {
        this.generalService.systemInitialized = false;
        try {
            await ServerInfoService.serverInfoPromise;
            if (!this.generalService.PDFMode) {
                await this.webSocketService.connect();
            }

            this.getMeteringConfiguration();
            this.generalService.finishedLoading('webSocket');
            return this.init();
        } catch (err) {
            if (err !== 'ConnectionExist') {
                const msg = err === 'expired' ? 'Session does not exist anymore' : 'The WebSocket server is down and could not perform the request';
                this.fireException(msg);
            }
            return false;
        }
    };

    updateSystemInfo = () => {
        // const url = this.restServerURLService.getRestServerURL() + 'status/rest';

        const success = (response: ServerInfo) => {
            this.acTrackerService.setAcTrackerIsActive(response.allowIntegrationWithUxApp);
            ServerInfoService.serverInfo = response;
            ServerInfoService.serverInfo.appVersion = response.ovocVersion;
            ServerInfoService.serverInfo.saasMode = response.saasMode;
            this.sessionService.setServerVersion(ServerInfoService.serverInfo.appVersion);

            ServerInfoService.serverInfo.oneLiveMode = response.ovocMode === 'OneLive';
            AuthorizationService.oneLiveMode = ServerInfoService.serverInfo.oneLiveMode;
            SessionHelperService.setSecurityLevels(ServerInfoService.serverInfo.oneLiveMode);

            if (!response.branding) {
                this.progressLoaderPath.next('assets/animations/ovoc-loader.json');
            }

            ServerInfoService.serverInfo.systemType = response.branding || response.whiteLabeling?.name || (ServerInfoService.serverInfo.oneLiveMode ? 'AudioCodes Live' : 'OVOC');

            if (ServerInfoService.serverInfo.branding === 'Nuera') {
                ExternalApplicationsActionsService.EXTERNAL_APPLICATION_OBJ.arm.disabledLink = 'https://www.nuera.com/';
            }

            if (ServerInfoService.serverInfo.oneLiveMode) {
                ConstantStringsService.linkOrCustomerProvider = 'Provider Side';
                ConstantStringsService.deviceOrCustomerTeams = 'Teams Side';
            }

            if (this.retrying) {
                this.acDialogService.closeDialog(this.serverDownDialogRef);
                this.serverDownDialogRef = null;
                this.router.navigateByUrl(this.router.url);
            }
            this.logoDisplayService.updateLogos();
        };

        ServerInfoService.serverInfoPromise = (window as any).__ovoc.restResponse;
        ServerInfoService.serverInfoPromise.then(success).catch(this.serverFailure);
        return ServerInfoService.serverInfoPromise;
    };

    serverFailure = (error) => {
        if (this.serverDownDialogRef) {
            return;
        }
        this.serverDownDialogRef = this.acDialogService.open(ServerDownDialogComponent, {
            onClose: () => {
                clearTimeout(this.retrying);
                this.serverDownDialogRef = null;
                delete this.retrying;
                this.updateSystemInfo();
            },
            noCancelButton: true,
            preventEscape: true
        });

        this.retrying = setTimeout(() => {
            this.updateSystemInfo();
        }, 5000);
    };

    setLocalizeAcFormErrorObject = () => {
        const ovocLocalize = {
            en: require('../ajvErrors/ovoc-errors-en.js')
        };

        this.generalService.setLocalizeErrorsObject(ovocLocalize);
    };

    initLockedSystem = async () => {
        await ServerInfoService.serverInfoPromise;
        this.getMeteringConfiguration();
        this.wsEntitiesService.clearLockedUrls();
        return this.webSocketService.hasActiveSocket() ? true : this.webSocketService.connect();
    }


    private init = async () => {
        try {
            await MetadataService.getMetadataTypes(this.buildURLsService, this.connection);

            this.generalService.finishedLoading('metadata');
            this.generalService.isViewMode = this.authorizationService.isMonitor();


            if (!this.generalService.PDFMode) {
                this.initWSEntities();
                await this.wsEntitiesService.filteredUpdate(undefined, 'entities');
                this.generalService.finishedLoading('entities');

                await this.workspaceManagerService.initWorkspace();
            }

            await Promise.all([
                this.tenantScopeService.initTenantScope(),
                this.dashboardAuthGroupConfigurationsService.initDashboardConfig(),
                this.reportActionsService.initMetrics(),
                this.reportActionsService.initInfoColumns()
            ]);

            if (!this.generalService.PDFMode) {

                await this.wsEntitiesService.filteredUpdate(undefined, 'filteredEntities');
                this.generalService.finishedLoading('filteredEntities');
            }

            if (this.generalService.PDFMode) {
                this.generalService.finishedLoading('entities');
                this.generalService.finishedLoading('filteredEntities');
            } else {
                if (!this.sessionService.activeSession.workspaceLoaded) {
                    this.sessionService.assignToSession({workspaceLoaded: true});
                }
                this.finalizeInit();
            }
            this.generalService.finishedLoading('workspace');
            this.generalService.systemInitialized = true;

            return true;
        } catch (err) {
            this.fireException('Failed to load resources');
            return false;
        }
    };

    private finalizeInit = () => {
        this.externalApplicationsActionsService.init();

        this.autoRefreshService.start();
        this.sessionService.restoreSession();
        this.webSocketNotification.init();
        this.setAlarmsDefaults();
        this.wsEntitiesService.processPendingWSRequests();
        this.workspaceManagerService.initializeAutoSave();

        // this.sessionService.activeSession.mocks = mocks;
        if (this.sessionService.activeSession && this.sessionService.activeSession.mocks) {
            const mockEntities = this.sessionService.activeSession.mocks.entities;
            console.log('%c USING MOCK ENTITIES...', 'color: yellow');
            _.forOwn(mockEntities, (entities, entityType) => {
                this.wsEntitiesService.setData(entityType, entities, 'id');
            });
        }
    };

    private getMeteringConfiguration = () => {
        if (!ServerInfoService.serverInfo?.devMode && ServerInfoService.serverInfo.oneLiveMode && this.authorizationService.validFor(AuthGroup.SYSTEM_ADMIN)) {
            this.serverConfigurationActionService.getMeteringConfiguration();
        }
    };

    private fireException = (message) => {
        this.sessionService.endSession();
        this.exceptionService.reportException({type: 'WSServerException', message});
    };

    private initWSEntities = () => {
        this.cachedConnection.updateCacheOptions({
            timeToLive: 30000
        });

        if (!this.authorizationService.validForMonitorLinkOrOneLiveTenantUserOrGroupUsers()) {
            this.wsEntitiesService.addLockedUrl({
                group: 'regions',
                wsUrlBuilder: this.regionsRestService.getLockedURL()
            });

            this.wsEntitiesService.addLockedUrl({
                group: 'groups',
                wsUrlBuilder: this.groupsRestService.getLockedURL('topology/groups')
            });
        } else {
            this.wsEntitiesService.clearLockedUrls();
        }

        if (ServerInfoService.serverInfo.oneLiveMode) {
            this.wsEntitiesService.addLockedUrl({
                group: 'customers',
                wsUrlBuilder: this.groupsRestService.getLockedURL('topology/customers')
            });

            if (!this.authorizationService.validForEndCustomer()) {
                this.wsEntitiesService.addLockedUrl({
                    group: 'channels',
                    wsUrlBuilder: this.channelsRestService.getLockedURL(),
                });
            }
        }

        this.wsEntitiesService.addLockedUrl({
            group: 'tenants',
            wsUrlBuilder: this.tenantsRestService.getLockedURL()
        });

        this.wsEntitiesService.addLockedUrl({
            group: 'devices',
            wsUrlBuilder: this.devicesRestService.getLockedURL(),
            wsGeoMapFilteredOut: true
        });


        this.wsEntitiesService.addLockedUrl({
            group: 'links',
            wsUrlBuilder: this.linksRestService.getLockedURL(),
            wsGeoMapFilteredOut: true
        });

        this.wsEntitiesService.addLockedUrl({
            group: 'sites',
            wsUrlBuilder: this.sitesRestService.getLockedURL(),
            wsGeoMapFilteredOut: true
        });
    };

    setAlarmsDefaults = () => {
        const filterName = FilterState.getStorageFilterName();
        const filter = SessionStorageService.getData(filterName) || {};

        filter.unpinnedAlarmsFilter = {
            moreFilters_alarms: {
                sources: [],
                alarmNames: [],
                alarmsType: 'Only Alarms'
            },
            ...filter.unpinnedAlarmsFilter
        };

        SessionStorageService.setData(filterName, filter);
    }
}
