import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AcNumSpinnerModule} from '../ac-num-spinner/ac-num-spinner.module';
import {AcIconsModule} from '../ac-icons/ac-icons.module';
import {InfraDirectivesModule} from '../../directives/infra.directives.module';
import {AcRangeComponent} from './ac-range.component';
import {AcRangeInputComponent} from './ac-range-input/ac-range-input.component';
import {AcInputContainerModule} from '../ac-input-container/ac-input-container.module';
import {AcCheckboxModule} from "../ac-checkbox/ac-checkbox.module";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AcNumSpinnerModule,
        AcIconsModule,
        InfraDirectivesModule,
        AcInputContainerModule,
        AcCheckboxModule
    ],
    declarations: [
        AcRangeComponent,
        AcRangeInputComponent
    ],
    entryComponents: [
        AcRangeComponent,
        AcRangeInputComponent
    ],
    exports: [
        AcRangeComponent,
        AcRangeInputComponent
    ]
})
export class AcRangeModule {
}
