<ac-form [auxiliaryModel]="model"
         formModelName="model"
         [ignoreViewMode]="ignoreViewMode"
         [formSettings]="{touched: false, valid: false}"
         [validateAuxiliaryForm]="validateTime" [formName]="formFieldName" #acTimePickerForm>
    <ac-input-container #inputContainer [formFieldName]="formFieldName || 'model.visibleSelectedTime'" [label]="label" [upperPadding]="false">
        <div class="flexRowParent">

            <input matInput [ngModel]="model.visibleSelectedTime" (ngModelChange)="visibleSelectedTimeChanged($event)" [disabled]="!_selectedTime || acTimePickerForm.isViewMode"
            />
            <ngx-material-timepicker-toggle [for]="timePicker">
                <ac-svg class="ac-time-picker-toggle-icon" name="clock" ngxMaterialTimepickerToggleIcon></ac-svg>
            </ngx-material-timepicker-toggle>
            <div class="hidden">
                <input matInput
                       class="ac-time-picker-input"
                       (ngModelChange)="onTimeChange($event)"
                       [ngModel]="_selectedTime | acFunc: formatTime"
                       [ngxTimepicker]="timePicker"
                       [value]="model.visibleSelectedTimeForComponent"
                       [format]="24"
                       [min]="minTime"
                       [disabled]="!_selectedTime || acTimePickerForm.isViewMode"
                       [disableClick]="true"/>

                <ngx-material-timepicker class="ac-time-picker"
                                         [theme]="theme"
                                         [minutesGap]="minutesGap"
                                         [hoursOnly]="hourOnly"
                                         [appendToInput]="true"
                                         #timePicker
                ></ngx-material-timepicker>
            </div>

        </div>
    </ac-input-container>
</ac-form>


