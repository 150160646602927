import {ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Optional, Output, ViewChild} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {DateTime} from 'luxon';
import {DateFormat} from '../../../utils/date-time-formatter';
import {AcDateCalendarComponent} from '../calender/ac-date-calendar/ac-date-calendar.component';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {AcInputContainerComponent} from '../../ac-input-container/ac-input-container.component';
import {MatFormFieldControl} from '@angular/material/form-field';
import {AcDatePickerContainerComponent} from '../ac-date-picker-container/ac-date-picker-container.component';

@UntilDestroy()
@Component({
    selector: 'ac-date-picker',
    templateUrl: './ac-date-picker.component.html',
    styleUrls: ['./ac-date-picker.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcDatePickerComponent {
    @ViewChild(MatFormFieldControl, {static: true}) matFormFieldControl: MatFormFieldControl<any>;
    @ViewChild(AcDatePickerContainerComponent, {static: true}) pickerContainer: AcDatePickerContainerComponent;
    @ViewChild(AcDateCalendarComponent, {static: true}) acDateCalendar: AcDateCalendarComponent;

    @Input() disabled = false;
    @Input() minDate: DateTime;
    @Input() hourOnly = false;
    @Input() date: DateTime;
    @Output() dateChange = new EventEmitter<DateTime>();

    constructor(@Optional() private acInputContainerComponent: AcInputContainerComponent, @Inject(MAT_DATE_FORMATS) formats: any) {
    }

    ngOnInit() {
        this.acInputContainerComponent?.initializeMaterialField(this.matFormFieldControl);
    }

    openPicker() {
        const dropdown = this.pickerContainer.acDropDown;
        if (dropdown.isOpen) {
            return;
        }
        this.acDateCalendar.goToDate();
        this.acDateCalendar.focus();
        dropdown.open();
    }

    formatInput = (date) => {
        return date?.toFormat(DateFormat.dateAndTimeNoSec) || '';
    };

    refreshRef() {
        setTimeout(() => {
            if (!this.date) {
                this.acDateCalendar.setSelectedDate = null;
                return;
            }
            this.date = this.date.set({});
        });
    }

    onApply(date: DateTime) {
        this.date = date;
        this.dateChange.emit(date);
        this.pickerContainer.acDropDown.close();
    }

    onCancel() {
        this.pickerContainer.acDropDown.close();
    };
}

