import {Component, Inject} from '@angular/core';

import {isString, toLower, startCase} from 'lodash';
import {AcDialog, DIALOG_CONFIG, DialogConfig} from '../../ac-dialog.models';

export interface ConfirmDialogData {
    [key: string]: any;

    message?: string;
    options?: any;
    disableModel?: boolean;
    confirmAlternativeText?: string;
}

@AcDialog({
    id: 'confirm-dialog',
    cancelButtonText: 'Close',
    multiInstanceAllowed: false,
})
@Component({
    selector: 'confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.less'],
})
export class ConfirmDialogComponent {

    message: string;
    options: any;
    disableModel = false;

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {}

    ngOnInit() {

        this.dialogConfig.submitButtonText = this.isNotEmptyString(this.dialogConfig.submitButtonText) ? startCase(toLower(this.dialogConfig.submitButtonText)) : 'OK';
        this.dialogConfig.title = this.dialogConfig.title || 'Confirmation';

        this.message = this.isNotEmptyString(this.dialogConfig.dialogData.message) ? this.dialogConfig.dialogData.message : 'Are you sure ?';
        this.options = this.dialogConfig.dialogData.options;

        this.disableModel = this.dialogConfig.dialogData.disableModel;
    }

    isNotEmptyString = (value) => value && isString(value) && value !== '';
}


