import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'removeUnderscore'})
export class RemoveUnderscorePipe implements PipeTransform {

    constructor() {
    }

    transform(value: any): any {
        return value.replace(new RegExp('_', 'g'), ' ');
    }
}
