import {
    Compiler,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {ActivatedRoute, Event, NavigationEnd, RouteConfigLoadEnd, Router} from '@angular/router';
import {ITreeNode} from '@circlon/angular-tree-component/lib/defs/api';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {AcTreeNavigationComponent} from '../ac-tree-navigation/ac-tree-navigation.component';
import {GeneralService} from '../../../services/general.service';
import {AcNavAutoService} from '../../ac-nav/ac-nav-auto/ac-nav-auto.service';
import {debounceTime, filter} from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'ac-tree-router',
    templateUrl: './ac-tree-router.component.html',
    styleUrls: ['../ac-tree-navigation.less'],
})
export class AcTreeRouterComponent implements OnInit, OnDestroy {

    @ViewChild('ACTreeNavigation', {static: true}) ACTreeNavigation: AcTreeNavigationComponent;

    @Input() treeId = 'ac-tree-router';
    @Input() routePath = '';
    @Input() customTemplate;
    @Input() keepOnlyOneOpenAtATime = false;

    @Output() activeChange = new EventEmitter<any>();
    @Output() initialized = new EventEmitter<any[]>();

    activeParents = {};

    routeNodes;
    routePathLevel: number;
    statusColorsList = GeneralService.statusColors;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private acNavAutoService: AcNavAutoService) {
        this.router.events
            .pipe(
                untilDestroyed(this),
                debounceTime(200),
                filter(event => event instanceof RouteConfigLoadEnd)
            )
            .subscribe((event: Event) => this.createTreeRoutes());
    }

    ngOnInit(): void {
        this.createTreeRoutes();
        // route tree events
        this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const idx = event.url.indexOf('?');
                this.ACTreeNavigation.setActive({id: idx === -1 ? event.url : event.url.substring(0, idx)});
            }
        });
    }

    onInitialized($event) {
        const initialId = this.acNavAutoService.navs
            .map((navType) => navType.urlWithParams).join('/');

        this.ACTreeNavigation.setActive({id: initialId});

        this.initialized.next($event);
    }

    onActiveNodes(nodes: any[]) {
        this.activeParents = {};
        const node = nodes[0];
        if (!node) {
            const pathSegments = this.router.url.split('/');
            pathSegments.forEach((path, idx) => {
                this.activeParents[pathSegments.slice(0, idx).join('/')] = true;
            });
            return;
        }
        this.navigateToNode(node);

        this.ACTreeNavigation.setExpand(node, true, !this.keepOnlyOneOpenAtATime);
        this.markActiveParents(node.parent);
        this.activeChange.emit(node.data);
    }

    ngOnDestroy(): void {
    }

    preClick(node) {
        if (this.keepOnlyOneOpenAtATime) {
            this.ACTreeNavigation.setExpand(node, !node.isExpanded, node.isExpanded, !node.isExpanded);
        }
    }

    isParent(node) {
        return node.level === 1 || node.hasChildren;
    }

    private navigateToNode(node: any) {
        const initialId = this.acNavAutoService.navs.map((navType) => navType.urlWithParams).join('/');

        (initialId !== node.id) && this.router.navigateByUrl(node.id);
    }

    private findRootRoutesByPathSegments(pathSegments: string[], routes) {
        if (pathSegments.length === 0) {
            return routes;
        }

        const routePath = pathSegments.shift();

        routes = routes.find(x => x.path === routePath);
        if (routes.loadChildren) {
            routes = (routes._loadedRoutes).find(x => x.path === '');
        }
        if (routes.children) {
            return this.findRootRoutesByPathSegments(pathSegments, routes.children);
        }
    }

    private addIdsToRouteNodes(routes: any[], base: string, nodes: any[]) {
        routes?.forEach((route) => {
            const children = route._loadedRoutes || route.children;
            const newNode = {...route, id: [base, route.path].join('/'), children: []};

            const emptyPath = route.path === '';
            const cachedNodes = emptyPath ? nodes : newNode.children;
            const cachedBase = emptyPath ? base : newNode.id;

            !emptyPath && nodes.push(newNode);
            this.addIdsToRouteNodes(children, cachedBase, cachedNodes);
        });
    }

    private createTreeRoutes() {
        const pathSegments = this.routePath.split('/');

        this.routePathLevel = pathSegments.length - 1;
        this.routeNodes = [];
        const rootRouteNodes = this.findRootRoutesByPathSegments(pathSegments, this.router.config);
        this.addIdsToRouteNodes(rootRouteNodes, this.routePath, this.routeNodes);
    }

    private markActiveParents(node: ITreeNode) {
        if (!node) {
            return;
        }
        this.activeParents[node.id] = true;
        this.markActiveParents(node.parent);
    }
}
