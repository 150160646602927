import {ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {cloneDeep, forOwn} from 'lodash';
import $ from 'jquery';
import {DragulaService} from 'ng2-dragula';
import {AcDragAndDropListComponent} from './ac-drag-and-drop-list/ac-drag-and-drop-list.component';
import {Observable} from 'rxjs';
import {GeneralService} from '../../services/general.service';
import {AcDialogService} from '../ac-dialog/ac-dialog.service';

@UntilDestroy()
@Component({
    selector: 'ac-drag-and-drop',
    templateUrl: './ac-drag-and-drop.component.html',
    styleUrls: ['./ac-drag-and-drop.component.less'],
})
export class AcDragAndDropComponent {

    @Input() listsObject: any;
    @Input() buttonsDisabled = false;
    @Input() isViewOnly = false;
    @Input() displayOrderArrows = false;
    @Input() displaySortableArrows = true;
    @Input() lockPropertyName: string;
    @Input() bindLabel = 'displayName';
    @Input() lockedTypes: {[key: string]: boolean};
    @Input() typesList: any;
    @Input() sortableOrderArray: any;
    @Input() updateColumnSortArray$: Observable<any>;
    @Output() listChanged = new EventEmitter<any>(); // = (...args: any[]) => undefined;
    @Output() sortableOrderArrayChanged = new EventEmitter<any>();
    @Output() onDrop = new EventEmitter<any>();
    @Output() onDropItemOrder = new EventEmitter<string>();
    disableDragging = false;
    isItemsInLeft: boolean;
    isItemsInRight: boolean;

    @ViewChild('secondList', {static: true}) secondList: AcDragAndDropListComponent;

    disableReason = '';
    searchedValue = '';

    hasMultiple = false;
    mirrorContainer;

    private droppedItem: any;
    private sourceIndex: number;
    private targetIndex: number;
    private selectedItemsModel = [];
    private atLeastOneItemIsNotAllowedOnTarget = false;

    private ELInnerHtml;

    constructor(private dragulaService: DragulaService,
                private generalService: GeneralService,
                private acDialogService: AcDialogService,
                private changeDetectorRef: ChangeDetectorRef) {
        this.listChanged.pipe(untilDestroyed(this)).subscribe(this.updateNoItemsInList);
        const ua = window.navigator.userAgent;
        const msie = ua.indexOf('MSIE ');

        this.disableDragging = msie > 0 || !!((/Trident.*rv\:11\./).exec(navigator.userAgent));

        if (this.dragulaService.find('dnd')) {
            this.dragulaService.destroy('dnd');
        }

        this.dragulaService.createGroup('dnd', {
            revertOnSpill: true,
            slideFactorX: 10,
            slideFactorY: 10,
            moves: (el: any, container: any, handle: any): any => !this.disableDragging && !this.isViewOnly,
            accepts: this.handleAccepts
        } as any);
    }

    ngAfterViewInit() {
        this.resetAllItemsToBeUnSelected('left');
        this.resetAllItemsToBeUnSelected('right');
        this.dragulaService.drag('dnd').pipe(untilDestroyed(this))
            .subscribe(({el, source}) => {
                this.handleDragStart(el, source);
            });

        this.dragulaService.cloned('dnd').pipe(untilDestroyed(this))
            .subscribe(({clone, original, cloneType}) => {
                this.handleCloned(original);
            });

        this.dragulaService.shadow('dnd').pipe(untilDestroyed(this))
            .subscribe(({source, container, el}) => {
                this.handleShadow(source, container, el);
            });

        this.dragulaService.dropModel('dnd').pipe(untilDestroyed(this))
            .subscribe(({item, sourceIndex, targetIndex}) => {
                this.droppedItem = item;
                this.sourceIndex = sourceIndex;
                this.targetIndex = targetIndex;
            });

        this.dragulaService.drop('dnd').pipe(untilDestroyed(this))
            .subscribe(({name, sibling, el, target, source}) => {
                this.handleDrop(sibling, el, source, target);
            });

        this.dragulaService.dragend('dnd').pipe(untilDestroyed(this))
            .subscribe(({el}) => {
                if (el) {
                    el.innerHTML = this.ELInnerHtml;
                }
                $('#leftList').css('cursor', 'pointer');
                $('#rightList').css('cursor', 'pointer');
            });

        this.changeDetectorRef.detectChanges();
    }

    handleAccepts = (el, target, source) => {
        if (source.id !== target.id) {
            if (this.atLeastOneItemIsNotAllowedOnTarget) {
                $(target).css('cursor', 'no-drop');
            } else {
                const style = ['cursor: -webkit-grabbing', 'cursor: -moz-grabbing', 'cursor: grabbing'].join(';');
                $(target).attr('style', style);
            }
            return !this.atLeastOneItemIsNotAllowedOnTarget;
        }

        return true;
    };

    getListName = (listId) => listId === 'leftList' ? 'left' : 'right';

    handleDragStart = (el, source) => {
        const sourceListName = this.getListName(source.id);
        const targetListName = sourceListName === 'left' ? 'right' : 'left';

        this.resetAllItemsToBeUnSelected(targetListName);
        el.classList.add('selected');
        // every drag start get the selected items
        this.selectedItemsModel = [];

        const sourceElements = document.getElementsByClassName(source.id);
        this.atLeastOneItemIsNotAllowedOnTarget = false;
        for (let i = 0; i < sourceElements.length; i++) {
            if (sourceElements[i].className.includes('selected')) {
                const selectedItem = cloneDeep(this.listsObject[sourceListName].items[i]);
                selectedItem.itemIndex = i;
                this.selectedItemsModel.push(selectedItem);

                this.checkIfItemIsAllowed(targetListName, selectedItem);
            }
        }

        this.ELInnerHtml = el.innerHTML;
    };

    checkIfItemIsAllowed = (targetListName, selectedItem) => {
        if (this.listsObject[targetListName].allowedTypes && !this.listsObject[targetListName].allowedTypes.includes(selectedItem[this.lockPropertyName])) {
            this.atLeastOneItemIsNotAllowedOnTarget = true;
        }
    };

    handleCloned = (original) => {
        this.mirrorContainer = $('.gu-mirror').first();
        this.mirrorContainer.removeClass('selected');
        this.hasMultiple = this.selectedItemsModel.length > 1 || (this.selectedItemsModel.length === 1 && !$(original).hasClass('selected'));
        if (this.selectedItemsModel && this.selectedItemsModel.length > 0) {
            $('.gu-transit').addClass('selected');
            this.mirrorContainer.empty();
            let height = 0;
            let width = 0;
            const temp = this.mirrorContainer;
            this.selectedItemsModel.forEach((item) => {
                const itemEl = document.createElement('div');
                $(itemEl).addClass('dnd-list-item');
                itemEl.innerHTML = this.generalService.HTMLDecode(item[this.bindLabel]);
                temp.append(itemEl);
                temp.css('background-color', 'transparent');
                const rect = itemEl.getBoundingClientRect();
                height += rect.height;
                width = rect.width;
            });
            this.mirrorContainer = temp;
            this.mirrorContainer.css('height', height + 'px');
        }
    };

    handleShadow = (source, container, el) => {
        if (this.hasMultiple) {
            el.innerHTML = this.selectedItemsModel.length.toString() + ' Selected';
        }

    };

    handleDrop = (sibling, el, source, target) => {
        this.onDrop.emit({sibling, el, source, target});
        const sourceListName = this.getListName(source.id);
        const targetListName = this.getListName(target.id);
        this.dragulaService.find('dnd').drake.cancel(true); // revert changes done by dragula handle DOM only and not model

        if (sourceListName === targetListName) {// drag in the same list
            this.revertModelDraggedItemByDragula(targetListName, targetListName);
            this.moveTo(targetListName, targetListName, this.targetIndex);
        } else {// drag from list to the other list
            this.revertModelDraggedItemByDragula(sourceListName, targetListName);
            this.moveTo(sourceListName, targetListName, this.targetIndex);
        }

        this.resetAllItemsToBeUnSelected(targetListName);

        this.listChanged.emit();
        this.secondList.updateColumnSortArray();
    };

    resetAllItemsToBeUnSelected = (listName) => {
        const listElements = document.getElementsByClassName(listName + 'List');
        this.listsObject[listName].items.forEach((item, index) => {
            item.selected = false;
            listElements[index] && listElements[index].classList.remove('selected');
        });

    };

    removeElementFromList = (list, index) => {
        list.splice(index, 1);
    };

    positionElementsInASpecificLocationInArray = (list, index, elements: any) => list.slice(0, index)
        .concat(elements)
        .concat(list.slice(index));

    revertModelDraggedItemByDragula = (sourceListName, targetListName) => {
        this.removeElementFromList(this.listsObject[targetListName].items, this.targetIndex);
        this.listsObject[sourceListName].items = this.positionElementsInASpecificLocationInArray(
            this.listsObject[sourceListName].items, this.sourceIndex, this.droppedItem);
    };

    moveTo(sourceListName, targetListName, siblingIndex?) {
        const isSortAvailable = this.listsObject[targetListName].enableSortOrder;

        if (this.selectedItemsModel && this.selectedItemsModel.length > 0) {
            for (let i = this.selectedItemsModel.length - 1; i >= 0; i--) {
                if(sourceListName !== targetListName){
                    this.selectedItemsModel[i].selected = false;
                }
                this.removeElementFromList(this.listsObject[sourceListName].items, this.selectedItemsModel[i].itemIndex);
                if (!isSortAvailable && this.selectedItemsModel[i].orderObj) {
                    this.onDropItemOrder.emit(this.selectedItemsModel[i].orderObj);
                    delete this.selectedItemsModel[i].orderObj;
                }
            }

            this.listsObject[targetListName].items = this.positionElementsInASpecificLocationInArray(
                this.listsObject[targetListName].items, siblingIndex, this.selectedItemsModel);
        }
    }

    onRowClick = (inverseListName) => {
        // unSelect all items in the inverse list
        this.resetAllItemsToBeUnSelected(inverseListName);

        this.changeDetectorRef.detectChanges();
    };

    searchEvent = (search) => {
        this.searchedValue = search.toLowerCase();
    };

    setDisableReason = (thereIsOneSelectedItemAtLeast, thereIsOneLockedItemAtLeast) => {
        if (this.buttonsDisabled) {
            this.disableReason = 'Left list is disabled';
        } else if (!thereIsOneSelectedItemAtLeast) {
            this.disableReason = 'No selected items';
        } else if (thereIsOneLockedItemAtLeast) {
            this.disableReason = 'One or more selected items are locked';
        } else {
            this.disableReason = '';
        }
    };

    isElementsSelected = (direction) => {
        let thereIsOneSelectedItemAtLeast = false;
        let thereIsOneLockedItemAtLeast = false;
        for (let i = 0; i < this.listsObject[direction].items.length; i++) {
            if (this.listsObject[direction].items[i].selected) {
                thereIsOneSelectedItemAtLeast = true;

                if (this.lockedTypes?.[this.listsObject[direction].items[i][this.lockPropertyName]]) {
                    thereIsOneLockedItemAtLeast = true;
                }
            }
        }

        this.setDisableReason(thereIsOneSelectedItemAtLeast, thereIsOneLockedItemAtLeast);

        return thereIsOneSelectedItemAtLeast && !thereIsOneLockedItemAtLeast;
    };

    moveSelectedToTheOtherSide = ({direction, isAll = false}) => {
        let skipMovingItemAction = false;

        if (!isAll && this.lockedTypes) {
            forOwn(this.listsObject[direction].items, (item) => {
                if (item.selected && this.lockedTypes?.[item[this.lockPropertyName]]) {
                    skipMovingItemAction = true;
                }
            });
        }

        if (skipMovingItemAction) {
            return;
        }

        const tempArr = [];
        let i = this.listsObject[direction].items.length;
        while (i--) {
            const item = this.listsObject[direction].items[i];
            if (item.selected) {
                item.selected = false;

                const ItemIsLocked = this.lockedTypes?.[item[this.lockPropertyName]];

                if (this.lockedTypes && !ItemIsLocked) {
                    if (item.orderObj) {
                        this.sortableOrderArray.splice(this.sortableOrderArray.indexOf(item.orderObj), 1);
                        this.sortableOrderArrayChanged.emit(this.sortableOrderArray);
                        delete item.orderObj;
                    }
                }

                if(!this.lockedTypes || !ItemIsLocked){
                    tempArr.push(item);
                    this.listsObject[direction].items.splice(i, 1);
                }
            }
        }

        this.mergeArrays(direction, tempArr.reverse());

        this.listChanged.emit();
        this.secondList.updateColumnSortArray();
    };

    mergeArrays = (direction, array = undefined) => {
        if (direction === 'right') {
            this.listsObject.left.items = this.listsObject.left.items.concat(array || this.listsObject.right.items);
        } else {
            this.listsObject.right.items = this.listsObject.right.items.concat(array || this.listsObject.left.items);
        }
    };

    moveAllToTheOtherSide = ({direction}) => {
        this.acDialogService.confirm('Are you sure you want to move all items?',{
            onSubmit: () => {
                forOwn(this.listsObject[direction].items, (item) => {
                    if (this.searchedValue) {
                        item.selected = !!item[this.bindLabel].toLowerCase().includes(this.searchedValue);
                    } else {
                        item.selected = true;
                    }
                });

                this.moveSelectedToTheOtherSide({direction, isAll: true});
            }
        });
    };

    onListChanged = ($event) => {
        this.listsObject.right = $event;
        this.listChanged.emit();
    };

    updateNoItemsInList = () => {
        this.isItemsInLeft = this.listsObject.left.items.some((item) => !this.lockedTypes?.[item[this.lockPropertyName]])
        this.isItemsInRight = this.listsObject.right.items.some((item) => !this.lockedTypes?.[item[this.lockPropertyName]])
    }

    ngOnDestroy() {
        this.dragulaService.destroy('dnd');
    }
}
