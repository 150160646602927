export class PromiseService {

    constructor() {}

    public static defer<T = any>() {
        var resolve, reject;
        var promise = new Promise<T>(function() {
            resolve = arguments[0];
            reject = arguments[1];
        });

        return {
            resolve,
            reject,
            promise
        };
    }
}
