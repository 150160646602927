<topology-tree #topologyTree
               (initialized)="onUpdateData($event)"
               (updateData)="onUpdateData($event)"
               (selectChange)="onSelectChange($event)"
               (searchChange)="onSearchChange($event)"
               [checkbox]="true"
               [class.ac-search-padding]="searchBarPadding"
               [disabled]="ngDisabled"
               [fakeAllSelected]="fakeAllSelected"
               [restoreExpanded]="restoreExpanded"
               [treeNodes]="topologyTreeData"
               [useTreeState]="useTreeState"
               [getChildren]="lazyWS ? getChildren : null"
               class="topology-tree-container"
               searchPlaceHolder="Search by Name, IP or Serial Number"
               treeId="topology-tree">

    <ng-template #nodePrefixIconTemplate let-node let-level="level">
        <ac-svg class="node-icon level-{{level}}"
                [name]="node | acFunc: iconForNode"
                [fillColor]="colors.grayStatus"
        ></ac-svg>
    </ng-template>
</topology-tree>
