import {ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {GridsterConfig} from 'angular-gridster2';
import {AcDropdownChild} from 'ac-infra';
import {Widget, WidgetItem} from '../../widget.modals';
import {WidgetService} from '../../services/widget.service';
import {debounceTime, Subject} from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'widget-layout',
    templateUrl: './widget-layout.component.html',
    styleUrls: ['./widget-layout.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetLayoutComponent {
    itemChangedSubject = new Subject();
    itemChanged$ = this.itemChangedSubject.asObservable().pipe(untilDestroyed(this),debounceTime(200));
    @Output() itemChanged = new EventEmitter();
    templates = {};
    @ContentChild('itemTemplate') set itemTemplate(itemTemplate: TemplateRef<any>) {
        this.templates = {...this.templates, itemTemplate};
    }

    @Input() widgetItems: WidgetItem[];

    editMode = false;
    @Input('editMode') set setEditMode(editMode: boolean) {
        this.editMode = editMode;
        this.setOptions = this.options;
    }
    @Input() options: GridsterConfig = {};
    @Input('options') set setOptions(options: GridsterConfig) {
        this.options = this.widgetService.setEditModeState(this.editMode, {
            ...options,
            itemChangeCallback: () => this.itemChangedSubject.next([]), // [] for different reference every time
        });
    }
    @Input() registerActions: (item: WidgetItem, instance: Widget) => AcDropdownChild[];

    constructor(private widgetService: WidgetService) {

        this.itemChanged$.subscribe((changeRef) => this.itemChanged.emit(changeRef));
    }
}
