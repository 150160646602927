import {ChangeDetectorRef, Component, Inject, InjectionToken, Input} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

export const getProgressLoaderPathFactory = (defaultPath?: string) => {
    return () => new BehaviorSubject(defaultPath || '');
};

export const AC_PROGRESS_LOADER_PATH = new InjectionToken<BehaviorSubject<string>>('ac.progress.loader.path', {
    providedIn: 'root',
    factory: getProgressLoaderPathFactory()
});


@Component({
    selector: 'ac-progress',
    templateUrl: './ac-progress.component.html',
    styleUrls: ['./ac-progress.component.less'],
})
export class AcProgressComponent {
    private static customAnimationPath;

    @Input() set size(size: string) {
        if (this.sizes[size]) {
            this.currentSize = this.sizes[size];
        } else {
            this.currentSize = size;
        }
    }

    @Input() loading = true;
    @Input() noData = false;

    @Input() center = true;
    options: any = {
        path: 'assets/loader.json'
    };

    sizes = {
        small: 24,
        large: 48
    };
    currentSize: any = this.sizes.large;
    animationLoaded = !!AcProgressComponent.customAnimationPath;

    constructor(
        @Inject(AC_PROGRESS_LOADER_PATH) private progressLoaderPath: BehaviorSubject<string>,
        private cdRef: ChangeDetectorRef,
    ) {
        const loaderPath = this.progressLoaderPath.getValue();
        this.options = {path: (loaderPath || this.options.path)};
        if (loaderPath && loaderPath !== AcProgressComponent.customAnimationPath) {
            this.animationLoaded = false;
        }
    }



    onAnimationLoaded() {
        if (this.animationLoaded) {
            return;
        }
        AcProgressComponent.customAnimationPath = this.options.path;
        this.animationLoaded = true;
        this.cdRef.detectChanges();
    }
}
