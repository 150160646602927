<ac-form #reportForm *ngIf="dialogConfig.ready"
         [allowNullProperties]="['entitiesFilter']"
         [auxiliaryModel]="listsObject"
         [formModel]="report"
         [formSchema]="schema"
         [formSettings]="dialogConfig.formSettings"
         [isViewMode]="isViewOnly"
         [isEdit]="isEdit"
         [requiredsAuxiliaryForm]="additionalSettings.reportType !== reportRestService.reportTypes.ELEMENT ? ['report.reportInterval'] : []"
         [validateAuxiliaryForm]="validateAuxiliaryForm"
         [deleteEmptyStringProperties]="['graphs.*.graphName']"
         formModelName="{{modalName}}">
    <div class="report-content" *ngIf="dataIsReady">
        <ac-tabs (onSelect)="onTabSelect($event)" tabsId="report-tab-set">
            <definition-tab *acTab="'Definition'; id:'definition-section-tab'"
                            [additionalSettings]="additionalSettings"
                            [handleColumnsList]="handleColumnsList"
                            [isEdit]="isEdit"
                            [isViewOnly]="isViewOnly"
                            [metrics]="metrics"
                            [reportTypeList]="reportTypeList"
                            [report]="report"
                            [getGroupSubject]="getGroupSubject"
                            [isReady]="dataIsReady"
                            (topTypeListUpdate)="tableLayoutTabEl.topTypeList = $event"
                            (treeScopeChanged)="reportFilterTabEl?.treeScopeChanged()">
            </definition-tab>
            <report-filter-tab *acTab="'Filter'; id:'filter-section-tab'" #reportFilterTab
                               [additionalSettings]="additionalSettings"
                               [handleColumnsList]="handleColumnsList"
                               [isActive]="activeTabId === 'filter-section-tab'"
                               [isEdit]="isEdit"
                               [isViewOnly]="isViewOnly"
                               [getGroup$]="getGroup$"
                               [report]="report"
            ></report-filter-tab>
            <table-layout-tab *acTab="'Table Layout'; id:'table-layout-section-tab'; height:'100%'" #tableLayoutTab
                              [additionalSettings]="additionalSettings"
                              [isEdit]="isEdit"
                              [isViewOnly]="isViewOnly"
                              (listChanged)="listChanged()"
                              [listsObject]="listsObject"
                              [lockedTypes]="lockedTypes"
                              [report]="report"
                              [isReady]="dataIsReady"
                              [executeUpdateColumnSortArray]="executeUpdateColumnSortArray"
                              [handleOrderArray$]="handleOrderArray$"
                              [updateColumnSortArray$]="updateColumnSortArray$"
            ></table-layout-tab>
            <graphs-tab *acTab="'Graphs View'; id:'graphs-section-tab'"
                        [additionalSettings]="additionalSettings"
                        [columnList]="columnList"
                        [isViewOnly]="isViewOnly"
                        [report]="report"
                        [tableColumns]="listsObject">
            </graphs-tab>
        </ac-tabs>
    </div>
</ac-form>
