<div class="splash-container" [class.ready]="ready">
    <div class="splash-content-container ">
        <div class="form-container">
            <div class="form-container-border">
                <div class="splash-content-container-inner form-wrapper">
                    <div *ngIf="pageTitle" class="title gradient-text">{{pageTitle}}</div>
                    <div class="form-and-error-container">
                        <ng-content select="[form]"></ng-content>
                    </div>
                    <div *ngIf="hasErrorField" [class.visibilityHidden]="!errorMessage" class="splash-message">
                        <span>{{errorMessage}}</span>
                    </div>
                </div>


                <div class="splash-content-container-inner companyLogo-container">
                    <ac-svg *ngIf="serverInfoService.serverInfo?.branding === null && logoDisplayService.type !== 'whiteLabel'"
                            class="default-company-logo"
                            name="audiocodes_logo"></ac-svg>
                </div>
            </div>
        </div>
    </div>

    <div class="splash-version">
        Ver. {{serverInfoService.serverInfo?.appVersion}}
    </div>

    <div class="splash-logo-container">
        <ovoc-splash-logo *ngIf="serverInfoService.serverInfo?.systemType==='OVOC' && serverInfoService.serverInfo?.branding === null"></ovoc-splash-logo>
        <other-splash-logo *ngIf="serverInfoService.serverInfo?.systemType!=='OVOC'"></other-splash-logo>
    </div>
</div>
