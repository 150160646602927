<ac-progress *ngIf="!isReady"></ac-progress>

<div class="definition-container" *ngIf="isReady">

    <div class="flexRowParentEqualChildren">
        <ac-input-container formFieldName="report.name" label="Name">
            <ac-textfield [(acModel)]="report.name"
                          textFieldId="report-name">
            </ac-textfield>
        </ac-input-container>

        <ac-input-container formFieldName="report.description" label="Description">
            <ac-textfield [(acModel)]="report.description" textFieldId="report-description">
            </ac-textfield>
        </ac-input-container>
    </div>

    <ac-input-container formFieldName="report.category" label="Category">
        <ac-single-select [sortItems]="true"
                   [(acModel)]="report.category"
                   [addCustomTags]="true"
                   bindLabel="category"
                   bindValue="category"
                   [items]="categoriesList">
        </ac-single-select>
    </ac-input-container>

    <network-tenant-region-selection tenantLabel="Report Scope"
                                     [model]="report"
                                     (acTenantChanged)="report.tenantId = $event;tenantIdChanged()"
                                     [additionalSettings]="additionalSettings"
                                     [tenants]="tenantList"
                                     [sortTenants]="false"
                                     tenantFormFieldName="report.tenantId"
                                     [ngDisabledTenant]="isEdit"
                                     [isEdit]="isEdit"
                                     [ignoreTreeSelection]="true"
                                     [showRegion]="false">
    </network-tenant-region-selection>

    <div class="radio-type-container">
        <div class="report-type-header">Report Type</div>

        <div class="report-type-inputs">
            <ac-radio-buttons-group [(acModel)]="(isEdit ? additionalSettings : report).reportType"
                                    (acModelChange)="reportTypeChanged();handleColumnsList(true)"
                                    [items]="reportTypeList"
                                    [ngDisabled]="isEdit"
                                    class="vertical"
                                    radioButtonId="radio-buttons-report-types">
            </ac-radio-buttons-group>

            <ac-input-container formFieldName="report.trendReportMetricID" *ngIf="additionalSettings.reportType === reportRestService.reportTypes.TREND"
                                label="Choose Metric" class="trend-report-metric-container">
                <ac-single-select [(acModel)]="report.trendReportMetricID"
                           (acModelChange)="trendReportMetricIDChanged()"
                           [bindLabel]="'displayName'"
                           [bindValue]="'columnBaseId'"
                           [items]="metrics"
                           placeholder="Choose Metric">
                </ac-single-select>
            </ac-input-container>
        </div>

    </div>

    <logo-selection (logoIdChanged)="report.reportLogoId = $event"
             (logotypeChanged)="report.reportLogoType = $event"
             [fixedFiles]="customLogo"
             [isLogoTypeIncluded]="true"
             [logoObject]="logoObject"
             [ngDisabled]="isViewOnly"
             [tenantId]="report.tenantId || additionalSettings.tenantId"
             logoTypeName="reportLogoType">
    </logo-selection>

    <ac-toggle text="Privacy" class="privacy-toggle"
               title="Only owner can edit and execute this report"
               [(acModel)]="report.isPrivate"
               toggleId="report-isPrivate">
    </ac-toggle>
</div>
