import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AcDropdownModule} from '../ac-dropdown/ac-dropdown.module';
import {AcDateRangePickerComponent} from './ac-date-range-picker/ac-date-range-picker.component';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {AcInputContainerModule} from '../ac-input-container/ac-input-container.module';
import {MatButtonModule} from '@angular/material/button';
import {AcIconsModule} from '../ac-icons/ac-icons.module';
import {AcButtonModule} from '../ac-button/ac-button.module';
import {MatIconModule} from '@angular/material/icon';
import {AcTimePickerModule} from '../ac-time-picker/ac-time-picker.module';
import {InfraPipesModule} from '../../pipes/infra.pipes.module';

import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {LuxonDateAdapter, MAT_LUXON_DATE_FORMATS} from '@angular/material-luxon-adapter';
import {DateFormat} from '../../utils/date-time-formatter';
import {AcDateCalendarComponent} from './calender/ac-date-calendar/ac-date-calendar.component';
import {AcRangeCalendarComponent} from './calender/ac-range-calendar/ac-range-calendar.component';
import {AcDatePickerHeaderComponent} from './ac-date-picker-header/ac-date-picker-header.component';

import {AcDatePickerContainerComponent} from './ac-date-picker-container/ac-date-picker-container.component';
import {AcDatePickerComponent} from './ac-date-picker/ac-date-picker.component';

const {parse, display} = MAT_LUXON_DATE_FORMATS;

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,

        AcDropdownModule,
        AcButtonModule,
        AcIconsModule,
        AcInputContainerModule,
        AcTimePickerModule,
        InfraPipesModule,

    ],
    providers: [
        {provide: DateAdapter, useClass: LuxonDateAdapter, deps: [MAT_DATE_LOCALE]},
        {
            provide: MAT_DATE_FORMATS,
            useValue: {parse, display: {...display, dateInput: DateFormat.dateAndTimeNoSec}}
        },
    ],
    declarations: [
        AcDatePickerContainerComponent,
        AcDatePickerComponent,
        AcDatePickerHeaderComponent,
        AcDateCalendarComponent,
        AcRangeCalendarComponent,
        AcDateRangePickerComponent,
    ],
    entryComponents: [
    ],
    exports: [
        AcDatePickerComponent,
        AcDateRangePickerComponent,
    ]
})
export class AcDatePickerModule {
}
