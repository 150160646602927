import {Injectable} from '@angular/core';

import * as _ from 'lodash';

import {PMRestService} from '../../statistics/services/pm-rest.service';
import {DevicesRestService} from '../../network/services/apis/devices-rest.service';
import {RegionsRestService} from '../../network/services/apis/regions-rest.service';
import {AlarmsRestService} from '../../alarms/apis/alarms-rest.service';
import {CallsRestService} from '../../calls/apis/calls-rest.service';
import {RestResponseFailure, RestResponseSuccess} from '../../common/server-actions/rest';

@Injectable({providedIn: 'root'})
export class DeviceDetailsDataFetcherService {

    constructor(private performanceMeasurementsService: PMRestService,
                private devicesRestService: DevicesRestService,
                private regionsRestService: RegionsRestService,
                private alarmsRestService: AlarmsRestService,
                private callsRestService: CallsRestService) {

    }

    fetchNetworkDataForDevice = (deviceId, callback) => {
        this.devicesRestService.get(
            {
                parameters: {filter: {specificId: [deviceId]}},
                filteredEntity: 'devicesForDynamicTab',
                ignoreUrlMapping: true,
                extendParameters: true,
                skipPopulateFilter: true,
                skipErrorHandler: true
            }).then((res: RestResponseSuccess) => {

            if (!res.data.devices?.[0]) {
                this.logErrorAndRunCallback({status: 404});
            }
            callback(res.data.devices?.[0]);

        }).catch((err: RestResponseFailure) => {
            this.logErrorAndRunCallback(err, callback);
        });
    };

    fetchStatisticsDataForDevice = (parameters, callback) => {
        const onSuccess = (value: RestResponseSuccess) => {
            callback(value.data);
        };
        const onFailure = (error: RestResponseFailure) => {
            this.logErrorAndRunCallback(error, callback);
        };

        this.devicesRestService.getEntitiesStatisticsForDynamicTab(onSuccess, onFailure, parameters);
    };

    fetchAlarmsDataForDevice = (filters, callback) => {
        const dataObject = {active: false, journal: false};

        const onSuccess = (value, source) => {
            dataObject[source] = value;

            if (dataObject.active && dataObject.journal) {
                callback(dataObject);
            }
        };

        const onFailure = (error, source) => {
            this.logErrorAndRunCallback(error, callback);
        };

        this.getActiveAlarms(onSuccess, onFailure, filters.active);
        this.getJournalAlarms(onSuccess, onFailure, filters.journal);
    };

    fetchCallsDataForDevice = (filters, callback) => {
        const wrappedParameters = {filter: filters, pager: {limit: 1000}};

        const onSuccess = (response: RestResponseSuccess) => {
            callback(response.data.calls);
        };
        const onFailure = (error: RestResponseFailure) => {
            this.logErrorAndRunCallback(error, callback);
        };

        this.callsRestService.getCallsListForDynamicTab(onSuccess, onFailure, wrappedParameters);
    };


    fetchPMDictionariesForDevice = (callback) => {
        const onSuccess = (response: RestResponseSuccess) => {
            callback(response.data.dictionaries);
        };
        const onFailure = (error: RestResponseFailure) => {
            this.logErrorAndRunCallback(error, callback);
        };

        return this.performanceMeasurementsService.getDictionaries(onSuccess, onFailure);
    };

    getDeviceProfile = (profileId, callback) => {
        const onSuccess = (response: RestResponseSuccess) => {
            callback(response.data);
        };
        const onFailure = (error: RestResponseFailure) => {
            this.logErrorAndRunCallback(error, callback);
        };

        return this.performanceMeasurementsService.getProfileById(profileId, onSuccess, onFailure);
    };

    fetchNetworkPMGridDataForDevice = (parameters, storeKey, tenantId, onSuccess, onFailure) => {
        if (_.isEmpty(parameters.deviceSingularPMs)) {
            onSuccess([]);
            return;
        }

        this.fetchDataForTopics(parameters.deviceSingularPMs, storeKey, tenantId, onSuccess, onFailure);
    };

    fetchNetworkDataCharts = (parameters, callback) => {
        const pairsCount = parameters.pairs.length;
        const dataObject = {};

        const onSuccess = (paramId) => (response: RestResponseSuccess) => {
            dataObject[paramId] = response.data;

            if (this.isAllReturned(pairsCount, dataObject)) {
                let mergedDataObject: any = {};

                Object.getOwnPropertyNames(dataObject).forEach((prop, index) => {
                    if (index === 0) {
                        mergedDataObject = _.extend(mergedDataObject, dataObject[prop]);
                    } else {
                        mergedDataObject.pmData.push.apply(mergedDataObject.pmData, dataObject[prop].pmData);
                    }
                });

                callback(mergedDataObject);
            }
        };

        const onFailure = (paramId) => (error) => {
            this.logErrorAndRunCallback(error, callback);
        };

        for (let i = 0; i < pairsCount; i++) {
            this.performanceMeasurementsService.getPMChartsDataForDynamicTab(
                onSuccess(parameters.pairs[i].paramId + '_' + parameters.pairs[i].index),
                onFailure(parameters.pairs[i].paramId + '_' + parameters.pairs[i].index),
                this.buildParametersToCall(parameters, parameters.pairs[i])
            );
        }
    };

    getActiveAlarms = (success, failure, filters) => {
        const wrappedParameters = {filter: filters, pager: {limit: 1000}};

        const onSuccess = (value: RestResponseSuccess) => success(value.data.alarms, 'active');
        const onFailure = (error: RestResponseFailure) => failure(error, 'active');

        this.alarmsRestService.getAlarms('active', {
            success: onSuccess,
            failure: onFailure,
            parameters: wrappedParameters
        });
    };

    getJournalAlarms = (success, failure, filters) => {
        const wrappedParameters = {filter: filters, pager: {limit: 1000}};

        const onSuccess = (value: RestResponseSuccess) => success(value.data.journal, 'journal');
        const onFailure = (error: RestResponseFailure) => failure(error, 'journal');

        this.alarmsRestService.getAlarms('journal', {
            success: onSuccess,
            failure: onFailure,
            parameters: wrappedParameters
        });
    };


    fetchDataForTopics = (topics, storeKey, tenantId, onSuccess, onFailure) => {
        const dataObject = {};

        const promiseArr = [];
        _.forOwn(topics, (topic, topicId) => {
            if (!_.isEmpty(topic.parameters)) {
                const promise = this.performanceMeasurementsService.getDataForDynamicTab(storeKey, tenantId, topic)
                    .then((response: RestResponseSuccess) => {
                        topics[topicId].data = response.data.pmData;
                    });
                promiseArr.push(promise);
            }
        });

        Promise.all(promiseArr).then(() => {
            _.forOwn(topics, (topic, topicId) => {
                if (topic.data) {
                    dataObject[topicId] = topic.data;
                }
            });
        }).catch((error: RestResponseFailure) => {
            onFailure(error);
            this.logErrorAndRunCallback(error, onSuccess);
        }).finally(() => {
            onSuccess(dataObject);
        });
    };

    isAllReturned = (pairsCount, dataObject) => pairsCount === Object.getOwnPropertyNames(dataObject).length;

    buildParametersToCall = (parameters, pair) => {
        const newParameters: any = {
            paramId: pair.paramId,
            deviceId: parameters.deviceId,
            interval: parameters.interval,
            timestamp: parameters.timestamp,
            pmDataType: parameters.pmDataType
        };

        if (pair.index !== undefined && pair.index !== null) {
            newParameters.index = pair.index;
        }

        return newParameters;
    };


    logErrorAndRunCallback = (error, callback?) => {
    };
}
