import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AcSearchComponent} from './ac-search.component';
import {AcIconsModule} from '../ac-icons/ac-icons.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AcIconsModule
    ],
    declarations: [
        AcSearchComponent,
    ],
    entryComponents: [
        AcSearchComponent,
    ],
    exports: [
        AcSearchComponent,
    ]
})
export class AcSearchModule {
}
