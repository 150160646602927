<ac-form-group titleText="Select Time">

    <ac-radio-buttons-group [(acModel)]="report.timeFilter.filterType"
                            class="time-filter-radio-button"
                            (acModelChange)="filterTypeChanged()"
                            [items]="[{text: 'Specific Period', value: 'LAST_X'}, {text: 'Specific Time', value: 'EXPLICIT'}]">
    </ac-radio-buttons-group>

    <div *ngIf="report.timeFilter.filterType === 'LAST_X'" class="last-time-filter-container flexRowParentEqualChildren">
        <ac-input-container formFieldName="report.timeFilter.lastXValue"
                            label="Amount"
                            [sideMargin]="false"
                            class="last-time-filter-input">
            <ac-num-spinner [(acModel)]="report.timeFilter.lastXValue"
                            (click)="report.timeFilter.filterType === 'EXPLICIT' ? filterTypeChanged() : $event.stopPropagation()"
                            [minValue]="1"
                            class="flexItem" numSpinnerId="last-time-filter-number">
            </ac-num-spinner>
        </ac-input-container>

        <ac-input-container formFieldName="report.timeFilter.lastXType" label="Period" [sideMargin]="false">
            <ac-single-select [(acModel)]="report.timeFilter.lastXType"
                              [items]="lastXTypeList"
                              class="flexItemBiggest">
            </ac-single-select>
        </ac-input-container>
    </div>

    <div *ngIf="report?.timeFilter?.filterType === 'EXPLICIT'" class="from-to-time-filter">
        <ac-input-container class="time-range-date-picker no-border" [sideMargin]="false">
            <ac-date-range-picker [dateRange]="report.timeFilter"
                                  (dateRangeChange)="onTimeFilterChange($event)"
            ></ac-date-range-picker>
        </ac-input-container>
    </div>

    <ac-input-container formFieldName="report.reportInterval"
                        *ngIf="additionalSettings.reportType !== reportRestService.reportTypes.ELEMENT"
                        label="Display Interval"
                        [sideMargin]="false">
        <ac-single-select [(acModel)]="report.reportInterval"
                          [items]="reportIntervalList">
        </ac-single-select>
    </ac-input-container>
</ac-form-group>

<ac-form-group class="tree-container" titleText="Topology">

    <ac-input-container formFieldName="report.entityType" label="Report Element Type" [sideMargin]="false">
        <ac-single-select [acModel]="report.entityType || additionalSettings.entityType"
                          (acModelChange)="report.entityType = $event;entityTypeChanged()"
                          [items]="entityTypeList"
                          [ngDisabled]="isEdit">
        </ac-single-select>
    </ac-input-container>

    <ng-container *ngIf="showTreeView">
        <entities-tree-details *ngIf="!authorizedToDoOnlyLinkActions && !additionalSettings.selectAllTreeEntities"
                               [list]="treeDetailsList"
                               postfix="s">
        </entities-tree-details>
        <div *ngIf="additionalSettings.selectAllTreeEntities" class="entities-tree-details-container">All relevant
            entities.
        </div>

        <ac-checkbox *ngIf="!isViewOnly"
                     [sideMargin]="false"
                     [(acModel)]="additionalSettings.selectAllTreeEntities"
                     (acModelChange)="selectAllTreeEntities()"
                     checkboxId="select-all-tree-entities-checkbox"
                     labelText="Select All Entities">
        </ac-checkbox>

        <topology-tree-select #topologyTreeSelect
                              (selectEntityChange)="onTopologyCheck($event)"
                              [entitiesNameList]="entitiesNamesToDisplayOnTopoTree"
                              [excludeArtificialNodes]="true"
                              [fakeAllSelected]="additionalSettings.selectAllTreeEntities"
                              [filterTreeEntitiesCallback]="filterDevicesByProductTypeAndLinksBySameTenantForSrcDeviceAndDstDevice"
                              [hideSearchButton]="true"
                              [initialTopologySelection]="checkedItemsObject"
                              [ngDisabled]="isViewOnly || additionalSettings.selectAllTreeEntities"
                              [restoreExpanded]="true"
                              [tenantId]="report.tenantId || additionalSettings.tenantId"
                              [useTreeState]="false"
        ></topology-tree-select>
    </ng-container>

</ac-form-group>

<ac-input-container *ngIf="!showTreeView && !oneLiveMode" label="Links">
    <ac-select [acModel]="report.entitiesFilter.links || additionalSettings.entitiesFilter.links"
               (acModelChange)="report.entitiesFilter.links = $event;"
               [items]="linksList"
               [ngDisabled]="authorizedForMonitorLink"
               bindLabel="name"
               bindValue="id">
    </ac-select>
</ac-input-container>

<network-groups-selection [type]="serverInfoService.serverInfo.oneLiveMode ? 'CUSTOMER' : (additionalSettings.entityType === 'Endpoint' ? 'ENDPOINT' : 'TOPOLOGY')"
                          [isVisible]="additionalSettings.entityType | acFunc: isGroupVisible"
                          [model]="report.groupsFilter"
                          [items]="groupsList"
                          (acChanged)="report.groupsFilter = $event"
                          formFieldName="report.groupsFilter">
</network-groups-selection>

<ac-input-container formFieldName="report.regExFilter" *ngIf="additionalSettings.entityType | acFunc: needRegex" label="Regex">
    <ac-textfield [(acModel)]="report.regExFilter"
                  textFieldId="report-regExFilter">
    </ac-textfield>
</ac-input-container>





