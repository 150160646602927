import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {InfraComponentsModule} from 'ac-infra';
import {AcSplashScreenComponent} from './ac-splash-screen.component';
import {OtherSplashLogoComponent} from './other/other-splash-logo.component';
import {OvocSplashLogoComponent} from './ovoc/ovoc-splash-logo.component';
import {LogoDisplayModule} from '../brand-logo/logo-display.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        InfraComponentsModule,
        LogoDisplayModule
    ],
    declarations: [
        AcSplashScreenComponent,
        OtherSplashLogoComponent,
        OvocSplashLogoComponent
    ],
    entryComponents: [
        AcSplashScreenComponent,
        OtherSplashLogoComponent,
        OvocSplashLogoComponent
    ],
    exports: [
        AcSplashScreenComponent,
        OtherSplashLogoComponent,
        OvocSplashLogoComponent
    ]
})
export class AcSplashScreenModule {
}

