<ng-container *ngFor="let iconName of iconOrder">
    <div class="group-icon" *ngIf="icons?.[iconName] || !collapse">
        <ac-svg *ngIf="icons?.[iconName] as iconObj"
                [updatable]="false"
                [attr.title]="(iconObj.title || iconObj.name) | titlecase"
                [name]="iconObj.name"
                [fillColor]="iconObj.color || colorByStatusMap[iconObj.status] || colors.primary"
        ></ac-svg>
    </div>
</ng-container>

