import {Component, Inject} from '@angular/core';
import {AcDialog, ArrayUtil, DIALOG_CONFIG, DialogConfig, StringUtils} from 'ac-infra';


@AcDialog({
    id: 'active-alarms-config-dialog',
    title: 'active alarms config',
    width: 320,
})
@Component({
    selector: 'active-alarms-config-dialog',
    template: `
        <widget-dialog-container>
            <ac-checkbox-group checkboxGroupId="active-alarms-config"
                               [parentModel]="checkboxModel"
                               [items]="checkboxItems"
                               [withoutHeader]="true"
                               (itemsChanged)="onChanged($event)"
            ></ac-checkbox-group>
        </widget-dialog-container>
    `,
})
export class ActiveAlarmsConfigDialogComponent {
    checkboxModel;
    checkboxItems;

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {
    }

    ngOnInit() {
        const {data, widgetConfig} = this.dialogConfig.dialogData;

        this.checkboxItems = Object.entries(data).map(([key]) => ({
            name: key,
            text: StringUtils.toTitleCase(key),
            textClass: `severity-${key}`
        }));
        this.checkboxModel = ArrayUtil.arrayToObjTransform(this.checkboxItems, (acc, {name}) => {
            acc[name] = !widgetConfig.data || widgetConfig.data[name];
        });
    }

    onChanged({name, value}: any) {
        this.checkboxModel[name] = value;
        this.dialogConfig.dialogData.widgetConfig.data = !this.isDataDefault(this.checkboxModel) ? this.checkboxModel : undefined;
    }

    isDataDefault = (data) => {
        return Object.values(data).reduce((acc, curr) => acc && curr, true);
    };

}


