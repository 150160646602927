import {Injectable} from '@angular/core';

import {GeneralService, SvgModelName} from 'ac-infra';
import {AuthGroup} from '../utilities/session-helper.service';
import {LogoSelectionService} from '../components/logo-selection/logo-selection.service';
import {SessionService} from './session.service';
import {AuthorizationService} from './authorization.service';
import {TenantsRestService} from '../../network/services/apis/tenants-rest.service';
import {ServerInfoService} from "./server-info.service";

@Injectable({providedIn: 'root'})
export class LogoDisplayService {

    branding;// nuera for example

    tenantLogoUrl;
    whiteLabelLogoUrl;

    logoName;

    hasSession;

    private oneLiveMode;
    private isTenantUserOrMonitorLinkOrGroupUser;
    type: 'internal' | 'branding' | 'login' | 'whiteLabel' | 'tenant' | 'empty';

    constructor(
        private generalService: GeneralService,
        private logoSelectionService: LogoSelectionService,
        private sessionService: SessionService,
        private tenantsRestService: TenantsRestService,
        private authorizationService: AuthorizationService
    ) {
    }

    updateLogos = () => {
        this.hasSession = !!this.sessionService.activeSession;
        this.oneLiveMode = ServerInfoService.serverInfo.oneLiveMode;
        const isTenantUser = this.authorizationService.validFor(AuthGroup.tenant_user);
        const isMonitorLinkOrSPOrChannelOrEndCustomer = this.authorizationService.validForMonitorLinkOrOneLiveTenantUserOrGroupUsers();
        this.isTenantUserOrMonitorLinkOrGroupUser = isMonitorLinkOrSPOrChannelOrEndCustomer || isTenantUser;

        this.branding = ServerInfoService.serverInfo?.branding;
        const whiteLabelLogoId = ServerInfoService.serverInfo?.whiteLabeling?.logoFileId;

        this.logoName = (this.oneLiveMode ? SvgModelName.audiocodesLive : SvgModelName.OvocLogo);

        if (this.sessionService.activeSession?.linkOperatorLogoId) {
            const logoObject = {logoId: this.sessionService.activeSession?.linkOperatorLogoId};
            this.initTenantLogo(logoObject).then(() => {
                this.finalizeLogoDecision();
            });
        } else if(this.sessionService.activeSession?.isSystemOperator && AuthorizationService.tenantScope){
            const tenant = this.tenantsRestService.getEntityById(AuthorizationService.tenantScope);
            if(tenant?.logoId !== -1){
                const logoObject = {logoId: tenant?.logoId};
                this.initTenantLogo(logoObject).then(() => {
                    this.finalizeLogoDecision();
                });
            }else{
                this.tenantLogoUrl = undefined;
                this.finalizeLogoDecision();
            }
        }else if (whiteLabelLogoId && !this.whiteLabelLogoUrl) {
            this.logoSelectionService.getLogoImage(ServerInfoService.serverInfo?.whiteLabeling, 'logoFileId', true).then((url) => {
                this.whiteLabelLogoUrl = url;
                this.finalizeLogoDecision();
            });
        } else {
            this.finalizeLogoDecision();
        }
    };


    finalizeLogoDecision = () => {
        if (this.isTenantUserOrMonitorLinkOrGroupUser && this.tenantLogoUrl) {
            this.type = 'tenant';
        } else if (this.whiteLabelLogoUrl) {
            this.type = 'whiteLabel';
        } else if (this.branding) {
            this.type = 'branding';
        } else if (!this.hasSession) {
            this.type = 'login';
        } else if (!this.isTenantUserOrMonitorLinkOrGroupUser || (!this.oneLiveMode && !this.tenantLogoUrl)) {
            this.type = 'internal';
        } else {
            this.type = 'empty';
        }
    };

    clear() {
        this.tenantLogoUrl = undefined;
    }

    initTenantLogo = (logoObject) => {
        return this.logoSelectionService.getLogoImage(logoObject, 'logoId').then((response) => {
            if (response !== -1) {
                this.tenantLogoUrl = response;
            }
            return response;
        });
    };

}
