import {Injectable} from '@angular/core';
import {Rest, RestResponseSuccess} from '../../common/server-actions/rest';
import {CallsFilterFormatterService} from '../services/calls-filter-formatter.service';
import {RequestOptions} from 'ac-infra';

@Injectable({providedIn: 'root'})
export class CallsRestService extends Rest {

    constructor(callsFilterFormatter: CallsFilterFormatterService) {
        super({
            url: 'callsMonitor/calls',
            entityType: 'callsList',
            entityFilterFormatter: callsFilterFormatter,
            filterEventName: 'QOEFilter'
        });
    }

    public getCallsList = ({
                               success = (res) => res.data,
                               failure = () => null,
                               ...requestOptions
                           }: RequestOptions): Promise<any> => {
        return this.get({
            ...requestOptions,
            extendParameters: true,
            addScopeIdFilter: true,
            filteredEntity: 'callsList'
        }).then(success).catch(failure);
    };

    public getCallById = (callId, success, failure) => {
        this.getById({success, failure, id: callId, skipPopulateFilter: true, requestAPI: 'empty'});
    };

    public getCallDetails = (parameters, {success = (res) => res.data, failure = undefined} = {}): Promise<any> => {
        return this.get({
            url: 'callsMonitor/calls/' + parameters.callId + '/details',
            skipPopulateFilter: true,
            requestAPI: 'empty'
        }).then(success).catch(failure);
    };

    public getCallSipMessages = (parameters, success, failure) => {
        this.get({
            url: 'callsMonitor/calls/' + parameters.callId + '/sipMessages',
            skipPopulateFilter: true,
            requestAPI: 'sipMessages'
        }).then(success).catch(failure);
    };

    public getCallsListForDynamicTab = (success, failure, parameters) => {
        this.get({
            parameters,
            skipPopulateFilter: true,
            addScopeIdFilter: true,
            extendParameters: true
        }).then(success).catch(failure);
    };

    public exportCallsToFile = (success, failure, parameters) => {
        this.get({
            parameters,
            url: 'callsMonitor/exportToFile',
            skipPopulateFilter: true,
            requestAPI: 'exportToFile',
            responseType: 'blob'
        }).then(success).catch(failure);
    };

    // not in use
    public downloadPCAPFile = (success, failure, callId) => {
        this.get({
            url: 'callsMonitor/calls/' + callId + '/sipMessagesFile',
            skipPopulateFilter: true,
            requestAPI: 'empty',
            responseType: 'blob'
        }).then(success).catch(failure);
    };

    protected handleSuccess = (requestAPI, response) => requestAPI === 'empty' ? response : this.extractData(response, requestAPI);

    private extractData = (response, requestAPI) => {
        let emptyObject;
        const defaultObject = {data: {}};

        if (requestAPI === 'sipMessages') {
            emptyObject = {sipMessages: []};
        } else if (requestAPI === 'exportToFile') {
            emptyObject = undefined;
        } else {
            emptyObject = {calls: [], cursor: {before: -1, after: -1}};
        }

        defaultObject.data = emptyObject;

        return response.status === 204 ? defaultObject : response;
    };
}
