import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AcTextareaComponent} from './ac-textarea.component';
import {InfraDirectivesModule} from '../../directives/infra.directives.module';
import {MatInputModule} from '@angular/material/input';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        InfraDirectivesModule,
        MatInputModule
    ],
    declarations: [
        AcTextareaComponent,
    ],
    entryComponents: [
        AcTextareaComponent,
    ],
    exports: [
        AcTextareaComponent,
    ]
})
export class AcTextareaModule {
}
