import {Injectable} from '@angular/core';
import {HashedObjectMap} from 'ac-infra';
import {ReportInfoColumns} from '../../dialogs/report-dialog/models/report-info-column';
import {ReportMetric} from '../../dialogs/report-dialog/models/report-metric';
import {ReportEntity} from '../../dialogs/report-dialog/models/report-entity';
import {Subject} from 'rxjs';
import * as _ from 'lodash';
import {WsEntitiesService} from '../../../common/services/communication/ws-entities.service';

@Injectable({providedIn: 'root'})
export class ReportEntitiesService extends HashedObjectMap {

    static readonly GLOBAL_REPORT_TENANT = -2;

    public categories = [];
    public entityTypeToWsEntityMap = {
        Device: 'devices',
        Link: 'links',
        Site: 'sites'
    };

    reportColumnTypeToInstanceMap = {
        Metric: 'ReportMetric',
        Entity: 'ReportEntity',
        Static: 'ReportInfoColumn',
        Mandatory: 'ReportInfoColumn',
    };
    entitiesWithoutWsSupport = ['User', 'URI', 'Endpoints'];

    sortOrderMap = {
        1: 'FIRST',
        2: 'SECOND',
        3: 'THIRD',
        4: 'QUARTER',
        FIRST: 1,
        SECOND: 2,
        THIRD: 3,
        QUARTER: 4
    };

    private categorySelectionChangedSubject = new Subject<any>();
    categorySelectionChanged$ = this.categorySelectionChangedSubject.asObservable();

    private updateSelectedCategorySubject = new Subject<any>();
    updateSelectedCategory$ = this.updateSelectedCategorySubject.asObservable();

    private reportsListUpdatedSubject = new Subject<any>();
    reportsListUpdated$ = this.reportsListUpdatedSubject.asObservable();

    private updateTopTypeSubject = new Subject<any>();
    updateTopType$ = this.updateTopTypeSubject.asObservable();

    constructor(private wsEntitiesService: WsEntitiesService) {
        super({addEntityType: false});
    }

    executeCategorySelectionChanged = (reportList, sameCategoryNoNeedToForcePage) => {
        this.categorySelectionChangedSubject.next({reportList, sameCategoryNoNeedToForcePage});
    };

    executeUpdateSelectedCategory = ({report, doRefresh}) => {
        this.updateSelectedCategorySubject.next({report, doRefresh});
    };

    executeReportsListUpdated = (reports) => this.reportsListUpdatedSubject.next(reports);


    executeUpdateTopType = () => {
        this.updateTopTypeSubject.next(null);
    };

    getFullColumn = (column, entityType?) => {
        const columnInstanceName = this.reportColumnTypeToInstanceMap[column.columnType];
        if (columnInstanceName === 'ReportEntity') {
            if (!this.entitiesWithoutWsSupport.includes(entityType)) {
                let entityFromWs = this.wsEntitiesService.getEntity(this.entityTypeToWsEntityMap[entityType], column.columnBaseId);
                entityFromWs.columnBaseId = column.columnBaseId || column.id;
                entityFromWs.columnType = column.columnType;
                entityFromWs.order = column.order;
                entityFromWs.sortType = column.sortType;
                entityFromWs.sortOrder = column.sortOrder;
                entityFromWs.displayName = entityFromWs.name;

                entityFromWs = this.reviveObject(entityFromWs);
                entityFromWs.generateId();

                return entityFromWs;
            } else {
                return column;
            }
        }

        const entity = _.cloneDeep(this.getEntity(columnInstanceName, column.columnBaseId));
        this.reviveObject(entity);

        return entity;
    };

    reviveObject = (item) => {
        switch (item.columnType) {
            case 'Metric':
                return new ReportMetric(item, true);
            case 'Entity':
                return new ReportEntity(item, true);
            case 'Static':
            case 'Mandatory':
                return new ReportInfoColumns(item, true);
        }
    };

}
