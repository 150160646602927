<mat-calendar class="calendar"
              [headerComponent]="acDatePickerHeader"
              [minDate]="minDate"
              [selected]="selectedDate"
              (selectedChange)="onSelect($event)"
              #calendar>
</mat-calendar>

<div class="calendar-footer flexColumnParent">
    <div class="ac-calendar-time-picker flexRowParent">

            <ac-time-picker [minTime]="selectedDate | acFunc: getMinDateTime"
                            [label]="label"
                            [hourOnly]="hourOnly"
                            [selectedTime]="timeState"
                            (selectedTimeChanged)="onTimeChange($event)"
            ></ac-time-picker>
    </div>

    <div class="ac-calendar-actions flexRowParent">
        <ac-button class="clear" (click)="cancel.emit()">Cancel</ac-button>
        <ac-button (click)="apply.emit(selectedDate)">Apply</ac-button>
    </div>
</div>

