import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'routeBuilder'
})
export class RouteBuilderPipe implements PipeTransform {

    transform(value: any, leading?: any, trailing?: any, ...args): any {
        let paths = [
            leading === true && '',
            value,
            leading !== true && leading !== false ? leading : undefined,
            trailing !== true ? trailing : undefined,
            ...args,
            trailing === true ? '' : undefined];
        paths = paths.filter((x) => x !== undefined);
        return paths.join('/');
    }

}
