import {Pipe, PipeTransform} from '@angular/core';

import {GeneralService} from 'ac-infra';

@Pipe({name: 'StatusColorPipe'})
export class StatusColorPipe implements PipeTransform {

    statusColors: any;

    constructor() {
        this.statusColors = GeneralService.statusColors;
    }

    transform(status = '', isTopology = false): any {
        switch (status.toLocaleUpperCase().replace(/\s+/g, '.')) {
            case 'ERROR':
            case 'CRITICAL':
            case 'UNAUTHORIZED':
            case 'NOT.CONNECTED':
                return this.statusColors.redStatus;
            case 'CLEAR':
            case 'MANAGED':
            case 'OK':
                return this.statusColors.greenStatus;
            case 'MINOR':
            case 'CONF.MISMATCH':
                return this.statusColors.yellowStatus;
            case 'MAJOR':
                return this.statusColors.orangeStatus;
            case 'WARNING':
                if (isTopology) {
                    return this.statusColors.yellowStatus;
                }
                return this.statusColors.blueStatus;
            case 'INFO':
            case 'UNKNOWN':
            case 'UNMANAGED':
            case 'INDETERMINATE':
            default:
                return this.statusColors.grayStatus;
        }
    }
}
