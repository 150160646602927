import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ConnectionService} from './connection.service';
import {CachedConnection} from './cached-connection.service';
import {SessionService} from '../session.service';
import {AuthorizationService} from '../authorization.service';
import {
    AutoRefreshService,
    CommonNotifiersService,
    GeneralService,
    HashedObjectMap,
    RefreshTableProperties,
    statuses,
    ThrottleClass,
    WSMessage
} from 'ac-infra';

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class WsEntitiesService extends HashedObjectMap {

    static readonly entitiesNamesMapper = {
        tenant: 'tenants',
        region: 'regions',
        device: 'devices',
        link: 'links',
        site: 'sites'
    };

    cacheOptions = {timeToLive: 60000, lockedURLs: {}};
    unhandledWsMessageSubject: Subject<WSMessage> = new Subject<WSMessage>();
    unhandledWsMessage$ = this.unhandledWsMessageSubject.asObservable();
    update$: ThrottleClass;
    filterCurrentlyUpdating = false; // prevent auto refresh from going when filter is processing and gonna update UI anyway.
    filterChangeCounter = 1; // Ensure Older filterUpdates dont get submitted if newer ones arrived.
    private pendingWS: WSMessage[] = [];
    private WSEntitiesUpdateFinishedSubject: Subject<WSMessage> = new Subject();
    WSEntitiesUpdateFinished$: Observable<WSMessage> = this.WSEntitiesUpdateFinishedSubject.asObservable();


    constructor(private generalService: GeneralService,
                private sessionService: SessionService,
                private cachedConnection: CachedConnection,
                private connection: ConnectionService,) {
        super({addEntityType: true});

        this.update$ = new ThrottleClass({
            callback: this.doSilentUpdate,
            destroyComponentOperator: untilDestroyed(this),
            maxRecurrentTime: 5000
        });

        AutoRefreshService.systemRefresh$.pipe(untilDestroyed(this)).subscribe(this.update$.tick);
    }

    clearLockedUrls = () => {
        this.cacheOptions.lockedURLs = {};
    };

    addLockedUrl = (lockedUrl) => {
        this.cacheOptions.lockedURLs[lockedUrl.group] = lockedUrl;

        lockedUrl.url = lockedUrl.wsUrlBuilder();
        if (lockedUrl.wsGeoMapFilteredOut) {
            lockedUrl.filteredUrl = lockedUrl.wsUrlBuilder(undefined, true);
        }
    };

    filteredUpdate = async (updateReason?: 'updateStatus' | 'updateFilteredIds', updatePartial?: 'entities' | 'filteredEntities') => {
        if (!this.cachedConnection.hasUnlockedActiveSession) {
            return;
        }
        const filterChangeCounter = ++this.filterChangeCounter;
        this.filterCurrentlyUpdating = true;
        const promiseArray = [];

        Object.getOwnPropertyNames(this.cacheOptions.lockedURLs).forEach((url) => {
            const lockedUrl = this.cacheOptions.lockedURLs[url];
            lockedUrl.url = lockedUrl.wsUrlBuilder();

            if (!updatePartial || updatePartial === 'entities') {
                promiseArray.push(this.updateEntity(lockedUrl, updateReason));
            }
            if (!updatePartial || updatePartial === 'filteredEntities') {
                promiseArray.push(this.updateFilteredEntity(lockedUrl, updateReason));
            }
        });


        const allPromise = Promise.all(promiseArray);
        allPromise.then((arr) => {
            CommonNotifiersService.entitiesAreReady();
            if (filterChangeCounter === this.filterChangeCounter) {
                this.doSilentUpdate(true, {gotoPage: 1, showLoader: true});
            }
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            this.filterCurrentlyUpdating = false;
        });
        return allPromise;
    };

    executeWSEntitiesUpdateFinished = (message) => {
        this.WSEntitiesUpdateFinishedSubject.next(message);
    };

    processPendingWSRequests = () => {
        this.pendingWS.forEach((message) => {
            this.processWSRequest(message);
        });
        this.pendingWS = [];
    };

    wsMessageArrived = (message: WSMessage) => {
        if (this.generalService.systemInitialized) {
            this.processWSRequest(message);
        } else if (message.messageType !== statuses.FullSync) {
            this.pendingWS.push(message);
        }
    };

    getLockedUrlFromParam = (param, paramName) => {
        const propertyNames = Object.getOwnPropertyNames(this.cacheOptions.lockedURLs);

        for (let i = 0; i < propertyNames.length; i++) {
            const lockedURL = this.cacheOptions.lockedURLs[propertyNames[i]];
            if (lockedURL[paramName] === param) {
                return lockedURL;
            }
        }

        return false;
    }

    updateWSMessageByTenantScope = (message) => {
        if (!AuthorizationService.tenantScope) {
            return message;
        }

        message.entities = message.entities.filter((entity) => entity.tenantId === AuthorizationService.tenantScope);
        message.entitiesIds = message.entities.map((entity) => entity.entityId);

        return message;
    }


    protected deleteEntities(message: WSMessage, lockedUrl: any) {
        if (!message || !lockedUrl) {
            return;
        }
        super.deleteEntities(lockedUrl.group, message.entitiesIds);
        if (lockedUrl.wsGeoMapFilteredOut) {
            this.updateFilteredIds(lockedUrl.group, [], 'id', message.entitiesIds);
        }
        this.update$.tick();
        this.updateWs(message);
    }

    private doSilentUpdate = (finished = false, tableProperties: RefreshTableProperties = {}) => {
        if (finished || !this.filterCurrentlyUpdating) {
            CommonNotifiersService.updateFinishedDataAndFiltered(tableProperties);
        }
    };

    private processWSRequest(message: WSMessage) {
        if (!this.cachedConnection.hasUnlockedActiveSession) {
            return;
        }

        if (message.messageType === statuses.FullSync) {
            this.cachedConnection.clearCache();
            this.filteredUpdate();
            return;
        }

        const lockedUrl = this.getLockedUrlFromParam(message.entityTypeName, 'group');
        if (lockedUrl && [statuses.Create, statuses.Update, statuses.Delete].includes(message.messageType)) {
            this.handleWSAddEditDelete(message, lockedUrl);
        } else {
            this.unhandledWsMessageSubject.next(message);
        }
    }

    private addEditEntities = (message: WSMessage, lockedUrl: any) => {
        const url = lockedUrl.wsUrlBuilder(message.entitiesIds);

        this.connection.get({uri: url}).then((newEntitiesResponse: any) => {
            const newEntities = newEntitiesResponse.data ? newEntitiesResponse.data[message.entityTypeName] : [];

            this.setData(lockedUrl.group, newEntities, 'id');

            if (lockedUrl.wsGeoMapFilteredOut) {
                const filteredUrlWithIds = lockedUrl.wsUrlBuilder(message.entitiesIds, true); // Problem with Topology filters not able to get IDS as AND.. only as OR

                this.connection.get({uri: filteredUrlWithIds}).then((filterNewEntitiesResponse: any) => {
                    const filteredNewEntities = filterNewEntitiesResponse.data ? filterNewEntitiesResponse.data[message.entityTypeName] : [];
                    this.updateFilteredIds(lockedUrl.group, filteredNewEntities, 'id', message.entitiesIds);
                    this.update$.tick();
                    this.updateWs(message);
                });
            } else {
                this.update$.tick();
                this.updateWs(message);
            }
        });
    };

    private handleWSAddEditDelete = (message: WSMessage, lockedUrl: any) => {
        if (message.messageType === statuses.Delete) {
            this.deleteEntities(message, lockedUrl);
        } else {
            this.addEditEntities(message, lockedUrl);
        }
        this.cachedConnection.clearCacheForName(message.entityTypeName);
    };

    private updateWs(message: WSMessage) {
        this.updateWSMessageByTenantScope(message);
        this.executeWSEntitiesUpdateFinished(message);
    }

    private extractData(response: any, group: any) {
        return (response && response.data) ? response.data[group] : [];
    }

    private updateEntity(lockedUrl: any, updateReason: 'updateStatus' | 'updateFilteredIds') {
        let temp_promise = Promise.resolve();
        const fields = (updateReason === 'updateStatus') ? ['status', 'licenseStatus', 'managementStatus', 'vqControlStatus', 'vqMediaStatus', 'voiceQualityStatus'] : undefined;
        if (!updateReason || updateReason === 'updateStatus') {
            temp_promise = this.connection.get({uri: lockedUrl.wsUrlBuilder(undefined, false, fields)}).then((response: any) => {
                this.setData(lockedUrl.group, this.extractData(response, lockedUrl.group), 'id', updateReason === 'updateStatus');
                if (lockedUrl.wsGeoMapFilteredOut && lockedUrl.filteredUrl === null) {
                    this.setFilteredIds(lockedUrl.group, this.getEntitiesArray(lockedUrl.group), 'id');
                }
            });
        }
        return temp_promise;
    }

    private updateFilteredEntity(lockedUrl: any, updateReason: 'updateStatus' | 'updateFilteredIds') {
        let temp_promise = Promise.resolve();
        if (lockedUrl.wsGeoMapFilteredOut && (!updateReason || updateReason === 'updateFilteredIds')) {
            lockedUrl.filteredUrl = lockedUrl.wsUrlBuilder(undefined, true);

            if (lockedUrl.filteredUrl) {
                temp_promise = this.connection.get({
                    uri: lockedUrl.filteredUrl,
                    singletonGroupName: lockedUrl.group
                });
                temp_promise.then((response: any) => {
                    this.setFilteredIds(lockedUrl.group, this.extractData(response, lockedUrl.group), 'id');
                });
            } else {
                this.setFilteredIds(lockedUrl.group, this.getEntitiesArray(lockedUrl.group), 'id');
            }
        }
        return temp_promise;
    }
}
