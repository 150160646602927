import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {DialogConfig, GeneralService, PromiseService, SvgModelName} from 'ac-infra';
import {ReportRestService} from '../services/report-rest.service';
import {ReportEntitiesService} from '../reports/services/report-entities.service';
import {ReportMetric} from '../dialogs/report-dialog/models/report-metric';
import {ReportInfoColumns} from '../dialogs/report-dialog/models/report-info-column';
import {PageService} from '../../common/services/routing/page.service';
import {MetadataService} from '../../metadata/metadata.service';
import {Actions} from '../../common/server-actions/actions';
import {RestResponseSuccess} from '../../common/server-actions/rest';
import {ReportDialogComponent} from '../dialogs/report-dialog/report-dialog.component';
import {CellTemplatesService} from '../../common/utilities/cell-templates.service';
import {Func} from '../../../types/types';
import {AuthorizationService} from '../../common/services/authorization.service';

@Injectable({providedIn: 'root'})
export class ReportActionsService extends Actions {

    reportEmptyObj = require('../../../app/statistics/dialogs/report-dialog/report.empty.json');
    statusColorsList;

    constructor(private reportRestService: ReportRestService,
                private reportEntitiesService: ReportEntitiesService,
                private cellTemplatesService: CellTemplatesService,
                private authorizationService: AuthorizationService,
                private pageService: PageService) {
        super({entityName: 'report', entityService: reportRestService});
        this.statusColorsList = GeneralService.statusColors;
    }

    getStatisticsReports = (operatorType: 'system' | 'tenant', onSuccess?) => {
        const promise = this.reportRestService.get({url: `analytics/statistics/${operatorType}Reports`});
        onSuccess && promise.then((res: RestResponseSuccess) => onSuccess(res.data));
        return promise.then((res: RestResponseSuccess) => res.data);
    };

    getReports = (onSuccess: Func, onFailure?: Func) => {
        const success = (response: RestResponseSuccess) => {
            const reportsList = response && response.data && response.data.reports || [];
            this.reportEntitiesService.setData('reports', reportsList, 'id');
            onSuccess(reportsList);
        };

        this.reportRestService.get({extendParameters: true}).then(success, onFailure);
    };

    getSingleReport = (onSuccess, id) => {
        const success = (response: RestResponseSuccess) => {
            onSuccess(response);
        };
        this.reportRestService.getById({success, failure: () => {}, id});
    };

    deleteReport = (selectedReports) => this.delete({entityArray: selectedReports, isDeleteSingle: true});

    duplicateReport = (selectedReport) => this.openReportDialog(selectedReport, false, true);

    runReport = (selectedReport) => {
        const onSuccess = (response) => this.pageService.openReportResultPage({
            id: response.data.resultId,
            name: selectedReport.name
        });
        this.reportRestService.executeReport(onSuccess, () => {}, selectedReport.id, AuthorizationService.tenantScope);
    };

    openReportDialog = (report = undefined, isViewOnly?,isDuplicate = false) => {
        const isEdit = report !== undefined && !isDuplicate;

        const id = isEdit && !isDuplicate ? report.id : undefined;

        const reportToSend = isEdit || isDuplicate ? _.cloneDeep(report) : _.cloneDeep(this.reportEmptyObj);

        if (isDuplicate) {
            delete reportToSend.id;
            delete reportToSend.name;
            reportToSend.isPredefined = false;
        }

        const onSuccessCallback = (response) => {
            dialogData.entity.id = id ? id : response.data.id;
            this.reportEntitiesService.executeUpdateSelectedCategory({report: dialogData.entity, doRefresh: true});
        };

        const dialogData = {entity: reportToSend, isViewOnly, isEdit, isDuplicate, onSuccessCallback};
        const serverCallback = (onSuccess, onFailure, dialogConfigRef: DialogConfig) => {
            const reportFromDialog = dialogConfigRef.dialogData.entity;

            if (isEdit) {
                this.reportRestService.edit(onSuccess, onFailure, reportFromDialog, id);
            } else {
                this.reportRestService.add(onSuccess, onFailure, reportFromDialog);
            }
        };

        this.genericAction({
            dialogComponentType: ReportDialogComponent,
            serverCallback,
            dialogData,
        });
    };

    getColorFromRestName = (restName) => {
        const restNameToCheck = restName.toLowerCase();
        if (restNameToCheck.indexOf('yellow') > -1 || restNameToCheck.indexOf('fair') > -1) {
            return this.statusColorsList.yellowStatus;
        } else if (restNameToCheck.indexOf('green') > -1 || restNameToCheck.indexOf('good') > -1 || restNameToCheck.indexOf('successful') > -1) {
            return this.statusColorsList.greenStatus;
        } else if (restNameToCheck.indexOf('grey') > -1 || restNameToCheck.indexOf('unknown') > -1) {
            return this.statusColorsList.grayStatus;
        } else if (restNameToCheck.indexOf('red') > -1 || restNameToCheck.indexOf('poor') > -1 || restNameToCheck.indexOf('failed') > -1) {
            return this.statusColorsList.redStatus;
        }

        return this.statusColorsList.grayStatus;
    };

    initMetrics = () => {
        const defer = PromiseService.defer();

        const onSuccess = (response: RestResponseSuccess) => {
            const metrics = [];
            const metricRestNames = {};
            response.data.metrics.forEach((metric) => {
                metric.color = this.getColorFromRestName(metric.restName);
                metricRestNames[metric.restName] = metric;
                metricRestNames['Metric' + metric.id] = metric;

                const assignedMetric: ReportMetric = new ReportMetric(metric);
                metrics.push(assignedMetric);
            });

            MetadataService.setType('reportColumnsRestNames', metricRestNames);
            this.reportEntitiesService.setData('ReportMetric', _.cloneDeep(metrics), 'columnBaseId');
            this.generalService.finishedLoading('metrics');
            defer.resolve();
        };
        const onFailure = () => defer.reject();
        this.reportRestService.getMetrics(onSuccess, onFailure);

        return defer.promise;
    };

    initInfoColumns = () => {
        const defer = PromiseService.defer();

        const onSuccess = (response: RestResponseSuccess) => {
            const infoColumns = [];
            const infoColumnsRestNames = {};
            response.data.infoColumns.forEach((infoColumn) => {
                infoColumn.color = this.getColorFromRestName(infoColumn.restName);
                infoColumnsRestNames[infoColumn.restName] = infoColumn;
                infoColumnsRestNames[infoColumn.columnType + infoColumn.id] = infoColumn;

                const assignedColumnInfo: ReportInfoColumns = new ReportInfoColumns(infoColumn);
                infoColumns.push(assignedColumnInfo);
            });

            MetadataService.setType('reportColumnsRestNames', infoColumnsRestNames);
            this.reportEntitiesService.setData('ReportInfoColumn', _.cloneDeep(infoColumns), 'columnBaseId');
            this.generalService.finishedLoading('infoColumns');
            defer.resolve();
        };
        const onFailure = () => defer.reject();
        this.reportRestService.getInfoColumns(onSuccess, onFailure);

        return defer.promise;
    };

    handlePrivateTableCell = (cell) => {
        const rowValue = cell.getValue();
        const rowData = cell.getRow();

        const svgName = rowData.isPrivate ? SvgModelName.Private : SvgModelName.Public;

        return this.cellTemplatesService.newSvgTemplate(svgName, rowValue, {
            fillColor: rowValue === 'Private Own Report' ? this.statusColorsList.greenStatus : this.statusColorsList.primary,
            height: '18px',
            width: '18px',
        });
    };

    isPrivateOwn = (report) => {
        return report?.isPrivate && this.authorizationService.isSameUserConnected(report.ownerUserName, report.ownerAuthType);
    }
}
