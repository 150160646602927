import {Component} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {animate, group, query, style, transition, trigger} from '@angular/animations';
import {DateTime} from 'luxon';
import {InfoPanelService} from './info-panel.service';
import {GeneralService} from 'ac-infra';
import {Widget} from '../../../common/widget/widget.modals';

@UntilDestroy()
@Component({
    selector: 'info-panel',
    templateUrl: './info-panel.component.html',
    styleUrls: ['./info-panel.component.less'],
    animations: [
        trigger('slider', [
            transition(':increment', group([
                query(':enter', [
                    style({left: '100%', opacity: '0'}),
                    animate('0.5s ease-out')
                ]),
                query(':leave', [
                    animate('0.5s ease-out'),
                    style({left: '-100%', opacity: '0'})
                ])
            ])),
            transition(':decrement', group([
                query(':enter', [
                    style({left: '-100%', opacity: '0'}),
                    animate('0.5s ease-out')
                ]),
                query(':leave', [
                    animate('0.5s ease-out'),
                    style({left: '100%', opacity: '0'})
                ])
            ])),
        ])
    ]
})
export class InfoPanelComponent extends Widget {
    statusColors;
    releaseHighlights = [];
    infoPanel: any = {};

    constructor(private infoPanelService: InfoPanelService) {
        super();
        this.statusColors = GeneralService.statusColors;
    }

    ngOnInit() {
        this.infoPanel = this.infoPanelService.getSavedInfoPanel();
        this.releaseHighlights = this.infoPanelService.releaseHighlights;
    }

    toggle() {
        this.infoPanel.isOpen = !this.infoPanel.isOpen;
        this.infoPanelService.saveInfoPanel(this.infoPanel);
    }

    isNewUpdate(date) {
        return DateTime.fromFormat(date, 'd/M/yyyy') >= DateTime.now().minus({month: 1});
    }

    next() {
        if (this.infoPanel.currentReleaseHighlight < (this.releaseHighlights.length - 1)) {
            this.infoPanel.currentReleaseHighlight++;
            this.infoPanelService.saveInfoPanel(this.infoPanel);
        }
    }

    prev() {
        if (this.infoPanel.currentReleaseHighlight > 0) {
            this.infoPanel.currentReleaseHighlight--;
            this.infoPanelService.saveInfoPanel(this.infoPanel);
        }
    }

    getWidgetConfigData(...args: any[]) {
        return;
    }
}




