import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'numberToK'})
export class numberToKPipe implements PipeTransform {

    transform(input: any, args?: any): any {
        const suffixes = ['K', 'M', 'G', 'T', 'P', 'E'];
        let exp;

        if (Number.isNaN(input)) {
            return null;
        }
        if (input < 1000) {
            return input;
        }

        exp = Math.floor(Math.log(input) / Math.log(1000));
        return '<span title="' + input + '" >' + (input / Math.pow(1000, exp)).toFixed(args) + suffixes[exp - 1] + '</span>';
    }
}
