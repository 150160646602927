import {ChangeDetectionStrategy, Component, Input, SkipSelf,} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {WidgetLayoutComponent} from '../widget-layout/widget-layout.component';
import {WidgetItem, WidgetTemplates} from '../../widget.modals';
import {WidgetService} from '../../services/widget.service';

@UntilDestroy()
@Component({
    selector: 'widget-item',
    templateUrl: './widget-item.component.html',
    styleUrls: ['./widget-item.component.less'],
})
export class WidgetItemComponent {

    @Input() editMode = false;
    @Input() templates: WidgetTemplates;
    @Input() widgetItem: WidgetItem;

    constructor(public widgetService: WidgetService,
                @SkipSelf() private widgetLayoutComponent: WidgetLayoutComponent
    ) {
    }

}

