<div class="ac-toast-container" [style.top]="topOffsetStyle" [class.rtl]="generalService.rtl">
    <div class="ac-toast-container-inner">
        <div (mouseenter)="enter(toast)"
             (mouseleave)="leave(toast)"
             (mouseup)="mouseUp($event, toast)"
             *ngFor="let toast of toasts"
             @toasts
             class="toast">

            <ng-template [ngTemplateOutlet]="toastContentTemplate" [ngTemplateOutletContext]="{$implicit: toast}"></ng-template>

            <ac-list-item *ngIf="!toastContentTemplate"
                          [header]="toast.title"
                          [content]="toast.content">

                <ac-svg [fillColor]="toast.status?.color"
                        [name]="toast.type"
                        icon
                ></ac-svg>
            </ac-list-item>


            <span (click)="close(toast)" aria-label="Close" class="close-button" data-dismiss="window" type="button">×</span>

            <div [style.background-color]="toast.status?.color" class="coloredRow"></div>
        </div>
    </div>
</div>
