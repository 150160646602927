import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats} from '@angular/material/core';
import {MatCalendar} from '@angular/material/datepicker';
import {Subject, takeUntil} from 'rxjs';

@Component({
    selector: 'example-header',
    styleUrls: ['./ac-date-picker-header.component.less'],
    templateUrl: './ac-date-picker-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcDatePickerHeaderComponent<D> implements OnDestroy {
    private _destroyed = new Subject<void>();

    constructor(
        public calendar: MatCalendar<D>,
        public dateAdapter: DateAdapter<D>,
        @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
        cdr: ChangeDetectorRef,
    ) {
        calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => cdr.markForCheck());
    }

    ngOnDestroy() {
        this._destroyed.next();
        this._destroyed.complete();
    }

    periodLabel = () => {
        return this.dateAdapter
            .format(this.calendar.activeDate, this._dateFormats.display.monthYearLabel)
            .toLocaleUpperCase();
    }

    previousClicked(mode: 'month' | 'year') {
        this.calendar.activeDate =
            mode === 'month'
                ? this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1)
                : this.dateAdapter.addCalendarYears(this.calendar.activeDate, -1);
    }

    nextClicked(mode: 'month' | 'year') {
        this.calendar.activeDate =
            mode === 'month'
                ? this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1)
                : this.dateAdapter.addCalendarYears(this.calendar.activeDate, 1);
    }
}
