import {Inject, Injectable, Optional} from '@angular/core';
import {Observable} from 'rxjs';
import {Select} from '@ngxs/store';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {cloneDeep, pickBy} from 'lodash';
import {AutoRefreshService, CommonNotifiersService, DIALOG_CONFIG, DialogConfig} from 'ac-infra';
import {DashboardService} from '../../../services/dashboard.service';
import {GroupsRestService} from '../../../../network/services/apis/groups-rest.service';
import {ActiveDirectoriesUsersLocationsRestService} from '../../../../users/apis/active-directories-user-locations.service';
import {DevicesRestService} from '../../../../network/services/apis/devices-rest.service';
import {ChannelsRestService} from '../../../../network/services/apis/channels-rest.service';
import {GroupsDashboardSummary} from './groups-dashboard-summary';
import {RestResponseSuccess} from '../../../../common/server-actions/rest';
import {LinksRestService} from '../../../../network/services/apis/links-rest.service';
import {SitesRestService} from '../../../../network/services/apis/sites-rest.service';
import {TenantsRestService} from '../../../../network/services/apis/tenants-rest.service';
import {NetworkDashboardSummary} from './network-dashboard-summary';
import {EndpointsRestService} from '../../../../network/services/apis/endpoints-rest.service';
import {ExternalApplicationState} from '../../../../common/state/external-application.state';
import {ExternalApplicationsActionsService} from '../../../../system/configuration/configuration-actions/external-applications-actions.service';
import {DashboardEntity, NetworkDashboardEntity, OnyLiveDashboardEntity} from '../entities-summary.modals';
import {AuthorizationService} from '../../../../common/services/authorization.service';
import {WsEntitiesService} from '../../../../common/services/communication/ws-entities.service';

@UntilDestroy()
@Injectable()
export class EntitiesSummaryService {

    @Select(ExternalApplicationState.application('zoompeering')) zoom$: Observable<any>;
    summaryEntities: { [key: string]: DashboardEntity };
    attachedCustomer;
    networkDashboardSummaryService = new NetworkDashboardSummary();
    groupsDashboardSummaryService = new GroupsDashboardSummary();

    constructor(private dashboardService: DashboardService,
                private wsEntitiesService: WsEntitiesService,
                private tenantsRestService: TenantsRestService,
                private devicesRestService: DevicesRestService,
                private linksRestService: LinksRestService,
                private sitesService: SitesRestService,
                private endpointsRestService: EndpointsRestService,
                private channelsRestService: ChannelsRestService,
                private groupsRestService: GroupsRestService,
                private authorizationService: AuthorizationService,
                private externalApplicationsActionsService: ExternalApplicationsActionsService,
                private activeDirectoriesUsersLocationsRestService: ActiveDirectoriesUsersLocationsRestService,
                @Optional() @Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig,
    ) {
        this.summaryEntities = this.dashboardService.isOneLiveGroupsView ? cloneDeep(this.groupsDashboardSummaryService.model) : cloneDeep(this.networkDashboardSummaryService.model);

        if (this.authorizationService.validForEndCustomer()) {
            const customers = this.groupsRestService.getAllEntities(undefined, false, 'customers');
            if (customers && customers.length === 1 && this.summaryEntities) {
                this.summaryEntities.endCustomer.title = customers[0].fullName || customers[0].name || 'Customer';
                this.attachedCustomer = customers[0];
            }
        } else if (this.authorizationService.validForServiceProvider()) {
            const tenants = this.tenantsRestService.getAllEntities();
            const spTenant = tenants.length === 1 ? tenants[0] : undefined;

            const zoomEnabled = spTenant?.zoomEnabled || !!this.dialogConfig;

            this.summaryEntities.ocQualifyLeads.count = [spTenant?.ocNewLeadsCount || 0, spTenant?.ocActiveLeadsCount || 0];
            this.summaryEntities.ocNumberManagement.state = spTenant ? 'network/customers' : undefined;
            this.summaryEntities.ocQualifyLeads.state = spTenant ? 'network/customers' : undefined;

            this.summaryEntities.zoomQualifyLeads.count = [spTenant?.zoomNewLeadsCount || 0, spTenant?.zoomActiveLeadsCount || 0];
            this.summaryEntities.zoomQualifyLeads.state = spTenant ? 'network/customers' : undefined;

            if (this.authorizationService.validForServiceProvider() && zoomEnabled) {
                this.zoom$.pipe(untilDestroyed(this)).subscribe({
                    next: (zoom) => {
                        if (zoom && this.externalApplicationsActionsService.isNotValid(zoom)) {
                            this.summaryEntities.zoomQualifyLeads.icon = 'ERROR-ZOOM';
                            this.summaryEntities.zoomQualifyLeads.tooltip = 'Value might be incorrect since zoom app is unreachable';
                        }

                        (this.summaryEntities.zoomQualifyLeads as OnyLiveDashboardEntity).externalApplications = zoom;
                    }
                });
            }
        }

        this.getEntitiesData();
        CommonNotifiersService.finishedDataAndFilteredUpdate$.pipe(untilDestroyed(this)).subscribe({next: this.getEntitiesData});

        if (!this.dashboardService.isOneLiveGroupsView) {
            this.getEndpoints();
            AutoRefreshService.systemRefresh$.pipe(untilDestroyed(this)).subscribe(() => {
                this.getEndpoints();
            });
        }
    }

    getEndpoints = () => {
        const parameters = {page: 1, limit: 25, detail: 1, fields: ['id']};
        const success = (response: RestResponseSuccess) => {
            this.summaryEntities.endpoints.count = response.data?.pages?.totalElements || 0;
        };
        this.endpointsRestService.getFilteredEndpoints({
            success,
            failure: () => {
                this.summaryEntities.endpoints.count = 0;
            },
            parameters,
            skipPopulateFilter: true
        });
    };


    getAuthorizedEntities = (entities) => {
        if (!this.dashboardService.isOneLiveGroupsView) {
            return entities;
        }

        return pickBy(entities, (entity) => {
            return this.isEntityAuthorized(entity);
        });
    };

    isEntityAuthorized = (entity) => {
        if (!entity.validFor) {
            return true;
        }

        return entity.validFor.reduce((acc, validForFunction) => {
            return acc || validForFunction();
        }, false);
    };

    getEntitiesData = () => {
        if (this.dashboardService.isOneLiveGroupsView) {
            this.getCustomerEntitiesData();
        } else {
            this.getNetworkEntitiesData();
        }
    };

    getNetworkEntitiesData = () => {
        this.handleDevicesCounts();
        this.summaryEntities.links.count = this.linksRestService.getAllEntities().length;
        this.summaryEntities.sites.count = this.sitesService.getAllEntities().length;
    };

    getCustomerEntitiesData = () => {
        const customers: any[] = this.groupsRestService.getAllEntities(undefined, false, 'customers');

        this.summaryEntities.users.count = 0;
        this.summaryEntities.userLocation.count = 0;

        this.summaryEntities.customers.count = customers.length;

        let totalDIDs = 0;
        let usedDIDs = 0;
        let freeDIDs = 0;
        let totalDMEndpoints = 0;
        let disconnectedDMEndpoints = 0;
        customers.forEach((customer) => {
            this.summaryEntities.users.count += customer.usersCount || 0;
            totalDIDs += customer.totalDIDs || 0;
            usedDIDs += customer.usedDIDs || 0;
            freeDIDs += customer.freeDIDs || 0;

            if (customer?.customerSource === 'DM') {
                totalDMEndpoints += customer.deviceManagerParams?.devicesCount?.personal + customer.deviceManagerParams?.devicesCount?.meeting;
                disconnectedDMEndpoints += customer.deviceManagerParams?.disconnectedDevices?.personal + customer.deviceManagerParams?.disconnectedDevices?.meeting;
            }
        });

        this.summaryEntities.DIDs.count = [totalDIDs, usedDIDs, freeDIDs];

        this.summaryEntities.deviceManagerEndpoints.state = this.dashboardService.isOneLiveGroupsView ? 'network/customers' : undefined;
        this.summaryEntities.deviceManagerEndpoints.count = [disconnectedDMEndpoints, totalDMEndpoints];

        const channels: any[] = this.channelsRestService.getAllEntities();
        this.summaryEntities.channels.count = channels.length;

        this.activeDirectoriesUsersLocationsRestService.getUnique().then((location: string[]) => {
            this.summaryEntities.userLocation.count = location.length || 0;
        });

    };

    handleDevicesCounts = () => {
        const familyTypeToEntityMap = Object.values(this.summaryEntities).reduce((acc, summaryEntity: NetworkDashboardEntity) => {
            if (!summaryEntity.preventCountReset) {
                summaryEntity.count = 0;
            }
            summaryEntity?.familyType?.forEach(familyType => {
                acc[familyType] = summaryEntity;
            });
            return acc;
        }, {});

        const devicesFromWS = this.devicesRestService.getAllEntities();

        devicesFromWS.forEach((device) => {
            if (familyTypeToEntityMap[device.familyType]) {
                familyTypeToEntityMap[device.familyType].count++;
            }
        });
    };
}
