import {Component, HostBinding, Input} from '@angular/core';

@Component({
    selector: 'ac-range',
    templateUrl: './ac-range.component.html',
    styleUrls: ['./ac-range.component.less'],

})

export class AcRangeComponent {

    @HostBinding('class.flexRowParent') flexRow = true;
    @Input() @HostBinding('class.small-view') smallTemplate = false;

    @Input() fromRange: string | number = 0;
    @Input() toRange: string | number = 0;

    @Input() hasIconPlaceholder = true;
    @Input() iconType = 'bell';
    @Input() hasIcon = false;
    @Input() type: string;
}



