import {Component, Optional} from '@angular/core';
import {AcFormComponent} from '../ac-form.component';

@Component({
    selector: 'child-forms-error',
    templateUrl: './child-forms-error.component.html',
})

export class ChildFormsErrorComponent {
    private errors = {};
    hasErrors = false;
    cycle = 0

    constructor(@Optional() public formHost: AcFormComponent) {
    }

    setError = (cycle, key, value) => {
        if(cycle > this.cycle){
            this.errors = {};
            this.cycle = cycle;
        }

        this.errors[key] = value;
        this.hasErrors = Object.values(this.errors).some(error => error);

        // console.log(cycle, key, value, 'hasErrors -> ', this.hasErrors);
    }
}
