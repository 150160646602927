import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'initialism'})
export class InitialismPipe implements PipeTransform {

    initialisms = {
        QOE: 'QOE',
        CLM: 'CLM',
        OK: 'OK',
        AD: 'AD',
        WIFI: 'WIFI',
        MOS: 'MOS',
        IP: 'IP',
        MAC: 'MAC',
        DNS: 'DNS',
        CPU: 'CPU',
    };

    constructor() {
    }

    transform(value: string): string {
        return value.split(' ').map((word) => this.initialisms[word.toUpperCase()] || word).join(' ');
    }
}
