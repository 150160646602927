<div class="ac-geo-location-picker-container flexRowParent">
    <ac-textfield class="flexItemBiggest"
                  [(acModel)]="addressValue" [attr.title]="addressValue"
                  [placeholderText]="isLocationReady?null:loadingText"
                  [ngDisabled]="(!isLocationReady || isDisabled || ngDisabled) ? true : null"
                  (acModelChange)="geoLocation.next($event)"
                  (keyup)="keyPressed($event)" >
    </ac-textfield>

    <ul *ngIf="locations&&locations.length>0" class="location-list">
        <li (click)="locationSelected(i)"
            *ngFor="let location of locations; let i=index"
            [class.active]="isActive(i)"
            class="location-item">
            {{ location.display_name }}
        </li>
    </ul>
</div>
