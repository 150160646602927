import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation} from '@angular/core';
import {GeneralService} from "../../../services/general.service";

@Component({
    selector: 'ac-nav-button',
    templateUrl: './ac-nav-button.component.html',
    styleUrls: ['./ac-nav-button.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AcNavButtonComponent{

    @HostBinding('attr.dropdown') @Input() dropdown = false;
    @Input() dynamic = false;
    @Input() icon = '';
    @Output() close = new EventEmitter();
    @Input() isLoading = false;
    @Input() wrapContent = false;
    @Input() closeButton = false;
    @Input() parentClass = '';

    systemColors = GeneralService.statusColors;

    onClose = () => {
        this.close.emit();
    };
}
