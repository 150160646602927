<ac-date-picker-container [disabled]="disabled" (onToggleClick)="openPicker()" (close)="refreshRef()">

    <mat-date-range-input class="range-picker-input flexItemBiggest"
                          [disabled]="disabled"
                          picker-input>
        <input matStartDate class="date-range-input" [value]="_dateRange.start" readonly>
        <input matEndDate  class="date-range-input" [value]="_dateRange.end" readonly>
    </mat-date-range-input>

    <ac-range-calendar [selectedRange]="_dateRange"
                       (cancel)="onCancel()"
                       (apply)="onApply($event)"
                       picker-calendar>
    </ac-range-calendar>
</ac-date-picker-container>

