import {Injectable} from '@angular/core';

import * as _ from 'lodash';

import {AcDialogService, DynamicTabsService} from 'ac-infra';
import {InitialismPipe} from '../../common/pipes/initialism.pipe';

@Injectable({providedIn: 'root'})
export class DynamicTabDetailsService {

    constructor(private dynamicTabsService: DynamicTabsService,
                private acDialogService: AcDialogService,
                private initialismPipe: InitialismPipe) {
    }

    openErrorDialog(entityId: number | string, entityType: string, error?) {
        const failureMsg = this.getEntityTypeFailMessage(entityType);

        this.openFailDialog(entityId, entityType, failureMsg);
    }

    openFailDialog(entityId: number | string, entityType: string, message?: string) {
        const failureMsg = message || this.getEntityTypeFailMessage(entityType);

        this.acDialogService.fail(failureMsg, {
            onClose: () => {
                const tab = this.dynamicTabsService.getByUrl(`/${entityType}Details/` + entityId);
                tab && this.dynamicTabsService.removeTab(tab, true);
            }
        });
    }

    getEntityTypeFailMessage = (entityType: string): string => {
        return `${this.initialismPipe.transform(_.startCase(_.toLower(entityType)))} was removed from OVOC.`;
    };
}
