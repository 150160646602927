import {Component, Input, TemplateRef} from '@angular/core';


@Component({
    selector: 'ac-list-item',
    templateUrl: './ac-list-item.component.html',
    styleUrls: ['./ac-list-item.component.less']
})
export class AcListItemComponent {

    @Input() subContentTemplate: TemplateRef<any>;

    @Input() header = '';
    @Input() content = '';
}



